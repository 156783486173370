import { PdfUploadedTable, BsHeaderTitle, BsResourceManagementPageHeaderTab } from 'components';
import React from 'react';
import { UiBsContentContainer } from 'styles';


/**
 * 教學資源列表
 */

export const PdfUploadPage = () => {
  return (
    <>
      <BsHeaderTitle title="資源列表" />
      <BsResourceManagementPageHeaderTab activeIndex={0} />
      <UiBsContentContainer>
        <PdfUploadedTable />
      </UiBsContentContainer>
    </>
  );
};



