import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { UiTitle, UiHint } from './HomeTitle.style';

export const HomeTitle = ({ title, controlMessage = '', hint = '' }) => {
  const { pathname } = useLocation();
  const homeOnLineExamRef = useRef();

  useEffect(() => {
    if (!homeOnLineExamRef.current) return;
    pathname && homeOnLineExamRef.current.focus();
  }, [homeOnLineExamRef, pathname]);

  return (
    <div>
      <UiTitle tabIndex={0} ref={homeOnLineExamRef} aria-label={controlMessage}>{title}</UiTitle>
      <UiHint>{hint}</UiHint>
    </div>
  );
};

HomeTitle.propTypes = {
  title: PropTypes.string,
  controlMessage: PropTypes.string,
  hint: PropTypes.string
};
