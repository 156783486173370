import { SingleLayout, UpgradeAccount } from 'components';
import React from 'react';


/**
 * 升級專業版頁面
 */

export const UpgradeAccountPage = () => {
  return (
    <SingleLayout>
      <UpgradeAccount />
    </SingleLayout>
  );
};
