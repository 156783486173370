import { FORGETPASSWORD } from 'constants/index';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button as MuiButton,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import oneClassLabelLogoSvg from 'assets/svg/one-class-label-logo.svg';
import { Button, OneClassLogo } from 'components';
import { setOnePaperLoginCookieByServiceRoot } from 'helper/onePaper';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { getMoeLoginUrl } from 'services/api/login/login';
import { useUser } from 'store/user';
import * as z from 'zod';
import { UiCountyLoginForm, UiMain, UiForm } from './CountyLoginForm.style';

const COUNTY_LOGO_URL = process.env.REACT_APP_LOGO;
const VALIDATION_SCHEMA = z.object({
  username: z.string().min(1, { message: '未填帳號' }),
  password: z
    .string()
    .min(8, { message: '密碼為 8 ~ 12 碼' })
    .max(12, { message: '密碼為 8 ~ 12 碼' }),
});

const Logos = () => {
  return (
    <Box className="logos-box">
      <OneClassLogo isShowLabel={false} logoSrc={oneClassLabelLogoSvg} />
      <Typography className="divider">×</Typography>
      <Box className="county-logo-box">
        <img src={COUNTY_LOGO_URL || oneClassLabelLogoSvg} alt="" />
      </Box>
    </Box>
  );
};

/**
 * 縣市登入表單
 */
export const CountyLoginForm = () => {
  const theme = useTheme();
  const [, { userLogin }] = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(VALIDATION_SCHEMA),
    defaultValues: {
      username: localStorage.getItem('username') || '',
      password: '',
      isRememberMe: !!localStorage.getItem('username') || false,
    },
  });

  /* 一搬登入 */
  const onLogin = async (formData) => {
    setIsLoading(true);
    const loginPayload = {
      username: formData.username,
      password: formData.password,
    };
    await userLogin(loginPayload);
    if (formData.isRememberMe) {
      localStorage.setItem('username', formData.username);
    } else {
      localStorage.removeItem('username');
    }
    setIsLoading(false);
  };

  /* 教育雲登入 */
  const cloudLogin = () => {
    setOnePaperLoginCookieByServiceRoot();
    getMoeLoginUrl();
  };

  return (
    <UiCountyLoginForm>
      <UiMain>
        <Logos />
        <UiForm onSubmit={handleSubmit(onLogin)}>
          <Box className="inputs-box">
            <FormControlLabel
              label="帳號 Account"
              labelPlacement="top"
              control={
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!errors?.username}
                      helperText={errors?.username?.message}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              }
            />

            <FormControlLabel
              label="密碼 Password"
              labelPlacement="top"
              control={
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!errors?.password}
                      helperText={errors?.password?.message}
                      variant="outlined"
                      size="small"
                      type={isShowPassword ? 'text' : 'password'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <MuiButton
                            style={{
                              minWidth: 'fit-content',
                              textDecoration: 'underline',
                              color: theme.palette.btn.normal,
                              padding: 'unset',
                            }}
                            onClick={() => setIsShowPassword((prev) => !prev)}
                          >
                            {isShowPassword ? '隱藏' : '顯示'}
                          </MuiButton>
                        ),
                      }}
                    />
                  )}
                />
              }
            />
          </Box>
          <Box className="remember-me-and-forget-password-box">
            <FormControlLabel
              label="記住我"
              control={
                <Controller
                  name="isRememberMe"
                  control={control}
                  render={({ field: { value, ...prop } }) => <Checkbox {...prop} checked={value} />}
                />
              }
            ></FormControlLabel>
            <NavLink to={`/${FORGETPASSWORD}`}>忘記密碼</NavLink>
          </Box>
          <Box className="buttons-box">
            <Button loading={isLoading} type="submit">
              登入
            </Button>
            <Button className="cloud-login-button" iconSvg="CloudLogin" onClick={cloudLogin}>
              教育雲登入
            </Button>
          </Box>
        </UiForm>
      </UiMain>
    </UiCountyLoginForm>
  );
};
