import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const UiOneClassLogo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '120px',

  '.logo-img': {
    width: '84px',
  },

  '.logo-label': {
    color: '#3A4052',
    fontFamily: 'Poppins',
    fontSize: '25.014px',
    fontWeight: 500,
  },
}));
