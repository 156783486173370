import PropTypes from 'prop-types';
import React from 'react';
import { UiEmptyPrompt, UiTitle, UiMessage } from './EmptyPrompt.style';


/**
 * 空狀態提示
 */

export const EmptyPrompt = ({ img, title = '', message = '', titleColor, messageColor }) => {
  return (
    <UiEmptyPrompt>
      <div>
        {img && <img src={img} alt='emptyImage' />}
        {title && <UiTitle titleColor={titleColor}>{title}</UiTitle>}
        {message && <UiMessage messageColor={messageColor}>{message}</UiMessage>}
      </div>
    </UiEmptyPrompt>);
};

EmptyPrompt.propTypes = {
  img: PropTypes.node,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  messageColor: PropTypes.string
};


