import { LoginForm, Header } from 'components';
import React from 'react';

import { UiLoginBox } from './LoginPage.style';


/**
 * 在這邊描述這個組件
 */

export const LoginPage = () => {
  return (
    <UiLoginBox>
      <Header />
      <LoginForm />
    </UiLoginBox>
  );
};

