import { Header, Footer, SideBar, HomeBanner } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  UiHomeOrganizationLayout,
  UiHomeGroupContent,
  UiChildrenContainer
} from './HomeOrganizationLayout.style';


/**
 * 非獨立班級組織頁
 */

export const HomeOrganizationLayout = ({ children, showSideBar = true }) => {
  const { organizationId } = useParams();

  const data =
    [
      {
        isPublic: true,
        link: '/home',
        label: '返回列表',
        icon: 'exitToApp',
      },
      {
        isPublic: true,
        link: `/home/${organizationId}/announcement`,
        label: '學校公告',
        icon: 'exitToApp',
      }
    ];

  return (<UiHomeOrganizationLayout>
    <Header />
    <HomeBanner showSideBar={showSideBar} isGroupsOrganization={true} />
    <UiHomeGroupContent showSideBar>
      <SideBar sideBarData={data} />
      <UiChildrenContainer>{children}</UiChildrenContainer>
    </UiHomeGroupContent>
    <Footer />
  </UiHomeOrganizationLayout>);
};

HomeOrganizationLayout.propTypes = {
  children: PropTypes.node,
};


