import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const UiHomeMemberChangePassword = styled.div`
	display: block;
`;
export const UiHomeMemberChangePasswordCard = styled.div`
	display: block;
	margin: auto;
	width: 75%;
	text-align: center;

	& .MuiOutlinedInput-root {
		background-color: #fff;
	}
`;

export const UiForm = styled.div`
	display: block;
	padding: 1.5rem;
`;

export const UiButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;

	& > button {
		margin: 0.5rem 0;
	}

	& > button + button {
		margin: 0.5rem 0.5rem 0.5rem;
	}
`;

export const MatTextField = styled(TextField)`
	margin: 1.5rem 0;
`;

export const UiHelperText = styled.div`
	& p {
		color: #f44336;
	}
`;
