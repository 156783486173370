import { SessionManagementTable, BsHeaderTitle } from 'components';
import React from 'react';
import { UiBsContentContainer } from 'styles';



/**
 * 課程管理列表
 */

export const SessionManagementPage = () => {
  return (
    <>
      <BsHeaderTitle title={'課程總覽'} />
      <UiBsContentContainer>
        <SessionManagementTable />
      </UiBsContentContainer>
    </>
  );
};

SessionManagementPage.propTypes = {

};


