import { HomeGroupLayout, InviteFreeGroupUserForm } from 'components';
import React from 'react';


/**
 * free group 邀請成員頁面
 */

export const InviteFreeGroupUserPage = () => {
  return (
    <HomeGroupLayout>
      <InviteFreeGroupUserForm />
    </HomeGroupLayout>);
};

