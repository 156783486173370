import LaunchIcon from '@material-ui/icons/Launch';
import {
    Icon
} from 'components';
import SVG from 'components/molecules/Icon/svg';
import React from 'react';

import {
    UiSuggestionContainer,
    UiSuggestionHeader,
    UiSuggestionTitle,
    UiSuggestionContent,
    UiSuggestionChromeLink,
    UiInstallButton,
    UiChromeIcon,
    UiInstallApkGroup
} from './BrowserSuggestion.style';


const CHROME_APK_LINK = 'https://storage.googleapis.com/onelink-demo-frontend-dev/Chrome_113.0.5672.163_apkcombo.com.apk';

export const BrowserSuggestion = () => {
    const goToChrome = () => {
        window.location.href = 'https://www.google.com/intl/zh-TW/chrome';
    };
    return (
        <>
            <UiSuggestionContainer>
                <UiSuggestionHeader>
                    <UiChromeIcon
                        component={SVG['ChromeIcon']}
                    />

                    <UiSuggestionTitle>
                        瀏覽器下載
                    </UiSuggestionTitle>
                </UiSuggestionHeader>
                <UiSuggestionContent>
                    為了讓您獲得最佳瀏覽體驗，建議您使用的瀏覽器軟體為：Google Chrome 或 Microsoft Edge。
                    <br />
                    如使用其他瀏覽器瀏覽本網站，可能導致某些網頁互動功能無法正確顯示。
                    <UiSuggestionChromeLink>
                        以下提供 Google Chrome 瀏覽器下載連結：
                    </UiSuggestionChromeLink>

                </UiSuggestionContent >
                <UiInstallButton
                    onClick={goToChrome}
                >
                    前往下載
                    <LaunchIcon />
                </UiInstallButton>

                <UiInstallApkGroup>
                    <div className='apkGroup__info'>
                        若無法透過上方連結下載，請點此下載安裝檔（.apk）
                    </div>
                    <a className="apkGroup__button" href={CHROME_APK_LINK} download>
                        下載安裝檔
                        <Icon.Svg name="DownloadBtn" color="#FF7257" />
                    </a>
                </UiInstallApkGroup>
            </UiSuggestionContainer >
        </>

    );
};