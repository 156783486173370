import { ROLES } from 'constants/index';

import commonRoutes from 'routes/routesConfig/commonRoutes';
import homeRoutes from 'routes/routesConfig/homeRoutes';



export const getAuthRoutes = role => {
  let authRoutes = [];

  switch (role) {
    case ROLES.USER:
      authRoutes = authRoutes.concat([...homeRoutes]);
      break;
    default:
      break;
  }

  return authRoutes.concat(commonRoutes);
};
