import { stringify } from 'query-string';
import { Api } from 'services/api';

const baseUrl = process.env.REACT_APP_EXAM_TEST_DOMAIN;
const oneExamApiBaseUrl = process.env.REACT_APP_ONEEXAM_API_DOMAIN;
const oneDataApiBaseUrl = process.env.REACT_APP_ONEDATA_API_DOMAIN;

export const getTeacherExamList = async (params) => {
  const querystring = stringify(params);
  const response = await Api.get(`${baseUrl}/quiz/list/teacher?${querystring}`);
  return response;
};

export const getStudentExamList = async (params) => {
  const querystring = stringify(params);
  const response = await Api.get(`${baseUrl}/quiz/list/student?${querystring}`);
  return response;
};

export const updateExamStatus = async payload => {
  const response = await Api.post(`${baseUrl}/quiz/status`, payload);
  return response;
};

export const getMarkToken = async payload => {
  const response = await Api.post(`${oneExamApiBaseUrl}/api/mark`, payload);
  return response;
};

export const getWrongExamsCount = async payload => {
  const response = await Api.post(`${oneDataApiBaseUrl}/api/Review/exams/student/examtotal`, payload);
  return response;
};
