import {
  Toolbar as MatToolbar,
  Grid as MatGrid,
  IconButton as MatIconButton,
  Hidden as MatHidden
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { AvatarMenu } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { UiAppBar } from './BsHeader.style';
/**
 * backstage header
 */

export const BsHeader = ({
  onDrawerToggle
}) => {
  return (
    <>
      <UiAppBar color="primary" position="sticky" elevation={0}>
        <MatToolbar>
          <MatGrid container spacing={1} alignItems="center" justify="flex-end">
            <MatHidden smUp>
              <MatGrid item>
                <MatIconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </MatIconButton>
              </MatGrid>
            </MatHidden>
            <MatGrid item xs />
            <MatGrid item>
              <AvatarMenu />
            </MatGrid>
          </MatGrid>
        </MatToolbar>
      </UiAppBar>
    </>
  );
};

BsHeader.propTypes = {
  onDrawerToggle: PropTypes.func
};


