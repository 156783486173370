import { QUERY, DEVICE } from 'constants/dimension';
import { day, recordTypeList } from 'constants/index';
import {
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  Box,
  TextField,
  Button,
  Menu,
  IconButton,
  Typography,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import HistoryIcon from '@material-ui/icons/History';
import InfoIcon from '@material-ui/icons/Info';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReplayIcon from '@material-ui/icons/Replay';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import CheckInImg from 'assets/images/checkIn.svg';
import SortImage from 'assets/images/sort.png';
import UserClock from 'assets/images/userClock.svg';
import { BsModal, Loading, Icon } from 'components';
import { format, subDays, parse } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getGroupsByOrganizationId } from 'services/api/home/class';
import { getPunchRecordsExcel } from 'services/api/organization/organizations';
import { useAttendance } from 'store/attendance';
import { useCustomer } from 'store/customer';
import { FILE_EXTENSION_MAP, downloadFile } from 'utils/downloadFile';
import { toASCII } from 'utils/helper';
import { useAlert } from 'utils/hooks/useAlert';
import { useResponsive } from 'utils/hooks/useResponsive';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiHomeAttendanceTeacher,
  UiGoBackButtonBox,
  UiGoBackButton,
  UiTitleRow,
  UiFlexBox,
  UiFlexBoxCol,
  UiTipBox,
  UiTipsBox,
  NoCheck,
  ReviseCheck,
  Pointer,
  SortImg,
  BodyRow,
  ModalSubtitle,
  GreenText,
  GreyText,
  CheckResultBlock,
  CheckResultName,
  CheckResultContent,
  CheckResultTitle,
  CheckResultMessage,
  Title,
  LeaveStatus,
  SnackbarIconBg,
  WhiteSnackBar,
} from './HomeAttendanceTeacher.style';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '164px',
  },
  buttonOutlined: {
    color: '#EC7963',
    fontSize: '11px',
    padding: '8px',
    borderColor: '#EC7963',
    '&:hover': {
      background: 'none',
    },
  },
  buttonFill: {
    background: '#EC7963',
    color: '#fff',
    fontSize: '11px',
    padding: '8px',
    '&:hover': {
      background: '#EC7963',
    },
  },
  buttonSearch: {
    background: '#121232',
    color: '#fff',
    fontSize: '16px',
    padding: '8px',
    height: '48px',
    '&:hover': {
      background: '#121232',
    },
  },
}));

const switchSortOrderMap = {
  asc: 'desc',
  desc: 'asc',
};

const LEAVE_DATE = 4;
const SNACKBAR_LEAVE = 'leave';
const SNACKBAR_REVISE = 'revise';

const SNACKBAR_TEXT = {
  [SNACKBAR_LEAVE]: '您已成功請假！',
  [SNACKBAR_REVISE]: '您已成功完成打卡！',
};

export const HomeAttendanceTeacher = () => {
  const isTabletL = useResponsive({ query: QUERY.DOWN, start: DEVICE.TABLET.WIDTH_L });
  const history = useHistory();
  const { setAlert } = useAlert();
  const [{ recordList, totalRecord }, { searchRecord, checkIn, reviseCheck, leaveDate }] = useAttendance();
  const { organizationId } = useParams();
  const [{ customers, existSeatNo }, { getCustomersByParams }] = useCustomer();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [classList, setClassList] = useState([]);
  const [isRestart, setIsRestart] = useState(false);
  const [downloadRecordsModal, setDownloadRecordsModal] = useState({
    isLoading: false,
    isOpen: false,
    startAt: Date.now(),
    endAt: Date.now(),
    maxDate: Date.now() + day * 59,
  });
  const [
    { startDate, endDate, userId, userName, classId, recordType, rowsPage, page, sortKey, sortOrder },
    setSearchQuery,
  ] = useSetState({
    startDate: format(subDays(new Date(), 7), 'yyyy/MM/dd'),
    endDate: format(new Date(), 'yyyy/MM/dd'),
    recordType: 0,
    userId: '',
    userName: { nickname: '' },
    classId: '',
    rowsPage: 10,
    page: 0,
    sortKey: 'punchIn',
    sortOrder: 'desc',
  });
  const today = new Date();

  const restartSearch = () => {
    setSearchQuery({
      startDate: format(subDays(new Date(), 7), 'yyyy/MM/dd'),
      endDate: format(new Date(), 'yyyy/MM/dd'),
      recordType: 0,
      userId: '',
      userName: { nickname: '' },
      classId: classList[0].id,
      rowsPage: 10,
      page: 0,
      sortKey: 'punchIn',
      sortOrder: 'desc',
    });
    setIsRestart(true);
  };

  useEffect(() => {
    if (isRestart) {
      getSearch();
      setIsRestart(false);
    }
  }, [isRestart]);

  const [
    { cardInput, cardNickname, cardTime, cardRecordShow, cardResultShow, cardErrorShow },
    setCardRecord,
  ] = useSetState({
    cardInput: '',
    cardNickname: '',
    cardTime: 0,
    cardRecordShow: !isTabletL,
    cardResultShow: false,
    cardErrorShow: false,
  });

  const toggleDownloadRecordsModal = () => {
    setDownloadRecordsModal((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
    }));
  };

  /* 下載打卡紀錄 */
  const downloadAttendanceRecordExcel = async () => {
    const params = {
      startAt: format(downloadRecordsModal.startAt, 'yyyyMMdd'), // ex. 20230501
      endAt: format(downloadRecordsModal.endAt, 'yyyyMMdd'), // ex. 20230530
    };
    const { status, data } = await getPunchRecordsExcel(organizationId, params);
    if (status === 'success') {
      const { excelFile, fileName } = data;
      await downloadFile({ buffer: excelFile.data, fileName, type: FILE_EXTENSION_MAP.XLSX });
    } else {
      setAlert('下載打卡紀錄失敗', 'error');
    }
  };

  /* 下載紀錄彈窗 - click 確認按鈕 */
  const clickOkForDownloadRecordsModal = async () => {
    setDownloadRecordsModal((prev) => ({
      ...prev,
      isLoading: true,
    }));
    await downloadAttendanceRecordExcel();
    setDownloadRecordsModal((prev) => ({
      ...prev,
      isLoading: false,
    }));
    toggleDownloadRecordsModal();
  };

  /* 下載紀錄彈窗 - change 日期選擇器 */
  const updateDownloadDate = (key) => (date) => {
    const startDateProps = {
      startAt: date,
      endAt: date.getTime() + day * 59,
      maxDate: date.getTime() + day * 59,
    };
    const endDateProps = {
      endAt: date,
    };
    const newProps = key === 'startAt' ? startDateProps : endDateProps;
    setDownloadRecordsModal((prev) => ({
      ...prev,
      ...newProps,
    }));
  };

  const openCardRecord = () => {
    setCardRecord({
      cardRecordShow: true,
    });
  };

  const closeCardRecord = () => {
    setCardRecord({
      cardRecordShow: false,
    });
    if (classId) {
      getSearch();
    }
  };

  const textCardInput = async (event) => {
    setCardRecord({
      cardInput: toASCII(event.target.value),
    });

    if (event.target.value.length === 10) {
      const { status, data } = await checkIn(organizationId, { cardId: event.target.value });

      closeCardRecord();
      if (status === 'success') {
        setCardRecord({
          cardNickname: data.nickname,
          cardTime: data.recordTime,
        });

        openCardResult();
      } else if (status === 'failure') {
        openErrorCard();
      }

      setTimeout(() => {
        closeCardResult();
        closeErrorCard();
        openCardRecord();
        setCardRecord({
          cardInput: '',
        });
      }, 4000);
    }
  };

  const openCardResult = () => {
    setCardRecord({
      cardResultShow: true,
    });
  };

  const closeCardResult = () => {
    setCardRecord({
      cardResultShow: false,
      cardInput: '',
    });
  };

  const openErrorCard = () => {
    setCardRecord({
      cardErrorShow: true,
    });
  };

  const closeErrorCard = () => {
    setCardRecord({
      cardErrorShow: false,
      cardInput: '',
    });
  };

  const [{ reviseShow, reviseClass, reviseId, reviseName, reviseInDate, reviseInTime }, setRevise] = useSetState({
    reviseShow: false,
    reviseClass: '',
    reviseId: '',
    reviseName: { nickname: '' },
    reviseInDate: format(new Date(), 'yyyy/MM/dd'),
    reviseInTime: format(new Date(), 'yyyy/MM/dd HH:mm'),
  });

  const openRevise = (defaultData) => {
    setRevise({
      reviseShow: true,
      reviseClass: classId,
    });

    if (defaultData) {
      const tempDate = defaultData.date;
      setRevise({
        reviseInDate: `${tempDate.substring(0, 4)}/${tempDate.substring(4, 6)}/${tempDate.substring(6, 8)}`,
        reviseName: { nickname: defaultData.name },
        reviseId: defaultData.id,
      });
    }
  };

  const closeRevise = () => {
    setRevise({
      reviseShow: false,
      reviseName: { nickname: '' },
    });
  };

  const reviseClassChange = (event) => {
    setRevise({
      reviseClass: event.target.value,
    });
  };

  const reviseIdChange = (event, value) => {
    if (value) {
      setRevise({
        reviseId: value.id,
        reviseName: {
          ...value,
        },
      });
    }
  };

  const reviseInputChange = (event, newInputValue, reason) => {
    if (reason === 'clear') {
      setRevise({
        reviseId: '',
        reviseName: {
          ...{
            id: '',
            nickname: '',
          },
        },
      });
    }
  };

  const reviseInDateChange = (date) => {
    setRevise({
      reviseInDate: format(date, 'yyyy/MM/dd'),
    });
  };

  const reviseInTimeChange = (time) => {
    setRevise({
      reviseInTime: format(time, 'yyyy/MM/dd HH:mm'),
    });
  };

  const reviseSubmit = async () => {
    const splitDate = reviseInDate.split('/');
    const splitTime = reviseInTime.split(' ')[1].split(':');
    const showSnackbar = await reviseCheck(organizationId, reviseClass, {
      userId: reviseId,
      addTime: new Date(splitDate[0], Number(splitDate[1]) - 1, splitDate[2], splitTime[0], splitTime[1]).toISOString(),
    });

    if (showSnackbar) {
      closeRevise();
      setSnackbarType(SNACKBAR_REVISE);
      openSnackbar();
      getSearch();
    }
  };

  const getClass = async () => {
    const { data } = await getGroupsByOrganizationId({ organizationId });
    setClassList(data);
    setSearchQuery({
      classId: data[0].id,
    });
  };

  const openSnackbar = () => {
    setIsOpenSnackbar(true);
  };

  const closeSnackbar = () => {
    setIsOpenSnackbar(false);
  };

  const getSearch = async () => {
    setIsLoading(true);
    await searchRecord(organizationId, classId, {
      startAt: startDate.replaceAll('/', ''),
      endAt: endDate.replaceAll('/', ''),
      recordType,
      userId,
      page,
      limit: rowsPage,
      sortKey,
      sortOrder,
    });
    setIsLoading(false);
  };

  const getStudents = async () => {
    await getCustomersByParams({ organizationId, classId: classId, rowsPage: -1 });
  };

  useEffect(() => {
    getClass();
  }, []);

  useEffect(() => {
    if (classId) {
      getStudents();
    }
  }, [classId]);

  useEffect(() => {
    if (classId) {
      getSearch();
    }
  }, [classId, rowsPage, sortKey, sortOrder, rowsPage, page]);

  const classIdChange = (event) => {
    setSearchQuery({
      classId: event.target.value,
    });
  };

  const recordTypeChange = (event) => {
    setSearchQuery({
      recordType: event.target.value,
    });
  };

  const userIdChange = (event, value) => {
    if (value) {
      setSearchQuery({
        userId: value.id,
        userName: { ...value },
      });
    }
  };
  const inputChange = (event, newInputValue, reason) => {
    if (reason === 'clear') {
      setSearchQuery({
        userId: '',
        userName: {
          ...{
            id: '',
            nickname: '',
          },
        },
      });
    }
  };

  const startDateChange = (date) => {
    setSearchQuery({
      startDate: format(date, 'yyyy/MM/dd'),
    });
  };

  const endDateChange = (date) => {
    setSearchQuery({
      endDate: format(date, 'yyyy/MM/dd'),
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchQuery({
      rowsPage: event.target.value,
      page: 0,
    });
  };

  const handleChangePage = (event, newPage) => {
    setSearchQuery({
      page: newPage,
    });
  };

  const switchSortOrder = (searchType) => {
    setSearchQuery({
      sortKey: searchType,
      sortOrder: switchSortOrderMap[sortOrder],
    });
  };

  const leaveRecordIn = async (date, leaveUserId) => {
    const showSnackbar = await leaveDate(organizationId, classId, date, { userId: leaveUserId });
    if (showSnackbar) {
      openSnackbar();
      setSnackbarType(SNACKBAR_LEAVE);
      getSearch();
    }
  };

  const TableContent = () => {
    const TableCellCondition = (props) => {
      if (props.type === 'leave') {
        return (
          <TableCell colSpan={2}>
            <LeaveStatus>請假</LeaveStatus>
          </TableCell>
        );
      }
      if (props.data) {
        return (
          <TableCell>
            {format(props.data.recordTime, 'HH:mm')}
            {props.data.revise && <ReviseCheck>補</ReviseCheck>}
          </TableCell>
        );
      } else {
        return (
          <TableCell>
            <NoCheck>未打卡</NoCheck>
          </TableCell>
        );
      }
    };

    const MoreOperation = (props) => {
      const [anchorEl, setAnchorEl] = useState(null);
      const isLeave = !(props.data.punchIn || props.data.punchOut);

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

      const showReviseAction = () => {
        props.openRevise({
          name: props.data.nickname,
          date: props.data.tag.toString(),
          id: props.data.userId,
        });
        handleClose();
      };

      const leaveAction = () => {
        props.leaveRecordIn(props.data.tag.toString(), props.data.userId);
        handleClose();
      };
      return (
        <div>
          <IconButton onClick={handleClick}>
            <MoreVertIcon style={{ color: '#212B36' }} />
          </IconButton>
          <Menu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={showReviseAction}>補打卡</MenuItem>
            {isLeave && <MenuItem onClick={leaveAction}>請假</MenuItem>}
          </Menu>
        </div>
      );
    };

    const SwitchLeaveTableCell = (props) => {
      if (props.recordType === LEAVE_DATE) {
        return <TableCellCondition type="leave" />;
      } else {
        return (
          <>
            <TableCellCondition data={props.data.punchIn} />
            <TableCellCondition data={props.data.punchOut} />
          </>
        );
      }
    };

    if (isLoading) {
      return <Loading></Loading>;
    } else {
      return (
        <>
          <Grid container>
            <Table>
              <TableHead>
                <UiTitleRow>
                  {existSeatNo && (
                    <TableCell width="120px">
                      <Box display="table">
                        <Pointer onClick={() => switchSortOrder('seatNo')}>
                          學生座號
                          <SortImg src={SortImage} alt="sort" />
                        </Pointer>
                      </Box>
                    </TableCell>
                  )}
                  <TableCell width="180px">學生姓名</TableCell>
                  <TableCell width="180px">到班日期</TableCell>
                  <TableCell width="250px">
                    <Box display="table">
                      <Pointer onClick={() => switchSortOrder('punchIn')}>
                        到班時間
                        <SortImg src={SortImage} alt="sort" />
                      </Pointer>
                    </Box>
                  </TableCell>
                  <TableCell width="250px">
                    <Box display="table">
                      <Pointer onClick={() => switchSortOrder('punchOut')}>
                        離班時間
                        <SortImg src={SortImage} alt="sort" />
                      </Pointer>
                    </Box>
                  </TableCell>
                  <TableCell></TableCell>
                </UiTitleRow>
              </TableHead>
              <TableBody>
                {recordList.map((item, index) => {
                  return (
                    <BodyRow key={index}>
                      {existSeatNo && <TableCell>{item.seatNo || '-'}</TableCell>}
                      <TableCell>{item.nickname}</TableCell>
                      <TableCell>{format(parse(item.tag, 'yyyyMMdd', new Date()), 'yyyy/MM/dd')}</TableCell>
                      <SwitchLeaveTableCell recordType={item.recordType} data={item}></SwitchLeaveTableCell>
                      <TableCell align="right">
                        {item.recordType !== LEAVE_DATE && (
                          <MoreOperation openRevise={openRevise} leaveRecordIn={leaveRecordIn} data={item} />
                        )}
                      </TableCell>
                    </BodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            <TablePagination
              count={totalRecord}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPage}
              labelRowsPerPage={'每頁顯示'}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              component="div"
            />
          </Box>
        </>
      );
    }
  };

  return (
    <UiHomeAttendanceTeacher>
      <UiGoBackButtonBox>
        <UiGoBackButton onClick={() => history.push('/home')}>
          <Icon.Svg name="ChevronLeft" />
          <Typography>返回班級列表</Typography>
        </UiGoBackButton>
      </UiGoBackButtonBox>

      <Box display="flex" justifyContent="space-between">
        <Title>班級打卡紀錄</Title>
        <Box display="flex">
          <Box height="33px" mr="13.5px">
            <Button
              className={classes.buttonOutlined}
              fullWidth={true}
              variant="outlined"
              onClick={toggleDownloadRecordsModal}
            >
              <CloudDownloadIcon />
              下載打卡紀錄
            </Button>
          </Box>
          <Box width="80px" height="33px" mr="13.5px">
            <Button className={classes.buttonOutlined} fullWidth={true} variant="outlined" onClick={() => openRevise()}>
              <HistoryIcon />
              補打卡
            </Button>
          </Box>
          <Box width="90px" height="33px">
            <Button
              fullWidth={true}
              className={classes.buttonFill}
              variant="contained"
              disableElevation
              onClick={openCardRecord}
            >
              <AddIcon />
              新增打卡
            </Button>
          </Box>
        </Box>
      </Box>

      <BsModal
        title="請選擇欲下載的日期區段"
        isLoading={downloadRecordsModal.isLoading}
        open={downloadRecordsModal.isOpen}
        onOk={clickOkForDownloadRecordsModal}
        onCancel={toggleDownloadRecordsModal}
      >
        <UiFlexBoxCol>
          <Box style={{ width: '100%' }}>
            <KeyboardDatePicker
              fullWidth={true}
              label="起始日期"
              format="yyyy/MM/dd"
              value={downloadRecordsModal.startAt}
              onChange={updateDownloadDate('startAt')}
              cancelLabel="取消"
              okLabel="確定"
              clearLabel="清除"
              inputVariant="outlined"
              maxDate={today}
            />
          </Box>
          <Box style={{ width: '100%' }}>
            <KeyboardDatePicker
              fullWidth={true}
              label="截止日期"
              format="yyyy/MM/dd"
              value={downloadRecordsModal.endAt}
              onChange={updateDownloadDate('endAt')}
              cancelLabel="取消"
              okLabel="確定"
              clearLabel="清除"
              inputVariant="outlined"
              maxDate={downloadRecordsModal.maxDate}
              minDate={downloadRecordsModal.startAt}
            />
          </Box>
          <UiTipsBox style={{ width: '100%', display: 'flex', gap: '8px' }}>
            <UiTipBox style={{ display: 'flex' }}>
              <InfoIcon style={{ fontSize: '14px' }} />
              <Typography>下載範圍：機構內的所有班級</Typography>
            </UiTipBox>
            <UiTipBox>
              <InfoIcon style={{ fontSize: '14px' }} />
              <Typography>日期區段限制：範圍不能超過 60 天</Typography>
            </UiTipBox>
          </UiTipsBox>
        </UiFlexBoxCol>
      </BsModal>

      <BsModal
        title="請進行打卡"
        open={cardRecordShow}
        onClose={closeCardRecord}
        oKDisplay={false}
        cancelDisplay={false}
      >
        <Box>
          <Box mb="32px">
            <ModalSubtitle>現在時間 | {format(new Date(), 'yyyy/MM/dd HH:mm')}</ModalSubtitle>
          </Box>
          <Box display="flex" justifyContent="center" mb="32px">
            <img src={CheckInImg} alt="" />
          </Box>
          <Box>
            <TextField
              autoFocus
              fullWidth={true}
              label="卡號"
              variant="outlined"
              value={cardInput}
              onChange={textCardInput}
              disabled={cardInput.length >= 10}
            />
          </Box>
        </Box>
      </BsModal>

      <BsModal
        title="請進行打卡"
        open={cardResultShow}
        onClose={closeCardResult}
        oKDisplay={false}
        cancelDisplay={false}
      >
        <Box>
          <Box mb="32px">
            <ModalSubtitle>現在時間 | {Date.now()}</ModalSubtitle>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" mb="32px">
            <CheckCircleIcon style={{ fontSize: '50px', color: '#4CAF50' }} />
            <GreenText>您已成功完成打卡！ </GreenText>
            <GreyText>稍後系統將回到主打卡畫面...</GreyText>
          </Box>
          <CheckResultBlock bgcolor="#8BBCC3CC">
            <Box display="flex" alignItems="center" mb="16px">
              <img src={UserClock} alt="" />
              <CheckResultName>{cardNickname}</CheckResultName>
            </Box>
            <CheckResultContent>
              <CheckResultTitle>卡號</CheckResultTitle>
              <CheckResultMessage>{cardInput}</CheckResultMessage>
              <CheckResultTitle>打卡時間</CheckResultTitle>
              <CheckResultMessage>{format(cardTime, 'yyyy/MM/dd HH:mm')}</CheckResultMessage>
            </CheckResultContent>
          </CheckResultBlock>
        </Box>
      </BsModal>

      <BsModal title="請進行打卡" open={cardErrorShow} onClose={closeErrorCard} oKDisplay={false} cancelDisplay={false}>
        <Box>
          <Box mb="32px">
            <ModalSubtitle>現在時間 | {Date.now()}</ModalSubtitle>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" mb="32px">
            <CancelIcon style={{ color: '#F44336', fontSize: '50px' }} />
            <Box color="#F44336" fontSize="24px" mt="14px">
              打卡失敗
            </Box>
            <Box color="#F44336" fontSize="24px" mt="14px">
              請先至後台設定卡號
            </Box>
          </Box>
        </Box>
      </BsModal>

      <BsModal
        title="補打卡"
        onOk={reviseSubmit}
        open={reviseShow}
        onClose={closeRevise}
        onCancel={closeRevise}
        okDisabled={reviseId === ''}
      >
        <Box display="flex" flexDirection="column" style={{ 'row-gap': '16px' }}>
          <FormControl variant="outlined" fullWidth={true}>
            <InputLabel id="check-label">班級名稱</InputLabel>
            <Select
              autoWidth={true}
              labelId="check-label"
              variant="outlined"
              label="班級名稱"
              value={reviseClass}
              onChange={reviseClassChange}
            >
              {classList.map((classItem) => (
                <MenuItem key={classItem.id} value={classItem.id}>
                  {classItem.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Autocomplete
            value={reviseName}
            options={customers.data}
            getOptionLabel={(option) => option.nickname}
            renderInput={(params) => <TextField {...params} label="學生姓名" variant="outlined" />}
            onChange={reviseIdChange}
            onInputChange={reviseInputChange}
          />
          <KeyboardDatePicker
            fullWidth={true}
            label="出勤日期"
            format="yyyy/MM/dd"
            value={reviseInDate}
            onChange={reviseInDateChange}
            cancelLabel="取消"
            okLabel="確定"
            clearLabel="清除"
            inputVariant="outlined"
            maxDate={today}
          />
          <KeyboardTimePicker
            fullWidth={true}
            label="出勤時間"
            format="HH:mm"
            value={reviseInTime}
            onChange={reviseInTimeChange}
            cancelLabel="取消"
            okLabel="確定"
            clearLabel="清除"
            inputVariant="outlined"
            ampm={false}
          />
        </Box>
      </BsModal>

      <Box mb="28px">
        <UiFlexBox flexWrap="wrap">
          <UiFlexBox>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="check-label">班級名稱</InputLabel>
              <Select
                labelId="check-label"
                variant="outlined"
                label="班級名稱"
                value={classId}
                onChange={classIdChange}
              >
                {classList.map((classItem) => (
                  <MenuItem key={classItem.id} value={classItem.id}>
                    {classItem.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <Autocomplete
                value={userName}
                options={customers.data}
                getOptionLabel={(option) => option.nickname}
                renderInput={(params) => <TextField {...params} label="學生姓名" variant="outlined" />}
                onChange={userIdChange}
                onInputChange={inputChange}
              />
            </FormControl>
          </UiFlexBox>

          <UiFlexBox>
            <Box width="164px">
              <KeyboardDatePicker
                format="yyyy/MM/dd"
                label={'起始日期'}
                value={startDate}
                onChange={startDateChange}
                cancelLabel="取消"
                okLabel="確定"
                clearLabel="清除"
                inputVariant="outlined"
                maxDate={today}
              />
            </Box>
            <Box width="164px">
              <KeyboardDatePicker
                format="yyyy/MM/dd"
                label={'結束日期'}
                value={endDate}
                onChange={endDateChange}
                cancelLabel="取消"
                okLabel="確定"
                clearLabel="清除"
                inputVariant="outlined"
                maxDate={today}
              />
            </Box>
          </UiFlexBox>

          <UiFlexBox>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="check-label">打卡類型</InputLabel>
              <Select
                labelId="check-label"
                variant="outlined"
                label="打卡類型"
                value={recordType}
                onChange={recordTypeChange}
              >
                {recordTypeList.map((type, index) => (
                  <MenuItem key={index} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </UiFlexBox>

          <UiFlexBox>
            <Button className={classes.buttonSearch} variant="contained" disableElevation onClick={getSearch}>
              搜尋
            </Button>
            <Button disableElevation onClick={restartSearch}>
              <ReplayIcon />
              <Box style={{ whiteSpace: 'nowrap' }}>清除搜尋</Box>
            </Button>
          </UiFlexBox>
        </UiFlexBox>
      </Box>

      {<TableContent />}

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpenSnackbar}
        autoHideDuration={5000}
        onClose={closeSnackbar}
      >
        <WhiteSnackBar>
          <SnackbarIconBg>
            <CheckCircleIcon style={{ color: '#54D62C' }} />
          </SnackbarIconBg>
          <span>{SNACKBAR_TEXT[snackbarType]}</span>
        </WhiteSnackBar>
      </Snackbar>
    </UiHomeAttendanceTeacher>
  );
};
