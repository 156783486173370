import { Checkbox as MatCheckbox } from '@material-ui/core';
import { Table, IconInput, Select, PdfUploader, Modal, Icon } from 'components';
import { Button } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useResource } from 'store/resource';
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';

/**
 * 上傳檔案列表
 */

// eslint-disable-next-line react/prop-types
const ActionComponents = ({ params }) => {
  const [, { delResource }] = useResource();
  const [{ deleteModalState }, setUploadModalState] = useSetState({
    deleteModalState: false
  });

  // eslint-disable-next-line react/prop-types
  const { resourceId } = params;
  const buttons = [
    {
      text: '確認',
      func: async () => {
        delResource(resourceId);
        setDeleteModalStateHandler(false);
      }
    },
    {
      text: '取消',
      func: () => { }
    }
  ];
  const setDeleteModalStateHandler = state => {
    setUploadModalState({
      deleteModalState: state
    });
  };


  const previewBook = () => {
    window.open(`https://cdn.oneclass.com.tw/uploadoutput/${resourceId}`);
  };
  return (
    <>
      <Modal
        isOpen={deleteModalState}
        text="是否刪除這筆資源"
        getModalState={state => setDeleteModalStateHandler(state)}
        buttons={buttons}
      />
      <UiflexBox>
        <Icon name="watchFile" title="預覽檔案" onClick={() => previewBook()} />
        <Icon name="delete" title="刪除檔案" onClick={() => setDeleteModalStateHandler(true)} />
      </UiflexBox>
    </>
  );
};

const schema = {
  displayName: {
    name: '檔案名稱',
    defaultValue: '',
  },
  fileSize: {
    name: '檔案大小',
    defaultValue: '',
  },
  numberOfPages: {
    name: '頁數',
    defaultValue: 0,
  },
  createTime: {
    name: '建立日期',
    defaultValue: '',
  },
  resourceStatus: {
    name: '狀態',
    defaultValue: '',
  },
  format: {
    name: '格式',
    defaultValue: '',
  }
};



const stateOptions = [
  {
    name: '轉檔完成',
    value: 'ready'
  },
  {
    name: '等待轉檔',
    value: 'waiting'
  },
  {
    name: '正在轉檔',
    value: 'converting'
  },
  {
    name: '轉檔失敗',
    value: 'convert_failed'
  }
];

export const PdfUploadedTable = props => {
  const { setAlert } = useAlert();
  const [{ resources }, { getResourcesList, createReource, refreshResources }] = useResource();
  const [{ uploadModalState, fileName, status, nowPage, rowsPage, isLoading }, setState] = useSetState({
    uploadModalState: false,
    fileName: '',
    status: '',
    nowPage: 0,
    rowsPage: 10,
    isLoading: false
  });

  const setUploadModalStateHandler = state => {
    setState({
      uploadModalState: state
    });
  };

  const FormatComponent = (compressImg, type) => {
    return (
      <>
        <span>可選字</span>
        <MatCheckbox disabled checked={compressImg} />
        <span>壓縮圖檔</span>
        <MatCheckbox disabled checked={type === 'svg_realtext' ? true : false} />
      </>
    );
  };
  const stateShowText = [
    {
      id: 'resourceStatus',
      state: {
        ready: '轉檔完成',
        waiting: '等待轉檔',
        convert_failed: '轉檔失敗',
        converting: '正在轉檔'
      }
    }
  ];
  const getFileHandler = async params => {
    setState({ isLoading: true });

    const fileName = params.file[0].name.split('.')[0];
    const regex = new RegExp('^(?!.*?_$)[\u4e00-\u9fa5_a-zA-Z0-9]+$');
    if (!regex.test(fileName)) {
      setAlert(ALERT_MESSAGE.FILENAME_ERROR, 'error');
      setState({ isLoading: false });
      return;
    }
    createReource(params).then(() => {
      setState({ isLoading: false });
      setUploadModalStateHandler(false);
    });
  };

  //換頁
  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage,
    });
  };

  //搜尋檔名
  const submitInputHandler = fileName => {
    setState({
      fileName,
      nowPage: 0
    });
  };

  //搜尋檔案狀態
  const submitSelectHandler = status => {
    setState({
      status,
      nowPage: 0
    });
  };

  useEffect(() => {
    getResourcesList({ nowPage, rowsPage, fileName, status });
  }, [nowPage, rowsPage, fileName, status]);

  return (
    <>
      <Modal
        isIconShow={false}
        isOpen={uploadModalState}
        title="上傳PDF"
        content={<PdfUploader isLoading={isLoading} getFileHandler={getFileHandler} />}
        getModalState={state => setUploadModalStateHandler(state)}
      />
      <UiTable>
        <UiActionBox>
          <UiflexBox>
            <IconInput placeholder="搜尋檔案名稱" onChange={submitInputHandler} />
            <Select label="狀態篩選" options={stateOptions} submitHandler={submitSelectHandler} />
          </UiflexBox>
          <UiflexBox>
            <Button
              icon='refresh'
              onClick={() => refreshResources()}
            >
              重新整理
            </Button>
            <Button
              icon="upload"
              onClick={() => setUploadModalStateHandler(true)}
            >
              上傳PDF
            </Button>
          </UiflexBox>
        </UiActionBox>
        <Table
          data={
            resources.data.map(item => {
              item.format = FormatComponent(item.compressImage, item.svgType);
              return item;
            })
          }
          schema={schema}
          stateShowText={stateShowText}
          changePage_Rows={changePage_Rows}
          totalPage={resources.total}
          ActionComponents={ActionComponents}
          nowPage={nowPage}
        />
      </UiTable>
    </>
  );
};

PdfUploadedTable.propTypes = {
  children: PropTypes.node,
};

