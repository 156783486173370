import { Box } from '@material-ui/core';
import { IconInput } from 'components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

export const HomeSessionActions = ({ onSearch }) => {
  const [filterData, setFilterData] = useState({
    name: null,
  });

  const changeFilterData = ({ key, value }) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const keyPressEnter = () => {
    onSearch(filterData);
  };

  const clickSearchIcon = () => {
    onSearch(filterData);
  };

  return (
    <Box>
      <IconInput
        placeholder="搜尋課程名稱"
        onChange={(value) => changeFilterData({ key: 'name', value })}
        onKeyPress={keyPressEnter}
        onClick={clickSearchIcon}
      />
    </Box>
  );
};

HomeSessionActions.propTypes = {
  onSearch: PropTypes.func,
};
