import {
  PictureBox,
  ClickablePopoverMenu,
  Icon,
} from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiClassProfileImageCard,
  UiPictureContent,
} from './ClassProfileImageCard.style';

/**
 * 班級圖像卡牌組件
 */

export const ClassProfileImageCard = ({
  data = {},
  profileImageId = null,
  isEdit = false,
  changeClassImage = () => { },
}) => {
  const [{ actionShow }, setState] = useSetState({
    actionShow: false
  });
  const { attachmentUrl, id } = data;
  const popoverMenuList = [
    {
      label: '設為班級圖片',
      func: () => { changeClassImage(data); },
      iconName: 'settings',
      isShow: true
    },
  ];
  return (
    <UiClassProfileImageCard
      onMouseEnter={() => setState({
        actionShow: true
      })}
      onMouseLeave={() => setState({
        actionShow: false
      })}
    >
      <PictureBox path={attachmentUrl || ''} ></PictureBox>
      <UiPictureContent
        style={{ opacity: actionShow ? 1 : 0, }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ClickablePopoverMenu menuList={popoverMenuList}>
          <Icon haveBg={false} name="moreHoriz" icolor="#fff"></Icon>
        </ClickablePopoverMenu>

      </UiPictureContent>
      {
        (profileImageId === id) &&
        <Icon name='emojiFlags' icolor='#fff'></Icon>
      }

    </UiClassProfileImageCard>
  );
};

ClassProfileImageCard.propTypes = {
  data: PropTypes.shape({
    attachmentUrl: PropTypes.string,
    canDelete: PropTypes.bool,
    createdAt: PropTypes.number,
    id: PropTypes.string,
    isAdmin: PropTypes.bool,
    path: PropTypes.string,
    userId: PropTypes.string,
  }),
  profileImageId: PropTypes.string,
  isEdit: PropTypes.bool,
  changeClassImage: PropTypes.func,
};


