import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  UiPaper,
  UiTable,
  UiTableBody,
  UiTableCell,
  UiFlexTableCell,
  UiTableContainer,
  UiTablePagination,
  UiTableHead,
  UiFirstTableRow,
  UiTableRow
} from './Table.style';

/**
 * Table 透過傳入 data 產生列表 ， 修改schema & list排序 ， 傳入ActionComponents 對table 產生操作行為
 */
export const Table = (
  {
    data,
    schema,
    totalPage,
    changePage_Rows,
    stateShowText,
    ActionComponents,
    pagesDisplay,
    nowPage,
    rowsPerPage,
    dataConditionFormat = () => { },//根據一些條件判斷td是否要做變化
    isNeedPagination = true,
    actionName = ''
  }
) => {
  const [page, setPage] = useState(nowPage || 0);
  const [newRowsPage, setRowsPerPage] = useState(rowsPerPage || pagesDisplay[0]);
  const isHome = useRouteMatch({ path: '/home' }) ? true : false;

  //換頁
  const changePageHandler = (event, newPage) => {
    setPage(newPage);
    changePage_Rows({ newPage, newRowsPage });
  };
  //更換每頁顯示幾筆
  const changeRowsPerPageHandler = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
    changePage_Rows({ newPage: 0, newRowsPage: event.target.value });
  };
  //狀態換字
  const stateShowTextHandler = (key, value) => {
    const showText = stateShowText.find(item => item.id === key);
    if (showText !== undefined && showText !== 'undefined') {
      return showText.state[value];
    }
    return value;
  };
  //多餘的字移除
  const stringFormat = (str, maxLength) => str?.length <= maxLength ? str : `${str?.substr(0, maxLength)}...`;

  //整理table title格式
  const tableHead = () => {
    return Object.entries(schema).map(([key, item], index) => {
      return {
        id: key,
        label: item.name,
        align: 'center',
        defaultValue: item.defaultValue,
        importantCondition: item.importantCondition,
        isLimit: {
          state: item.maxLength ? true : false,
          limit: item.maxLength
        }
      };
    });
  };
  const tableHeaderTitle = tableHead();

  //資料狀態顯示的文字
  const dataFormat = obj => tableHeaderTitle.map(td => {
    const { id, isLimit, defaultValue, importantCondition } = td;
    let isImportant = false;
    if (importantCondition) {
      isImportant = dataConditionFormat(obj[id], obj[importantCondition]);
    }
    let value = stateShowTextHandler(id, obj[id]);
    if (isLimit.state) value = stringFormat(value, isLimit.limit);
    return {
      key: id,
      value: value || defaultValue,
      isImportant
    };
  });

  useEffect(() => {
    if (nowPage !== 0) return;
    setPage(0);
  }, [nowPage]);

  return (
    <UiPaper>
      <UiTableContainer>
        <UiTable>
          <UiTableHead>
            <UiFirstTableRow isHome={isHome}>
              {tableHeaderTitle.map(column => (
                <UiTableCell
                  key={column.id}
                  align={column.align}
                  isHome={isHome}
                >
                  {column.label}
                </UiTableCell>
              ))}
              {ActionComponents && <UiTableCell align={'center'}>{actionName}</UiTableCell>}
            </UiFirstTableRow>
          </UiTableHead>
          <UiTableBody>
            {data && data.map((row) => {
              return (
                <UiTableRow key={row.id} isHome={isHome}>
                  {
                    dataFormat(row).map((column, index) => {
                      return (
                        <UiTableCell
                          key={row.id + index}
                          align={'center'}
                          isHome={isHome}
                          isimportant={column.isImportant.toString()}>
                          {column.value}
                        </UiTableCell>
                      );
                    })
                  }
                  {ActionComponents && <UiFlexTableCell align="center">
                    <ActionComponents params={row} />
                  </UiFlexTableCell>}
                </UiTableRow>
              );
            })}
          </UiTableBody>
        </UiTable>
      </UiTableContainer>
      {
        (data.length > 0 && isNeedPagination) && <UiTablePagination
          rowsPerPageOptions={pagesDisplay}
          component="div"
          count={totalPage}
          rowsPerPage={newRowsPage}
          labelRowsPerPage={'每頁顯示'}
          page={page}
          onChangePage={changePageHandler}
          onChangeRowsPerPage={changeRowsPerPageHandler}
        />
      }

    </UiPaper>
  );
};

Table.defaultProps = {
  data: [],
  stateShowText: [],
  pagesDisplay: [10, 15, 20]
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  schema: PropTypes.shape({
    officialName: PropTypes.shape({
      name: PropTypes.string.isRequired,
      defaultValue: PropTypes.string.isRequired,
      maxLength: PropTypes.number
    })
  }).isRequired,
  totalPage: PropTypes.number.isRequired,
  stateShowText: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      state: PropTypes.object.isRequired
    })
  ),
  changePage_Rows: PropTypes.func,
  ActionComponents: PropTypes.func,
  pagesDisplay: PropTypes.array,
  nowPage: PropTypes.number,
  rowsPerPage: PropTypes.number,
  dataConditionFormat: PropTypes.func,
  isNeedPagination: PropTypes.bool,
  actionName: PropTypes.string
};
