import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { UiSvgIcon, useStyles } from './Icon.style';
import SVG from './svg';

export const IconSvg = ({ className, name, color, onClick, title, disabled = false }) => {
  const classes = useStyles();
  return (
    title ?
      <Tooltip title={title}>
        <UiSvgIcon onClick={onClick} className={className} component={SVG[name]} color={color} />
      </Tooltip> :
      <UiSvgIcon
        onClick={onClick}
        className={`${className} ${disabled ? classes.disabled : ''}`}
        component={SVG[name]}
        color={color}
      />
  );
};
