import { HomeOrganizationLayout, HomeAnnouncementTable } from 'components';
import React from 'react';

/**
 * 組織公告頁
 */

export const HomeOrganizationAnnouncementPage = () => {
  return (<HomeOrganizationLayout>
    <HomeAnnouncementTable />
  </HomeOrganizationLayout>);
};

