import { COMMON_ACTION_TYPE } from 'constants/index';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';



export const useLocationChange = () => {
  const dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    dispatch({
      type: COMMON_ACTION_TYPE.LOCATION_CHANGE,
      payload: {
        location
      }
    });
  }, [location]);
};

