import { useLayoutEffect, useState } from 'react';
import debounce from 'utils/debounce';

/**
 * 當 window resize 後，取得其 size
 * @param {number} wait debounce time
 * @returns {[number, number]} [width, height]
 */
export function useWindowSize(wait = 500) {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        const debounceUpdateSize = debounce(() => {
            setSize([window.innerWidth, window.innerHeight]);
        }, wait);
        window.addEventListener('resize', debounceUpdateSize);
        debounceUpdateSize();
        return () => window.removeEventListener('resize', debounceUpdateSize);
    }, []);

    return size;
}