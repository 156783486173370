import countyLoginPageBgPng from 'assets/images/county_login_page_bg.png';
import { CountyLoginForm } from 'components';
import React from 'react';
import { UiCountyLoginPage } from './CountyLoginPage.style';

/**
 * 縣市登入頁面
 */

export const CountyLoginPage = () => {
  return (
    <UiCountyLoginPage style={{ backgroundImage: `url(${countyLoginPageBgPng})` }}>
      <CountyLoginForm />
    </UiCountyLoginPage>
  );
};
