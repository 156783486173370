import { Divider } from '@material-ui/core';
import styled from 'styled-components';

export const UiEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const UiEmptyImgContainer = styled.div`
  margin-bottom: 50px;
`;

export const UiEmptyMessage = styled.div`
  font-family: 'Noto Sans CJK TC';
  font-size: 1.2rem;
`;

export const UiActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
`;

export const UiPreviousPageContainer = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #8b90a0;
  font-size: 1rem;
  font-weight: 500;
`;

export const UiSection = styled.div`
  margin-top: 40px;
`;

export const UiSectionTitle = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-left: 32px;
  margin-bottom: 16px;
  color: #242c3f;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    background: #30a9fa;
    border-radius: 50%;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
  }
`;

export const UiInformationPanel = styled.div`
  display: flex;
  background: #fafafc;
  border-radius: 16px;
  padding: 26px 40px;
`;

export const UiInfoIntro = styled.div`
  flex: 0.8;
`;

export const UiDivider = styled(Divider).attrs({
  orientation: 'horizontal',
})`
  border: 1px solid #e4e7ec;
  height: inherit;
`;

export const UiInfoTrophy = styled.div`
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const UiInfoTrophyIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const UiInfoTrophyIntroTitle = styled.div`
  color: #8b90a0;
  font-size: 16px;
  font-weight: 400;
`;
export const UiInfoTrophyIntroCount = styled.div`
  font-family: Inter;
  font-size: 40px;
  font-weight: 700;
`;
export const UiInfoTrophyIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UiFeedbackTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
  color: #242c3f;
`;

export const UiFeedbackTitleItem = styled.div`
  padding: 4px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #454b5c;
`;

export const UiFeedbackTitleItemLabel = styled.div`
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

export const UiSWOT = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const UiContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  background: #fafafc;
  border-radius: 16px;
  padding: 16px 24px;
`;

export const UiChartBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 320px;
`;
