import { EDIT_STATUS } from 'constants/index';
import { Table, Select, IconInput, Icon, Checkbox } from 'components';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAdmin } from 'store/admin';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiAccountTable,
  UiFilterBox,
  UiFilterBoxLeft,
} from './AccountTable.style';

/**
 * 帳號列表組件
 */
const setGender = {
  other: '其他',
  male: '男',
  female: '女'
};

const setPremiumStatus = {
  pending: '申請中',
  trial: '體驗版',
  approved: '已開通',
  expired: '已過期',
};

export const AccountTable = () => {
  const [{
    nowPage,
    newRowsPage,
    selectOption,
    search,
    usersListData,
    isTeacher,
  }, setState] = useSetState({
    nowPage: 0,
    newRowsPage: 10,
    selectOption: '',
    search: '',
    usersListData: [],
    isTeacher: false,
  });
  const history = useHistory();
  const [{ users }, { getUsersList }] = useAdmin();
  const { data: userData, total } = users;

  useEffect(() => {
    if (!userData) return;
    const nextData = userData.map(item => {
      const realName = (item.lastName && item.firstName) ? item.lastName + item.firstName : '';
      const sex = item.gender && setGender[item.gender];
      const premiumFormateStatus = item.premiumStatus && setPremiumStatus[item.premiumStatus];
      const groupCount = item.totalOwnedOrganizationGroupCount + '/' + item.totalOwnedFreeGroupCount;
      return {
        ...item,
        realName,
        sex,
        groupCount,
        premiumFormateStatus
      };
    });
    setState({
      usersListData: nextData
    });
  }, [userData]);

  const schema = {
    id: {
      name: '帳號ID',
      defaultValue: '--',
    },
    realName: {
      name: '姓名',
      defaultValue: '--',
    },
    nickname: {
      name: '暱稱',
      defaultValue: '--',
    },
    sex: {
      name: '性別',
      defaultValue: '其他',
    },
    mobileNumber: {
      name: '手機',
      defaultValue: '--',
    },
    email: {
      name: 'E-mail',
      defaultValue: '--',
    },
    groupCount: {
      name: '導師班級數',
      defaultValue: '--/--',
    },
    premiumFormateStatus: {
      name: '狀態',
      defaultValue: '未付費',
    }
  };

  // table 操作
  const ActionComponents = params => {
    return (
      <>
        <Icon
          onClick={() => history.push(`/admin/account/${EDIT_STATUS.EDIT}/${params.params.id}`)}
          name='edit'
        />
      </>
    );
  };

  // 更換 每頁顯示
  const changePageRows = params => {
    setState({ nowPage: params.newPage, newRowsPage: params.newRowsPage });
  };

  // table 狀態
  const stateShowText = [
    {
      id: 'status',
      state: {
        active: '開通',
        inactive: '未開通',
        suspended: '停權'
      }
    }
  ];

  // 下拉選單系列
  const selectProps = {
    label: '篩選條件',
    options: [
      {
        name: '暱稱',
        value: 'nickname'
      },
      {
        name: '手機號碼',
        value: 'mobileNumber'
      },
      {
        name: 'E-mail',
        value: 'email'
      },
      {
        name: '使用者 Id',
        value: 'userId'
      }
    ],
    submitHandler: value => {
      setState({ selectOption: value, nowPage: 0 });
    }
  };

  // 搜尋系列
  const IconInputProps = {
    placeholder: '搜尋欄位名稱',
    onChange: value => {
      setState({ search: value, nowPage: 0 });
    }
  };
  // 初始近來呼叫 api
  useEffect(() => {
    let query = { nowPage, rowsPage: newRowsPage };
    query[selectOption] = search;
    query['isTeacher'] = isTeacher;
    // 取得所有使用者
    const fetchUserList = async () => {
      setState({
        listLoading: true,
      });
      await getUsersList(query);
      setState({
        listLoading: false,
      });
    };
    fetchUserList();
  }, [nowPage, newRowsPage, search, isTeacher]);

  const onChange = (val) => {
    setState({
      isTeacher: val === 'isTeacher' ? true : false
    });
  };
  return (
    <UiAccountTable>
      <UiFilterBox>
        <UiFilterBoxLeft>
          <Select
            label={selectProps.label}
            value={selectOption}
            options={selectProps.options}
            submitHandler={selectProps.submitHandler}
          />
          <IconInput
            placeholder={IconInputProps.placeholder}
            onChange={IconInputProps.onChange}
          />
          <Checkbox
            onChange={onChange}
            label="只顯示認證教師帳號"
            checkedValue="isTeacher"
          />
        </UiFilterBoxLeft>
      </UiFilterBox>
      <Table
        data={usersListData}
        schema={schema}
        stateShowText={stateShowText}
        totalPage={total}
        ActionComponents={ActionComponents}
        changePage_Rows={changePageRows}
        nowPage={nowPage}
      />
    </UiAccountTable>
  );
};

AccountTable.propTypes = {

};


