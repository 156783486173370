import { QUERY, DEVICE } from 'constants/dimension';
import { COMPANY_TITLE } from 'constants/index';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import logo from 'assets/svg/logo.svg';
import { AvatarMenu, Icon, NotificationList, BlindBrick, SidebarToggler } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { usePage } from 'utils/hooks/usePage';
import { useResponsive } from 'utils/hooks/useResponsive';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiHeader,
  UiHeaderText,
  UiOrganizationText,
  UiClassName,
  UiNotice,
  UiNoticeNew,
  UiIsLogin,
  UiLogo,
  UiSidebarTogglerAndLogoBox,
} from './Header.style';

/**
 * Header
 */

const isCompany = process.env.REACT_APP_TITLE === COMPANY_TITLE;
const logoUrl = process.env.REACT_APP_LOGO || logo;

// eslint-disable-next-line react/prop-types
export const Header = ({ isNeedLogo = true, toggleSidebar }) => {
  const isTabletL = useResponsive({ query: QUERY.DOWN, start: DEVICE.TABLET.WIDTH_L });
  const isMobileL = useResponsive({ query: QUERY.DOWN, start: DEVICE.MOBILE.WIDTH_L });
  const [{ isOpen, isNew }, setState] = useSetState({
    isOpen: false,
    isNew: false,
  });
  const invModalToggle = () => {
    setState({
      isOpen: !isOpen,
    });
  };

  const { pageStatus } = usePage();
  const { organizationId, classId } = useParams();
  const [{ profile, myOrganization }, { fetchOrganizationById }] = useUser();
  const [{ myClasses }, { getGroupInfoById }] = useClass();
  const { dataInfo: myClassInfo } = myClasses;

  const isLogin = profile?.id;
  const customOrganizationLogoUrl = myOrganization?.organization?.customOrganizationLogoUrl;

  const getNewState = (isNew) => {
    setState({
      isNew,
    });
  };

  useEffect(() => {
    if (!classId) return;
    getGroupInfoById(classId);
  }, [classId]);

  useEffect(() => {
    if (!organizationId) return;
    fetchOrganizationById(organizationId);
  }, [organizationId]);

  return (
    <UiHeader isNeedLogo={isNeedLogo}>
      <BlindBrick className="accesskey" href="#U" id="U" title="上方功能區塊" accessKey="U" name="U" />
      <div className="container">
        <UiSidebarTogglerAndLogoBox>
          {!!classId && <SidebarToggler onToggle={toggleSidebar} />}
          {isNeedLogo && (
            <h1 className="logo">
              <Link to="/home">
                <UiLogo>
                  <img
                    src={pageStatus.isHomePage ? logoUrl : customOrganizationLogoUrl || logoUrl}
                    alt={`${
                      isCompany ? 'onelink' : process.env.REACT_APP_TARGET_ID
                    }親師生數位雲端互動平台，點擊可回到首頁`}
                    style={{ height: '100%', width: '100%' }}
                  />
                </UiLogo>
              </Link>
            </h1>
          )}
        </UiSidebarTogglerAndLogoBox>
        {isLogin && !pageStatus.isHomePage && !isMobileL && (
          <Breadcrumbs className="breadcrumbs">
            {organizationId && (
              <div className="breadcrumbItem">
                <UiHeaderText tabIndex={0}>
                  <Icon.Svg name="School" />
                  {isTabletL ? (
                    <Tooltip title={myOrganization?.organization?.officialName || ''}>
                      <UiOrganizationText isTabletL={isTabletL}>
                        {myOrganization?.organization?.officialName || ''}
                      </UiOrganizationText>
                    </Tooltip>
                  ) : (
                    <UiOrganizationText isTabletL={isTabletL}>
                      {myOrganization?.organization?.officialName || ''}
                    </UiOrganizationText>
                  )}
                </UiHeaderText>
              </div>
            )}
            {myClassInfo?.name && (
              <Typography color="textPrimary">
                <UiHeaderText>
                  {isTabletL ? (
                    <Tooltip title={myClassInfo?.name}>
                      <UiClassName isTabletL={isTabletL}>{myClassInfo?.name}</UiClassName>
                    </Tooltip>
                  ) : (
                    <UiClassName isTabletL={isTabletL}>{myClassInfo?.name}</UiClassName>
                  )}
                </UiHeaderText>
              </Typography>
            )}
          </Breadcrumbs>
        )}
        <UiIsLogin>
          {isLogin && (
            <>
              <UiNotice tabIndex={0} aria-label="加入班級通知" onClick={invModalToggle}>
                <Icon.Svg name="Notice" />
                {isNew && <UiNoticeNew></UiNoticeNew>}
                {isLogin && <NotificationList onClick={invModalToggle} isOpen={isOpen} getNewState={getNewState} />}
              </UiNotice>
            </>
          )}
          <AvatarMenu />
        </UiIsLogin>
      </div>
    </UiHeader>
  );
};

Header.propTypes = {
  isNeedLogo: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};
