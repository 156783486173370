import { format, getUnixTime } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';

const FETCH_SERVER_TIME = 'FETCH_SERVER_TIME';
// const GET_TODAY = 'GET_TODAY';

const initState = {
  timestamp: null,
  allInterval: {
    startAt: null,
    endAt: null,
  },
  futureInterval: {
    startAt: null,
    endAt: null,
  },
  pastInterval: {
    startAt: null,
    endAt: null,
  },
  processingInterval: {
    startAt: null,
    endAt: null,
  },
  todayInterval: {
    startAt: null,
    endAt: null,
  },
};

const actions = {
  fetchServerTime: (params) => ({
    type: FETCH_SERVER_TIME,
    payload: params,
  }),
  // getToday: (params) => ({
  //   type: GET_TODAY,
  //   payload: params,
  // }),
};

export const useServerTime = () => {
  const dispatch = useDispatch();
  const { timestamp } = useSelector((state) => state.serverTime);

  /* server date, 自定義格式 */
  const getToday = (formatStr = 'yyyy/MM/dd') => {
    if (!timestamp) return;
    const todayDate = new Date(timestamp);
    const todayDateText = format(todayDate, formatStr);
    return todayDateText;
  };

  // 今日日期 2022/05/04
  const today = getToday();
  // 今日完整最晚時間 Wed May 04 2022 23:59:59 GMT+0800 (台北標準時間)
  const todayLastTime = new Date(new Date(today).getTime() + 24 * 60 * 60 * 1000 - 1);
  // 今日開始時間 00:00:00
  const startAtTimeStamp = getUnixTime(new Date(today)) * 1000;
  // 今日結束時間 23:59:59
  const endAtTimeStamp = getUnixTime(new Date(todayLastTime)) * 1000;

  /* 所有時間 */
  const allInterval = {
    startAt: 0,
    endAt: 0,
  };

  /* 未來時間 */
  const futureInterval = {
    startAt: endAtTimeStamp + 2000,
    endAt: 0,
  };

  /* 過去時間 */
  const pastInterval = {
    startAt: 0,
    endAt: (getUnixTime(new Date(today)) - 1) * 1000,
  };

  /* 當前時間 */
  const processingInterval = {
    startAt: timestamp,
    endAt: timestamp,
  };

  /* 今日時間 */
  const todayInterval = {
    startAt: startAtTimeStamp,
    endAt: endAtTimeStamp,
  };

  /* 取得 server time */
  const fetchServerTime = async () => {
    const resp = await fetch(`${process.env.REACT_APP_ONEBOARD_API_URL}/Prod/oneboard/timestamp`);
    const { status, data } = await resp.json();
    if (status === 'success' && data?.timestamp) {
      dispatch(actions.fetchServerTime({ timestamp: data.timestamp }));
    }
    return {
      timestamp: data?.timestamp,
    };
  };

  return [
    { timestamp, allInterval, futureInterval, pastInterval, processingInterval, todayInterval },
    { fetchServerTime },
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_SERVER_TIME: {
      return {
        ...state,
        ...action.payload,
      };
    }
    // case GET_TODAY: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    default:
      return state;
  }
};
export default reducer;
