import {
    PROBLEM_SOLVING_CLASS
} from 'constants/index';
import { Loading } from 'components';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';


import SessionCard from '../SessionCard';
import {
    UiClassRoomWrapper,
    UiClassRoomTitleWrapper,
    UiTag,
    UiClassRoomContent,
    UiEmptyImgContainer,
    UiEmptyImg,
    UiEmptyImgFigcaption,
} from './ClassRoom.style';


const EMPTY_CLASS = '目前尚無開放教室';

export const ClassRoom = ({
    background,
    title,
    sessions,
    isLoading,
    onClickCard,
}) => {
    const today = new Date().getDate();
    const filterTodaySession = sessions.filter((item) => {
        return Number(format(item.startAt, 'dd')) === today;
    });
    const CardItem = () => {
        if (isLoading) {
            return <Loading />;
        } else if (!filterTodaySession.length) {
            return (
                <UiEmptyImgContainer>
                    <UiEmptyImg />
                    <UiEmptyImgFigcaption>{EMPTY_CLASS}</UiEmptyImgFigcaption>
                </UiEmptyImgContainer>
            );
        } else {
            return (
                <>
                    {
                        filterTodaySession.map((session) => (
                            <SessionCard
                                key={session.id}
                                type={title}
                                session={session}
                                onClick={onClickCard}
                            />
                        ))
                    }
                </>
            );
        }
    };

    return (
        <UiClassRoomWrapper background={background}>
            <UiClassRoomTitleWrapper>
                {title}
                {
                    title === PROBLEM_SOLVING_CLASS && <UiTag>New</UiTag>
                }
            </UiClassRoomTitleWrapper>
            <UiClassRoomContent>
                {CardItem()}
            </UiClassRoomContent>
        </UiClassRoomWrapper>
    );
};

ClassRoom.propTypes = {
    background: PropTypes.string,
    isLoading: PropTypes.bool,
    onClickCard: PropTypes.func,
    sessions: PropTypes.object,
    title: PropTypes.string,
};
