import { HomeGroupLayout, SessionForm } from 'components';
import React from 'react';

// import { UiHomeSessionFormPage } from './HomeSessionFormPage.style';


/**
 * 新增課程頁面
 */

export const HomeSessionFormPage = props => {
  return (
    <HomeGroupLayout>
      <SessionForm {...props} />
    </HomeGroupLayout>
  );
};



