import CryptoJS from 'crypto-js';

const KEY = 'oneclasscool';

export const encrypt = (text, key = KEY) => {
  const ciphertext = CryptoJS.AES.encrypt(text, key);
  return ciphertext.toString();
};

export const decrypt = (text, key = KEY) => {
  const bytes = CryptoJS.AES.decrypt(text, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};
