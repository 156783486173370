import { Icon as MatIcon } from '@material-ui/core';
import styled from 'styled-components';

export const UiSuggestionContainer = styled.div`
    @media (max-width: 767px) {
        margin: 0px 32px;
        padding-bottom: 32px;
    };
`;
export const UiSuggestionHeader = styled.div`
    font-size: 24px;
    display: flex;
    align-items: center;
    padding-bottom: 34px;
    border-bottom: 1px solid #DFE3E8;
    font-weight: 700;
    color: #212B36;

    @media (min-width: 767px) {
        padding: 0px 24px 34px 24px;
    };
`;

export const UiChromeIcon = styled(MatIcon)`
    width: 40px;
    height: 40px;
`;

export const UiSuggestionTitle = styled.div`
    margin-left: 25px;
`;

export const UiSuggestionContent = styled.div`
    margin: 32px 0;
    font-size: 16px;
    line-height: 24px;
    color: #212B36;

    @media (min-width: 767px) {
        padding: 0px 24px;
    };
`;

export const UiSuggestionChromeLink = styled.div`
    margin-top: 32px;
    font-size: 16px;
    line-height: 24px;
    color: #212B36;
`;

export const UiInstallButton = styled.button`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 124px;
    background-color: #FF7257;
    color: #fff;
    border-radius: 8px;
    width: 124px;
    height: 48px;
    cursor: pointer;
    font-size: 15px;

    @media (min-width: 767px) {
        margin: 0px 24px
    };
`;

export const UiInstallApkGroup = styled.div`
 @media (min-width: 767px) {
        display: none;
    };

    .apkGroup{
        &__info{
            border-top: 1px solid #DFE3E8;
            margin: 32px 0;
            padding-top: 32px;
            font-size: 16px;
        }
        &__button{
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 124px;
            background-color: #fff;
            color: #FF7257;
            border-radius: 8px;
            width: 124px;
            height: 48px;
            cursor: pointer;
            border: 1px solid #FF7257;
            font-size: 15px;
            text-decoration: none;
        }
    }
    
`;