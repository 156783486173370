import { CLASSIFICATION, CLASSIFICATION_LABEL_MAP } from 'constants/index';
import { Modal as MuiModal } from '@material-ui/core';
import { Loading, Icon, Button } from 'components';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from 'store/user';
import { getOneBoardUrlForExtraLearning } from 'utils/helper';
import { useServerTime } from 'utils/hooks/useServerTime';
// import { useAlert } from 'utils/hooks/useAlert';

import {
  UiModalContent,
  UiClose,
  UiDetail,
  UiHeader,
  UiTitle,
  UiCategory,
  UiCategoryColor,
  UiCategoryName,
  UiBody,
  UiBodyRow,
  UiBodyCol,
} from './SessionModal.style';

const CATEGORY_COLORS = {
  selfLearning: '#EC7963',
  problemSolving: '#FACD61',
};

const GRADE_TITLE = '年級';
const SUBJECT_TITLE = '科目';
const DURATION_TITLE = '時間';

const BTN = {
  notStart: {
    text: '尚未開放',
    color: 'disable',
    disabled: true,
  },
  open: {
    text: '進入教室',
    color: 'normal',
    disabled: false,
  },
  close: {
    text: '已結束',
    color: 'disable',
    disabled: true,
  },
};

export const SessionModal = ({ session, isOpen, onClose }) => {
  // const { setAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [
    {
      myOrganization: { userProfile },
    },
  ] = useUser();
  const [{ timestamp }, { fetchServerTime }] = useServerTime();
  const { classId } = useParams();
  // 教室使用狀態按鈕
  const [btnCode, setBtnCode] = useState(null);
  const { classification, courseId, durationText, eduSubjectsText, endAt, gradesText, startAt, title } = session;
  const body = [
    { title: GRADE_TITLE, text: gradesText },
    { title: SUBJECT_TITLE, text: eduSubjectsText },
    { title: DURATION_TITLE, text: durationText },
  ];

  const categoryCode =
    classification === CLASSIFICATION.SELF_LEARNING_CLASS
      ? 'selfLearning'
      : classification === CLASSIFICATION.PROBLEM_SOLVING_CLASS && 'problemSolving';

  const categoryBg = CATEGORY_COLORS[categoryCode];
  const classificationLabel = CLASSIFICATION_LABEL_MAP[classification];

  const userId = `${userProfile?.id}${classId.substring(0, 8)}`;
  const userName = userProfile?.nickname;
  const classroomUrl = getOneBoardUrlForExtraLearning({ userId, userName, courseId });

  const fetchTimestamp = async () => {
    setIsLoading(true);
    await fetchServerTime();

    if (timestamp) {
      const isNotStart = timestamp < startAt;
      const isOpen = timestamp >= startAt && timestamp <= endAt;
      const isClose = timestamp > endAt;
      if (isNotStart) setBtnCode('notStart');
      // 尚未開放
      else if (isOpen) setBtnCode('open');
      // 進入教室
      else if (isClose) setBtnCode('close'); // 已結束
    } else {
      // setAlert('無法取得 server time', 'error');
      onClose();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!userProfile) return;
    fetchTimestamp();
  }, [userProfile, session]);
  return (
    <MuiModal open={isOpen} onClose={onClose} disableAutoFocus disableEnforceFocus>
      <UiModalContent>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <UiClose>
              <Icon haveBg={false} icolor="#A1A4B1" name="close" size="1.6rem" onClick={onClose} />
            </UiClose>

            <UiDetail>
              <UiHeader>
                <UiTitle>{title}</UiTitle>
                <UiCategory>
                  <UiCategoryColor background={categoryBg} />
                  <UiCategoryName>{classificationLabel}</UiCategoryName>
                </UiCategory>
              </UiHeader>

              <UiBody>
                {body.map(({ title, text }, index) => (
                  <UiBodyRow key={title}>
                    <UiBodyCol fontWeight="500" whiteSpace="nowrap">
                      {title}
                    </UiBodyCol>
                    <UiBodyCol>{text}</UiBodyCol>
                  </UiBodyRow>
                ))}
              </UiBody>
            </UiDetail>

            <Button
              borderRadius="16px"
              btnSize="16px"
              buttonColor={BTN[btnCode]?.color}
              buttonPadding="16px"
              disabled={BTN[btnCode]?.disabled}
              isFullWidth
              onClick={() => window.open(classroomUrl, '_blank')}
            >
              {BTN[btnCode]?.text}
            </Button>
          </>
        )}
      </UiModalContent>
    </MuiModal>
  );
};

SessionModal.propTypes = {
  session: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
