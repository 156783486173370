import { minute } from 'constants/index';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getSessions as getSessionsApi,
  createSession as createSessionApi,
  getMySessions as getMySessionsApi,
  attendanceSession as attendanceSessionApi
} from 'services/api/home/sessions';
import {
  getOrganizationSession as getOrganizationSessionApi,
  createOrganizationGroupSession as createOrganizationGroupSessionApi,
  updateOrganizationGroupSession as UpdateOrganizationGroupSessionApi,
  getSessionSummary as getSessionSummaryApi,
  changeSessionRecordingStatus as changeSessionRecordingStatusApi,
  deleteOrganizationGroupSession as deleteOrganizationGroupSessionApi
} from 'services/api/organization/session';
import { convertArrayToMap } from 'utils/array';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';

const GET_SESSIONS = 'GET_SESSIONS';
const CLEAR_SESSIONS = 'CLEAR_SESSIONS';
const GET_ORG_SESSIONS = 'GET_ORG_SESSIONS';
const CREATE_SESSION = 'CREATE_SESSION';
const CREATE_ORG_SESSION = 'CREATE_ORG_SESSION';
const EDIT_ORG_SESSION = 'EDIT_ORG_SESSION';
const GET_SESSION_SUMMARY = 'GET_SESSION_SUMMARY';
const GET_MY_SESSIONS = 'GET_MY_SESSIONS';

const initState = {
  sessions: {
    data: [],
    dataMaps: null,
    total: 0
  },
  mySessions: {
    data: [],
    dataMaps: null,
    total: 0
  },
  sessionSummary: null
};

const actions = {
  getSessions: sessions => ({
    type: GET_SESSIONS,
    payload: { sessions },
  }),
  clearSessions: () => ({
    type: CLEAR_SESSIONS,
  }),
  getOrganizationSessions: sessions => ({
    type: GET_ORG_SESSIONS,
    payload: { sessions },
  }),
  createSession: params => ({
    type: CREATE_SESSION,
    payload: params
  }),
  createOrganizationSession: params => ({
    type: CREATE_ORG_SESSION,
    payload: params
  }),
  updateOrganizationSession: params => ({
    type: EDIT_ORG_SESSION,
    payload: params
  }),
  getSessionSummary: data => ({
    type: GET_SESSION_SUMMARY,
    payload: data
  }),
  getMySessions: data => ({
    type: GET_MY_SESSIONS,
    payload: data,
  }),
};

export const useSessions = () => {
  const { setAlert } = useAlert();
  const { organizationId, classId, sessionId, timeSpanId } = useParams();
  const dispatch = useDispatch();
  const { sessions, sessionSummary, mySessions } = useSelector(state => state.sessions);
  const isCanEnterClass = (startAt, endAt, serverTimestamp) => {
    let enterClass = {
      canEnter: false,
      type: '',
    };

    if (serverTimestamp >= endAt && serverTimestamp <= endAt + 5400000) {
      /**
      * 上課時間結束後90分
        教師端按鍵文字為 回到課程
        學生端按鍵文字為 回到課程
     */
      enterClass = {
        canEnter: true,
        type: 'after90',
      };
    } else if (serverTimestamp >= endAt) {
      /**
       * 教師端按鍵文字為查看課程
         學生端按鍵文字為複習課程
       */
      enterClass.type = 'end';
    }

    if (serverTimestamp >= startAt - 600000 && serverTimestamp < endAt) {
      /**
       * 上課時間前10分開始
          教師端按鍵文字為進入教室
          學生端按鍵文字為進入教室
       */
      enterClass = {
        canEnter: true,
        type: 'before10',
      };
    } else if (serverTimestamp >= startAt - 1200000 && serverTimestamp < endAt) {
      /**
       * 上課時間前20分
          教師端按鍵文字為進入教室
          學生端按鍵文字為預習課程
       */
      enterClass = {
        canEnter: true,
        type: 'before20'
      };
    } else if (serverTimestamp < startAt && serverTimestamp < endAt) {
      /**
       * 上課時間未到
          按鍵文字為預習課程
       */
      enterClass = {
        canEnter: true,
        type: 'noStart',
      };
    }

    return enterClass;
  };

  const isToday = (startAt, nowAt) => {
    const startDate = format(new Date(startAt), 'yyyy/MM/dd');
    const nowDate = format(new Date(nowAt), 'yyyy/MM/dd');
    const startTimestamp = new Date(startDate).getTime();
    const nowTimestamp = new Date(nowDate).getTime();
    return startTimestamp === nowTimestamp;
  };

  const getSessions = async (params, serverTimestamp) => {
    const { data, isSuccess } = await getSessionsApi(classId)(params);
    if (!isSuccess) return;
    const { sessions, total } = data;
    const newSessions = sessions.map(item => ({
      ...item,
      subject: item.subjects.length > 0 && item.subjects[0].name,
      startDate: format(new Date(item.startAt), 'yyyy-MM-dd'),
      startTime: `${format(new Date(item.startAt), 'HH:mm')}
       ~ ${format(new Date(item.endAt), 'HH:mm')} ( ${((item.endAt - item.startAt) / 1000 / 60)}分 )`,
      duration: ((item.endAt - item.startAt) / 1000 / 60) + '分鐘',
      isCanEnterClass: isCanEnterClass(item.startAt, item.endAt, serverTimestamp),
      exam: '',
    }));
    dispatch(actions.getSessions({ data: newSessions, total }));
  };

  const getOrganizationSessions = async params => {
    const { data, isSuccess } = await getOrganizationSessionApi(organizationId, classId)(params);
    if (!isSuccess) return;
    const { sessions, total } = data;
    const recordingStatusText = {
      ready: '未錄影',
      recording: '錄影中',
      stopped: '錄影結束',
      notStart: '課程尚未開始',
      isFinished: '課程已結束'
    };
    const newSessions = sessions.map(item => ({
      ...item,
      subject: item.subjects.length > 0 && item.subjects[0].name,
      startDate: format(new Date(item.startAt), 'yyyy-MM-dd'),
      startTime: format(new Date(item.startAt), 'HH:mm'),
      duration: ((item.endAt - item.startAt) / 1000 / 60) + '分鐘',
      isCanEnterClass: isCanEnterClass(item.startAt, item.endAt),
      recordingStatusText: recordingStatusText[item.recordingStatus]
    }));
    const sessionsMap = convertArrayToMap(newSessions, 'id');
    dispatch(actions.getOrganizationSessions({ data: newSessions, dataMaps: sessionsMap, total }));
  };

  const createSession = async params => {
    try {
      dispatch(actions.createSession(params));
      const { isSuccess, error } = await createSessionApi(classId)(params);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
      return isSuccess;
    } catch (error) {
      const { errorCode } = error;
      const ERROR_MESSAGE = () => {
        switch (errorCode) {
          case 40022:
            return '請選擇其他授課老師';
          default:
            return ALERT_MESSAGE.CREATE_FAIL;
        }
      };
      setAlert(ERROR_MESSAGE(), 'error', errorCode);
    }
  };

  const createOrganizationSession = async params => {
    try {
      dispatch(actions.createOrganizationSession(params));
      const { isSuccess, error } = await createOrganizationGroupSessionApi(organizationId, classId)(params);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
      return isSuccess;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error', errorCode);
    }
  };

  const updateOrganizationSession = async params => {
    try {
      dispatch(actions.updateOrganizationSession(params));
      const { isSuccess, error } = await UpdateOrganizationGroupSessionApi(organizationId, classId, sessionId)(params);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      return isSuccess;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
  };

  const clearSessions = () => dispatch(actions.clearSessions());

  const getSessionSummary = async () => {
    const { isSuccess, data } = await getSessionSummaryApi(organizationId, sessionId, timeSpanId)();
    if (!isSuccess) return;
    dispatch(actions.getSessionSummary(data));
  };

  const getMySessions = async (params, serverTimestamp) => {
    const { isSuccess, data: { sessions, total } } = await getMySessionsApi(params);
    if (!isSuccess) return;
    const nowTime = serverTimestamp;
    sessions.forEach(item => {
      item.during = (item.endAt - item.startAt) / minute;
      if (item.endAt < nowTime) {
        item.classType = 'over';
      }
      else if (nowTime > (item.startAt - minute) && item.endAt > nowTime) {
        item.classType = 'class';
      }
      else if (isToday(item.startAt, nowTime)) {
        item.classType = 'pendding';
      }
      else if (item.startAt > nowTime) {
        item.classType = 'yet';
      } else {
        item.classType = 'over';
      }
    });
    dispatch(actions.getMySessions({ sessions, total }));
  };

  const attendanceSession = async (id, timeSpanId, payload) => {
    const { isSuccess, data } = await attendanceSessionApi(id, timeSpanId)(payload);
    if (!isSuccess) {
      setAlert(ALERT_MESSAGE.ATTENDANCE_FAIL, 'fail');
      return;
    }
    setAlert(ALERT_MESSAGE.ATTENDANCE_SUCCESS, 'success');
    const newSessions = sessions.data.map((item) => {
      if (item.id === data.id) {
        return {
          ...item,
          ...data,
          subject: data.subjects.length > 0 && data.subjects[0].name,
          startDate: format(new Date(data.startAt), 'yyyy-MM-dd'),
          startTime: `${format(new Date(item.startAt), 'HH:mm')}
          ~ ${format(new Date(item.endAt), 'HH:mm')} ( ${((item.endAt - item.startAt) / 1000 / 60)}分 )`,
          duration: ((data.endAt - data.startAt) / 1000 / 60) + '分鐘',
          isCanEnterClass: isCanEnterClass(data.startAt, data.endAt)
        };
      }
      return item;
    });
    const sessionsMap = convertArrayToMap(newSessions, 'id');
    dispatch(actions.getOrganizationSessions({ data: newSessions, dataMaps: sessionsMap, total: sessions.total }));
  };

  const changeSessionRecordingStatus = async (sessionId, timeSpanId, payload) => {
    const { isSuccess, data } = await changeSessionRecordingStatusApi(organizationId, sessionId, timeSpanId)(payload);
    if (!isSuccess) {
      setAlert('錄影狀態變更失敗', 'fail');
      return isSuccess;
    }
    if (data.recordingStatus === 'stopped') {
      setAlert('課程錄影結束', 'success');
    } else {
      setAlert('課程錄影開始', 'success');
    }
    return isSuccess;
  };

  const deleteOrganizationGroupSession = async (sessionId, timeSpanId) => {
    const { isSuccess } = await deleteOrganizationGroupSessionApi(organizationId, classId, sessionId, timeSpanId)();
    if (!isSuccess) {
      setAlert('刪除課程失敗', 'fail');
    } else {
      setAlert('刪除課程成功', 'success');
    }
    return isSuccess;
  };

  return [
    { sessions, sessionSummary, mySessions }, // state
    {
      getSessions,
      clearSessions,
      createSession,
      getOrganizationSessions,
      createOrganizationSession,
      updateOrganizationSession,
      getSessionSummary,
      getMySessions,
      attendanceSession,
      changeSessionRecordingStatus,
      deleteOrganizationGroupSession
    }, // eventHanlder
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_SESSIONS: {
      const { data, total } = action.payload.sessions;
      return {
        ...state,
        sessions: {
          data,
          total,
          dataMap: convertArrayToMap(data, 'id')
        },
      };
    }
    case CLEAR_SESSIONS: {
      return {
        ...state,
        sessions: {
          ...initState.sessions
        }
      };
    }
    case GET_ORG_SESSIONS: {
      return {
        ...state,
        sessions: action.payload.sessions,
      };
    }
    case GET_SESSION_SUMMARY: {
      return {
        ...state,
        sessionSummary: action.payload
      };
    }
    case GET_MY_SESSIONS: {
      return {
        ...state,
        mySessions: {
          data: action.payload.sessions,
          total: action.payload.total
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
