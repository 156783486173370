import PropTypes from 'prop-types';
import React from 'react';
import { UiSectionCard, UiSectionCardTitle, UiSectionCardContent } from './SectionCard.style';


/**
 * 在這邊描述這個組件
 */

export const SectionCard = ({
  title = '',
  primary = false,
  secondary = false,
  children
}) => {
  return (
    <UiSectionCard>
      <UiSectionCardTitle
        primary={primary}
        secondary={secondary}
      >
        {title}
      </UiSectionCardTitle>
      <UiSectionCardContent>
        {children}
      </UiSectionCardContent>
    </UiSectionCard>
  );
};

SectionCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  primary: PropTypes.bool,
  secondary: PropTypes.bool
};


