import { HomeGroupLayout, SecretDiscuss } from 'components';
import React from 'react';
// import { UiSecretDiscussPage } from './SecretDiscussPage.style';

/**
 * 導師商談頁面
 */

export const SecretDiscussPage = () => {
  return (
    <HomeGroupLayout>
      <SecretDiscuss />
    </HomeGroupLayout>
  );
};

