import { QUERY, DEVICE } from 'constants/dimension';
import styled from 'styled-components';
import { useResponsive } from 'utils/hooks/useResponsive';

export const UiSessionGroup = styled('div')(() => {
  return {
    flex: 1,
    '> p': {
      fontSize: '1rem',
      color: '#3a4052',
    },
  };
});

export const UiDashBoardCardContainer = styled('div')(() => {
  const isTabletS = useResponsive({ query: QUERY.DOWN, start: DEVICE.TABLET.WIDTH_S });
  const isMobileL = useResponsive({ query: QUERY.DOWN, start: DEVICE.MOBILE.WIDTH_L });
  return {
    ...(isTabletS && {
      display: 'flex',
      flexDirection: isMobileL ? 'column' : 'row',
      flexWrap: 'wrap',
      gap: '16px',
      justifyContent: 'space-between',
    }),
  };
});

export const UiExpandTogglerBox = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '4px',
    '& .MuiButtonBase-root': {
      padding: '4px 12px',
    },
    '& .MuiButton-label': {
      gap: '8px',
    },
    '& svg': {
      fontSize: '16px',
    },
  };
});
