import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getHomeworks as getHomeworksApi,
  createHomework as createHomeworkApi,
  updateHomework as updateHomeworkApi,
  pinHomework as pinHomeworkApi,
  cancelPinHomework as cancelPinHomeworkApi,
  deleteHomework as deleteHomeworkApi,
  getSubmittedHomeworks as getSubmittedHomeworksApi,
  submitHomework as submitHomeworkApi,
  getUserSubmissions as getUserSubmissionsApi
} from 'services/api/home/homework';
import { convertArrayToMapById } from 'utils/array';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';

const GET_HOMEWORKS = 'GET_HOMEWORKS';
const CLEAR_HOMEWORKS = 'CLEAR_HOMEWORKS';
const CREATE_HOMEWORK = 'CREATE_HOMEWORK';
const UPDATE_HOMEWORK = 'UPDATE_HOMEWORK';
const PIN_HOMEWORK = 'PIN_HOMEWORK';
const CANCEL_PIN_HOMEWORK = 'CANCEL_PIN_HOMEWORK';
const DELETE_HOMEWORK = 'DELETE_HOMEWORK';
const GET_SUBMITTED_HOMEWORKS = 'GET_SUBMITTED_HOMEWORKS';
const SUBMIT_STUDENT_HOMEWORK = 'SUBMIT_STUDENT_HOMEWORK';
const GET_USER_SUBMISSIONS = 'GET_USER_SUBMISSIONS';

const initState = {
  homeworks: {
    data: [],
    dataMap: {},
    total: 0,
  },
  submittedHomeworks: {
    data: [],
    total: 0
  },
  userSubmittedHomeworks: {
    data: [],
    total: 0
  },
};

const actions = {
  getHomeworks: ({ data, total }) => ({
    type: GET_HOMEWORKS,
    payload: { data, total }
  }),
  clearHomeworks: () => ({
    type: CLEAR_HOMEWORKS,
  }),
  createHomework: () => ({
    type: CREATE_HOMEWORK,
  }),
  updateHomework: () => ({
    type: UPDATE_HOMEWORK,
  }),
  pinHomework: params => ({
    type: PIN_HOMEWORK,
    payload: params
  }),
  cancelPinHomework: params => ({
    type: CANCEL_PIN_HOMEWORK,
    payload: params
  }),
  deleteHomework: params => ({
    type: DELETE_HOMEWORK,
    payload: params
  }),
  getSubmittedHomeworks: ({ data, total, mission }) => ({
    type: GET_SUBMITTED_HOMEWORKS,
    payload: { data, total, mission }
  }),
  submitStudentHomework: () => ({
    type: SUBMIT_STUDENT_HOMEWORK,
  }),
  getUserSubmissions: ({ data, total }) => ({
    type: GET_USER_SUBMISSIONS,
    payload: { data, total }
  })
};

export const useHomework = () => {
  const { classId, homeworkId, studentId } = useParams();

  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const { homeworks, submittedHomeworks, userSubmittedHomeworks } = useSelector(state => state.homework);

  const getHomeworks = async params => {
    const { isSuccess, data } = await getHomeworksApi(classId)(params);
    if (!isSuccess) return;
    const { missions, total } = data;
    dispatch(actions.getHomeworks({ data: missions, total }));
    return data;
  };

  const clearHomeworks = () => dispatch(actions.clearHomeworks());


  const createHomework = async params => {
    try {
      const { isSuccess, data, error } = await createHomeworkApi(classId)(params);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error', errorCode);
      return null;
    }
  };
  //更新作業
  const updateHomework = async params => {
    try {
      const { isSuccess, data, error } = await updateHomeworkApi(classId)(params);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return null;
    }
  };

  //學生上傳作業
  const submitStudentHomework = async params => {
    const { isSuccess, data, error } = await submitHomeworkApi(classId)(params);
    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return null;
    }
  };

  // 置頂作業
  const pinHomework = async id => {
    dispatch(actions.pinHomework({ classId, homeworkId: id }));
    try {
      const { isSuccess, data, error } = await pinHomeworkApi(classId, id)();
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return null;
    }
  };

  // 取消置頂作業
  const cancelPinHomework = async id => {
    dispatch(actions.cancelPinHomework({ classId, homeworkId: id }));
    try {
      const { isSuccess, data, error } = await cancelPinHomeworkApi(classId, id)();
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return null;
    }
  };

  // 刪除作業
  const deleteHomework = async id => {
    dispatch(actions.deleteHomework({ classId, homeworkId: id }));
    try {
      const { isSuccess, data, error } = await deleteHomeworkApi(classId, id)();
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
      return null;
    }
  };

  const getSubmittedHomeworks = async params => {
    const { isSuccess, data } = await getSubmittedHomeworksApi(classId, homeworkId)(params);
    if (!isSuccess) return;
    const { submissions, mission, total } = data;
    dispatch(actions.getSubmittedHomeworks({ data: submissions, total, mission }));
  };

  const getUserSubmissions = async params => {
    const { isSuccess, data } = await getUserSubmissionsApi(classId, studentId)(params);
    if (!isSuccess) return;
    const { missions, total } = data;
    dispatch(actions.getUserSubmissions({ data: missions, total }));
    return data;
  };

  useEffect(() => {
    clearHomeworks();
  }, [classId, homeworkId]);

  return [
    { homeworks, submittedHomeworks, userSubmittedHomeworks }, // state
    {
      getHomeworks,
      clearHomeworks,
      createHomework,
      updateHomework,
      pinHomework,
      cancelPinHomework,
      deleteHomework,
      getSubmittedHomeworks,
      submitStudentHomework,
      getUserSubmissions
    }, // eventHanlder
  ];
};



const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_HOMEWORKS: {
      const { data, total } = action.payload;
      const newData = state.homeworks.data.concat(data);
      return {
        ...state,
        homeworks: {
          data: newData,
          dataMap: convertArrayToMapById(newData),
          total
        }
      };
    }
    case CLEAR_HOMEWORKS: {
      return {
        ...state,
        homeworks: {
          ...initState.homeworks
        }
      };
    }
    case GET_SUBMITTED_HOMEWORKS: {
      const { data, total, mission } = action.payload;
      return {
        ...state,
        submittedHomeworks: {
          data,
          total,
          mission
        }
      };
    }
    case GET_USER_SUBMISSIONS: {
      const { data, total } = action.payload;
      return {
        ...state,
        userSubmittedHomeworks: {
          data,
          total
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;
