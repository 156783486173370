import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const UiLineConnectSuccessState = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& > .connectMessageBox': {
    marginTop: '68px',
    '& > .connectMessage': {
      color: '#2F3649',
      fontFamily: 'Noto Sans TC',
      fontSize: '1.5rem',
      fontWeight: 500,
    },
  },
}));
