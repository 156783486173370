import { List, Loading } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useClass } from 'store/class';
import { useServerTime } from 'store/serverTime/index';
import { deactivationPageValid } from 'utils/validation';
import { UiSideBarFloat } from './SideBarFloat.style';

const BASE_NAVS = [
  {
    text: '回首頁',
    icon: 'BackToList',
    url: '/home',
  },
];
const NAVS = [
  {
    text: '簡介',
    icon: 'Introduction',
    path: 'info',
    permissions: ['brief'],
  },
  {
    text: '公告',
    icon: 'Bullhorn',
    path: 'announcement',
    permissions: ['announcement'],
  },
  {
    text: '課程',
    icon: 'BookReader',
    path: 'sessions',
    permissions: ['session'],
  },
  {
    text: '作業',
    icon: 'Homework',
    path: 'homeworks',
    permissions: ['mission'],
  },
  {
    text: '學習資源',
    icon: 'Lightbulb',
    permissions: ['onlineExam', 'onlineQuiz', 'multimediaLearning', 'supplementaryResource'],
    subNavs: [
      {
        text: '線上測驗',
        // icon: 'Exam',
        path: 'onlineExam',
        permissions: ['onlineExam'],
      },
      {
        text: '線上派卷',
        // icon: 'ScrollSolid',
        path: 'exam',
        permissions: ['onlineQuiz'],
      },
      {
        text: '影音學習',
        // icon: 'FullVideo',
        path: 'video',
        permissions: ['multimediaLearning'],
      },
      {
        text: '教學資源',
        // icon: 'Shapes',
        path: 'supplementary',
        permissions: ['supplementaryResource'],
      },
    ],
  },
  {
    text: '課外學習',
    path: 'extraLearning',
    icon: 'ExtraLearning',
    permissions: ['extraLearning'],
  },
  {
    text: '社群',
    icon: 'Comments',
    permissions: ['post', 'album'],
    subNavs: [
      {
        text: '貼文',
        // icon: 'CommentAlt',
        path: 'paste',
        permissions: ['post'],
      },
      {
        text: '相簿',
        // icon: 'Album',
        path: 'album',
        permissions: ['album'],
      },
    ],
  },
  {
    text: '導師商談室',
    icon: 'Community',
    path: 'secretdiscuss',
    permissions: ['counseling'],
  },
  {
    text: '學習歷程',
    icon: 'Stopwatch',
    path: 'summary',
    permissions: ['setting'], // 跟著「設定」的權限
  },
  {
    text: '常用資源',
    icon: 'AttachFile',
    path: 'webResource',
    permissions: ['webResource'],
  },
  {
    text: '成員',
    path: 'member',
    icon: 'Users',
    permissions: ['member'],
  },
  {
    text: '設定',
    path: 'setting',
    icon: 'Cog',
    permissions: ['setting'],
  },
];

/**
 * 平板以下用的 sidebar
 */
export const SideBarFloat = () => {
  const [
    {
      myClasses: { dataInfo },
    },
  ] = useClass();
  const [{ timestamp: serverTimestamp }] = useServerTime();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const permissions = dataInfo.navigationFunctions;

  /* 取得啟用中的 navs */
  const getActivationNavs = (navs, serverTimestamp) => {
    const newNavs = navs
      .filter((nav) => {
        // 過濾掉第一層停用的頁面
        const isDeactivate = deactivationPageValid({
          item: nav,
          componentName: 'SideBarFloat',
          nowAt: serverTimestamp,
        });
        return !isDeactivate;
      })
      .map((nav) => {
        // 過濾掉第二層停用的頁面
        if (nav?.subNavs) {
          const newSubNavs = nav.subNavs.filter((item) => {
            const isDeactivate = deactivationPageValid({ item, componentName: 'SideBarFloat', nowAt: serverTimestamp });
            return !isDeactivate;
          });
          return {
            ...nav,
            subNavs: newSubNavs,
          };
        } else {
          return nav;
        }
      });
    return newNavs;
  };

  const navs = useMemo(() => {
    if (!permissions?.length || !serverTimestamp) return [];
    const activationNavs = getActivationNavs(NAVS, serverTimestamp);
    const paths = location.pathname.split('/');
    const lastPath = paths.pop();
    const basePath = paths.join('/');
    // 符合權限的 nav
    const matchedPermissionNavs = activationNavs.reduce((prev, nav) => {
      // 有子層的 nav
      if (nav?.subNavs) {
        // 有權限顯示的 subNavs
        const authorizedSubNavs = nav.subNavs.filter((subNav) => {
          return permissions.some((permission) => subNav.permissions.includes(permission));
        });
        // 設定是否為當下頁面、url 給 subNavs
        const newSubNavs = authorizedSubNavs.map((subNav) => {
          const isActive = subNav.path === lastPath;
          const url = `${basePath}/${subNav.path}`;
          return {
            ...subNav,
            isActive,
            url,
          };
        });
        // 是否 nav 內的 subNavs 為當下的頁面
        const isActiveForNav = authorizedSubNavs.some((subNav) => subNav.path === lastPath);
        const newNav = { ...nav, isActive: isActiveForNav, nestedList: newSubNavs };
        authorizedSubNavs.length && prev.push(newNav);
      }
      // 有權限的 nav
      else if (nav?.permissions) {
        // 有權限顯示的 navs
        const isAuthorization = permissions.some((permission) => nav.permissions.includes(permission));
        const isActive = nav.path === lastPath;
        const url = `${basePath}/${nav.path}`;
        const newNav = {
          ...nav,
          isActive,
          url,
        };
        isAuthorization && prev.push(newNav);
      }
      return prev;
    }, []);
    const correctNavs = [...BASE_NAVS, ...matchedPermissionNavs];
    return correctNavs;
  }, [permissions, serverTimestamp]);

  useEffect(() => {
    if (permissions) setIsLoading(false);
  }, [permissions]);

  return <UiSideBarFloat>{isLoading ? <Loading /> : <List list={navs} />}</UiSideBarFloat>;
};
