import {
	TableBody,
	TableCell,
	TableRow,
	Tabs as MatTabs,
} from '@material-ui/core';
import styled from 'styled-components';

export const UiHomeOnlineExamTable = styled.div`
	display: block;
`;

export const UiAlertBox = styled('div')(() => ({
	marginTop: '1rem',
	'.MuiAlert-message': {
		color: '#242C3F',
	}
}));

export const UiTitle = styled.div`
	font-size: 20px;
	color: #242c3f;
	margin-bottom: 1.5rem;
`;

export const UiTitleRow = styled(TableRow)`
	background-color: #fafafc;
`;

export const UiContentRow = styled(TableRow)`
	background-color: ${({ isCollapseOpen }) => isCollapseOpen ? '#f2f4f8' : '#fff'};
`;

export const UiTBody = styled(TableBody)`
	background-color: #fafafc;
`;

export const UiCell = styled(TableCell)`
	padding: 16px;
`;

export const UiTableBody = styled(TableBody)`
	td {
		border-bottom: none;
	}
`;

export const UiMatTabs = styled(MatTabs)`
	display: block;
	margin: 1rem 0;

	.MuiTab-textColorInherit.Mui-selected {
		color: #ec7963;
	}

	> div > span {
		background-color: #ec7963;
	}
`;

export const UiSelectBox = styled.div`
	display: flex;

	> div:first-child {
		margin-right: 20px;
	}
`;

export const UiIconBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: min-content;
	background-color: #fafafc;
	border-radius: 8px;

	svg {
		position: relative;
		top: 6px;
		left: 4px;
		font-size: 2.3rem;
		cursor: pointer;
	}

	> span {
		margin: 0 0.5rem;
	}

	> div {
		width: 0.4px;
		height: 32px;
		background-color: #d5d7de;
	}
`;

export const UiPdf = styled.span`
	svg {
		> path {
			fill: #121232;
		}

		&:hover {
			> path {
				fill: #2a2a47;
			}
		}
	}
`;

export const UiPlane = styled.span`
	svg {
		> path {
			fill: #ec7963;
		}

		&:hover {
			> path {
				fill: #ee8773;
			}
		}
	}
`;

export const UiNoDataBox = styled.div`
	width: 100%;
`;

export const UiPictureBox = styled.div`
	display: block;
	margin: auto;
	width: 36%;
	height: 240px;
	background: url("${({ path }) => path}");
	background-position: center;
	background-size: cover;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
`;

export const UiNoDataText = styled.div`
	margin-top: 4rem;
	font-size: 16px;
	text-align: center;
	color: #3a4052;
	line-height: 2;
`;

export const UiEditBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	padding: 4px;
	width: min-content;
	background: #fafafc;
	border: 0.6px solid #d5d7de;
	border-radius: 8px;
	box-sizing: border-box;
	cursor: pointer;
	opacity: ${({ disabled }) => disabled ? '0.6' : '1'};

	> span {
		margin: 0 0.5rem;
	}

	> div {
		width: 0.4px;
		height: 32px;
		background-color: #d5d7de;
	}
`;

export const UiTabBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const UiTabItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px 0;
	width: 48%;
	font-size: 16px;
	color: #242c3f;
	border: 1px solid #d5d7de;
	border-radius: 8px;
	line-height: 24px;
	cursor: pointer;
	transition: 0.35s;

	&.active {
		color: #fff;
		background: #121232;
		border: 1px solid #121232;
	}

	& > * + * {
		margin-left: 18px;
	}
`;

export const UiFormBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
	padding: 10px 0;
	border: 1px solid #e4e7ec;
	border-radius: 8px;
	flex-wrap: wrap;

	&::after {
		position: absolute;
		top: -8px;
		left: ${({ position }) => position};
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 8px 8px;
		border-color: transparent transparent #e4e7ec transparent;
		transition: 0.35s;
		content: '';
	}

	&::before {
		position: absolute;
		top: -7px;
		left: ${({ position }) => position};
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 8px 8px;
		border-color: transparent transparent #fff transparent;
		z-index: 1;
		transition: 0.35s;
		content: '';
	}
`;
