import styled from 'styled-components';

export const UiGroupCard = styled.div`
  position: relative;
  overflow: hidden;
  width: 160px;
  height: 240px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.35s;
  cursor: pointer;

  & > div {
    padding: 10px 12px;
    color: #454b5c;
    letter-spacing: 2px;
    line-height: 1.5;

    & > div:nth-child(1) {
      font-size: 11px;
      color: #a1a4b1;
      opacity: 0.8;
    }

    & > div:nth-child(2) {
      font-size: 1rem;
      overflow: hidden;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &:hover {
    box-shadow: 0 6px 7px rgba(0, 0, 0, 0.2), 0 5px 6px rgba(0, 0, 0, 0.17);
  }
`;

export const UiGroupsImage = styled.div`
  width: 100%;
  height: 160px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eee;
  background-image: ${(props) => `url(${props.url}) `};
`;

export const UiRoleIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0 !important;

  > .tag {
    padding: 2px 8px;
    font-size: 11px;
    color: #fff;
    background: #121232;
    border-radius: 4px;
    line-height: 16px;
  }
`;

export const UiGroupsPrompt = styled.div`
  margin: 0.25rem 0 1rem;
  padding: 15px;
  width: 100%;
  font-size: 1.3rem;
  color: #f00;
`;
