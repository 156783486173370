import { Avatar as MatAvatar } from '@material-ui/core';
import styled from 'styled-components';

export const UiAvatar = styled(MatAvatar)`
	margin: ${({ isSecret }) => isSecret ? '0.5rem 0.5rem 0 0 ' : '0'};
	width: 2.5rem;
	height: 2.5rem;
`;

export const UiLikeListModal = styled.div`
	width: 550px;
	height: 500px;
	background-color: #fff;
	border-radius: 16px;
`;

export const UiTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.3rem 1.75rem;
	border-bottom: 1px solid #f2f4f8;
`;

export const UiClose = styled.div`
	width: max-content;
	width: 24px;
	cursor: pointer;
	height: 24px;

	&:hover {
		opacity: 0.8;
	}
`;
export const UiLikeBox = styled.div`
	display: flex;
	align-items: center;

	& span {
		display: flex;
		align-items: center;
		padding: 0 0.5rem;
		height: max-content;
		font-size: 12px;
		background-color: #f2f4f8;
		border-radius: 4px;

		&:hover {
			font-weight: 700;
		}
	}

	& > svg {
		margin-right: 5px;
		font-size: 2rem;
	}
`;

export const UiContent = styled.div`
	overflow-x: auto;
	padding: 1.3rem 1.75rem;
	height: 29rem;
`;

export const UiContentList = styled.div`
	display: flex;
	align-items: center;
	margin: 1rem 0;

	& > div {
		margin-right: 1rem;
	}

	&:first-child {
		margin-top: 0;
	}

	& span {
		font-size: 14px;
		font-weight: 700;
	}
`;