import { ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { BsHeaderTab } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useUser } from 'store/user';

/**
 * 資源列表page tab
 */

export const BsResourceManagementPageHeaderTab = ({ activeIndex = 0 }) => {
  const { organizationId } = useParams();
  const [{ myOrganization: { organization } }] = useUser();
  const history = useHistory();
  const goPath = path => history.push(path);

  const [tabData, setTabData] = useState([
    { label: '機構資源', onClick: () => goPath(`/organization/${organizationId}/resources`) }
  ]);

  useEffect(() => {
    if (organization.accessToPublicResourceSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW) {
      setTabData(prev => [
        ...prev,
        { label: '公開資源', onClick: () => goPath(`/organization/${organizationId}/resources/public`) }
      ]);
    }
  }, [organization]);

  return (
    <BsHeaderTab data={tabData} activeIndex={activeIndex} />
  );
};

BsResourceManagementPageHeaderTab.propTypes = {
  activeIndex: PropTypes.number
};



