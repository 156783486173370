import { QUERY } from 'constants/dimension';
import { useMediaQuery, useTheme } from '@material-ui/core';

export const useResponsive = ({ query, start, end }) => {
  const theme = useTheme();
  const mediaUp = useMediaQuery(theme.breakpoints.up(start), { noSsr: true });
  const mediaDown = useMediaQuery(theme.breakpoints.down(start), { noSsr: true });
  const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end), { noSsr: true });

  if (query === QUERY.UP) {
    return mediaUp;
  }
  if (query === QUERY.DOWN) {
    return mediaDown;
  }
  if (query === QUERY.BETWEEN) {
    return mediaBetween;
  }
};
