import { Box, TableRow } from '@material-ui/core';
import styled from 'styled-components';

export const UiHomeAttendanceStudent = styled.div`
  margin: 0 auto;
`;

export const UiGoBackButtonBox = styled.div`
  margin-bottom: 18px;
`;

export const UiGoBackButton = styled.div`
  display: flex;
  color: #8b90a0;
  font-weight: 400;
  font-size: 14px;
  font-family: Noto Sans TC;
  cursor: pointer;
  width: fit-content;
`;

export const UiFlexBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #242c3f;
  margin-bottom: 28px;
`;

export const UiTitleRow = styled(TableRow)`
  height: 56px;
  background-color: #fafafc;
  padding: 16px 24px;
  box-sizing: border-box;
`;

export const SortImg = styled.img`
  display: inline-block;
  padding-left: 5px;
`;

export const Pointer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BodyRow = styled(TableRow)`
  height: 56px;
  padding: 16px 24px;
  box-sizing: border-box;
`;

export const NoCheck = styled.span`
  color: #b72136;
  font-size: 14px;
  line-height: 24px;
  background: rgba(236, 121, 99, 0.16);
  border-radius: 6px;
  padding: 1px 8px;
`;

export const ReviseCheck = styled.span`
  color: #229a16;
  font-size: 14px;
  line-height: 24px;
  background: rgba(84, 214, 44, 0.16);
  border-radius: 6px;
  padding: 1px 8px;
`;
