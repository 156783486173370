import { HomeMemberCenterLayout, HomeMemberChangePassword } from 'components';
import React from 'react';


/**
 * 在這邊描述這個組件
 */

export const HomeMemberChangePasswordPage = () => {
  return (
    <HomeMemberCenterLayout>
      <HomeMemberChangePassword />
    </HomeMemberCenterLayout>
  );
};
