import {
  HomeGroupLayout,
  HomeMemberTable
} from 'components';
import React from 'react';

/**
 * 前台 成員頁面
 */

export const HomeMemberPage = () => {
  return (
    <HomeGroupLayout>
      <HomeMemberTable />
    </HomeGroupLayout>
  );
};

HomeMemberPage.propTypes = {

};


