import {
	Tabs as MatTabs,
} from '@material-ui/core';
import styled from 'styled-components';

export const UiHomeSupplementaryMaterialsTable = styled.div`
	.flex {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> h4 {
			font-size: 20px;
			font-weight: 100;
			color: #242c3f;
			line-height: 30px;
		}
	}
`;

export const UiAlertBox = styled('div')(() => ({
	marginTop: '1rem',
	'.MuiAlert-message': {
		color: '#242C3F',
	}
}));

export const UiMatTabs = styled(MatTabs)`
	display: block;
	margin: 1rem 0;

	.MuiTab-textColorInherit.Mui-selected {
		color: #ec7963;
	}

	> div > span {
		background-color: #ec7963;
	}

`;


export const UiModalContent = styled.div`
	margin-top: 16px;
	color: ${({ isError }) => isError ? '#f00' : '#505565'};
`;


export const UiModalSuccessContent = styled.div`
	font-size: 14px;
	line-height: 24px;

	> .title {
		color: #505565;
	}

	ul {
		overflow-x: hidden;
		padding: 0;
		max-height: 268px;
		list-style: none;

		li {
			color: #8b90a0;
		}
	}

	> .remind {
		display: flex;
		align-items: center;
		margin-top: 12px;
		padding: 16px 12px;
		font-size: 12px;
		color: #4c99a5;
		background: #fafafc;
		border-radius: 8px;

		svg {
			margin-right: 12px;
		}
	}
`;


export const UiCategories = styled.div`
	display: flex;
`;

export const UiCategory = styled.div`
	padding: 7px 18px;
	font-size: 12px;
	color: #3a4052;
	border: 1px solid #e4e7ec;
	border-radius: 16px;
	line-height: 16px;
	transition: 0.35s;
	cursor: pointer;

	& + div {
		margin-left: 16px;
	}

	&.active {
		color: #fff;
		background-color: #121232;
	}
`;

export const UiFlexBox = styled.div`
	display: flex;
	align-items: center;

	& > div + div {
		margin-left: 12px;
	}
`;

export const UiNoContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	flex-direction: column;

	> div {
		margin-top: 30px;
		height: 100%;
		font-size: 16px;
		text-align: center;
		color: #3a4052;
		line-height: 24px;
	}
`;
