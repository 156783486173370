import {
  Avatar as MatAvatar
} from '@material-ui/core';
import { useInterval } from 'ahooks';
import Notification from 'assets/images/notification.png';
import { Button, LatestNews } from 'components';
import React, { useEffect, useRef } from 'react';
import {
  getOrganizationInvitations,
  getGroupInvitations,
  acceptOrganizationInvitation as acceptOrganizationInvitationApi,
  acceptGroupInvitation as acceptGroupInvitationApi
} from 'services/api/home/users';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { useIntersectionObserver } from 'utils/hooks/useIntersectionObserver';
import { useSetState } from 'utils/hooks/useSetState';
import { useUuid } from 'utils/hooks/useUuid';
import { UiBackGround, UiNotificationList, UiNotificationItem } from './NotificationList.style';


/**
 * 通知列表
 */

const role = {
  customer: '學生',
  host: '老師'
};

const errorMessage = {
  20009: '已被邀請成為本校學生',
  20006: '已進入本校，請勿重複邀請',
  20021: '已為本校教師，無法邀請為本班學生',
  30004: '已在這個班級，請勿重複邀請',
};

const timeInterval = [1, 2, 4, 8, 12, 24, 30];

export const NotificationList = ({ isOpen, onClick, getNewState }) => {
  const ref = useRef(null);
  const visibleRef = useRef(null);
  const nowTime = new Date().getTime();
  const threeDayStamp = 1000 * 60 * 60 * 24 * 3;
  const [getUuid] = useUuid();
  const { setAlert } = useAlert();
  const [, { isNeedReloadDashBoard }] = useUser();

  const isBottomVisible = useIntersectionObserver(visibleRef, {
    threshold: 0
  }, false);

  const [{
    organizationInvitationsTotal,
    organizationLastInviteTime,
    groupInvitationsTotal,
    groupsLastInviteTime,
    resultData,
    targetTime,
    hasNewNotification,
    transform
  }, setState] = useSetState({
    organizationInvitationsTotal: 0,
    organizationLastInviteTime: nowTime,
    groupInvitationsTotal: 0,
    groupsLastInviteTime: nowTime,
    resultData: [],
    targetTime: 0,
    hasNewNotification: false,
    transform: 0
  });

  const fetchOrgInv = async (isNew) => {
    const payload = {};
    isNew ? payload.invitedAfter = nowTime : payload.invitedBefore = organizationLastInviteTime;
    const { data: { organizationInvitations: fetchData, total } } = await getOrganizationInvitations(payload);
    const nextFetchData = fetchData.map((item, index, arr) => {
      const { invitedAs, organizationName, lastInvitedAt } = item;
      const isNew = (nowTime - lastInvitedAt) < threeDayStamp;
      if (index === arr.length - 1) {
        setState({
          organizationLastInviteTime: lastInvitedAt
        });
      }
      return {
        ...item,
        text: `邀請你以${role[invitedAs]}身份加入${organizationName}`,
        isNew,
        id: getUuid(),
        type: 'organization'
      };
    });
    setState({
      organizationInvitationsTotal: total,
    });
    if (isNew && nextFetchData.length > 0) setState({ targetTime: 0, hasNewNotification: true });
    return nextFetchData;
  };

  const fetchGroupsInv = async (isNew) => {
    const payload = {};
    isNew ? payload.invitedAfter = nowTime : payload.invitedBefore = groupsLastInviteTime;
    const { data: { groupInvitations: fetchData, total } } = await getGroupInvitations(payload);
    const nextFetchData = fetchData.map((item, index, arr) => {
      const { invitedAs, groupName, organizationCityName, organizationName, lastInvitedAt } = item;
      const isNew = (nowTime - lastInvitedAt) < threeDayStamp;
      if (index === arr.length - 1) {
        setState({
          groupsLastInviteTime: lastInvitedAt
        });
      }
      return {
        ...item,
        text: `邀請你以${role[invitedAs]}身份加入${organizationCityName + organizationName + groupName}`,
        isNew,
        id: getUuid(),
        type: 'group'
      };
    });
    setState({
      groupInvitationsTotal: total,
    });
    if (isNew && nextFetchData.length > 0) setState({ targetTime: 0, hasNewNotification: true });
    return nextFetchData;
  };

  const formatInvData = async (isNew) => {
    const orgData = await fetchOrgInv(isNew);
    const groupData = await fetchGroupsInv(isNew);
    const nextData = resultData.concat(orgData).concat(groupData);
    const formatData = nextData.sort((a, b) => {
      return b.lastInvitedAt - a.lastInvitedAt;
    });
    const newState = formatData.some(item => item.isNew);
    getNewState(newState);//小紅點
    if (isNew) {
      setState({
        targetTime: (timeInterval.length - 1) === targetTime ? targetTime : targetTime + 1
      });
    }
    setState({
      resultData: formatData,
    });
  };

  const onClickHandle = e => {
    if (e.currentTarget !== e.target) {
      e.stopPropagation();
      return;
    }
    onClick();
  };

  const scrollHandle = e => {
    const scrollTop = ref.current.scrollTop;
    if (scrollTop === 0) setState({ hasNewNotification: false });
    setState({
      transform: scrollTop
    });
  };

  const goToTop = () => {
    const transition = 500;
    setState({ hasNewNotification: false });
    const scrollInterval = setInterval(function () {
      const scrollY = ref.current.scrollTop;
      const scrollStep = - scrollY / (transition / 15);
      if (scrollY !== 0 && scrollY > 0) {
        ref.current.scrollTop += scrollStep;
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  };

  const acceptGroupInvitation = async (targetId, groupId) => {
    const { isSuccess, error } = await acceptGroupInvitationApi(groupId);
    if (isSuccess) {
      const filterData = resultData.filter(item => item.id !== targetId);
      setState({
        resultData: filterData
      });
      isNeedReloadDashBoard(true);
      setAlert('已成功加入', 'success');
    } else {
      const { errorCode } = error;
      setAlert(errorMessage[errorCode] || '加入失敗', 'error');
    }
  };

  const acceptOrganizationInvitation = async (targetId, organizationId) => {
    const { isSuccess, error } = await acceptOrganizationInvitationApi(organizationId);
    if (isSuccess) {
      const filterData = resultData.filter(item => item.id !== targetId);
      setState({
        resultData: filterData
      });
      isNeedReloadDashBoard(true);
      setAlert('已成功加入', 'success');
    } else {
      const { errorCode } = error;
      setAlert(errorMessage[errorCode] || '加入失敗', 'error');
    }
  };

  useInterval(() => {
    formatInvData(true);
  }, timeInterval[targetTime] * 60000);

  useEffect(() => {
    (
      isBottomVisible &&
      resultData.length < (organizationInvitationsTotal + groupInvitationsTotal)
    )
      && formatInvData(false);
  }, [isBottomVisible]);

  useEffect(() => {
    //第一次打
    formatInvData(false);
  }, []);

  return (
    <UiBackGround onClick={onClickHandle} isOpen={isOpen}>
      <UiNotificationList>
        {(hasNewNotification && transform !== 0) && <LatestNews onClick={goToTop} />}
        <div className="content" ref={ref} onScroll={scrollHandle}>
          {
            resultData.length > 0 ? resultData.map((item, index) => {
              const { isNew, text, groupId, id, type, organizationId } = item;
              return (
                <UiNotificationItem isNew={isNew} key={index}>
                  <MatAvatar />
                  <div tabIndex={0} className="content">
                    {text}
                  </div>
                  <Button
                    buttonColor="new"
                    onClick={
                      () => type === 'group' ?
                        acceptGroupInvitation(id, groupId) :
                        acceptOrganizationInvitation(id, organizationId)}
                  >
                    加入
                  </Button>
                </UiNotificationItem>
              );
            }) :
              <div className="noContent">
                <img src={Notification} alt="noNotification" />
                <div tabIndex={0}>目前尚未有老師或機構邀請您加入班級喔！</div>
              </div>
          }
          <div ref={visibleRef} style={{ width: '100%', height: '20px', marginTop: 'auto' }}></div>
        </div>
      </UiNotificationList>
    </UiBackGround>
  );
};
