import { Button } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useSetState } from 'utils/hooks/useSetState';
/**
 * 透過時間限制按鈕
 */

export const TimeButton = ({ children, defaultTime = 5, onClick = () => { }, loading = false }) => {
  const [{
    time,
    loadingState
  }, setState] = useSetState({
    time: loading ? defaultTime : 0,
    loadingState: loading,
  });

  const onClickHandler = () => {
    setState({
      time: defaultTime,
      loadingState: true
    });
    onClick();
  };

  useEffect(() => {
    const verificationTime = setTimeout(() => {
      setState({
        time: time - 1
      });
    }, 1000);
    if (loadingState === false || time === 0) {
      setState({
        loadingState: false
      });
      clearTimeout(verificationTime);
      return;
    }
  }, [time]);
  return (
    <Button onClick={onClickHandler} loading={loadingState} disabled={loadingState}>
      {children}{time !== 0 && `(${time}秒)`}
    </Button>
  );
};

TimeButton.propTypes = {
  children: PropTypes.node,
  defaultTime: PropTypes.number,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};


