/**
 * 取得使用者於 OneBoard 之紀錄
 * @param {object} params
 * @returns
 */
export const getUserRecords = async (params) => {
  const body = JSON.stringify(params);
  const endpoint = `${process.env.REACT_APP_ONEBOARD_API_URL}/Prod/oneboard/course/multiUserRecord`;
  const response = await fetch(endpoint, { body, method: 'POST' });
  const json = await response.json();
  return json;
};
