import { TextField } from '@material-ui/core';
import {
  AlbumCard,
  Button,
  Modal,
  Loading,
  HomeTitle
} from 'components';
import React, { useEffect } from 'react';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { useAlbum } from 'store/album';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiAlbumTable,
  UiAlbumTableHeader,
  UiModal,
  UiModalInput,
  UiAlbumList,
  UiHelperText,
} from './HomeAlbumTable.style';


const categoryText = {
  image: '貼文',
  video: '影片',
  profile: '班級圖像'
};

/**
 * 相簿列表
 */

export const HomeAlbumTable = () => {
  const [{ myOrganization, profile: { id } }] = useUser();
  const [{ myClasses }] = useClass();
  const { setAlert } = useAlert();
  const [
    {
      userId,
      isAdmin,
      isOpenModal,
      isloading,
      isModalBtnDisabled,
      nowPage,
      rowsPage,
      listLoading,
      newAlbumName,
      oldAlbumName,
      hasNextPage,
      newAlbumNameCount,
      editAlbumId,
      editAlbumTitle,
    }, setState] = useSetState({
      userId: '',
      isAdmin: false,
      isOpenModal: false,
      isloading: false,
      isModalBtnDisabled: true,
      nowPage: 0,
      rowsPage: 10,
      listLoading: false,
      newAlbumName: null,
      oldAlbumName: null,
      hasNextPage: true,
      newAlbumNameCount: 0,
      editAlbumId: '',
      editAlbumTitle: '新增相簿'
    });

  const [{
    albumList
  }, {
    getAlbum,
    createAlbum,
    updateAlbum,
    deleteAlbum,
  }] = useAlbum();

  //滾動加載觸發後事件
  const handleLoadMore = async () => {
    setState({
      listLoading: true
    });
    const page = await getAlbum({ nowPage, rowsPage });
    const pageLength = page.albums && page.albums.length;
    let isHasNextPage = ((nowPage * rowsPage) + pageLength) >= page.total ? false : true;

    setState({
      listLoading: false,
      nowPage: nowPage + 1,
      hasNextPage: isHasNextPage
    });
  };
  //滾動加載trigger
  const infiniteRef = useInfiniteScroll({
    loading: listLoading,
    hasNextPage,
    onLoadMore: handleLoadMore
  });
  //權限 組織名取得
  useEffect(() => {
    const { dataInfo: { isOwner, userType } } = myClasses;
    setState({
      isAdmin: isOwner,
      userType,
      userId: id
    });
  }, [myOrganization, myClasses]);

  const submitAlbumHandler = async (type) => {
    if (oldAlbumName === newAlbumName) return false;
    if (checkIsRepeatName(newAlbumName)) {
      return false;
    } else {
      setState({
        isloading: true
      });
      if (editAlbumId !== '') {//更新相簿名稱
        await updateAlbumHandler(newAlbumName, editAlbumId);
      } else {//新增相簿
        await createAlbum({ name: newAlbumName });
      }
      setState({
        isloading: false
      });
    }
  };

  const updateAlbumHandler = async (name, id) => {
    await updateAlbum(name, id);
  };

  const deleteAlbumHandler = async (data, id) => {
    const type = data.ownerId === userId ? 'delete' : 'ban';
    await deleteAlbum(data, id, type);
  };

  const inputChangeHandler = (val) => {
    setState({
      newAlbumNameCount: val.length
    });
    if (val.length >= 2 && val.length <= 24) {
      setState({
        isModalBtnDisabled: false,
        newAlbumName: val
      });
    } else {
      setState({
        isModalBtnDisabled: true
      });
    }
  };

  const checkIsRepeatName = (name) => {
    const isRepeat = albumList.albumNames &&
      albumList.albumNames.find(item => item === name);
    if (isRepeat) {
      setAlert('相簿名稱重複', 'error');
      return true;
    } else {
      return false;
    }
  };

  const getAlbumModalStateHandler = (state, value = '', id = '') => {
    setState({
      isOpenModal: state,
      newAlbumNameCount: value.length,
      newAlbumName: value,
      oldAlbumName: value,//檢查更新相簿時 值有無變化
      isModalBtnDisabled: true,
      editAlbumId: id,
      editAlbumTitle: '重新命名相簿',
    });
  };

  const buttons = [
    {
      text: '取消',
      color: 'cancel',
      func: () => { setState({ isOpenModal: false }); }
    },
    {
      text: '確認',
      color: 'new',
      loading: isloading,
      disabled: isModalBtnDisabled,
      func: () => { submitAlbumHandler(); }
    },
  ];
  return (
    <UiAlbumTable ref={infiniteRef}>
      <UiAlbumTableHeader>
        <HomeTitle title={'相簿'} />
        <Button
          icon='add'
          onClick={() => {
            setState({
              isOpenModal: true,
              editAlbumTitle: '新增相簿',
            });
          }}>新增相簿
        </Button>
      </UiAlbumTableHeader>

      <UiAlbumList>
        {
          albumList && albumList.albums.map(item => {
            item.id = (item.category && item.category !== undefined) ? item.category : item.id;
            item.name = item.category ? (
              categoryText[item.category]
            ) : item.name;
            item.isAdmin = isAdmin;
            item.path = item.cover ? (item.category === 'video' ? '' : (item.cover.attachmentUrl || '')) : '';
            const type = item.category ? item.category : 'image';
            const canDelete = item.ownerId && (userId === item.ownerId || isAdmin) ? true : false;
            const canEdit = item.ownerId && (userId === item.ownerId) ? true : false;
            if (!item.isBanned) {
              return (
                <AlbumCard
                  key={item.id}
                  data={item}
                  canDelete={canDelete}
                  canEdit={canEdit}
                  type={type}
                  updateAlbum={updateAlbumHandler}
                  checkIsRepeatName={checkIsRepeatName}
                  deleteAlbum={deleteAlbumHandler}
                  getEditAlbumModalState={getAlbumModalStateHandler}
                />
              );
            }
          })
        }
      </UiAlbumList>


      {listLoading && <Loading />}
      <Modal
        isOpen={isOpenModal}
        title={editAlbumTitle}
        buttons={buttons}
        isIconShow={false}
        content={
          <UiModal>
            <div>請輸入相簿名稱</div>
            <UiModalInput>
              <TextField
                variant="outlined"
                inputProps={{ maxLength: 24 }}
                placeholder={'請輸入相簿名稱'}
                defaultValue={newAlbumName}
                onChange={e => inputChangeHandler(e.target.value)}
              />
            </UiModalInput>
            <UiHelperText tabIndex={0} role='note'>
              相簿名稱上限24個字、目前字數{newAlbumNameCount}字
            </UiHelperText>
          </UiModal>
        }
        getModalState={getAlbumModalStateHandler}
      />
    </UiAlbumTable>
  );
};

HomeAlbumTable.propTypes = {

};


