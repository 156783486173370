import { QUERY, DEVICE } from 'constants/dimension';
import styled from 'styled-components';
import { useResponsive } from 'utils/hooks/useResponsive';

export const UiSessionSummary = styled.div`
  display: block;
`;

export const UiSummaryTitle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 48px;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d5d7de;
  margin-bottom: 1rem;
`;

export const UiSummaryClass = styled.div`
  font-size: 25px;
  font-weight: 700;
`;

export const UiSummaryHostBox = styled.div`
  display: flex;
  align-items: center;
`;
export const UiSummaryIconBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding-right: 10px;

  span {
    color: #a1a4b1;
  }

  &::after {
    position: absolute;
    right: 0;
    width: 1px;
    height: 70%;
    background-color: #c4c4c4;
    content: '';
  }
`;
export const UiSummaryHostName = styled.div``;

export const UiSummaryContent = styled('div')(() => {
  const isTabletL = useResponsive({ query: QUERY.DOWN, start: DEVICE.TABLET.WIDTH_L });
  return {
    display: 'flex',
    flexWrap: isTabletL ? 'wrap' : 'nowrap',
    gap: '24px',
  };
});

export const UiSummaryInfo = styled('div')(() => {
  const isTabletL = useResponsive({ query: QUERY.DOWN, start: DEVICE.TABLET.WIDTH_L });
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: isTabletL ? '100%' : '40%',
    minWidth: '275px',
    padding: '52px 0',
    backgroundColor: '#fef8f7',
    border: '1px solid #FBE4E0',
    borderRadius: '24px',
  };
});

export const UiInfoClassName = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-family: Noto Sans TC;
  margin-bottom: 4px;
`;
export const UiInfoClassTeacher = styled.div`
  font-size: 16px;
  color: #8b90a0;
  font-weight: 500;
  font-family: Noto Sans TC;
  margin-bottom: 12px;
`;
export const UiInfoClassDataTime = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-family: Inter;
  margin-bottom: 20px;

  > span:first-child {
    position: relative;
    margin-right: 10px;
    padding-right: 10px;

    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 1px;
      height: 70%;
      background-color: #3a4052;
      content: '';
    }
  }
`;
export const UiInfoClassButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  & > button {
    padding: 8px 16px;
    border-radius: 50px;
  }
`;

export const UiSummaryTime = styled('div')(() => {
  const isTabletL = useResponsive({ query: QUERY.DOWN, start: DEVICE.TABLET.WIDTH_L });
  return {
    display: 'flex',
    justifyContent: 'space-between',
    width: isTabletL ? '100%' : '60%',
    flexDirection: 'column',
    gap: '16px',
  };
});

export const UiTimeCardBox = styled('div')(() => {
  const isMobileL = useResponsive({ query: QUERY.DOWN, start: DEVICE.MOBILE.WIDTH_L });
  return {
    display: 'flex',
    gap: '16px',
    flexWrap: isMobileL ? 'wrap' : 'nowrap',
  };
});

export const UiTimeCard = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    padding: '1.3rem 0',
    paddingLeft: '1rem',
    minWidth: '260px',
    width: '100%',
    backgroundColor: '#fafafc',
    borderRadius: '24px',
    border: '1px solid #F2F4F8',
  };
});

export const UiTimeBox = styled.div`
  padding: 1rem 1.75rem;
  border-radius: 8px;
`;
export const UiTimeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: #f9c74f;
  border-radius: 20px;

  > svg {
    font-size: 2.5rem;
  }
`;
export const UiTimeText = styled.div`
  font-size: 20px;
  color: #4e5d78;
`;
export const UiTimeNumber = styled.div`
  font-size: 40px;
  font-weight: 700;
  color: ${({ haveNumber }) => (haveNumber ? '' : '#F6564B')};
  > span {
    font-size: 25px;
  }
`;
export const UiTimeSession = styled.div`
  color: #8b90a0;
`;
