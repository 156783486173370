import { Box, Tabs as MatTabs } from '@material-ui/core';
import { ToggleButtonGroup } from '@material-ui/lab';
import styled from 'styled-components';

export const UiHomeOnlineExam = styled.a`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  flex: 1;
`;

export const UiTitle = styled.div`
  font-size: 20px;
  color: #242c3f;
  margin-bottom: 1.5rem;
`;

export const UiMatTabs = styled(MatTabs)`
  margin: 1rem 0;
  border-bottom: 1px solid #d5d7de;

  .MuiTab-textColorInherit.Mui-selected {
    color: #ec7963;
  }

  > div > span {
    background-color: #ec7963;
  }
`;

export const UiActionsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
}));

export const UiHomeExamPaperBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
}));

export const UiToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& > button': {
    border: 'none',
    color: '#637381',
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    margin: '4px',

    '&.Mui-selected': {
      color: theme.palette.btn.normal,
      backgroundColor: `${theme.palette.btn.normal}14`,
      borderRadius: '8px',
    },

    '&:hover': {
      borderRadius: '8px',
    },
  },
}));

export const UiExamIcons = styled.div`
  display: flex;

  > div {
    font-size: 2rem;
    cursor: pointer;

    > svg {
      width: auto;
      height: auto;
    }
  }
`;

export const UiEntryBanner = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .banner {
    &__item {
      background-color: #fbe4e0;
      display: flex;
      padding: 18px 25px 16px 16px;
      width: 360px;
      border-radius: 12px;
      align-items: center;
      row-gap: 16px;
    }

    &__text {
      width: 100%;
    }

    &__header {
      display: flex;
      margin-bottom: 8px;
      align-items: center;
    }

    &__icon {
      margin-right: 8px;
      width: 75px;
    }

    &__title {
      font-size: 18px;
      line-height: 1.5;
      font-weight: 700;
      white-space: nowrap;
    }

    &__link {
      position: relative;
      background-color: #121232;
      display: flex;
      margin-left: auto;
      padding: 4px 12px;
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      align-items: center;
      border-radius: 4px;
      column-gap: 8px;
      text-decoration: none;
    }

    &__linkDisabled {
      background-color: #fff;
      margin-left: auto;
      padding: 4px 12px;
      color: #454b5c;
      font-size: 14px;
      line-height: 24px;
      border-radius: 28px;
    }

    &__wrongExamsCount {
      position: absolute;
      top: -10px;
      right: -14px;
      width: 24px;
      height: 24px;
      background-color: #ec7963;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      border-radius: 50%;
    }

    &__description {
      white-space: nowrap;
    }
  }
`;

export const UiMainScreenBox = styled(Box)`
	display: flex;
	flex-direction: column;
	flex: 1;
  & td {
    width: unset;
  },
`;
