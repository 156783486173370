import { Table, IconInput, Select, Icon } from 'components';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useResource } from 'store/resource';
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { useSetState } from 'utils/hooks/useSetState';
import { useUuid } from 'utils/hooks/useUuid';


const Tags = ({ data = [] }) => (
  <>
    {
      data.map(item => <div key={item.tagId}>{item.label}</div>)
    }
  </>
);

// eslint-disable-next-line react/prop-types
const ActionComponents = ({ params }) => {
  // eslint-disable-next-line react/prop-types
  const { bookId } = params;

  const previewBook = () => {
    window.open(`https://cdn.oneclass.com.tw/uploadoutput/${bookId}`);
  };
  return (
    <>
      <UiflexBox>
        <Icon name="watchFile" title="預覽檔案" onClick={() => previewBook()} />
      </UiflexBox>
    </>
  );
};

const schema = {
  SchoolYear: {
    name: '學年度',
    defaultValue: '',
  },
  EducationalSystem: {
    name: '學制',
    defaultValue: '',
  },
  Grade: {
    name: '年級',
    defaultValue: '',
  },
  Subject: {
    name: '科目',
    defaultValue: '',
  },
  displayName: {
    name: '檔案名稱',
    defaultValue: '',
  },
};

/**
 * 機構後台公開資源table
 */

export const BsPublicResourceTable = () => {
  const [getUuid] = useUuid();
  const [{
    bookstoreBooks,
    bookstoreTags,
    gradeTags,
    subjectTags
  }, {
    getBookstoreBooks,
    getBookstoreTags,
    getGradeTags,
    getSubjectTags
  }] = useResource();
  const [{ displayName, nowPage, rowsPage }, setState] = useSetState({
    displayName: '',
    nowPage: 0,
    rowsPage: 10,
  });

  const [selectedOption, setSelectedOption] = useSetState({
    schoolYear: '',
    educationalSystem: '',
    grade: '',
    subject: ''
  });

  const { educationalSystem } = selectedOption;

  //換頁
  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage,
    });
  };

  //搜尋檔名
  const submitInputHandler = displayName => {
    setState({
      displayName,
      nowPage: 0
    });
  };

  useEffect(() => {
    getBookstoreBooks({
      nowPage,
      rowsPage,
      displayName,
      tags: Object.values(selectedOption).filter(item => item)
    });
  }, [nowPage, rowsPage, displayName, selectedOption]);

  useEffect(() => {
    const tagCategory = ['SchoolYear', 'EducationalSystem', 'Grade', 'Subject'];
    Promise.all(tagCategory.map(category => getBookstoreTags({ category })));
  }, []);

  useEffect(() => {
    if (!educationalSystem) return;
    const code = bookstoreTags.dataMap['EducationalSystem'][educationalSystem].code;
    getGradeTags(code);
    getSubjectTags(code);
  }, [educationalSystem]);

  const formatTagOptions = useCallback((tags = []) => {
    return tags?.map(item => ({ name: item.label, value: item.tagId })).concat([{ name: '清除條件', value: '' }]) || [];
  }, []);

  const schoolYearOptions = useMemo(() =>
    formatTagOptions(bookstoreTags.data['SchoolYear'])
    , [bookstoreTags.data]);

  const educationalSystemOptions = useMemo(() =>
    formatTagOptions(bookstoreTags.data['EducationalSystem'])
    , [bookstoreTags.data]);

  const gradeOptions = useMemo(() => formatTagOptions(gradeTags.data), [gradeTags.data]);
  const subjectOptions = useMemo(() => formatTagOptions(subjectTags.data), [subjectTags.data]);


  const tagFormat = useCallback((category, tagIds) => {
    if (!category || !bookstoreTags.data[category]?.length) return [];
    const filteredTags = tagIds.filter(tagId => bookstoreTags.dataMap[category][tagId]);
    return filteredTags.map(tagId => bookstoreTags.dataMap[category][tagId]);
  }, [bookstoreTags]);

  const tableData = useMemo(() => bookstoreBooks.data.map((book, index) => {
    const { bookId, displayName, tags } = book;

    return {
      id: getUuid(),
      bookId,
      displayName,
      SchoolYear: <Tags data={tagFormat('SchoolYear', tags)} />,
      EducationalSystem: <Tags data={tagFormat('EducationalSystem', tags)} />,
      Grade: <Tags data={tagFormat('Grade', tags)} />,
      Subject: <Tags data={tagFormat('Subject', tags)} />
    };
  }),
    [bookstoreBooks.data, subjectOptions, educationalSystemOptions, gradeOptions, subjectOptions]);

  const selectChangeHandler = key => value => {
    setSelectedOption({ [key]: value });
  };

  return (
    <UiTable>
      <UiActionBox>
        <UiflexBox>
          <Select
            label="選擇學年度"
            options={schoolYearOptions}
            value={selectedOption.schoolYear}
            submitHandler={selectChangeHandler('schoolYear')}
          />
          <Select
            label="選擇學制"
            options={educationalSystemOptions}
            value={selectedOption.educationalSystem}
            submitHandler={selectChangeHandler('educationalSystem')}
          />
          <Select
            label="選擇年級"
            options={gradeOptions}
            value={selectedOption.grade}
            submitHandler={selectChangeHandler('grade')}
          />
          <Select
            label="選擇科目"
            options={subjectOptions}
            value={selectedOption.subject}
            submitHandler={selectChangeHandler('subject')}
          />
          <IconInput placeholder="搜尋檔案名稱" onChange={submitInputHandler} />
        </UiflexBox>
      </UiActionBox>
      <Table
        data={tableData}
        schema={schema}
        changePage_Rows={changePage_Rows}
        totalPage={bookstoreBooks.total}
        ActionComponents={ActionComponents}
        nowPage={nowPage}
      />
    </UiTable>
  );
};

BsPublicResourceTable.propTypes = {

};


