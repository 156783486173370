import MatButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

export const UiButton = styled(MatButton)`
  position: relative;
  display: flex;
  padding: 8px;
  min-height: 40px;
  font-size: ${({ size }) => (size ? '14px' : '12px')};
  color: ${({ theme, textColor, buttoncolor }) =>
    textColor
      ? textColor
      : buttoncolor === 'disable'
      ? '#D5D7DE'
      : buttoncolor === 'cancel'
      ? '#3a4052'
      : theme.oneClass.button.text.default};

  background: ${({ theme, buttoncolor, loading }) =>
    loading === true
      ? theme.oneClass.button.background['disable']
      : buttoncolor === 'danger'
      ? theme.palette.main[buttoncolor]
      : theme.palette.btn[buttoncolor]};
  border: ${({ buttoncolor }) => (buttoncolor === 'cancel' ? '0.6px solid #D5D7DE' : 'none')};
  border-radius: 5px;
  line-height: ${({ size }) => (size ? '24px' : '16px')};
  cursor: ${({ buttoncolor, loading }) => (buttoncolor === 'disable' || loading === true ? 'not-allowed' : 'pointer')};

  &:hover {
    color: ${({ theme, buttoncolor, loading }) =>
      loading === true
        ? theme.oneClass.button.mouseOver.background['grey']
        : buttoncolor === 'cancel'
        ? theme.palette.primary.main
        : ''};
    background: ${({ theme, buttoncolor, loading }) =>
      loading === true
        ? theme.oneClass.button.mouseOver.background['grey']
        : buttoncolor === 'danger'
        ? theme.palette.main.rose
        : theme.palette.btn[buttoncolor + 'hover']};
  }
`;
export const UiCircularProgress = styled(CircularProgress)(({ color }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  margin: 'auto',
  color,
}));
export const UiInlineFlex = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  & > svg {
    margin-left: ${({ endIcon }) => endIcon && '5px'};
    font-size: 1.7rem;
  }
`;
