import styled from 'styled-components';

export const UiNoDataBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UiPictureBox = styled.div`
	display: block;
	margin: auto;
	width: 150px;
	height: 150px;
	background: url("${({ path }) => path}");
	background-position: center;
	background-size: cover;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
`;

export const UiNoDataTextBox = styled.div`
  margin-top: 1rem;
  font-family: Noto Sans CJK TC;
  text-align: center;
`;

export const UiNoDataTextPrimary = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #242c3f;
  margin-bottom: 12px;
`;

export const UiNoDataTextSecondary = styled.div`
  font-size: 16px;
  color: #8b90a0;
`;
