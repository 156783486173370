import { TextField as MatTextField } from '@material-ui/core';
import { Form } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { UiPostCardEdit } from './PostCardEdit.style';

/**
 * 貼文
 */


/**
 * 新增編輯貼文表單
 */
const schema = {
  content: {
    component: MatTextField,
    elementProps: {
      label: '建立貼文',
      placeholder: '請輸入內容',
      variant: 'outlined',
      helperText: '',
      multiline: true,
      rows: 5
    },
    rules: {
      required: {
        value: true,
        message: '本欄位為必填'
      }
    }
  }
};

export const PostCardEdit = ({
  isLoading,
  onSubmitHandler = () => { },
  reset = false
}) => {
  const formButtons = [
    {
      text: '發布貼文',
      type: 'submit',
    }
  ];
  const onSubmitForm = data => {
    onSubmitHandler(data);
  };
  return (
    <UiPostCardEdit>
      <Form schema={schema} buttons={formButtons} onSubmit={onSubmitForm} isLoading={isLoading} resetTrigger={reset} />
    </UiPostCardEdit>
  );
};

PostCardEdit.propTypes = {
  isLoading: PropTypes.bool,
  onSubmitHandler: PropTypes.func,
  reset: PropTypes.bool
};


