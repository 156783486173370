import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { useUuid } from 'utils/hooks/useUuid';
import {
  UiLikeListModal,
  UiTitle,
  UiClose,
  UiLikeBox,
  UiContent,
  UiAvatar,
  UiContentList,
} from './LikeListModal.style';

/**
 * 顯示成員列表彈窗
 */

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const LikeListModal = ({
  open, // 彈窗開關
  memberData, // 成員資料
  onCancel, // 取消 callback
  isLoading = false, // 是否在等待
}) => {
  const [getUuid] = useUuid();
  const classes = useStyles();
  const handleClose = () => {
    onCancel && !isLoading && onCancel();
  };

  return (
    <Modal className={classes.modal} open={open} onClose={handleClose} disableAutoFocus disableEnforceFocus>
      <UiLikeListModal>
        <UiTitle>
          <UiLikeBox>
            <Icon.Svg name="HeartOn" />
            <span>{memberData?.length}</span>
          </UiLikeBox>
          <UiClose onClick={handleClose}>
            <Icon name="close" icolor="#a1a4b1" haveBg={false} />
          </UiClose>
        </UiTitle>
        <UiContent>
          {memberData &&
            memberData.map((user) => {
              const id = getUuid();
              return (
                <UiContentList key={id}>
                  <UiAvatar src={user.thumbnailUrl || ''} alt={user.nickname} />
                  <span>{user.nickname}</span>
                </UiContentList>
              );
            })}
        </UiContent>
      </UiLikeListModal>
    </Modal>
  );
};

LikeListModal.propTypes = {
  open: PropTypes.bool,
  memberData: PropTypes.array,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
};
