
import { EDIT_STATUS, ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import {
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import {
  Form,
  DateTimePicker,
  Select,
  TransferListModal,
  TraceTextForm,
  Checkbox,
  Button,
} from 'components';
import { format, add, addMinutes, getTime } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { useClass } from 'store/class';
import { useOrganization } from 'store/organization';
import { useSessions } from 'store/sessions';
import { useTeacher } from 'store/teacher';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiSessionForm,
  UiFilter,
  UiSearchInput,
} from './SessionForm.style';
import SessionFormResourceModal from './SessionFormResourceModal';


/**
 * 新增課程表單
 */
const SessionTypeMap = {
  normal: '一般課程',
  video: '視訊課程',
  interactive: '互動課程',
  collaborative: '協作課程'
};

const SelectOptions = [
  { name: '15分', value: 15 },
  { name: '25分', value: 25 },
  { name: '30分', value: 30 },
  { name: '45分', value: 45 },
  { name: '50分', value: 50 },
  { name: '60分', value: 60 },
  { name: '90分', value: 90 },
  { name: '120分', value: 120 }
];

// eslint-disable-next-line react/prop-types
const DurationSelect = ({ dateTime, duration, ...props }) => {
  const time = format(add(new Date(dateTime), { minutes: duration }), 'HH:mm');
  const info = duration ? time : '__:__';
  return (
    <Select {...props} helperText={`下課時間 當天 ${info}`} />
  );
};

const OTHER = 'other';

export const SessionForm = ({ isOrganization, editStatus }) => {
  const history = useHistory();
  const { organizationId, classId, sessionId } = useParams();
  const [{ orgSubjects }, { getOrganizationSubjects }] = useOrganization();
  const [{ organizationStaffs },
    { getAllTeachers, getTeachers, getOrganizationStaffs }] = useTeacher();
  const [{ profile, myOrganization: { organization } }] = useUser();
  const [{ myClasses, classes, subjects }, { getSubjects }] = useClass();
  const [{ sessions }, { createSession, createOrganizationSession, updateOrganizationSession }] = useSessions();
  const { dataMaps } = sessions;
  const { dataInfo: { id, isOwner, educationName, groupRole } } = myClasses;
  const { dataInfo: { id: orgClassId, isOwner: orgISOwner, educationName: orgEducationName } } = classes;
  const isHome = useRouteMatch({ path: '/home' }) ? true : false;
  const { setAlert } = useAlert();
  const [{
    startAt,
    duration,
    timeSpanId,
    searchTeacherValue,
    isLoading,
    data,
    title,
    teacherHelpText,
    error,
    hideTime,
    reset,
    formSchema,
    searchKey,
    searchValue,
    currentGroupHostOnly,
    searchIsLoading,
    hostId,
    resourceIdList,
  }, setState
  ] = useSetState({
    startAt: Date.now(),
    duration: 0,
    searchTeacherValue: '',
    isLoading: editStatus === EDIT_STATUS.CREATE ? false : true,
    data: null,
    teacherHelpText: '請選擇教師',
    error: false,
    hideTime: false,
    reset: false,
    formSchema: {},
    searchKey: '',
    searchValue: '',
    currentGroupHostOnly: false,
    searchIsLoading: false,
    hostId: '',
    resourceIdList: [],
  });

  let selectTypeOptions = organization?.availableSessionTypeSetting.map(sessionType => ({
    name: SessionTypeMap[sessionType],
    value: sessionType
  }));


  const selectFilterTypeOptions = [
    {
      name: '手機號碼',
      value: 'mobileNumber'
    },
    {
      name: '暱稱',
      value: 'nickname'
    },
    {
      name: '帳號ID',
      value: 'targetUserId'
    }
  ];

  const searchPlaceholderMap = {
    mobileNumber: '請輸入教師手機號碼',
    nickname: '請輸入教師暱稱',
    targetUserId: '請輸入教師帳號ID'
  };

  useEffect(() => {
    if (organizationId) {
      if (!organization) return;
      // delete schema.sessionType;
      setState({
        isLoading: true
      });
    } else {
      if (!profile) return;
      setState({
        isLoading: true
      });
    }
    if (educationName === OTHER || orgEducationName === OTHER) delete schema.subjectCode;
    Object.entries(schema).forEach(([key]) => {
      if (key === 'subjectCode') {
        schema[key].elementProps.options = isOrganization ? orgSubjects.data : subjects.data;
      }
    });
    setState({
      formSchema: schema,
      isLoading: false
    });
  }, [organization, profile, orgSubjects.data, subjects.data, resourceIdList]);

  const schema = {
    name: {
      component: TraceTextForm,
      elementProps: {
        label: '課程名稱',
        helperText: '',
        variant: 'outlined',
        placeholder: '請輸入課程名稱',
        maxTextNum: 70
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        },
        maxLength: {
          value: 70,
          message: '最大長度為 70 個字元'
        },
      }
    },
    sessionType: {
      component: Select,
      elementProps: {
        label: '課程類型',
        defaultValue: 'normal',
        options: selectTypeOptions,
        onChange: (type) => { sessionTypeChange(type[0]); return type[0]; },
        onChangeName: 'submitHandler'
      }
    },
    subjectCode: {
      component: Select,
      elementProps: {
        label: '科目',
        defaultValue: '',
        options: [],
        onChange: (subject) => { subjectChangeHandle(subject[0]); return subject[0]; },
        onChangeName: 'submitHandler'
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        }
      }
    },
    startAt: {
      component: DateTimePicker,
      elementProps: {
        label: '上課時間',
        defaultValue: Date.now(),
        value: '',
        helperText: '',
        needHide: true,
      }
    },
    duration: {
      elementProps: {
        label: '課程時長',
        value: '',
        defaultValue: null,
        options: SelectOptions,
        onChangeName: 'submitHandler',
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        }
      }
    },
    hostId: {
      elementProps: {
        buttonName: '選擇授課教師',
        title: '選擇授課教師',
        helperText: '',
        type: 'singleChoice',
        onChange: ([{ id }]) => id
      }
    },
    resourceIdList: {
      elementProps: {
        buttonName: '選擇教學檔案',
        title: '選擇教學檔案',
        helperText: '',
        type: 'singleChoice',
        onChange: ([resourceIdList]) => {
          setState({
            resourceIdList,
            isReset: false
          });
          return resourceIdList;
        }
      }
    },
    preExamId: {
      component: TextField,
      elementProps: {
        label: '課前測驗ID',
        helperText: '',
        variant: 'outlined',
        placeholder: '請輸入課前測驗ID',
        maxTextNum: 30
      }
    },
    postExamId: {
      component: TextField,
      elementProps: {
        label: '課後測驗ID',
        helperText: '',
        variant: 'outlined',
        placeholder: '請輸入課後測驗ID',
        maxTextNum: 30
      }
    },
  };

  // 取得所有教師
  useEffect(() => {
    if (!id && !orgClassId) return;
    if (isOrganization) {
      if (orgEducationName !== OTHER) getOrganizationSubjects(organizationId, classId);
    } else {
      if (educationName !== OTHER) getSubjects(classId);
    }
    getAllTeachers();
    getOrganizationStaffs();

    // 畫面標題
    let title = '';
    switch (editStatus) {
      case EDIT_STATUS.CREATE:
        title = '新增課程';
        break;
      case EDIT_STATUS.EDIT:
        title = '編輯課程';
        break;
      case EDIT_STATUS.READ:
        title = '檢視課程';
        break;
      default:
        break;
    }

    setState({ title });

    if (sessionId) {
      if (dataMaps) {
        // 取 store 資料
        let sessionInfo = dataMaps[sessionId];
        setState({ timeSpanId: sessionInfo.timeSpanId });
        sessionInfo = Object.assign(
          sessionInfo,
          { duration: sessionInfo.duration.slice(0, sessionInfo.duration.length - 2) }
        );
        setState({ data: sessionInfo });
      }
    }
  }, [id]);

  useEffect(() => {
    if (!data) return;
    setState({ isLoading: false });
  }, [data]);

  const nextData = useMemo(() => {
    // 不可被編輯的欄位
    let blackList = ['hostId', 'name', 'timeSpanId', 'startAt', 'endAt', 'resourceIdList'];
    let isRead = false;
    const nowSchema = formSchema || schema;
    // schema 比對
    let newData = Object.assign({}, nowSchema);
    // 根據網址狀態判斷
    switch (editStatus) {
      // 編輯
      case EDIT_STATUS.EDIT:
        if (!data) return;
        if (data.startAt > new Date().getTime()) {
          blackList = blackList.filter(item => item !== 'startAt');
        }
        break;
      // 檢視
      case EDIT_STATUS.READ:
        isRead = true;
        if (!data) return;
        break;
      // 建立 & 預設
      case EDIT_STATUS.CREATE:
        Object.entries(newData).forEach(([key]) => {
          if (organization.tutorialExamSetting === ORGANIZATION_SETTINGS_VALUE.DISABLED) {
            if (key === 'postExamId' || key === 'preExamId') {
              delete newData[key];
            }
          }
        });
        return newData;
      default:
        return formSchema;
    }

    Object.entries(newData).forEach(([key]) => {
      if (key === 'subjectCode') {
        newData[key].elementProps.defaultValue = data.subjects[0]?.code;
      } else if (key === 'sessionType') {
        newData[key].elementProps.defaultValue = data.type;
      } else {
        newData[key].elementProps.defaultValue = data[key];
      }

      if (editStatus === EDIT_STATUS.READ) {
        newData[key].elementProps.disabled = isRead;
      } else {
        newData[key].elementProps.disabled = blackList.includes(key) ? !isRead : isRead;
      }
      if (organization.tutorialExamSetting === ORGANIZATION_SETTINGS_VALUE.DISABLED) {
        if (key === 'postExamId' || key === 'preExamId') {
          delete newData[key];
        }
      }
    });

    return newData;
  }, [data, formSchema]);

  const onFormChangeHandler = data => {
    if (data.startAt && data.startAt !== startAt) setState({ startAt: data.startAt });
    if (data.duration && data.duration !== duration) setState({ duration: data.duration });
    if (data.hostId && data.hostId !== hostId) {
      setState({
        hostId: data.hostId,
        resourceIdList: [],
        isReset: true
      });
    }
  };

  const onSubmitHandler = async data => {
    if (resourceIdList.length === 0) {
      setAlert('請重新選擇教材');
      setState({
        error: true
      });
      return;
    }
    if (!hostId || resourceIdList.length === 0) {
      setState({
        error: true
      });
      return;
    }

    setState({ isLoading: true });
    data.endAt = getTime(addMinutes(data.startAt, data.duration));
    data.hostId = hostId;
    data.resourceIdList = resourceIdList;
    delete data.duration;
    data = Object.assign(data, { timeSpanId });
    let isSuccess;

    if (isOrganization) {
      const isEdit = editStatus === EDIT_STATUS.EDIT;
      isEdit ?
        // 編輯課程頁面，更新課程資訊
        isSuccess = await updateOrganizationSession({
          data
        }) :
        // 新增課程頁面，新增課程資訊
        isSuccess = await createOrganizationSession({
          data
        });
    } else {
      isSuccess = await createSession({
        data
      });
    }

    setState({ isLoading: false });
    isSuccess && history.goBack();
  };

  // 搜尋教師列表
  useEffect(() => {
    if (!searchTeacherValue) return;
    const params = {
      mobileNumber: searchTeacherValue
    };
    getTeachers(params);
  }, [searchTeacherValue]);

  useEffect(() => {
    if (isOwner) return;
    setState({ hostId: profile.id });
  }, [profile]);

  const formatTeacherListData = (teacherList = []) => {
    // const { dataInfo: { isOwner, ownerId } } = myClasses;
    // const ownerIsTeacher = teacherList.some(item => item.id === ownerId);
    // if (isOwner && searchTeacherValue === '' && !ownerIsTeacher) {
    //   teacherList = [{
    //     id: profile.id,
    //     nickname: profile.nickname
    //   }, ...teacherList];
    // }
    return teacherList.map(item => ({
      id: item.id,
      imagePath: item.thumbnailUrl,
      title: item.nickname,
      content: item.mobileNumber,
    }));
  };

  const buttons = [
    {
      text: '取消',
      color: 'cancel',
      func: () => history.goBack()
    },
    {
      text: '確認',
      color: 'new',
      type: 'submit',
    },
  ];

  const sessionTypeChange = (type) => {
    setState({
      hideTime: type === 'permanent' ? true : false
    });
  };

  const subjectChangeHandle = subject => {
    setState({
      subjectCode: subject
    });
  };

  const submitSelectHandler = value => {
    setState({
      searchKey: value
    });
  };
  const submitInputHandler = event => {
    setState({
      searchValue: event.target.value
    });
  };

  const submitSearchHandler = async () => {
    setState({
      searchIsLoading: true
    });
    if (!searchKey) {
      alert('請先選擇搜尋目標');
      return;
    }

    const params = {
      currentGroupHostOnly: currentGroupHostOnly,
      [searchKey]: searchValue
    };

    await getOrganizationStaffs(params);
    setState({
      searchIsLoading: false
    });
  };

  const onSwitchChange = (val) => {
    const currentGroupHostOnly = val === 'currentGroupHostOnly' ? true : false;
    const params = {
      currentGroupHostOnly: currentGroupHostOnly,
      [searchKey]: searchValue
    };
    getOrganizationStaffs(params);
    setState({
      currentGroupHostOnly: currentGroupHostOnly
    });
  };

  return (
    <UiSessionForm>
      <Form
        title={title}
        schema={!isLoading ? nextData : {}}
        onFormChange={onFormChangeHandler}
        onSubmit={onSubmitHandler}
        buttons={buttons}
        isLoading={isLoading}
        resetTrigger={reset}
        needHide={hideTime}//永久課程用的
      >
        {
          !hideTime &&
          <DurationSelect
            name="duration"
            dateTime={startAt}
            duration={duration}
          />
        }
        {
          (isOwner || groupRole === 'organizationOwner') && <TransferListModal
            name='hostId'
            error={error}
            helperText={teacherHelpText}
            data={formatTeacherListData(organizationStaffs?.data)}
            errorMsg='無符合條件教師，請重新設定搜尋條件'
          >
            <UiFilter>
              <Select label="搜尋目標" options={selectFilterTypeOptions}
                value={searchKey} submitHandler={submitSelectHandler} />
              <UiSearchInput>
                <TextField placeholder={searchPlaceholderMap[searchKey]}
                  defaultValue={searchValue}
                  onChange={event => submitInputHandler(event)}
                  fullWidth={true}
                  type="search" variant="outlined" label='搜尋' InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}></TextField>
              </UiSearchInput>
              <Button onClick={submitSearchHandler} loading={searchIsLoading}>搜尋</Button>
              {isHome && (isOwner || orgISOwner) &&
                <Checkbox label="顯示本班教師" size='small' checkedValue="currentGroupHostOnly" onChange={onSwitchChange} />}
            </UiFilter>
          </TransferListModal>
        }

        {/* 選擇教學檔案 */}
        <SessionFormResourceModal
          name='resourceIdList'
          hostId={hostId}
          error={error}
        />
      </Form>
    </UiSessionForm>
  );
};
