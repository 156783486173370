import { Avatar as MatAvatar } from '@material-ui/core';
import styled from 'styled-components';

export const UiSessionCard = styled.div`
	position: relative;
	display: flex;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	letter-spacing: 2px;
	cursor: pointer;
	flex-direction: column;

	&:hover {
		box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
	}
`;

export const UiSessionCardImage = styled.div`
	overflow: hidden;
	width: 100%;
	height: 180px;
	font-size: 0;

	img {
		width: 100%;
		object-fit: contain;
	}
`;

export const UiSessionCardContent = styled.div`
	padding: 10px;
`;

export const UiSessionCardLocal = styled.div`
	font-size: 1rem;
	color: #ccc;
`;

export const UiSessionCardTitile = styled.div`
	padding: 0.5rem 0;
	height: 75px;
	font-size: 1.4rem;
`;

export const UiSessionCardDate = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	margin-bottom: 1rem;
	color: ${({ color }) => color};
`;

export const UiSessionCardInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	span {
		display: flex;
		align-items: center;
	}

	svg {
		margin-left: 5px;
		font-size: 1.5rem;
		color: #ccc;
	}
`;

export const UiSessionCardBorder = styled.div`
	width: 100%;
	height: 9px;
	background-color: ${({ color }) => color};
`;

export const UiSessionCardType = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	padding: 3px 8px;
	color: #fff;
	background-color: ${({ color }) => color};
`;

export const UiAvatar = styled(MatAvatar)`
	margin-right: 5px;

	svg {
		margin: 0;
	}
`;

export const UiIcon = styled.div`
	position: absolute;
	top: 3px;
	right: 3px;
`;
