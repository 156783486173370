import { EDIT_STATUS } from 'constants/index';
import { TextField, Box } from '@material-ui/core';
import { Modal, Button, DateTimePicker, TodoList, FileList, CheckboxGroup, OutlineButton } from 'components';
import { getTime, format } from 'date-fns';
import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAnnouncement } from 'store/announcement';
import { subtractionById, intersectionById } from 'utils/array';
import { isExist } from 'utils/helper';
import { useAlert } from 'utils/hooks/useAlert';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { useSetState } from 'utils/hooks/useSetState';

import {
  UiHomeAnnouncementForm,
  UiFlexBox,
  UiActionBox,
  UiForm,
  UiDateTimePicker,
  UiCheckboxGroup,
  UiCheckboxGroupText
} from './HomeAnnouncementForm.style';
/**
 * 新增編輯公告表單
 */


const checkboxList = [
  {
    value: 'groupOwner',
    label: '導師',
    checked: true,
  },
  {
    value: 'staff',
    label: '教師',
    checked: true,
  },
  {
    value: 'student',
    label: '學生',
    checked: true,
  }
];

export const HomeAnnouncementForm = ({ editStatus }) => {
  const history = useHistory();
  const { setAlert } = useAlert();
  const { organizationId, classId, announcementId } = useParams();
  const { upload } = useFirebaseStorage();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    todoData: [],
    fileData: [],
    audiences: editStatus === EDIT_STATUS.CREATE ? ['groupOwner', 'staff', 'student'] : []
  });
  const [,
    {
      createAnnouncement,
      updateAnnouncement,
      clearAnnouncements,
      getAnnouncementById
    }] = useAnnouncement();

  const [{
    isModalOpen,
    isLoading,
    isValidating,
    publishedAt,
    attachment,
    defaultTodoData,
    defaultFileData
  }, setState] = useSetState({
    isModalOpen: false,
    isLoading: editStatus === EDIT_STATUS.CREATE ? false : true,
    isValidating: false,
    publishedAt: null,
    formTitle: '',
    formContent: '',
    todoData: [],
    fileData: [],
    attachment: {
      url: '',
      name: ''
    },
    defaultTodoData: [],
    defaultFileData: []
  });

  const disabled = useMemo(() => {
    if (formData.title === '' ||
      formData.content === '' ||
      (!classId && formData.audiences.length === 0)
    ) {
      return true;
    }
    return false;
  }, [formData.title, formData.content, formData.audiences]);




  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };

  // 上傳檔案
  const onUploaded = async file => {
    if (!file.type) return;
    const fileType = file.type.split('/')[1];
    const uploadPath = `${classId}/announcement/${Date.now()}.${fileType}`;
    const { status, url } = await upload(uploadPath, file);

    if (status) {
      setAlert('上傳檔案成功!', 'success');
      return url;
    } else {
      setAlert('上傳檔案失敗!', 'wrarning');
      return false;
    }
  };

  const modalButtons = [
    {
      text: '取消',
      color: 'cancel',
      func: () => { }
    },
    {
      text: '確定',
      color: 'new',
      func: () => {
        clearAnnouncements();
        history.goBack();
      }
    },
  ];

  const goBack = () => history.push(!classId ? `/home/${organizationId}/announcement`
    : organizationId ?
      `/home/${organizationId}/class/${classId}/announcement` :
      `/home/class/${classId}/announcement`);

  const putAnnouncement = async isDraft => {
    setState({ isLoading: true, isValidating: true });

    const { errors, errorTotal } = validateForm();
    if (errorTotal > 0) {
      setState({ isLoading: false });
      setErrors(errors);
      return;
    }
    const data = {
      title: formData.title,
      content: formData.content
    };
    if (!classId) data.audiences = formData.audiences;
    if (formData.todoData.length > 0) {
      if (editStatus === EDIT_STATUS.CREATE) {
        data.checkList = formData.todoData.map(item => {
          return {
            description: item.description,
            dueAt: item.dueAt
          };
        });
      } else {
        if (defaultTodoData.length > 0) {
          //新增
          const newCheckListItemList = subtractionById(formData.todoData, defaultTodoData)
            .map(item => {
              return {
                description: item.description,
                dueAt: item.dueAt
              };
            });
          if (newCheckListItemList.length > 0) data.newCheckListItemList = newCheckListItemList;

          //更新
          const updateCheckListItemList = intersectionById(formData.todoData, defaultTodoData);
          if (updateCheckListItemList.length > 0) data.updateCheckListItemList = updateCheckListItemList;

          //刪除
          const deleteCheckListItemList = subtractionById(defaultTodoData, formData.todoData).map(item => {
            return {
              id: item.id
            };
          });
          if (deleteCheckListItemList.length > 0) data.deleteCheckListItemList = deleteCheckListItemList;
        } else {
          data.newCheckListItemList = formData.todoData;
        }
      }
      //
    }
    if (attachment.urls) {
      data.attachmentUrl = attachment.url;
      data.attachmentName = attachment.name;
    }
    if (formData.fileData.length > 0) {
      const url = await onUploaded(formData.fileData[0].value);
      if (url) {
        data.attachmentUrl = url;
        data.attachmentName = formData.fileData[0].value.name;
      }
    }

    let isSuccess = false;
    if (editStatus === EDIT_STATUS.CREATE) {
      if (isDraft) { //草稿
        data.isDraft = true;
      } else {
        if (publishedAt && publishedAt !== null) { //發佈且 有預約時間
          data.publishedAt = publishedAt;
        } else {
          data.isDraft = false; //發佈且 沒有預約時間
        }
      }
      isSuccess = await createAnnouncement({ payload: data });
    } else {
      if (isDraft) { //草稿
        data.isDraft = true;
      } else {
        if (publishedAt && publishedAt !== null) {
          data.publishedAt = publishedAt;
        } else {
          data.isPublish = true;
        }
      }

      if (data.publishedAt < getTime(new Date())) {
        delete data.publishedAt;
      }
      isSuccess = await updateAnnouncement(announcementId)({ payload: data });
    }
    setState({ isLoading: false });
    if (isSuccess) {
      clearAnnouncements();
      goBack();
    }
  };



  const onDateTimeChange = date => {
    setState({
      publishedAt: date || null
    });
  };

  const onTodoChangeHandler = data => {
    const nextTodoList = data.map(item => {
      if (item.dueAt === null) {
        delete item.dueAt;
      }
      return item;
    });
    setFormData({
      ...formData,
      todoData: nextTodoList
    });
  };

  const onFileChangeHandler = fileData => {
    setFormData({
      ...formData,
      fileData
    });
  };

  const getAnnouncement = async id => {
    const data = await getAnnouncementById(announcementId);

    const { title, content, audiences, publishedAt, checkList, attachmentName, attachmentUrl } = data;
    setState({ isLoading: false });
    setFormData({
      ...formData,
      title,
      content,
      audiences: audiences || []
    });
    setState({
      publishedAt,
      defaultTodoData: checkList,
      attachment: {
        url: attachmentUrl,
        name: attachmentName
      }
    });
    if (attachmentName) {
      setState({
        defaultFileData: [{
          id: 'file',
          value: {
            name: attachmentName,
          }
        }]
      });
    }
  };

  const checkboxChange = (val) => {
    setFormData({
      ...formData,
      audiences: val
    });
  };

  const changeHandler = (property, value) => {
    if (value !== formData[property]) {
      setFormData({
        ...formData,
        [property]: value
      });
    }
  };


  const formValidationRules = {
    title: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ],
    content: [
      {
        message: '本欄位為必填',
        validate: value => isExist(value)
      }
    ]
  };


  const validateForm = () => {
    let errors = {};
    let errorTotal = 0;
    Object.entries(formData).forEach(([key, value]) => {
      if (!formValidationRules[key]) return;
      formValidationRules[key].forEach(rule => {
        if (!rule.validate(value)) {
          errors[key] = {
            message: rule.message
          };
          errorTotal++;
        } else {
          delete errors[key];
        }
      });
    });
    return { errors, errorTotal };
  };

  const isSchedule = () => (publishedAt && (getTime(new Date(publishedAt)) > getTime(new Date())) ?
    true : false);

  useEffect(() => {
    if (!isValidating) return;
    const { errors } = validateForm();
    setErrors(errors);
  }, [isValidating, formData]);



  useEffect(() => {
    if (editStatus !== EDIT_STATUS.EDIT || !announcementId) return;
    getAnnouncement(announcementId);
  }, []);

  return (
    <UiHomeAnnouncementForm>
      <UiFlexBox>
        <UiFlexBox>
          <Button buttonColor="cancel" onClick={() => goBack()}>
            取消
          </Button>
          {
            (!publishedAt) &&
            <OutlineButton buttoncolor="new" disabled={disabled} onClick={() => putAnnouncement(true)} loading={isLoading}>
              儲存草稿
            </OutlineButton>
          }

          <Button buttonColor="new" disabled={disabled} onClick={() => putAnnouncement(false)} loading={isLoading}>
            發佈
          </Button>
        </UiFlexBox>
      </UiFlexBox>
      <UiFlexBox>
        <UiDateTimePicker>
          {
            (editStatus === EDIT_STATUS.CREATE || isSchedule() || !publishedAt) &&
            <DateTimePicker
              label='預約發佈時間'
              minDate={publishedAt ? format(publishedAt, 'yyyy-MM-dd') : ''}
              value={publishedAt}
              onChange={onDateTimeChange}
            />
          }
        </UiDateTimePicker>
        {
          !classId &&
          <UiCheckboxGroup>
            <UiCheckboxGroupText>
              誰能看見？
            </UiCheckboxGroupText>

            <CheckboxGroup
              data={checkboxList}
              onChange={checkboxChange}
              defaultValue={formData.audiences}
            />
          </UiCheckboxGroup>
        }


      </UiFlexBox>


      <UiForm>
        <Box my={'1.5rem'}>
          <TextField
            label={'公告標題'}
            placeholder={'請輸入公告標題'}
            value={formData.title}
            error={!!errors.title}
            helperText={errors.title && errors.title.message}
            variant={'outlined'}
            fullWidth
            onChange={e => { changeHandler('title', e.target.value); }}
          />
        </Box>
        <Box my={'1.5rem'}>
          <TextField
            label={'公告內容'}
            placeholder={'請輸入公告內容'}
            variant={'outlined'}
            value={formData.content}
            error={!!errors.content}
            helperText={errors.content && errors.content.message}
            multiline
            fullWidth
            rows={10}
            onChange={e => { changeHandler('content', e.target.value); }}
          />
        </Box>


      </UiForm>

      <UiActionBox>
        <TodoList data={defaultTodoData || []} onChange={onTodoChangeHandler} />
        <FileList data={defaultFileData || []} onChange={onFileChangeHandler} />
      </UiActionBox>
      <Modal
        isOpen={isModalOpen}
        text="公告內容不保存，回到公告列表"
        buttons={modalButtons}
        getModalState={getModalStateHandler}
      />
    </UiHomeAnnouncementForm>
  );
};
