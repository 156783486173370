import { availableEntryNavigation } from 'constants/index';
import defaultImage from 'assets/images/organization.png';
import { Loading } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useClass } from 'store/class';
import { UiGroupCard, UiGroupsImage, UiRoleIcon, UiGroupsPrompt } from './GroupCard.style';

/**
 * 班級卡牌
 */

const RoleIcon = ({ role, ownerId, userId }) => {
  const nextRole = ownerId === userId ? 'groupOwner' : role;
  switch (nextRole) {
    case 'groupOwner':
      return <div className="tag">導師</div>;
    case 'staff':
      return <div className="tag">教師</div>;
    case 'customer':
      return <div className="tag">學生</div>;
    default:
      return '';
  }
};

const educationNameFormat = (educationName) => {
  const educationNameText = {
    E: '國小',
    J: '國中',
    H: '高中',
  };
  return educationNameText[educationName];
};

const gradesFormat = (arr) => {
  if (arr.length === 0) return '';
  let str = '';
  arr.forEach((item) => {
    str += `${item},`;
  });
  return `${str.substring(0, str.length - 1)} 年級  - `;
};

export const GroupCard = ({ organizationId, year, isOwner, role, userId }) => {
  const [, { getAllUserClasses }] = useClass();
  const [isFetching, setIsFetching] = useState(false);
  const [groupData, setState] = useState([]);
  const history = useHistory();
  const fetchGroupsById = async () => {
    setIsFetching(true);
    const params = {
      organizationId,
      schoolYear: year,
    };
    const data = await getAllUserClasses(params);
    setIsFetching(false);
    setState(data);
  };

  const goMyGroup = (organizationId, classId, entryNavigationFunction) => {
    history.push(`/home/${organizationId}/class/${classId}/${availableEntryNavigation[entryNavigationFunction]}`);
  };

  useEffect(() => {
    if (!organizationId) return;
    fetchGroupsById();
  }, [organizationId, year]);

  const handleKeyDown = (event, organizationId, id, entryNavigationFunction) => {
    if (event.key === 'Enter') {
      goMyGroup(organizationId, id, entryNavigationFunction);
    }
  };

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : groupData.length > 0 ? (
        groupData.map((item) => {
          return (
            <UiGroupCard
              tabIndex={0}
              key={item.id}
              onClick={() => goMyGroup(item.organizationId, item.id, item.entryNavigationFunction)}
              onKeyDown={(e) => handleKeyDown(e, item.organizationId, item.id, item.entryNavigationFunction)}
            >
              <UiGroupsImage tabIndex={-1} url={item.thumbnailUrl || defaultImage} />
              <UiRoleIcon>
                <RoleIcon role={item.organizationRole} ownerId={item.ownerId} userId={userId} />
              </UiRoleIcon>
              <div>
                <div>
                  {educationNameFormat(item.educationName)}
                  {item.year && `${item.year}學年度`}
                </div>
                <div>
                  {item.grades && gradesFormat(item.grades)}
                  {item.name}
                </div>
              </div>
            </UiGroupCard>
          );
        })
      ) : (
        <UiGroupsPrompt>
          {isOwner
            ? '您目前尚未建立任何班級，請點選後台管理、進入管理後台建立班級'
            : role === 'staff'
            ? '您目前尚未被安排為授課教師或導師，請聯絡該機構相關負責人員'
            : '尚未加入班級'}
        </UiGroupsPrompt>
      )}
    </>
  );
};

GroupCard.propTypes = {
  organizationId: PropTypes.string,
  year: PropTypes.any,
  isOwner: PropTypes.bool,
  role: PropTypes.string,
  userId: PropTypes.string,
};
