import { FORGETPASSWORD, REGISTER, RULE, BROWSERSUGGESTION } from 'constants/index';
import {
  LoginPage,
  CountyLoginPage,
  CustomerServicePage,
  HomeRulePage,
  BrowserSuggestionPage,
  LineConnectedPage,
  QRCodePage,
  QRCodeLoginPage,
} from 'components';
import { isCounty } from 'utils/validation';
import { PATH } from './constants';

const LOGIN_PAGE = isCounty ? CountyLoginPage : LoginPage;

const routes = [
  {
    path: PATH.LOGIN,
    component: LOGIN_PAGE,
    exact: true,
  },
  {
    path: PATH.QR_CODE,
    component: QRCodePage,
    exact: true,
  },
  {
    path: PATH.QR_CODE_LOGIN,
    component: QRCodeLoginPage,
    exact: true,
  },
  {
    path: `/${FORGETPASSWORD}`,
    component: CustomerServicePage,
    exact: true,
    serviceStatus: FORGETPASSWORD,
  },
  {
    path: `/${REGISTER}`,
    component: CustomerServicePage,
    exact: true,
    serviceStatus: REGISTER,
  },
  {
    path: `/${RULE}`,
    component: HomeRulePage,
    exact: true,
  },
  {
    path: `/${BROWSERSUGGESTION}`,
    component: BrowserSuggestionPage,
    exact: true,
  },
  {
    path: PATH.LINE_CONNECTED,
    component: LineConnectedPage,
    exact: true,
  },
];

export default routes;
