import { minute, SESSION_STATUS, SESSION_TYPE } from 'constants/index';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from '@material-ui/core';
import forest from 'assets/images/forest.svg';
import { Button, EmptyPrompt, HomeSessionActions, Loading } from 'components';
import { UiHomeSessionTableBox } from 'components/pages/HomeSessionPage/HomeSessionPage.style';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getSessions } from 'services/api/home/sessions';
import { keepSessionReport } from 'services/api/organization/session';
import { useServerTime } from 'store/serverTime/index';
import { useUser } from 'store/user';
import { formatTimestamp } from 'utils/date';
import { useAlert } from 'utils/hooks/useAlert';
import { useUuid } from 'utils/hooks/useUuid';
import { UiDateTimeBox, UiActionsBox } from './HomeSessionTableNormal.style';

const SESSION_STATE = {
  PREPARING: 'preparing', // 課程準備中
  BEFORE_30: 'before30', // 課程開始前 30 分鐘
  PROCESSING: 'processing', // 課程進行中
};

const TABLE_HEAD_CELLS = [
  {
    TITLE: '課程名稱',
  },
  {
    TITLE: '科目',
  },
  {
    TITLE: '時間',
  },
  {
    TITLE: '教師',
  },
  {
    TITLE: '開始上課',
  },
  {
    TITLE: '',
  },
];

const ROWS_PER_PAGE_OPTIONS = [10, 15, 20];

/* 課程準備中 */
const ActionsComponentPreparing = ({ session, clickSessionResource }) => {
  return (
    <>
      <TableCell>
        <UiActionsBox>
          <Box>
            <Button buttonColor="disable">尚未開始</Button>
          </Box>
        </UiActionsBox>
      </TableCell>
      <TableCell>
        <UiActionsBox>
          <Box>
            <Button buttonColor="new" onClick={clickSessionResource({ session, isClickSessionResourceButton: true })}>
              課程教材
            </Button>
          </Box>
        </UiActionsBox>
      </TableCell>
    </>
  );
};

/* 課程結束前 30 分鐘 */
const ActionsComponentBefore30 = ({ session, clickEnterClassroom, clickSessionReport, clickSessionResource }) => {
  return (
    <>
      <TableCell>
        <UiActionsBox>
          <Box>
            <Button onClick={clickEnterClassroom({ session })}>進入教室</Button>
          </Box>
        </UiActionsBox>
      </TableCell>
      <TableCell>
        <UiActionsBox>
          <Box>
            <Button buttonColor="new" onClick={clickSessionResource({ session, isClickSessionResourceButton: true })}>
              課程教材
            </Button>
          </Box>
          {// 不是一般課程才顯示
          session.type !== SESSION_TYPE.NORMAL && (
            <Box>
              <Button buttonColor="visibility" onClick={() => clickSessionReport({ session })}>
                課堂報告
              </Button>
            </Box>
          )}
        </UiActionsBox>
      </TableCell>
    </>
  );
};

/* 課程進行中 */
const ActionsComponentProcessing = ({ session, clickEnterClassroom, clickSessionReport, clickSessionResource }) => {
  return (
    <>
      <TableCell>
        <UiActionsBox>
          <Box>
            <Button onClick={clickEnterClassroom({ session })}>進入教室</Button>
          </Box>
        </UiActionsBox>
      </TableCell>
      <TableCell>
        <UiActionsBox>
          <Box>
            <Button buttonColor="new" onClick={clickSessionResource({ session, isClickSessionResourceButton: true })}>
              課程教材
            </Button>
          </Box>
          {// 不是一般課程才顯示
          session.type !== SESSION_TYPE.NORMAL && (
            <Box>
              <Button buttonColor="visibility" onClick={() => clickSessionReport({ session })}>
                課堂報告
              </Button>
            </Box>
          )}
        </UiActionsBox>
      </TableCell>
    </>
  );
};

export const HomeSessionTableNormal = ({ handleEnterClassroom, clickSessionResource }) => {
  const history = useHistory();
  const { classId } = useParams();
  const { setAlert } = useAlert();
  const [{ profile }] = useUser();
  const [getUuid] = useUuid();
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState();
  const [sessionsApiData, setSessionsApiData] = useState();
  const [pagination, setPagination] = useState({
    count: 0,
    rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    page: 0,
  });
  const [{ timestamp: serverTimestamp }] = useServerTime();
  const serverTimestampInMinutes = serverTimestamp ? serverTimestamp / 1000 / 60 : null;
  const hasSessions = !!sessionsApiData?.total;

  const sessions = useMemo(() => {
    if (!serverTimestampInMinutes || !sessionsApiData) return [];
    const newSessions = sessionsApiData.sessions.map((session) => {
      const subjectText = session.subjects.map((subject) => subject.name).join('、');
      const date = formatTimestamp(session.startAt, 'yyyy-MM-dd');
      const startTime = formatTimestamp(session.startAt, 'HH:mm');
      const endTime = formatTimestamp(session.endAt, 'HH:mm');
      const startTimeInMinutes = session.startAt / minute;
      const durationText = {
        time: `${startTime}-${endTime}`,
        minute: `(${(session.endAt - session.startAt) / 1000 / 60}分)`,
      };
      const teacher = {
        oneClubId: session.hostId,
        name: session.hostName,
      };
      const state =
        serverTimestampInMinutes < startTimeInMinutes - 30
          ? SESSION_STATE.PREPARING
          : serverTimestampInMinutes < startTimeInMinutes
          ? SESSION_STATE.BEFORE_30
          : SESSION_STATE.PROCESSING;
      const newSession = {
        id: session.id,
        name: session.name,
        subjectText,
        date,
        durationText,
        teacher,
        state,
        timeSpanId: session.timeSpanId,
        type: session.type,
        hostId: session.hostId,
        mmsCourseId: session?.mmsCourseId,
        mmsCourseType: session?.mmsCourseType,
        users: session.users,
        resources: session.resources,
        resourceObject: session.resourceObject,
      };
      return newSession;
    });
    return newSessions;
  }, [serverTimestampInMinutes, sessionsApiData]);

  /* click 課堂報告按鈕 */
  const clickSessionReport = ({ session }) => {
    history.push({
      pathname: `/home/class/${classId}/sessions/${session.id}/timespan/${session.timeSpanId}/report`,
      state: {
        className: session.name,
        sessionType: session.type,
      },
    });
  };

  /* 建立課堂報表 */
  const fetchKeepSessionReport = async ({ session }) => {
    const payload = {
      sessionId: session.id,
      timeSpanId: session.timeSpanId,
    };
    const { isSuccess } = await keepSessionReport(payload);
    !isSuccess && setAlert('取得課堂報表失敗!', 'error');
  };

  /* click 進入教室按鈕 */
  const clickEnterClassroom = ({ session }) => () => {
    const isCallReportApi =
      session.type !== SESSION_TYPE.NORMAL &&
      (session.hostId === profile.id || session.users.some((id) => id === profile.id));
    isCallReportApi && fetchKeepSessionReport({ session });
    handleEnterClassroom({ session });
  };

  /* 取得課程列表 */
  const fetchSessions = async ({ page, rowsPerPage, filterData }) => {
    const payload = {
      ...filterData,
      status: SESSION_STATUS.NORMAL,
      nowPage: page,
      rowsPage: rowsPerPage,
    };
    const { data, status } = await getSessions(classId)(payload);
    status === 'success' ? setSessionsApiData(data) : setAlert('取得課程列表失敗', 'error');
    setPagination((prev) => ({
      ...prev,
      count: data?.total || prev.count,
    }));
  };

  /* 搜尋課程 */
  const searchSessions = async (filterData) => {
    setIsLoading(true);
    const page = 0;
    setFilterData((prev) => ({ ...prev, ...filterData }));
    setPagination((prev) => ({
      ...prev,
      page,
    }));
    await fetchSessions({ page, rowsPerPage: pagination.rowsPerPage, filterData });
    setIsLoading(false);
  };

  /* 切上下頁 */
  const changePage = async (event, newPage) => {
    setIsLoading(true);
    const page = newPage;
    setPagination((prev) => ({
      ...prev,
      page,
    }));
    await fetchSessions({ page, rowsPerPage: pagination.rowsPerPage, filterData });
    setIsLoading(false);
  };

  /* 改每頁列數 */
  const changeRowsPerPage = async (event) => {
    setIsLoading(true);
    const page = 0;
    const rowsPerPage = parseInt(event.target.value);
    setPagination((prev) => ({
      ...prev,
      rowsPerPage,
      page,
    }));
    await fetchSessions({ page, rowsPerPage, filterData });
    setIsLoading(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    await fetchSessions({ page: pagination.page, rowsPerPage: pagination.rowsPerPage });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <UiHomeSessionTableBox>
      <HomeSessionActions onSearch={searchSessions} />
      {!isLoading && !hasSessions ? (
        <EmptyPrompt img={forest} message={'尚無課程'} messageColor={'#8B90A0'} />
      ) : (
        <>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD_CELLS.map((cell) => {
                    const uuid = getUuid();
                    const title = cell.TITLE;
                    return <TableCell key={uuid}>{title}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow style={{ position: 'relative' }}>
                    <TableCell colSpan={TABLE_HEAD_CELLS.length}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                ) : (
                  sessions.map((session) => (
                    <TableRow key={session.id}>
                      <TableCell>{session.name}</TableCell>
                      <TableCell>{session.subjectText}</TableCell>
                      <TableCell>
                        <UiDateTimeBox>
                          <Typography className="date">{session.date}</Typography>
                          <Typography>{`${session.durationText.time} ${session.durationText.minute}`}</Typography>
                        </UiDateTimeBox>
                      </TableCell>
                      <TableCell>{session.teacher.name}</TableCell>
                      {
                        {
                          [SESSION_STATE.PREPARING]: (
                            <ActionsComponentPreparing session={session} clickSessionResource={clickSessionResource} />
                          ),
                          [SESSION_STATE.BEFORE_30]: (
                            <ActionsComponentBefore30
                              session={session}
                              clickEnterClassroom={clickEnterClassroom}
                              clickSessionReport={clickSessionReport}
                              clickSessionResource={clickSessionResource}
                            />
                          ),
                          [SESSION_STATE.PROCESSING]: (
                            <ActionsComponentProcessing
                              session={session}
                              clickEnterClassroom={clickEnterClassroom}
                              clickSessionReport={clickSessionReport}
                              clickSessionResource={clickSessionResource}
                            />
                          ),
                        }[session.state]
                      }
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={pagination.count}
            labelRowsPerPage="每頁顯示"
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onChangePage={changePage}
            onChangeRowsPerPage={changeRowsPerPage}
          />
        </>
      )}
    </UiHomeSessionTableBox>
  );
};

ActionsComponentPreparing.propTypes = {
  session: PropTypes.shape,
  clickSessionResource: PropTypes.func,
};
ActionsComponentBefore30.propTypes = {
  session: PropTypes.shape,
  clickEnterClassroom: PropTypes.func,
  clickSessionReport: PropTypes.func,
  clickSessionResource: PropTypes.func,
};
ActionsComponentProcessing.propTypes = {
  session: PropTypes.shape,
  clickEnterClassroom: PropTypes.func,
  clickSessionReport: PropTypes.func,
  clickSessionResource: PropTypes.func,
};
HomeSessionTableNormal.propTypes = {
  handleEnterClassroom: PropTypes.func,
  clickSessionResource: PropTypes.func,
};
