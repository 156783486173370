import { HomeGroupLayout, WebResource } from 'components';
import React from 'react';

/**
 * 常用資源頁面
 */

export const WebResourcePage = () => {
  return (
    <HomeGroupLayout>
      <WebResource />
    </HomeGroupLayout>);
};


