import { minute, SESSION_STATUS, SESSION_TYPE } from 'constants/index';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from '@material-ui/core';
import forest from 'assets/images/forest.svg';
import { Button, EmptyPrompt, HomeSessionActions, Loading } from 'components';
import { UiHomeSessionTableBox } from 'components/pages/HomeSessionPage/HomeSessionPage.style';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getSessions } from 'services/api/home/sessions';
import { useServerTime } from 'store/serverTime/index';
import { formatTimestamp } from 'utils/date';
import { useAlert } from 'utils/hooks/useAlert';
import { useUuid } from 'utils/hooks/useUuid';
import { UiDateTimeBox, UiActionsBox } from './HomeSessionTableDone.style';

const SESSION_STATE = {
  WITHIN_90: 'within90', // 課程結束 90 分鐘內
  AFTER_90: 'after90', // 課程結束 90 分鐘後
};

const TABLE_HEAD_CELLS = [
  {
    TITLE: '課程名稱',
  },
  {
    TITLE: '科目',
  },
  {
    TITLE: '時間',
  },
  {
    TITLE: '教師',
  },
  {
    TITLE: '開始上課',
  },
  {
    TITLE: '',
  },
];

const ROWS_PER_PAGE_OPTIONS = [10, 15, 20];

/* click 觀看錄影按鈕 */
function clickWatchRecording(session) {
  const sessionId = session?.mmsCourseId || session.id;
  const url = `${process.env.REACT_APP_ONEBOARD_BS_DOMAIN}/player/${sessionId}`;
  window.open(url);
}

/* 課程結束後 90 分鐘內 */
const ActionsComponentWithin90 = ({ session, handleEnterClassroom }) => {
  /* click 進入教室按鈕 */
  const clickEnterClassroom = () => {
    handleEnterClassroom({ session });
  };
  return (
    <UiActionsBox>
      <Box>
        <Button buttonColor="normal" onClick={clickEnterClassroom}>
          進入教室
        </Button>
      </Box>
      {// 不是一般課程才顯示
      session.type !== SESSION_TYPE.NORMAL && (
        <Box>
          <Button buttonColor="new" onClick={() => clickWatchRecording(session)}>
            觀看錄影
          </Button>
        </Box>
      )}
    </UiActionsBox>
  );
};

/* 課程結束 90 分鐘內 */
const ActionsComponentAfter90 = ({ session }) => {
  return (
    <UiActionsBox>
      <Box>
        <Button buttonColor="disable">課程結束</Button>
      </Box>
      {// 不是一般課程才顯示
      session.type !== SESSION_TYPE.NORMAL && (
        <Box>
          <Button buttonColor="new" onClick={() => clickWatchRecording(session)}>
            觀看錄影
          </Button>
        </Box>
      )}
    </UiActionsBox>
  );
};

export const HomeSessionTableDone = ({ handleEnterClassroom, clickSessionResource }) => {
  const history = useHistory();
  const { organizationId, classId } = useParams();
  const { setAlert } = useAlert();
  const [getUuid] = useUuid();
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState();
  const [sessionsApiData, setSessionsApiData] = useState();
  const [pagination, setPagination] = useState({
    count: 0,
    rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    page: 0,
  });
  const [{ timestamp: serverTimestamp }] = useServerTime();
  const serverTimestampInMinutes = serverTimestamp ? serverTimestamp / 1000 / 60 : null;
  const hasSessions = !!sessionsApiData?.total;

  const sessions = useMemo(() => {
    if (!serverTimestampInMinutes || !sessionsApiData) return [];
    const newSessions = sessionsApiData.sessions.map((session) => {
      const subjectText = session.subjects.map((subject) => subject.name).join('、');
      const date = formatTimestamp(session.startAt, 'yyyy-MM-dd');
      const startTime = formatTimestamp(session.startAt, 'HH:mm');
      const endTime = formatTimestamp(session.endAt, 'HH:mm');
      const endTimeInMinutes = session.endAt / minute;
      const durationText = {
        time: `${startTime}-${endTime}`,
        minute: `(${(session.endAt - session.startAt) / 1000 / 60}分)`,
      };
      const teacher = {
        oneClubId: session.hostId,
        name: session.hostName,
      };
      const state = serverTimestampInMinutes < endTimeInMinutes + 90 ? SESSION_STATE.WITHIN_90 : SESSION_STATE.AFTER_90;
      const isMmsCourse = !!session?.mmsCourseId;
      const isIndividualTutorialCenterCourse = session.users.length === 1; // 是否為一對一補習班課
      const newSession = {
        ...session,
        subjectText,
        date,
        durationText,
        teacher,
        state,
        isMmsCourse,
        isIndividualTutorialCenterCourse,
        id: session.id,
        name: session.name,
        timeSpanId: session.timeSpanId,
        type: session.type,
        hostId: session.hostId,
        mmsCourseId: session?.mmsCourseId,
        mmsCourseType: session?.mmsCourseType,
        users: session.users,
        resources: session.resources,
        resourceObject: session.resourceObject,
      };
      return newSession;
    });
    return newSessions;
  }, [serverTimestampInMinutes, sessionsApiData]);

  /* 取得課程列表 */
  const fetchSessions = async ({ page, rowsPerPage, filterData }) => {
    const payload = {
      ...filterData,
      status: SESSION_STATUS.DONE,
      nowPage: page,
      rowsPage: rowsPerPage,
    };
    const { data, status } = await getSessions(classId)(payload);
    status === 'success' ? setSessionsApiData(data) : setAlert('取得課程列表失敗', 'error');
    setPagination((prev) => ({
      ...prev,
      count: data?.total || prev.count,
    }));
  };

  /* 搜尋課程 */
  const searchSessions = async (filterData) => {
    setIsLoading(true);
    const page = 0;
    setFilterData((prev) => ({ ...prev, ...filterData }));
    setPagination((prev) => ({
      ...prev,
      page,
    }));
    await fetchSessions({ page, rowsPerPage: pagination.rowsPerPage, filterData });
    setIsLoading(false);
  };

  /* click 課堂報告按鈕 */
  const clickSessionReport = ({ session }) => {
    history.push({
      pathname: `/home/class/${classId}/sessions/${session.id}/timespan/${session.timeSpanId}/report`,
      state: {
        className: session.name,
        sessionType: session.type,
      },
    });
  };

  /* click 課後回饋按鈕 */
  const clickSessionFeedback = ({ session }) => {
    const location = {
      pathname: `/home/${organizationId}/class/${classId}/sessions/${session.id}/feedback`,
      state: { session },
    };
    history.push(location);
  };

  /* 切上下頁 */
  const changePage = async (event, newPage) => {
    setIsLoading(true);
    const page = newPage;
    setPagination((prev) => ({
      ...prev,
      page,
    }));
    await fetchSessions({ page, rowsPerPage: pagination.rowsPerPage, filterData });
    setIsLoading(false);
  };

  /* 改每頁列數 */
  const changeRowsPerPage = async (event) => {
    setIsLoading(true);
    const page = 0;
    const rowsPerPage = parseInt(event.target.value);
    setPagination((prev) => ({
      ...prev,
      rowsPerPage,
      page,
    }));
    await fetchSessions({ page, rowsPerPage, filterData });
    setIsLoading(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    await fetchSessions({ page: pagination.page, rowsPerPage: pagination.rowsPerPage });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <UiHomeSessionTableBox>
      <HomeSessionActions onSearch={searchSessions} />
      {!isLoading && !hasSessions ? (
        <EmptyPrompt img={forest} message={'尚無課程'} messageColor={'#8B90A0'} />
      ) : (
        <>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD_CELLS.map((cell) => {
                    const uuid = getUuid();
                    const title = cell.TITLE;
                    return <TableCell key={uuid}>{title}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={TABLE_HEAD_CELLS.length}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                ) : (
                  sessions.map((session) => (
                    <TableRow key={session.id}>
                      <TableCell>{session.name}</TableCell>
                      <TableCell>{session.subjectText}</TableCell>
                      <TableCell>
                        <UiDateTimeBox>
                          <Typography className="date">{session.date}</Typography>
                          <Typography>{`${session.durationText.time} ${session.durationText.minute}`}</Typography>
                        </UiDateTimeBox>
                      </TableCell>
                      <TableCell>{session.teacher.name}</TableCell>
                      <TableCell>
                        {
                          {
                            [SESSION_STATE.WITHIN_90]: (
                              <ActionsComponentWithin90 session={session} handleEnterClassroom={handleEnterClassroom} />
                            ),
                            [SESSION_STATE.AFTER_90]: <ActionsComponentAfter90 session={session} />,
                          }[session.state]
                        }
                      </TableCell>
                      <TableCell>
                        <UiActionsBox>
                          <Box>
                            <Button
                              buttonColor="new"
                              onClick={clickSessionResource({ session, isClickSessionResourceButton: true })}
                            >
                              課程教材
                            </Button>
                          </Box>
                          {// 不是一般課程才顯示
                          session.type !== SESSION_TYPE.NORMAL && (
                            <Box>
                              <Button buttonColor="visibility" onClick={() => clickSessionReport({ session })}>
                                課堂報告
                              </Button>
                            </Box>
                          )}
                          {session.isMmsCourse && session.isIndividualTutorialCenterCourse && (
                            <Box>
                              <Button buttonColor="visibility" onClick={() => clickSessionFeedback({ session })}>
                                課後回饋
                              </Button>
                            </Box>
                          )}
                        </UiActionsBox>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={pagination.count}
            labelRowsPerPage="每頁顯示"
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onChangePage={changePage}
            onChangeRowsPerPage={changeRowsPerPage}
          />
        </>
      )}
    </UiHomeSessionTableBox>
  );
};

ActionsComponentWithin90.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf(SESSION_TYPE),
    mmsCourseId: PropTypes.string,
  }),
  handleEnterClassroom: PropTypes.func,
};
ActionsComponentAfter90.propTypes = {
  session: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf(SESSION_TYPE),
    mmsCourseId: PropTypes.string,
  }),
};
HomeSessionTableDone.propTypes = {
  handleEnterClassroom: PropTypes.func,
  clickSessionResource: PropTypes.func,
};
