import { BsModal } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSetState } from 'utils/hooks/useSetState';
import BasicData from './BasicData';
import Teachers from './Teachers';

const CREATE_TYPE = {
  NOW: 'now',
  RESERVE: 'reserve'
};

const BASIC_DATA_TEXT = {
  cancelText: '取消',
  okText: '下一步',
  title: '我要派卷',
};

const TEACHERS_TEXT = {
  cancelText: '上一步',
  okText: '派卷',
  title: '選擇批改老師',
};

export const ExamModal = ({
  title = '我要派卷',
  isOpen,
  onClick,
  onClose,
  isLoading,
  defaultType,
  onChange,
  examPeriod,
  startAt,
  examName,
  examTeachers,
  disabledTab,
  formData,
  setFormData,
  selectedOptions,
  setSelectedOptions,
  gradeSetting,
  setGradeSetting
}) => {
  const [{
    createExamType,
  }, setState] = useSetState({
    createExamType: defaultType || CREATE_TYPE.NOW,
  });
  const [isLoadingTeachers, setIsLoadingTeachers] = useState(false);
  const [modal, setModal] = useState({
    cancelText: BASIC_DATA_TEXT.cancelText,
    okText: BASIC_DATA_TEXT.okText,
    onCancel: null,
    onOk: null,
    step: 1,
    title: title || BASIC_DATA_TEXT.title,
  });

  const onChangeCreateType = type => {
    setState({
      createExamType: type
    });
  };

  /**
   * 本班老師的選擇方法
   * @param {string} name checkbox name
   * @param {bool} checked checkbox isChecked
   */
  const changeCheckboxTeachers = (value) => {
    let newExamTeachers = [];
    newExamTeachers = value.map(item => item.id);

    onChange(newExamTeachers, 'examTeachers');
  };

  /* 派卷 */
  const clickOkTeachers = () => {
    onClick(createExamType);
  };

  /* 選擇批改老師彈窗 上一步 */
  const clickCancelTeachers = () => {
    setModal({
      cancelText: BASIC_DATA_TEXT.cancelText,
      okText: BASIC_DATA_TEXT.okText,
      onCancel: onClose,
      onOk: clickOkBasicData,
      step: 1,
      title: title || BASIC_DATA_TEXT.title,
    });
  };

  /* 基本資料彈窗 下一步 */
  const clickOkBasicData = () => {
    setModal({
      cancelText: TEACHERS_TEXT.cancelText,
      okText: TEACHERS_TEXT.okText,
      onCancel: clickCancelTeachers,
      onOk: clickOkTeachers,
      step: 2,
      title: TEACHERS_TEXT.title,
    });
  };

  useEffect(() => {
    setModal({
      cancelText: BASIC_DATA_TEXT.cancelText,
      okText: BASIC_DATA_TEXT.okText,
      onOk: clickOkBasicData,
      onCancel: onClose,
      step: 1,
      title: title || BASIC_DATA_TEXT.title,
    });
    // return () => setModal({ step: 1 });
  }, []);

  useEffect(() => {
    setModal(prev => ({
      ...prev,
      onCancel: clickCancelTeachers,
      onOk: clickOkTeachers,
    }));
  }, [examTeachers]);

  useEffect(() => {
    setModal(prev => ({
      ...prev,
      onCancel: onClose,
      onOk: clickOkBasicData,
    }));
  }, [examName, examPeriod, startAt]);

  const isStudentInputValid = () => {
    const validClassList = formData?.createClass?.find((item) => item?.value === '');
    const validStudentList = formData?.createClass?.find((item) => item?.studentList?.length === 0);

    if (validClassList || validStudentList || selectedOptions.length === 0) {
      return true;
    }
    return false;
  };
  return (
    <BsModal
      isLoading={isLoading || isLoadingTeachers}
      cancelText={modal.cancelText}
      okText={modal.okText}
      onCancel={modal.onCancel}
      onClose={onClose}
      onOk={modal.onOk}
      open={isOpen}
      title={modal.title}
      okDisabled={isStudentInputValid()}
    >
      {
        modal.step === 1 &&
        <BasicData
          createExamType={createExamType}
          disabledTab={disabledTab}
          examName={examName}
          examPeriod={examPeriod}
          onChange={onChange}
          onChangeCreateType={onChangeCreateType}
          startAt={startAt}
          setFormData={setFormData}
          formData={formData}
          setSelectedOptions={setSelectedOptions}
          selectedOptions={selectedOptions}
          gradeSetting={gradeSetting}
          setGradeSetting={setGradeSetting}
        />
      }

      {
        modal.step === 2 &&
        <Teachers
          examTeachers={examTeachers}
          isLoading={isLoadingTeachers}
          onChange={changeCheckboxTeachers}
          setIsLoading={setIsLoadingTeachers}
          formData={formData}
          setFormData={setFormData}
        />
      }

    </BsModal>
  );
};

ExamModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  defaultType: PropTypes.string,
  onChange: PropTypes.func,
  examPeriod: PropTypes.number,
  startAt: PropTypes.number,
  endAt: PropTypes.number,
  examName: PropTypes.string,
  examTeachers: PropTypes.array,
  disabledTab: PropTypes.bool,
  formData: PropTypes.instanceOf(Array),
  setFormData: PropTypes.func,
  selectedOptions: PropTypes.string,
  setSelectedOptions: PropTypes.func,
  gradeSetting: PropTypes.bool,
  setGradeSetting: PropTypes.func,
};
