import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const UiHomeSessionPage = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(3.25)}px ${theme.spacing(2.75)}px`,
  flex: 1,
  position: 'relative',
}));

export const UiTitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
}));

export const UiTitle = styled(Typography)(({ theme }) => ({
  color: '#242C3F',
  fontFamily: 'Noto Sans TC',
  fontSize: theme.spacing(2),
  fontWeight: 500,
}));

export const UiHomeSessionTableBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  margin: `${theme.spacing(2.25)}px 0`,
  '& .MuiTableHead-root': {
    background: '#FAFAFC',
  },
}));
