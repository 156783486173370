import forest from 'assets/images/forest.svg';
import { AnnouncementCard, Button, EmptyPrompt, Loading, HomeTitle } from 'components';
import { format, getDay, getTime } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { useParams, useHistory } from 'react-router-dom';
import { useAnnouncement } from 'store/announcement';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiHomeAnnouncementTable,
  UiCardBox,
  UiTitleBox,
} from './HomeAnnouncementTable.style';
/**
 * 公告列表
 */

export const HomeAnnouncementTable = () => {
  const [{ myOrganization, profile }] = useUser();
  const [{ myClasses }] = useClass();

  const { organizationId, classId } = useParams();
  const history = useHistory();
  const [{ announcements },
    {
      getAnnouncements,
      clearAnnouncements,
      pinAnnouncement,
      cancelPinAnnouncement,
      deleteAnnouncement,
      confirmAnnouncementCheckListItem,
      unconfirmAnnouncementCheckListItem
    }] = useAnnouncement();
  const [{
    nowPage,
    rowsPage,
    AnnouncementDataList,
    listLoading,
    hasNextPage,
    userType,
    userId,
    ownerId,
  }, setState
  ] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    AnnouncementDataList: [],
    listLoading: false,
    hasNextPage: true,
    userType: '',
    userId: '',
    ownerId: '',
  });
  const announcementPermission = useMemo(() => {
    const { dataInfo: { isOwner } } = myClasses;
    let result = false;
    const { permissionGroupsMap, userProfile } = myOrganization;
    if (classId && isOwner) result = true;
    if (!userProfile?.permissionGroups) return result;
    Object.entries(permissionGroupsMap).forEach(([key, value]) => {
      if (value.name === 'organization_announcement_management') result = true;
    });
    if (ownerId === userId) result = true;

    return result;
  }, [myOrganization]);


  //滾動加載觸發後事件
  const handleLoadMore = async () => {
    setState({
      listLoading: true
    });
    const page = await getAnnouncements({ nowPage, rowsPage });
    const pageLength = page.announcements && page.announcements.length;
    let isHasNextPage = ((nowPage * rowsPage) + pageLength) >= page.total ? false : true;

    setState({
      AnnouncementDataList:
        AnnouncementDataList.length > 0 ?
          AnnouncementDataList.concat(announcements.data) :
          announcements.data,
      listLoading: false,
      nowPage: nowPage + 1,
      hasNextPage: isHasNextPage
    });
  };

  //重取公告列表

  //滾動加載trigger
  const infiniteRef = useInfiniteScroll({
    loading: listLoading,
    hasNextPage,
    onLoadMore: handleLoadMore
  });
  //權限 組織名取得
  useEffect(() => {
    const { organization } = myOrganization;
    const { dataInfo: { isOwner, userType } } = myClasses;
    const { id: userId } = profile;//用戶ID

    setState({
      isAdmin: isOwner,
      userType,
      userId,
      ownerId: organization?.ownerId || '',

    });
  }, [myOrganization, myClasses]);

  /**
   * 重取作業列表
   */
  const reloadAnnouncementList = () => {
    clearAnnouncements();
    setState({
      nowPage: 0,
      hasNextPage: true,
      isLoading: false
    });
  };
  //置頂
  const onPinHandler = async data => {
    await pinAnnouncement(data.id);
    reloadAnnouncementList();
  };

  const onCancelPinHandler = async data => {
    await cancelPinAnnouncement(data.id);
    reloadAnnouncementList();
  };

  //刪除
  const onDeleteHandler = async data => {
    await deleteAnnouncement(data.id);
    reloadAnnouncementList();
  };

  //事項列表
  const onCheckListChangeHandler = async (val, itemVal, type, Announcementid) => {
    if (type === 'popIn') {
      await confirmAnnouncementCheckListItem(Announcementid, itemVal);
    } else {
      await unconfirmAnnouncementCheckListItem(Announcementid, itemVal);
    }
  };

  const covertWeek = [
    '星期日',
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六'
  ];

  return (
    <UiHomeAnnouncementTable ref={infiniteRef}>
      <UiTitleBox>
        <HomeTitle title={'公告'} />
        {
          announcementPermission && (
            <Button
              onClick={() => {
                classId ?
                  history.push(`/home/${organizationId}/class/${classId}/announcement/create`) :
                  history.push(`/home/${organizationId}/announcement/create`);
              }}
              icon='add'
            >
              發布新公告
            </Button>
          )
        }
        {/* {
          (!classId && organizationId && announcementPermission) &&
          <Button
              onClick={() => {
                history.push(`/home/${organizationId}/announcement/create`);
              }}
              icon='add'
            >
              發布新公告
            </Button>
        } */}
      </UiTitleBox>
      {listLoading && <Loading />}
      {(announcements.data.length <= 0 && !listLoading) && <EmptyPrompt img={forest} message="尚無班級公告" messageColor={'#8B90A0'} />}
      <UiCardBox>
        {
          announcements.data && announcements.data.map(item => ({
            ...item,
            publishedAt: (item.publishedAt) && format(new Date(item.publishedAt), 'yyyy-MM-dd HH:mm:ss'),
            year: (item.publishedAt) && format(new Date(item.publishedAt), 'yyyy'),
            months: (item.publishedAt) && format(new Date(item.publishedAt), 'MM'),
            day: (item.publishedAt) && format(new Date(item.publishedAt), 'dd'),
            clock: (item.publishedAt) && format(new Date(item.publishedAt), 'HH:mm'),
            week: (item.publishedAt) && covertWeek[getDay(new Date(item.publishedAt))],
            nickname: item.groupOwnerName,
            fileName: (item.attachmentName) && item.attachmentName,
            fileUrl: (item.attachmentUrl) && item.attachmentUrl,
            content: item.content.replace(/(\r\n|\n|\r)/gm, '<br />'),
            isStaff: (userType === 'teacher' || userId === item.groupOwnerId || userId === ownerId) ? true : false,
            isOwner: (userId === item.groupOwnerId || userId === ownerId) ? true : false,
            isSchedule: (item.publishedAt) ?
              (getTime(new Date(item.publishedAt)) > getTime(new Date()) ?
                true : false) :
              false
          })).map(announcement => (
            <AnnouncementCard
              key={announcement.id}
              data={announcement}
              isPinned={announcement.isPinned}
              onPin={onPinHandler}
              cancelPin={onCancelPinHandler}
              onDelete={onDeleteHandler}
              onCheckListChange={onCheckListChangeHandler}
              isCanUse={announcementPermission}
            />
          ))
        }
      </UiCardBox>
    </UiHomeAnnouncementTable>
  );
};
