import { ThemeProvider } from '@material-ui/core/styles';
import { HomeGroupLayout, HomeOnlineExam } from 'components';
import React, { useMemo } from 'react';
//import { useHistory } from 'react-router-dom';
import { useUser } from 'store/user';
import { theme } from './HomeOnlineExamPage.style';

/**
 * 線上測驗頁面
 */

export const HomeOnlineExamPage = () => {
  const [{ myOrganization }] = useUser();
  //const history = useHistory();
  //const isCanUseOnlineExam = myOrganization.organization?.onlineExamSetting === 'enabled';

  const isTeacher = useMemo(() => {
    if (!myOrganization.isLoaded) return undefined;
    switch (myOrganization?.userProfile?.role) {
      case 'groupOwner':
      case 'staff':
        return true;
      case 'student':
        return false;
      default:
        return false;
    }
  }, [myOrganization.isLoaded]);

  // // TODO: 目前只要貼網址就倒回首頁
  // useEffect(() => {
  //   if (!isCanUseOnlineExam) {
  //     history.push('/');
  //   }
  // }, [isCanUseOnlineExam]);

  return (
    <HomeGroupLayout tabIndex={0}>
      <ThemeProvider theme={theme}>
        <HomeOnlineExam isTeacher={isTeacher} />
      </ThemeProvider>
    </HomeGroupLayout>
  );
};
