import { BsHeaderTitle } from 'components';
import { BsHomeDashboard } from 'components';
import React from 'react';
import { UiBsContentContainer } from 'styles';

// import { UiBsHomePage } from './BsHomePage.style';


/**
 * 機構概況頁面
 */

export const BsHomePage = () => {
  return (
    <>
      <BsHeaderTitle title="機構概況" />
      <UiBsContentContainer>
        <BsHomeDashboard />
      </UiBsContentContainer>
    </>
  );
};


