import { Table } from 'components';
import React, { useEffect, useMemo } from 'react';
import { useOrganization } from 'store/organization';
import { UiTable } from 'styles';
import { formatTimestamp } from 'utils/date';
import { useSetState } from 'utils/hooks/useSetState';



/**
 * 學生邀請中列表
 */
const schema = {
  nickname: {
    name: '暱稱',
    defaultValue: '無'
  },
  lastInvitedAt: {
    name: '邀請時間',
    defaultValue: '無'
  }
};


export const CustomerInvitingTable = () => {
  const [{ invitations }, { getOrganizationInvitations }] = useOrganization();

  const [{ nowPage, rowsPage }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
  });

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  useEffect(() => {
    getOrganizationInvitations({ nowPage, rowsPage, role: 'customer' });
  }, [nowPage, rowsPage]);

  const invitationsData = useMemo(() =>
    invitations.data.map(item =>
      ({ ...item, lastInvitedAt: formatTimestamp(item.lastInvitedAt, 'yyyy-MM-dd HH:mm:ss') })
    )
    , [invitations.data]);

  return (
    <UiTable>
      <Table
        data={invitationsData}
        schema={schema}
        changePage_Rows={changePage_Rows}
        totalPage={invitations.total}
        nowPage={nowPage}
      />
    </UiTable>
  );
};

CustomerInvitingTable.propTypes = {

};


