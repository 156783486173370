import { COMPANY_DOMAIN, EDUCATION_LEVEL, SERVICE_NAME, SUBJECT } from 'constants/index';
import { delCookie, getCookie, setCookie } from 'utils/cookie';

const COOKIE_NAME_SERVICE_ROOT = 'nani_oneclass_serviceRoot';
const COOKIE_NAME_ONELINK_ORGANIZATION_ID = 'nani_oneclass_onelink_organizationId';
const COOKIE_NAME_ALLOW_EDU_SUBJECTS = 'nani_oneclass_allow_edu_subjects';
const ALL_SUBJECT_PERMISSIONS_CODES = ['CH', 'MA', 'EN', 'NA', 'SO']; // OneLink 紀錄的全科的科目
const ELEMENTARY_SUBJECT_TO_ONEPAPER_MAP = {
  [SUBJECT.ALL]: ['ECH', 'EMA', 'EEN', 'ELI', 'ENA', 'EPE'],
  [SUBJECT.CH]: ['ECH'],
  [SUBJECT.MA]: ['EMA'],
  [SUBJECT.EN]: ['EEN'],
  [SUBJECT.NA]: ['ELI', 'ENA'],
  [SUBJECT.SO]: ['EPE'],
};
const JUNIORHIGH_SUBJECT_TO_ONEPAPER_MAP = {
  [SUBJECT.ALL]: ['JPC', 'JMA', 'JEN', 'JNA', 'JBI', 'JPY', 'JEA', 'JSO', 'JGE', 'JHI', 'JCT', 'JPE', 'JCO', 'JTC'],
  [SUBJECT.CH]: ['JPC'],
  [SUBJECT.MA]: ['JMA'],
  [SUBJECT.EN]: ['JEN'],
  [SUBJECT.NA]: ['JNA', 'JBI', 'JPY', 'JEA'],
  [SUBJECT.SO]: ['JSO', 'JGE', 'JHI', 'JCT'],
};
const HIGH_SUBJECT_TO_ONEPAPER_MAP = {
  [SUBJECT.ALL]: ['HPC', 'HMA', 'HEN', 'HBI', 'HPH', 'HCE', 'HEA', 'HGE', 'HHI', 'HCS'],
  [SUBJECT.CH]: ['HPC'],
  [SUBJECT.MA]: ['HMA'],
  [SUBJECT.EN]: ['HEN'],
  [SUBJECT.NA]: ['HBI', 'HPH', 'HCE', 'HEA'],
  [SUBJECT.SO]: ['HGE', 'HHI', 'HCS'],
};

export const delOnePaperLoginCookie = () => {
  const cookieNameServiceRoot = COOKIE_NAME_SERVICE_ROOT;
  const cookieNameOrganizationId = COOKIE_NAME_ONELINK_ORGANIZATION_ID;
  const cookieDomain = COMPANY_DOMAIN;
  const hostname = window.location.hostname;
  delCookie(cookieNameServiceRoot, cookieDomain, hostname);
  delCookie(cookieNameOrganizationId, cookieDomain, hostname);
};

export const getOnePaperLoginCookie = () => {
  const cookieNameServiceRoot = COOKIE_NAME_SERVICE_ROOT;
  const cookieNameOrganizationId = COOKIE_NAME_ONELINK_ORGANIZATION_ID;
  return {
    [cookieNameServiceRoot]: getCookie(cookieNameServiceRoot),
    [cookieNameOrganizationId]: getCookie(cookieNameOrganizationId),
  };
};

export const setOnePaperLoginCookieByServiceRoot = () => {
  const cookieName = COOKIE_NAME_SERVICE_ROOT;
  const cookieValue = SERVICE_NAME;
  const cookieDomain = COMPANY_DOMAIN;
  const hostname = window.location.hostname;
  setCookie(cookieName, cookieValue, cookieDomain, hostname);
};

export const setOnePaperLoginCookieByOrganizationId = (organizationId) => {
  const cookieName = COOKIE_NAME_ONELINK_ORGANIZATION_ID;
  const cookieValue = organizationId;
  const cookieDomain = COMPANY_DOMAIN;
  const hostname = window.location.hostname;
  setCookie(cookieName, cookieValue, cookieDomain, hostname);
};

export const setOnePaperAllowEduSubjectsCookieBySubjects = (eductionSubjects) => {
  const cookieName = COOKIE_NAME_ALLOW_EDU_SUBJECTS;
  const cookieValue = JSON.stringify(eductionSubjects);
  const cookieDomain = COMPANY_DOMAIN;
  const hostname = window.location.hostname;
  setCookie(cookieName, cookieValue, cookieDomain, hostname);
};

/* 格式化成 OnePaper 用的 education subjects */
export function formatEducationSubjects(educationSettings) {
  if (!educationSettings) return [];
  let isAllSubject = false;
  // 國小
  const elementarySubject = educationSettings.find(({ id }) => id === EDUCATION_LEVEL.ELEMENTARY);
  isAllSubject = ALL_SUBJECT_PERMISSIONS_CODES.every((CODE) => elementarySubject?.subjects.includes(CODE));
  const elementarySubjects = isAllSubject
    ? ELEMENTARY_SUBJECT_TO_ONEPAPER_MAP[SUBJECT.ALL]
    : elementarySubject?.subjects.flatMap((subject) => ELEMENTARY_SUBJECT_TO_ONEPAPER_MAP[subject]) || [];
  // 國中
  const juniorHighSubject = educationSettings.find(({ id }) => id === EDUCATION_LEVEL.JUNIOR);
  isAllSubject = ALL_SUBJECT_PERMISSIONS_CODES.every((CODE) => juniorHighSubject?.subjects.includes(CODE));
  const juniorHighSubjects = isAllSubject
    ? JUNIORHIGH_SUBJECT_TO_ONEPAPER_MAP[SUBJECT.ALL]
    : juniorHighSubject?.subjects.flatMap((subject) => JUNIORHIGH_SUBJECT_TO_ONEPAPER_MAP[subject]) || [];
  // 高中
  const highSubject = educationSettings.find(({ id }) => id === EDUCATION_LEVEL.SENIOR);
  isAllSubject = ALL_SUBJECT_PERMISSIONS_CODES.every((CODE) => highSubject?.subjects.includes(CODE));
  const highSubjects = isAllSubject
    ? HIGH_SUBJECT_TO_ONEPAPER_MAP[SUBJECT.ALL]
    : highSubject?.subjects.flatMap((subject) => HIGH_SUBJECT_TO_ONEPAPER_MAP[subject]) || [];
  return [...elementarySubjects, ...juniorHighSubjects, ...highSubjects];
}
