
import { EDUCATION, EDUCATION_LEVEL, ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { Box } from '@material-ui/core';
import organizationDefaultImage from 'assets/images/organization.png';
import { Select, FormCheckBoxGroup, Icon, Button, ClickablePopoverMenu, Modal, RadioGroup, Checkbox } from 'components';
import lrz from 'lrz';
import MatImage from 'material-ui-image';
import React, { useEffect, useRef, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { uniqueArray, convertArrayToMap } from 'utils/array';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { useAllSchoolYear } from 'utils/hooks/useSchoolYear';
import { useSetState } from 'utils/hooks/useSetState';
import { useUuid } from 'utils/hooks/useUuid';
import {
  UiHomeSetting,
  UiHomeSettingTitle,
  UiHomeSettingContent,
  UiHomeSettingImage,
  UiHomeSettingButton,
  UiTextArea,
  UiButtonBox,
} from './HomeSetting.style';



/**
 * 設定頁面
 */

const SAVE = 'SAVE';
const DELETEIMAGE = 'DELETEIMAGE';
const CANCEL = 'CANCEL';
const VISIBILITY = 'VISIBILITY';
const UNVISIBILITY = 'UNVISIBILITY';
const DELETECLASS = 'DELETECLASS';

const modalText = {
  [SAVE]: '儲存變更',
  [DELETEIMAGE]: '移除圖片',
  [CANCEL]: '離開',
  [VISIBILITY]: '隱藏班級',
  [UNVISIBILITY]: '開啟班級',
  [DELETECLASS]: '刪除班級'
};

const modalContent = {
  [SAVE]: '儲存目前設定。',
  [DELETEIMAGE]: '',
  [CANCEL]: '',
  [VISIBILITY]: '隱藏後，將無法再使用導師商談與邀請功能!',
  [UNVISIBILITY]: '開啟後，將可以使用導師商談與邀請功能!',
  [DELETECLASS]: '刪除班級後，所有資料將消失，此步驟無法回復，請謹慎使用!!'
};

const selectProps = {
  options: [
    {
      name: '國小',
      value: EDUCATION_LEVEL.ELEMENTARY
    },
    {
      name: '國中',
      value: EDUCATION_LEVEL.JUNIOR
    },
    {
      name: '高中',
      value: EDUCATION_LEVEL.SENIOR
    },
    {
      name: '其他',
      value: EDUCATION_LEVEL.OTHER
    }
  ]
};
const educationOptionsMap = convertArrayToMap(selectProps.options, 'value');

export const HomeSetting = () => {
  const fileUploader = useRef(null);
  const history = useHistory();
  const [getUuid] = useUuid();
  const { upload } = useFirebaseStorage();
  const { organizationId, classId } = useParams();
  const [
    { myClasses: { dataInfo, dataMap } },
    { updateClass, deleteGroup, setClassVisibility }] = useClass();

  const [{ myOrganization }] = useUser();
  const [errors, setErrors] = useState({});
  const thumbnailRef = useRef();

  const organizationDefaultEducationName = myOrganization.isLoaded ?
    [...myOrganization?.organization?.educationNames] : [];
  const classesInitEducationName = dataMap[classId]?.educationName || '';
  const classesInitGrades = dataMap[classId]?.grades;
  const educationOptions = uniqueArray(
    [...[classesInitEducationName], ...organizationDefaultEducationName]
  ).map(item => educationOptionsMap[item] || []) || [];
  const defaultEducationName = organizationDefaultEducationName[0];

  const [formData, setFormData] = useSetState({
    name: '',
    year: '',
    educationName: defaultEducationName,
    grades: classesInitGrades || [],
    thumbnailUrl: '',
    description: ''
  });

  const gradesData = useMemo(() => {
    return formData.educationName ? EDUCATION[formData.educationName] : [];
  }, [formData.educationName]);

  const isSchoolYearRequired = formData.educationName !== EDUCATION_LEVEL.OTHER;
  const {
    schoolYearOptions: schoolYearBaseOptions,
    schoolYearOptionsWithNone
  } = useAllSchoolYear();
  const schoolYearOptions = useMemo(() =>
    isSchoolYearRequired ? schoolYearBaseOptions : schoolYearOptionsWithNone
    , [isSchoolYearRequired, schoolYearBaseOptions, schoolYearOptionsWithNone]);


  const [{
    tempThumbnailBase64,
    isValidating,
    isModalOpen,
    modalType,
    isButtonLoading,
  }, setState] = useSetState({
    tempThumbnailBase64: '',
    isValidating: false,
    isModalOpen: false,
    modalType: '',
    isButtonLoading: false
  });

  const popoverMenuList = [
    {
      label: '上傳圖片',
      func: () => fileUploader.current.click(),
      iconName: 'upload',
    },
    {
      label: '刪除',
      func: () => {
        setState({
          modalType: DELETEIMAGE,
          isModalOpen: true
        });
      },
      iconName: 'delete',
    },
  ];

  const changeThumbnailUrl = async file => {
    await lrz(file).then(async res => {
      setState({
        tempThumbnailBase64: res.base64
      });

      let thumbnail = '';
      for (let value of res.formData.values()) {
        thumbnailRef.current = value;
      }
      handleUpdate('thumbnailUrl', thumbnail);
    });
  };

  const onUploaded = async file => {
    if (!file.type) return;
    const uploadPath = `${classId}/classAlbum/${getUuid()}${file.name}`;
    const { status, url } = await upload(uploadPath, file);

    if (status) {
      return url;
    } else {
      return false;
    }
  };

  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };

  //儲存變更
  const saveChange = async () => {
    setState({
      isButtonLoading: true
    });

    const { errors, errorTotal } = validateForm();
    if (errorTotal > 0) {
      setState({ isLoading: false });
      setErrors(errors);
      return;
    }

    const data = {
      ...formData,
      grades: formData.grades || []
    };

    if (formData.educationName === EDUCATION_LEVEL.OTHER) {
      data.grades = [];
    }

    if (thumbnailRef.current) {
      const url = await onUploaded(thumbnailRef.current);
      if (url) {
        data.thumbnailUrl = url;
      }
    }

    const success = await updateClass(data);

    setState({
      isButtonLoading: false
    });

    if (success) {
      history.push(`/organization/${organizationId}/class`);
    }
  };

  const buttons = [
    {
      text: '取消',
      color: 'cancel',
      func: () => { }
    },
    {
      text: '確認',
      color: 'new',
      func: async () => {
        switch (modalType) {
          case DELETEIMAGE:
            setFormData({ ...formData, thumbnailUrl: '' });
            thumbnailRef.current = '';
            setState({ tempThumbnailBase64: '' });
            fileUploader.current.value = '';
            return;
          case SAVE:
            saveChange();
            return;
          case CANCEL:
            history.push(`/home/${organizationId ? organizationId + '/' : ''}class/${classId}/info`);
            return;
          case UNVISIBILITY:
          case VISIBILITY:
            setClassVisibility(!dataInfo.isHidden);
            return;
          case DELETECLASS: {
            const isSuccess = await deleteGroup();
            if (isSuccess) history.push('/home');
            return;
          }
          default:
            return;
        }
      }
    },
  ];

  useEffect(() => {
    if (Object.keys(dataInfo || {}).length < 1) return;
    const {
      name,
      year,
      educationName,
      schoolName,
      grades,
      thumbnailUrl,
      description,
      isOwner,
      groupHostCreateSessionSetting,
      groupRole
    } = dataInfo;

    if (isOwner === false && groupRole !== 'organizationOwner') {
      history.push(`/home/${organizationId ? organizationId + '/' : ''}class/${classId}/info`);
    }
    setFormData({
      name,
      year,
      educationName,
      schoolName,
      grades: grades || [],
      thumbnailUrl,
      description,
      groupHostCreateSessionSetting
    });
  }, [dataInfo]);

  const handleUpdate = (property, value) => {
    switch (property) {
      case 'educationName':
        if (value !== formData.educationName) {
          setFormData({
            [property]: value,
            grades: []
          });
        }
        break;
      default:
        setFormData({ [property]: value });
        break;
    }
  };

  const formValidationRules = {
    name: [
      { message: '最大長度為 20 個字元', validate: value => value.length <= 20 }
    ],
    description: [
      { message: '最大長度為 200 個字元', validate: value => value.length <= 200 }
    ],
    educationName: [
      {
        message: '班級學制不符合機構學制', validate: value => {
          const educationNameIsAvailable = organizationDefaultEducationName.indexOf(value) !== -1;
          return educationNameIsAvailable;
        }
      }
    ],
    grades: [
      {
        message: '此學制下，本欄位為必填',
        validate: value => {
          if (isSchoolYearRequired) {
            return value && value.length > 0;
          }
          return true;
        }
      },
    ],
    year: [
      {
        message: '此學制下，本欄位為必填',
        validate: value => {
          if (isSchoolYearRequired) {
            return !!value;
          }
          return true;
        }
      }
    ]
  };

  const validateForm = () => {
    let errors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (!formValidationRules[key]) return;
      formValidationRules[key].forEach(rule => {
        if (!rule.validate(value)) {
          errors[key] = {
            message: rule.message
          };
        } else {
          delete errors[key];
        }
      });
    });
    return { errors };
  };

  useEffect(() => {
    if (!isValidating) return;
    const { errors } = validateForm();
    setErrors(errors);
  }, [isValidating, formData]);

  const ImageSrc = useMemo(() => {
    return formData.thumbnailUrl || tempThumbnailBase64 || organizationDefaultImage;
  }, [formData.thumbnailUrl, tempThumbnailBase64]);

  return (
    <UiHomeSetting>
      <UiHomeSettingTitle>設定</UiHomeSettingTitle>
      <UiHomeSettingContent>
        <div>
          <UiHomeSettingImage>
            <MatImage src={ImageSrc} alt="org" />
          </UiHomeSettingImage>
          <ClickablePopoverMenu menuList={popoverMenuList} width={'100%'}>
            <UiHomeSettingButton>
              <Icon name="image" haveBg={false} />
              班級圖片變更
              <input type="file" onChange={e => changeThumbnailUrl(e.target.files[0])} ref={fileUploader} />
            </UiHomeSettingButton>
          </ClickablePopoverMenu>
        </div>
        <div className="selection">
          <Box my={2}>
            <Select
              name='year'
              label='學年度'
              value={formData.year}
              error={!!errors.year}
              helperText={errors.year && errors.year.message}
              options={schoolYearOptions}
              onChangeHandler={(value) => handleUpdate('year', value)}
            />
          </Box>
          <Box my={2}>
            <Select
              name='educationName'
              label='學制'
              value={formData.educationName}
              error={!!errors.educationName}
              onChangeHandler={(value) => handleUpdate('educationName', value)}
              helperText={errors.educationName && errors.educationName.message}
              options={educationOptions}
            />
          </Box>
          <Box my={2}>
            {
              myOrganization.organization?.groupGradeSelectionSetting === ORGANIZATION_SETTINGS_VALUE.SINGLE ? (
                <RadioGroup
                  data={gradesData}
                  value={formData.grades[0] || ''}
                  defaultValue={formData.grades[0]}
                  onChange={(e) => handleUpdate('grades', [e.target.value])}
                  error={!!errors.grades}
                  helperText={errors.grades && errors.grades.message}
                />
              ) : (
                <FormCheckBoxGroup
                  name='grades'
                  label='年級'
                  error={!!errors.grades}
                  helperText={errors.grades && errors.grades.message}
                  data={gradesData}
                  onChange={(value) => handleUpdate('grades', value)}
                  value={formData.grades}
                />
              )
            }
          </Box>
        </div>
      </UiHomeSettingContent>
      <UiTextArea
        placeholder="請輸入班級簡介"
        value={formData.description}
        onChange={e => handleUpdate('description', e.target.value)}
      />
      <Box>
        <Checkbox
          name='groupHostCreateSessionSetting'
          label="允許教師自行開課"
          helperText=''
          checkedValue={true}
          checked={formData.groupHostCreateSessionSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW}
          onChange={value => {
            const isChecked = (value === true) ? ORGANIZATION_SETTINGS_VALUE.ALLOW :
              ORGANIZATION_SETTINGS_VALUE.DISALLOW;
            handleUpdate('groupHostCreateSessionSetting', isChecked);
          }}
        />
      </Box>
      <UiButtonBox>
        <Button
          icon={dataInfo.isHidden ? 'visibility' : 'visibilityOff'}
          buttonColor='visibility'
          loading={isButtonLoading}
          onClick={() => setState({ modalType: dataInfo.isHidden ? UNVISIBILITY : VISIBILITY, isModalOpen: true })}
        >
          {dataInfo.isHidden ? '開啟班級' : '隱藏班級'}
        </Button>
        <Button
          icon='delete'
          buttonColor='delete'
          loading={isButtonLoading}
          onClick={() => setState({ modalType: DELETECLASS, isModalOpen: true })}
        >
          刪除班級
        </Button>
        <Button
          buttonColor='cancel'
          onClick={() => setState({ modalType: CANCEL, isModalOpen: true })}
          loading={isButtonLoading}
        >
          取消變更
        </Button>
        <Button
          buttonColor="new"
          onClick={() => setState({ modalType: SAVE, isModalOpen: true })}
          loading={isButtonLoading}
        >
          儲存變更
        </Button>
      </UiButtonBox>
      <Modal
        isOpen={isModalOpen}
        text={`確定要<span>${modalText[modalType]}</span>嗎?`}
        content={modalContent[modalType]}
        buttons={buttons}
        getModalState={getModalStateHandler}
      />
    </UiHomeSetting>
  );
};
