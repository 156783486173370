import { BsHeaderTab } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// import { UiBsTeacherManagementPageHeaderTab } from './BsTeacherManagementPageHeaderTab.style';


/**
 * 學生管理 header tab
 */

export const BsTeacherManagementPageHeaderTab = ({ activeIndex = 0 }) => {
  const { organizationId } = useParams();
  const history = useHistory();
  const goPath = path => history.push(path);

  const tabData = [
    { label: '教師列表', onClick: () => goPath(`/organization/${organizationId}/teachers`) },
    { label: '邀請中', onClick: () => goPath(`/organization/${organizationId}/teachers/inviting`) }
  ];

  return (
    <BsHeaderTab data={tabData} activeIndex={activeIndex} />
  );
};

BsTeacherManagementPageHeaderTab.propTypes = {
  activeIndex: PropTypes.number
};


