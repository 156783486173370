import {
  HomeGroupLayout,
  MemberSeatsSelectTable
} from 'components';
import React from 'react';

/**
 * 設定座號
 */

export const MemberSeatsSelectPage = () => {
  return (
    <HomeGroupLayout>
      <MemberSeatsSelectTable />
    </HomeGroupLayout>
  );
};

MemberSeatsSelectPage.propTypes = {

};


