import forest from 'assets/images/forest.svg';
import { HomeTitle, EmptyPrompt } from 'components';
import React, { useEffect } from 'react';
import { useClass } from 'store/class';
import { UiHomeInfo, UiInfoBox, UiDescription } from './HomeInfo.style';


/**
 * 班級簡介頁面
 */

export const HomeInfo = () => {
  const [{ classBrief }, { getClassBrief }] = useClass();
  useEffect(() => {
    getClassBrief();
  }, []);
  return (
    <UiHomeInfo>
      <HomeTitle title={'簡介'} />
      <UiInfoBox>
        {
          classBrief.description ?
            <UiDescription tabIndex={0}>{classBrief.description}</UiDescription> :
            <EmptyPrompt img={forest} message='尚無班級簡介' messageColor={'#8B90A0'} />
        }

      </UiInfoBox>
    </UiHomeInfo>
  );
};
