import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getGroupAlbums as getGroupAlbumsApi,
  createCustomGroupAlbum as createCustomGroupAlbumApi,
  updateCustomGroupAlbum as updateCustomGroupAlbumApi,
  deleteCustomGroupAlbum as deleteCustomGroupAlbumApi,
  getGroupAlbumEntries as getGroupAlbumEntriesApi,
  banCustomGroupAlbum as banCustomGroupAlbumApi,
  addGroupAlbumEntry as addGroupAlbumEntryApi,
  deleteCustomGroupAlbumEntry as deleteCustomGroupAlbumEntryApi
} from 'services/api/home/album';

import {
  likeGroupPost as likeGroupPostApi,
  undoLikeGroupPost as undoLikeGroupPostApi,
  replyGroupPost as replyGroupPostApi,
  getGroupPostReplies as getGroupPostRepliesApi
} from 'services/api/home/posts';
import { useUser } from 'store/user';
import { convertArrayToMap } from 'utils/array';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';

const GET_ALBUM = 'GET_ALBUM';
const CREATE_ALBUM = 'CREATE_ALBUM';
const UPDATE_ALBUM = 'UPDATE_ALBUM';
const DELETE_ALBUM = 'DELETE_ALBUM';
const CLEAR_ALBUM = 'CLEAR_ALBUM';

const GET_PICTURE = 'GET_PICTURE';
const CREATE_PICTURE = 'CREATE_PICTURE';
const DELETE_PICTURE = 'DELETE_PICTURE';
const CLEAR_PICTURE = 'CLEAR_PICTURE';

const REPLY_POST = 'REPLY_POST';
const GET_POST_REPLIES = 'GET_POST_REPLIES';
const LIKE_POST_PICTURE = 'LIKE_POST_PICTURE';
const UNLIKE_POST_PICTURE = 'UNLIKE_POST_PICTURE';
const LIKE_POST_REPLIE = 'LIKE_POST_REPLIE';
const UNLIKE_POST_REPLIE = 'UNLIKE_POST_REPLIE';

const initState = {
  albumList: {
    albumNames: [],
    albums: [],
    total: 0
  },
  pictureList: {
    album: [],
    entries: [],
    posts: [],
    dataMap: {},
    total: 0
  }
};

const actions = {
  getAlbum: albumList => ({
    type: GET_ALBUM,
    payload: { ...albumList }
  }),
  createAlbum: ({ data }) => ({
    type: CREATE_ALBUM,
    payload: { data }
  }),
  updateAlbum: (params, albumId) => ({
    type: UPDATE_ALBUM,
    payload: { params, albumId }
  }),
  deleteAlbum: (params, albumId) => ({
    type: DELETE_ALBUM,
    payload: { params, albumId }
  }),
  clearAlbum: () => ({
    type: CLEAR_ALBUM
  }),
  getPicture: pictureList => ({
    type: GET_PICTURE,
    payload: { ...pictureList }
  }),
  createPicture: ({ data }) => ({
    type: CREATE_PICTURE,
    payload: { data }
  }),
  deletePicture: ({ postId, pictureId }) => ({
    type: DELETE_PICTURE,
    payload: { postId, pictureId }
  }),
  clearPicture: () => ({
    type: CLEAR_PICTURE
  }),
  replyPost: (params, postId) => ({
    type: REPLY_POST,
    payload: { params, postId }
  }),
  getPostReplies: (params, postId) => ({
    type: GET_POST_REPLIES,
    payload: { params, postId }
  }),
  updateUnlikedReplie: (postId, userId) => ({
    type: LIKE_POST_REPLIE,
    payload: { postId, userId }
  }),
  updateLikedReplie: (postId, userId) => ({
    type: UNLIKE_POST_REPLIE,
    payload: { postId, userId }
  }),
  updateUnlikedPicture: (postId, userId) => ({
    type: LIKE_POST_PICTURE,
    payload: { postId, userId }
  }),
  updateLikedPicture: (postId, userId) => ({
    type: UNLIKE_POST_PICTURE,
    payload: { postId, userId }
  }),
};

export const useAlbum = () => {
  const [{ profile: { id } }] = useUser();
  const dispatch = useDispatch();
  const { classId, albumCategory } = useParams();
  const { setAlert } = useAlert();
  const { albumList, pictureList } = useSelector(state => state.album);

  const getAlbum = async params => {
    const { isSuccess, data, error } = await getGroupAlbumsApi(classId)(params);
    try {
      if (!isSuccess) throw error;
      const { albumNames, albums, total } = data;
      dispatch(actions.getAlbum({ albumNames, albums, total }));
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
      return null;
    }
  };

  const createAlbum = async params => {
    const { isSuccess, error, data } = await createCustomGroupAlbumApi(classId)(
      params
    );
    try {
      if (!isSuccess) throw error;
      dispatch(actions.createAlbum({ data }));
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error', errorCode);
      return null;
    }
  };
  const updateAlbum = async (name, albumId) => {
    const params = {
      name
    };
    const { isSuccess, error, data } = await updateCustomGroupAlbumApi(
      classId,
      albumId
    )(params);
    try {
      if (!isSuccess) throw error;
      const { id } = data;
      dispatch(actions.updateAlbum(data, id));
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
  };
  //刪除自訂相簿(導師刪學生相簿:ban 自刪:delete)
  const deleteAlbum = async (data, albumId, type) => {
    const { isSuccess, error } =
      type === 'ban'
        ? await banCustomGroupAlbumApi(classId)(albumId)
        : await deleteCustomGroupAlbumApi(classId)(albumId);
    try {
      if (!isSuccess) throw error;
      dispatch(actions.deleteAlbum(data, albumId));
      setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
    }
  };

  const clearAlbum = () => dispatch(actions.clearAlbum());
  const clearPicture = () => dispatch(actions.clearPicture());

  const getPicture = async params => {
    const { isSuccess, data, error } = await getGroupAlbumEntriesApi(
      classId,
      albumCategory
    )(params);
    try {
      if (!isSuccess) throw error;
      const { album, entries, posts, total } = data;
      dispatch(actions.getPicture({ album, entries, posts, total }));
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
      return null;
    }
  };
  //上傳相片
  const createPicture = async params => {
    const { isSuccess, error, data } = await addGroupAlbumEntryApi(
      classId,
      albumCategory
    )(params);
    try {
      if (!isSuccess) throw error;
      dispatch(actions.createPicture({ data }));
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
      return isSuccess;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error', errorCode);
      return null;
    }
  };

  //回覆貼文
  const replyPostToAlbum = postId => async payload => {
    try {
      const { isSuccess, data, error } = await replyGroupPostApi(
        classId,
        postId
      )(payload);
      if (!isSuccess) throw error;
      dispatch(actions.replyPost(data, postId));
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
  };

  //獲取完整留言
  const getPostRepliesToAlbum = postId => async payload => {
    try {
      const { isSuccess, data, error } = await getGroupPostRepliesApi(
        classId,
        postId
      )(payload);
      if (!isSuccess) throw error;
      dispatch(actions.getPostReplies(data, postId));
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
  };

  // 貼文按讚
  const likePostToAlbum = async (postId, type) => {
    type === 'replie' ?
      dispatch(actions.updateUnlikedReplie(postId, id)) :
      dispatch(actions.updateUnlikedPicture(postId, id));
    try {
      const { isSuccess, data, error } = await likeGroupPostApi(classId, postId);
      if (!isSuccess) throw error;
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return null;
    }
  };
  // 貼文收回讚
  const undoLikePostToAlbum = async (postId, type) => {
    type === 'replie' ?
      dispatch(actions.updateLikedReplie(postId, id)) :
      dispatch(actions.updateLikedPicture(postId, id));
    try {
      const { isSuccess, data, error } = await undoLikeGroupPostApi(classId, postId);
      if (!isSuccess) throw error;
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return null;
    }
  };
  //刪除相片
  const deletePicture = async (postId, pictureId) => {
    const { isSuccess, error } = await deleteCustomGroupAlbumEntryApi(
      classId,
      albumCategory,
      postId,
      pictureId
    );
    try {
      if (!isSuccess) throw error;
      dispatch(
        actions.deletePicture({ postId, pictureId })
      );
      setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
    }
  };
  useEffect(() => {
    clearPicture();
    clearAlbum();
  }, [classId, albumCategory]);

  return [
    {
      albumList,
      pictureList
    }, // state
    {
      getAlbum,
      clearAlbum,
      createAlbum,
      updateAlbum,
      deleteAlbum,
      getPicture,
      createPicture,
      deletePicture,
      clearPicture,
      replyPostToAlbum,
      getPostRepliesToAlbum,
      likePostToAlbum,
      undoLikePostToAlbum,
    }, // eventHanlder
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALBUM: {
      const newAlbum = state.albumList.albums.concat(action.payload.albums);
      return {
        ...state,
        albumList: {
          ...action.payload,
          albums: newAlbum
        }
      };
    }
    case CREATE_ALBUM: {
      const { name } = action.payload.data;
      const newAlbum = state.albumList.albums.concat(action.payload.data);
      state.albumList.albumNames.push(name);
      return {
        albumList: {
          albumNames: state.albumList.albumNames,
          albums: newAlbum,
          total: state.albumList.total + 1
        }
      };
    }
    case UPDATE_ALBUM: {
      const { params, albumId } = action.payload;
      let oldName = '';
      const newAlbums = state.albumList.albums.map(item => {
        if (item.id === albumId) {
          oldName = item.name;
          item.name = params.name;
        }
        return item;
      });
      const newAlbumsName = state.albumList.albumNames.map(item => {
        if (item === oldName) {
          item = params.name;
        }
        return item;
      });
      return {
        albumList: {
          albumNames: newAlbumsName,
          albums: newAlbums,
          total: state.albumList.total
        }
      };
    }
    case DELETE_ALBUM: {
      const { params, albumId } = action.payload;
      const newAlbums = state.albumList.albums.filter(item => item.id !== albumId);
      const newAlbumsName = state.albumList.albumNames.filter(item => item !== params.name);
      return {
        albumList: {
          albums: newAlbums,
          albumNames: newAlbumsName,
          total: state.albumList.total - 1
        }
      };
    }
    case CLEAR_ALBUM: {
      return {
        ...state,
        albumList: {
          ...initState.albumList
        }
      };
    }
    case CLEAR_PICTURE: {
      return {
        ...state,
        pictureList: {
          ...initState.pictureList
        }
      };
    }
    case GET_PICTURE: {
      const newPicture = state.pictureList.entries.concat(
        action.payload.entries
      );
      const newPost = action.payload.posts ? state.pictureList.posts.concat(action.payload.posts) : [];
      const dataMap = newPost.length > 0 ? convertArrayToMap(newPost, 'id') : [];
      return {
        ...state,
        pictureList: {
          album: action.payload.album,
          posts: newPost,
          entries: newPicture,
          dataMap,
          total: action.payload.total
        }
      };
    }
    case CREATE_PICTURE: {
      const { attachments, id } = action.payload.data;
      let total = state.pictureList.total;
      const nextAttachments = attachments.map((item, index) => {
        total += 1;
        return {
          ...item,
          postId: id
        };
      });

      const nextPosts = state.pictureList.posts.concat(action.payload.data);
      const nextEntries = state.pictureList.entries.concat(nextAttachments);

      const dataMap = convertArrayToMap(nextPosts, 'id');
      return {
        ...state,
        pictureList: {
          ...state.pictureList,
          entries: nextEntries,
          posts: nextPosts,
          dataMap,
          total
        }
      };
    }
    case DELETE_PICTURE: {
      const { pictureId } = action.payload;
      const newEntries = state.pictureList.entries.filter(item => item.id !== pictureId);
      const newPosts = state.pictureList.posts.map(item => {
        item.attachments.filter(img => img.id !== pictureId);
        return item;
      });
      const dataMap = convertArrayToMap(newPosts, 'id');
      return {
        pictureList: {
          ...state.pictureList,
          entries: newEntries,
          posts: newPosts,
          dataMap,
          total: state.pictureList.total - 1
        }
      };
    }
    case REPLY_POST: {
      const { params, postId } = action.payload;
      const nextPosts = state.pictureList.posts.map(item => {
        if (item.id === postId) {
          if (!item.replies) {
            const replies = {
              posts: [params],
              total: 1
            };
            return { ...item, replies };
          } else {
            item.replies.posts.push(params);
            item.replies.total += 1;
          }
        }
        return item;
      });
      const dataMap = convertArrayToMap(nextPosts, 'id');
      return {
        ...state,
        pictureList: {
          ...state.pictureList,
          posts: nextPosts,
          dataMap: dataMap
        }
      };
    }
    case GET_POST_REPLIES: {
      const { params, postId } = action.payload;
      const nextPosts = state.pictureList.posts.map(item => {
        if (item.id === postId) {
          item = params;
        }
        return item;
      });
      const dataMap = convertArrayToMap(nextPosts, 'id');
      return {
        ...state,
        pictureList: {
          ...state.pictureList,
          posts: nextPosts,
          dataMap
        }
      };
    }
    case LIKE_POST_REPLIE: {
      const { postId, userId } = action.payload;
      const nextPosts = state.pictureList.posts.map(post => {
        if (post.replies) {
          post.replies.posts = post.replies.posts.map(item => {
            if (item.id === postId) {
              if (item.likedUsers) {
                item.likedUsers.push(userId);
              } else {
                item.likedUsers = [userId];
              }
            }
            return item;
          });
        }

        return post;
      });
      const dataMap = convertArrayToMap(nextPosts, 'id');
      return {
        ...state,
        pictureList: {
          ...state.pictureList,
          posts: nextPosts,
          dataMap
        }
      };
    }
    case UNLIKE_POST_REPLIE: {
      const { postId, userId } = action.payload;
      const nextPosts = state.pictureList.posts.map(post => {
        if (post.replies) {
          post.replies.posts = post.replies.posts.map(item => {
            if (item.id === postId) {
              item.likedUsers = item.likedUsers.filter(liked => liked !== userId);
            }
            return item;
          });
        }

        return post;
      });
      const dataMap = convertArrayToMap(nextPosts, 'id');
      return {
        ...state,
        pictureList: {
          ...state.pictureList,
          posts: nextPosts,
          dataMap
        }
      };
    }
    case LIKE_POST_PICTURE: {
      const { postId, userId } = action.payload;
      const nextPosts = state.pictureList.posts.map(post => {
        if (post.id === postId) {
          if (post.likedUsers) {
            post.likedUsers.push(userId);
          } else {
            post.likedUsers = [userId];
          }
        }
        return post;
      });
      const dataMap = convertArrayToMap(nextPosts, 'id');
      return {
        ...state,
        pictureList: {
          ...state.pictureList,
          posts: nextPosts,
          dataMap
        }
      };
    }
    case UNLIKE_POST_PICTURE: {
      const { postId, userId } = action.payload;
      const nextPosts = state.pictureList.posts.map(post => {
        if (post.id === postId) {
          post.likedUsers = post.likedUsers.filter(liked => liked !== userId);
        }
        return post;
      });
      const dataMap = convertArrayToMap(nextPosts, 'id');
      const nextNewPicture = state.pictureList.entries.map((item, index) => {
        nextPosts.map(posts => {
          if (posts.id === item.postId) {
            item.postInfo = posts;
          }
        });
        return item;
      });
      return {
        ...state,
        pictureList: {
          ...state.pictureList,
          entries: nextNewPicture,
          posts: nextPosts,
          dataMap
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;
