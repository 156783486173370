import { TextField } from '@material-ui/core';
import { Form, SchoolCitySelector, Select } from 'components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { useAllSchoolYear } from 'utils/hooks/useSchoolYear';
import { useSetState } from 'utils/hooks/useSetState';

import { UiHomeSchoolForm } from './HomeSchoolForm.style';


/**
 * 學校教師，新增班級表單
 */

export const HomeSchoolForm = () => {
  const [{ isLoading }, setState] = useSetState({
    isLoading: false
  });
  const history = useHistory();
  const [, { createFreeGroup }] = useClass();
  const [, { getMyAllOrganizations }] = useUser();
  const { schoolYearOptions } = useAllSchoolYear();

  const schema = {
    schoolData: {
      component: SchoolCitySelector,
      elementProps: {
        onChange: ([val]) => val,
        width: '100%',
        variant: 'outlined'
      },
      rules: {
        maxLength: {
          value: 200,
          message: '最小長度為 200 個字元'
        },
      }
    },
    year: {
      component: Select,
      elementProps: {
        label: '學年度',
        isOutline: false,
        options: schoolYearOptions,
        onChangeName: 'submitHandler',
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        },
      },
    },
    name: {
      component: TextField,
      elementProps: {
        label: '班級名稱',
        defaultValue: '',
        helperText: ''
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        },
        maxLength: {
          value: 20,
          message: '最小長度為 20 個字元'
        }
      }
    },
    description: {
      component: TextField,
      elementProps: {
        label: '班級簡介',
        defaultValue: '',
        multiline: true,
        rows: 5,
        helperText: ''
      },
      rules: {
        maxLength: {
          value: 200,
          message: '最小長度為 200 個字元'
        }
      }
    },
  };

  const changeHandler = data => {
    schema.schoolData.elementProps['required'] = true;
  };

  const submitHandler = async data => {
    setState({ isLoading: true });
    delete data.organizationName;
    const schoolValue = data.schoolData;
    delete data.schoolData;
    const isSuccess = await createFreeGroup({ ...data, ...schoolValue });
    setState({ isLoading: false });
    if (isSuccess) {
      getMyAllOrganizations(true); // 回到首頁要即時顯示
      history.push('/home');
    }
  };

  const buttons = [
    {
      text: '回到上一頁',
      color: 'cancel',
      func: () => { history.push('/home'); }
    },
    {
      text: '確認',
      color: 'new',
      type: 'submit',
    },
  ];

  return (
    <UiHomeSchoolForm>
      <Form
        schema={schema}
        isLoading={isLoading}
        buttons={buttons}
        onFormChange={changeHandler}
        onSubmit={submitHandler}
      />
    </UiHomeSchoolForm>
  );
};
