import { Box } from '@material-ui/core';
import { Loading } from 'components';
import PropTypes from 'prop-types';
import React from 'react';

export const LoadingCard = ({ boxStyle }) => {
  return (
    <Box style={{ ...boxStyle, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <Loading />
    </Box>
  );
};

LoadingCard.propTypes = {
  boxStyle: PropTypes.shape({}),
};
