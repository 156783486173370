import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getOrgs as getOrgsApi,
  getOrgInfo as getOrgInfoApi,
  createOrgInfo as createOrgInfoApi,
  updateOrgInfo as updateOrgInfoApi,
} from 'services/api/admin/organzitions';
import {
  getResources as getResourcesApi,
  deleteResources as deleteResourcesApi,
  addResources as addResourcesApi
} from 'services/api/admin/resource';
import {
  getUsers as getUsersApi,
  updateUserProfile as updateUserProfileApi,
  getUserGroupsAndOrganizations as getUserGroupsAndOrganizationsApi,
  getPremiumUsers as getPremiumUsersApi,
  reviseUserPremiumStatus as reviseUserPremiumStatusApi,
} from 'services/api/admin/users';
import { convertArrayToMap } from 'utils/array';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';

const ADMIN_GET_USERS = 'ADMIN_GET_USERS';
const ADMIN_UPDATE_USER_PROFILE = 'ADMIN_UPDATE_USER_PROFILE';
const GET_USER_GROUPS_AND_ORGANIZATIONS = 'GET_USER_GROUPS_AND_ORGANIZATIONS';
const ADMIN_GET_ORGANZITIONS = 'ADMIN_GET_ORGANZITIONS';
const ADMIN_GET_ORGANZITION_INFO = 'ADMIN_GET_ORGANZITION_INFO';
const ADMIN_ADD_ORGANZITIONS = 'ADMIN_ADD_ORGANZITIONS';
const ADMIN_UPDATE_ORGANZITIONS = 'ADMIN_UPDATE_ORGANZITIONS';
const AMIND_GET_PREMIUMUSERS = 'AMIND_GET_PREMIUMUSERS';
const ADMIN_REVISE_USER_PREMIUMSTATUS = 'ADMIN_REVISE_USER_PREMIUMSTATUS';
const ADMIN_GET_RESOURCES = 'ADMIN_GET_RESOURCES';
const ADMIN_DELETE_RESOURCES = 'ADMIN_DELETE_RESOURCES';
const ADMIN_ASSIGN_RESOURCES = 'ADMIN_ASSIGN_RESOURCES';

const initState = {
  users: {
    data: [],
    dataInfo: {},
    total: 1,
  },
  orgs: {
    data: [],
    dataMap: {},
    dataInfo: null,
    total: 1,
  },
  premiumUsers: {
    data: [],
    total: 0
  },
  resources: {
    data: [],
    dataMap: {},
    total: 1,
  }
};

const actions = {
  getUsersList: params => ({
    type: ADMIN_GET_USERS,
    payload: params
  }),
  updateUserProfile: params => ({
    type: ADMIN_UPDATE_USER_PROFILE,
    payload: params
  }),
  getUserGroupsAndOrganizations: params => ({
    type: GET_USER_GROUPS_AND_ORGANIZATIONS,
    payload: params
  }),
  getOrganzitions: ({ data, dataMap, total }) => ({
    type: ADMIN_GET_ORGANZITIONS,
    payload: { data, dataMap, total },
  }),
  getOrgInfo: dataInfo => ({
    type: ADMIN_GET_ORGANZITION_INFO,
    payload: { dataInfo },
  }),
  addOrganzitionsInfo: params => ({
    type: ADMIN_ADD_ORGANZITIONS,
    payload: params,
  }),
  updateOrganizationInfo: params => ({
    type: ADMIN_UPDATE_ORGANZITIONS,
    payload: params,
  }),
  getPremiumUsers: params => ({
    type: AMIND_GET_PREMIUMUSERS,
    payload: params
  }),
  reviseUserPremiumStatus: params => ({
    type: ADMIN_REVISE_USER_PREMIUMSTATUS,
    payload: params
  }),
  getResources: ({ data, dataMap, total }) => ({
    type: ADMIN_GET_RESOURCES,
    payload: { data, dataMap, total },
  }),
  deleteResources: resourceIdList => ({
    type: ADMIN_DELETE_RESOURCES,
    payload: { resourceIdList }
  }),
  addResources: resourceIdList => ({
    type: ADMIN_ASSIGN_RESOURCES,
    payload: { resourceIdList }
  })
};

export const useAdmin = () => {
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const { organizationId, userId } = useParams();
  const { users, orgs, premiumUsers, resources } = useSelector(state => state.admin);

  const getUsersList = async (params) => {
    const { data, isSuccess } = await getUsersApi(params);
    if (!isSuccess) return;
    dispatch(actions.getUsersList({ data }));
  };
  const getAllUsersList = async (params) => {
    const { data, isSuccess } = await getUsersApi({ ...params, nowPage: 0, rowsPage: 100 });
    if (!isSuccess) return [];
    dispatch(actions.getUsersList({ data }));

    return data.users;
  };

  const updateUserProfile = async isTeacher => {
    const { isSuccess, error } = await updateUserProfileApi(userId)(isTeacher);
    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
    return isSuccess;
  };

  const getUserGroupsAndOrganizations = async () => {
    const { data, isSuccess, error } = await getUserGroupsAndOrganizationsApi(userId);
    try {
      if (!isSuccess) throw error;
      dispatch(actions.getUserGroupsAndOrganizations(data));
      return data;
    } catch (error) {
      return null;
    }
  };


  const getOrganzitions = async params => {
    const { data, isSuccess } = await getOrgsApi(params ? params : '');
    if (!isSuccess) return;
    const { organizations, total } = data;
    const dataMap = convertArrayToMap(organizations, 'id');
    dispatch(actions.getOrganzitions({ data: organizations, dataMap, total }));
  };

  const getOrgInfo = async () => {
    const { data, isSuccess } = await getOrgInfoApi(organizationId);
    if (!isSuccess) return;
    dispatch(actions.getOrgInfo(data));
  };

  const createOrganzitionsInfo = async params => {
    const { isSuccess, error } = await createOrgInfoApi(params);

    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error', errorCode);
    }

    dispatch(actions.addOrganzitionsInfo(params));
    return isSuccess;
  };

  const updateOrganizationInfo = async (id, params) => {
    const { isSuccess, error } = await updateOrgInfoApi(id, params);

    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }

    dispatch(actions.updateOrganizationInfo(params));
    return isSuccess;
  };

  const getPremiumUsers = async params => {
    const { data, isSuccess } = await getPremiumUsersApi(params);
    if (!isSuccess) return;
    const dateFormat = date => format(date, 'yyyy-MM-dd');
    data.premiumInfos.forEach(item => {
      item.openApplyDate = item.lastApprovedAt ? dateFormat(item.lastApprovedAt) : dateFormat(item.lastAppliedAt);
      item.expiredDate = item.expiredAt ? format(item.expiredAt, 'yyyy-MM-dd') : '--';
      item.id = item.userId;
    });
    dispatch(actions.getPremiumUsers({ data }));
  };

  const reviseUserPremiumStatus = userId => async params => {
    const { data, isSuccess, error } = await reviseUserPremiumStatusApi(userId)(params);
    dispatch(actions.reviseUserPremiumStatus({ data }));
    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.OPEN_PERMISSIONS_SUCCESS, 'success');
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.OPEN_PERMISSIONS_FAIL, 'error', errorCode);
    }
  };

  const getResources = async (params) => {
    const { data, isSuccess } = await getResourcesApi(organizationId)(params);
    if (!isSuccess) return;
    const { resources, total } = data;
    const dataMap = convertArrayToMap(resources, 'resourceId');
    dispatch(actions.getResources({ data: resources, dataMap, total }));
  };

  const deleteResources = async (resourceIdList = []) => {
    try {
      dispatch(actions.deleteResources(resourceIdList));
      const { isSuccess, error } = await deleteResourcesApi(organizationId)(resourceIdList);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');
      getResources({ nowPage: 0, rowsPage: 10 });
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
    }
  };

  const addResources = async (resourceIdList = []) => {
    try {
      dispatch(actions.addResources(resourceIdList));
      const { isSuccess, error } = await addResourcesApi(organizationId)(resourceIdList);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      getResources({ nowPage: 0, rowsPage: 10 });
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
  };

  return [
    { users, orgs, premiumUsers, resources }, // state
    {
      getUsersList,
      updateUserProfile,
      getUserGroupsAndOrganizations,
      getAllUsersList,
      getOrganzitions,
      getOrgInfo,
      createOrganzitionsInfo,
      updateOrganizationInfo,
      getPremiumUsers,
      reviseUserPremiumStatus,
      getResources,
      deleteResources,
      addResources,
    }, // eventHanlder
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_GET_USERS: {
      const { data: { total, users } } = action.payload;
      return {
        ...state,
        users: {
          data: users,
          total,
        },
      };
    }
    case GET_USER_GROUPS_AND_ORGANIZATIONS: {
      return {
        ...state,
        users: {
          ...state.users,
          dataInfo: action.payload
        }
      };
    }
    case ADMIN_GET_ORGANZITIONS: {
      const { data, dataMap, total } = action.payload;
      return {
        ...state,
        orgs: { data, dataMap, total }
      };
    }
    case ADMIN_GET_ORGANZITION_INFO: {
      const { dataInfo } = action.payload;
      return {
        ...state,
        orgs: { ...state.orgs, dataInfo }
      };
    }
    case AMIND_GET_PREMIUMUSERS: {
      const { data: { premiumInfos, total } } = action.payload;
      return {
        ...state,
        premiumUsers: {
          data: premiumInfos,
          total,
        }
      };
    }
    case ADMIN_GET_RESOURCES: {
      const { data, dataMap, total } = action.payload;
      return {
        ...state,
        resources: { data, dataMap, total }
      };
    }
    default:
      return state;
  }
};

export default reducer;
