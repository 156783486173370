import { HomeSetting, HomeGroupLayout, SchoolHomeSetting } from 'components';
import React from 'react';
import { useParams } from 'react-router-dom';


/**
 * 班級設定頁面
 */

export const HomeSettingPage = () => {
  const { organizationId } = useParams();
  return (
    <HomeGroupLayout>
      {
        organizationId ? <HomeSetting /> : <SchoolHomeSetting />
      }
    </HomeGroupLayout>
  );
};



