import { DASHBOARD_SUMMARY_CATEGORY } from 'constants/index';
import { SummaryCard } from 'components';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { useClass } from 'store/class';
import { UiGridContainer, UiGridItem } from 'styles';

import { UiClassSummary } from './ClassSummary.style';

/**
 * 班級報表
 */

const girds = {
  desktop: 33.3,
  notebook: 33.3,
  pad: 33.3,
  phone: 100
};

const graphGirds = {
  desktop: 50,
  notebook: 50,
  pad: 50,
  phone: 100
};

// TODO: 需要被封裝 處理 DoughnutChart 中間的 text
var originalDoughnutDraw = window.Chart.controllers.doughnut.prototype.draw;
window.Chart.helpers.extend(window.Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);

    var chart = this.chart;
    var width = chart.chart.width,
      height = chart.chart.height,
      ctx = chart.chart.ctx;

    var fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + 'em sans-serif';
    ctx.textBaseline = 'middle';

    var text = chart.config.data.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;

    ctx.fillText(text, textX, textY);
  }
});

// eslint-disable-next-line react/prop-types
const DoughnutChart = ({ value = 0 }) => {
  const val = parseInt(value);

  const data = {
    datasets: [{
      data: [val, 100 - val],
      backgroundColor: ['#ec7963', '#FEF2F0']
    }],
    text: `${val}%`
  };

  return (
    <Doughnut
      data={data}
      width={300}
      height={150}
      options={{ lengend: { display: false }, tooltips: { enabled: false } }}
    />
  );
};

export const ClassSummary = () => {
  const [{ classSummary }, {
    getDashboardClassSummary,
    getDashboardUserSummary,
    getDashboardHostSummary,
    getDashboardAnnouncementSummary,
    getDashboardHomeworkSummary,
    getDashboardPostSummary,
    getDashboardSessionSummary
  }] = useClass();

  const lifeInDays = classSummary[DASHBOARD_SUMMARY_CATEGORY.GROUP] ?
    classSummary[DASHBOARD_SUMMARY_CATEGORY.GROUP].lifeInDays : 0;
  const teacherTotal = classSummary[DASHBOARD_SUMMARY_CATEGORY.HOSTCANDIDATE] ?
    classSummary[DASHBOARD_SUMMARY_CATEGORY.HOSTCANDIDATE].total : 0;
  const studentTotal = classSummary[DASHBOARD_SUMMARY_CATEGORY.USER] ?
    classSummary[DASHBOARD_SUMMARY_CATEGORY.USER].total : 0;

  const announcementTotal = classSummary[DASHBOARD_SUMMARY_CATEGORY.ANNOUNCEMENT] ?
    classSummary[DASHBOARD_SUMMARY_CATEGORY.ANNOUNCEMENT].total : 0;
  const announcementLatestCreatedAt = classSummary[DASHBOARD_SUMMARY_CATEGORY.ANNOUNCEMENT] ?
    classSummary[DASHBOARD_SUMMARY_CATEGORY.ANNOUNCEMENT].latestCreatedAt : 0;

  const postTotal = classSummary[DASHBOARD_SUMMARY_CATEGORY.POST] ?
    classSummary[DASHBOARD_SUMMARY_CATEGORY.POST].total : 0;
  const postLatestCreatedAt = classSummary[DASHBOARD_SUMMARY_CATEGORY.POST] ?
    classSummary[DASHBOARD_SUMMARY_CATEGORY.POST].latestCreatedAt : 0;
  const postLatestRepliedAt = classSummary[DASHBOARD_SUMMARY_CATEGORY.POST] ?
    classSummary[DASHBOARD_SUMMARY_CATEGORY.POST].latestRepliedAt : 0;

  const postReplyRate = classSummary[DASHBOARD_SUMMARY_CATEGORY.POST] ?
    classSummary[DASHBOARD_SUMMARY_CATEGORY.POST].replyRate : 0;
  const postReplyTotal = parseInt(postTotal * postReplyRate);

  const sessionTotal = classSummary[DASHBOARD_SUMMARY_CATEGORY.SESSION] ?
    classSummary[DASHBOARD_SUMMARY_CATEGORY.SESSION].total : 0;

  const homeworkSubmitRate = classSummary[DASHBOARD_SUMMARY_CATEGORY.MISSION] ?
    classSummary[DASHBOARD_SUMMARY_CATEGORY.MISSION].submitRate : 0;

  useEffect(() => {
    getDashboardClassSummary();
    getDashboardUserSummary();
    getDashboardHostSummary();
    getDashboardAnnouncementSummary();
    getDashboardHomeworkSummary();
    getDashboardPostSummary();
    getDashboardSessionSummary();
  }, []);

  const timeFormater = timestamp => timestamp ? format(new Date(timestamp), 'yyyy/MM/dd') : '----/--/--';

  return (
    <UiClassSummary>
      <UiGridContainer>
        <UiGridItem {...girds}>
          <SummaryCard
            iconName="calendarToday"
            label="班級成立天數"
            value={lifeInDays}
            iconBgColor="success"
          />
        </UiGridItem>
        <UiGridItem {...girds}>
          <SummaryCard
            iconName="person"
            label="教師人數"
            value={teacherTotal}
            iconBgColor="success"
          />
        </UiGridItem>
        <UiGridItem {...girds}>
          <SummaryCard
            iconName="people"
            label="學生人數"
            value={studentTotal}
            iconBgColor="success"
          />
        </UiGridItem>
        <UiGridItem {...girds}>
          <SummaryCard
            iconName="announcement"
            label="公告總數"
            value={announcementTotal}
            iconBgColor="success"
            statsLabel="最新發布時間"
            statsValue={timeFormater(announcementLatestCreatedAt)}
          />
        </UiGridItem>
        <UiGridItem {...girds}>
          <SummaryCard
            iconName="homeWork"
            label="貼文總數"
            value={postTotal}
            iconBgColor="success"
            statsLabel="最新發布時間"
            statsValue={timeFormater(postLatestCreatedAt)}
          />
        </UiGridItem>
        <UiGridItem {...girds}>
          <SummaryCard
            iconName="chat"
            label="貼文回覆總數"
            value={postReplyTotal}
            iconBgColor="success"
            statsLabel="最新發布時間"
            statsValue={timeFormater(postLatestRepliedAt)}
          />
        </UiGridItem>
        <UiGridItem {...girds}>
          <SummaryCard
            iconName="importContacts"
            label="總課程數"
            value={sessionTotal}
            iconBgColor="success"
            statsLabel={''}
            statsValue={''}
          />
        </UiGridItem>
      </UiGridContainer>
      <UiGridContainer>
        <UiGridItem {...graphGirds}>
          <SummaryCard
            iconName="assignmentTurnedIn"
            label="作業繳交率"
            iconBgColor="success"
            statsLabel={''}
            statsValue={''}
            chart
          >
            <DoughnutChart value={homeworkSubmitRate} />
          </SummaryCard>
        </UiGridItem>
        <UiGridItem {...graphGirds}>
          <SummaryCard
            iconName="forum"
            label="貼文回復率"
            iconBgColor="success"
            statsLabel={''}
            statsValue={''}
            chart
          >
            <DoughnutChart value={postReplyRate} />
          </SummaryCard>
        </UiGridItem>
      </UiGridContainer>
    </UiClassSummary>
  );
};
