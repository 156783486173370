import { setPremiumStatus } from 'constants/index';
import {
  Checkbox,
  Button,
  Loading,
} from 'components';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAdmin } from 'store/admin';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiAccountForm,
  UiAccountFormTitle,
  UiAccountFormRow,
  UiAccountFormItem,
  UiAccountFormList,
  UiAccountFormListHeader,
  UiAccountFormListItem,
  UiAccountFormListRow,
  UiAccountFormItemButton,
  UiAccountFormItemTable,
} from './AccountForm.style';


/**
 * 編輯帳號組件
 */

const setGender = {
  other: '其他',
  male: '男',
  female: '女'
};


export const AccountForm = () => {
  const [, { updateUserProfile, getUserGroupsAndOrganizations }] = useAdmin();
  const history = useHistory();
  const [{
    usersData,
    isTeacher,
    loading,
    dataLoading,
    schoolDataList,
    organizationSchoolDataList,
  }, setState] = useSetState({
    usersData: [],
    isTeacher: false,
    loading: false,
    dataLoading: true,
    schoolDataList: [],
    organizationSchoolDataList: [],
  });
  const getUserClass = async () => {
    setState({
      dataLoading: true
    });
    const { groups, organizations, user: usersData } = await getUserGroupsAndOrganizations();
    const schoolDataList = groups && groups.filter(item => !item.organizationId);
    const organizationSchoolDataList = groups && groups.filter(item => item.organizationId);
    setState({
      dataLoading: false,
      usersData,
      organizations,
      schoolDataList,
      organizationSchoolDataList,
    });
  };
  useEffect(() => {
    getUserClass();
  }, []);
  useEffect(() => {
    if (usersData.length <= 0) return;
    if (usersData.verificationInfoList.length > 0) {
      setState({
        isTeacher: true
      });
    }
  }, [usersData]);

  const onChange = val => {
    setState({
      isTeacher: val === 'isTeacher' ? true : false
    });
  };
  const updateUserHandler = async () => {
    setState({
      loading: true
    });
    const isSuccess = await updateUserProfile(isTeacher);
    if (isSuccess) {
      history.push('/admin/account');
    }
    setState({
      loading: false
    });
  };
  return (
    <UiAccountForm>
      {dataLoading && <Loading />}
      {
        !dataLoading &&
        <>
          <UiAccountFormTitle>帳號編輯</UiAccountFormTitle>
          <UiAccountFormRow>
            <UiAccountFormItem>
              ID : {usersData.id}
            </UiAccountFormItem>
            <UiAccountFormItem>
              <Checkbox
                onChange={onChange}
                label="認證教師"
                checkedValue="isTeacher"
                checked={isTeacher}
                disabled={isTeacher}
              />
            </UiAccountFormItem>
          </UiAccountFormRow>
          <UiAccountFormRow>
            <UiAccountFormItem>姓 : {usersData.lastName || '--'}</UiAccountFormItem>
            <UiAccountFormItem>名 : {usersData.firstName || '--'}</UiAccountFormItem>
            <UiAccountFormItem>暱稱 : {usersData.nickname || '--'}</UiAccountFormItem>
            <UiAccountFormItem>性別 : {setGender[usersData.sex] || '其他'}</UiAccountFormItem>
          </UiAccountFormRow>
          <UiAccountFormRow>
            <UiAccountFormItem>手機 : {usersData.mobileNumber || '--'}</UiAccountFormItem>
            <UiAccountFormItem>專業校園版付費狀態 : {setPremiumStatus[usersData.premiumStatus] || '--'}</UiAccountFormItem>
          </UiAccountFormRow>
          <UiAccountFormRow>
            <UiAccountFormItem>電子郵件 : {usersData.email || '--'}</UiAccountFormItem>
            <UiAccountFormItem>
              專業校園版使用期限 :
              {usersData.premiumExpiredAt ? format(usersData.premiumExpiredAt, 'yyyy-MM-dd') : '--'}
            </UiAccountFormItem>
          </UiAccountFormRow>
          <UiAccountFormRow>
            <UiAccountFormItemTable>
              <UiAccountFormListRow>
                <UiAccountFormList>
                  <UiAccountFormListHeader>獨立班級</UiAccountFormListHeader>
                  {
                    schoolDataList.length > 0 ? schoolDataList.map(item => (
                      <UiAccountFormListItem key={item.id}>{item.name}</UiAccountFormListItem>
                    )) : <UiAccountFormListItem> -- </UiAccountFormListItem>
                  }
                </UiAccountFormList>
                <UiAccountFormList>
                  <UiAccountFormListHeader>機構班級</UiAccountFormListHeader>
                  {
                    organizationSchoolDataList.length > 0 ?
                      organizationSchoolDataList.map(item => (
                        <UiAccountFormListItem key={item.id}>{item.name}</UiAccountFormListItem>
                      )) : <UiAccountFormListItem> -- </UiAccountFormListItem>
                  }
                </UiAccountFormList>
              </UiAccountFormListRow>
            </UiAccountFormItemTable>
          </UiAccountFormRow>

          <UiAccountFormRow>
            <UiAccountFormItemButton>
              <Button
                loading={loading}
                onClick={() => { history.goBack(); }}
              >取消</Button>
            </UiAccountFormItemButton>
            <UiAccountFormItemButton>
              <Button
                loading={loading}
                buttonColor='highlight'
                onClick={() => { updateUserHandler(); }}
              >確認</Button>
            </UiAccountFormItemButton>
          </UiAccountFormRow>
        </>
      }

    </UiAccountForm>
  );
};


