import { BsHeaderTitle, PermissionSetting } from 'components';
import React from 'react';
import { UiBsContentContainer } from 'styles';

/**
 * 權限設定頁面
 */

export const PermissionSettingPage = () => {
  return (
    <>
      <BsHeaderTitle title="權限設定" />
      <UiBsContentContainer>
        <PermissionSetting />
      </UiBsContentContainer>
    </>
  );
};

PermissionSettingPage.propTypes = {

};


