import styled from 'styled-components';

export const UiHomeMemberCenterLayout = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #fff;
  flex-direction: column;
  gap: 28px;
`;

export const UiMemberCenterActionsWrapper = styled.div`
  padding: 0 12vw;
`;

export const UiMemberCenterGoBackButton = styled.div`
  display: flex;
  color: #8b90a0;
  font-weight: 400;
  font-size: 14px;
  font-family: Noto Sans TC;
  cursor: pointer;
  width: fit-content;
`;

export const UiMemberCenterTitle = styled.div`
  padding: 0 12vw;
  font-size: 1.6rem;
  font-weight: bolder;
/* ${({ theme: { media } }) => media.desktop`
        width:${({ theme }) => theme.mediaWidth.desktop}
        `}
${({ theme: { media } }) => media.notebook`
        width:${({ theme }) => theme.mediaWidth.notebook}
        `}
${({ theme: { media } }) => media.pad`
        width:${({ theme }) => theme.mediaWidth.pad}
        `}
${({ theme: { media } }) => media.phone`
        width:${({ theme }) => theme.mediaWidth.phone}
        `} */
`;
export const UiMemberCenterSiderBar = styled.div``;

export const UiHomeMemberCenterContent = styled.div`
	display: flex;
      justify-content: center;
	padding: 0 0 2rem;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	flex: 1;
	/* ${({ theme: { media } }) => media.desktop`
      width:${({ theme }) => theme.mediaWidth.desktop}
      `}
	${({ theme: { media } }) => media.notebook`
      width:${({ theme }) => theme.mediaWidth.notebook}
      `}
	${({ theme: { media } }) => media.pad`
      width:${({ theme }) => theme.mediaWidth.pad}
      `}
	${({ theme: { media } }) => media.phone`
      width:${({ theme }) => theme.mediaWidth.phone}
      `} */
`;
export const UiChildrenContainer = styled.div`
  position: relative;
  padding: 0 1rem;
`;
