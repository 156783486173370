import PropTypes from 'prop-types';
import React from 'react';

import {
    getIsProcessing,
    getDurationText,
    getEduSubjectsText,
    getGradesText
} from 'utils/helper';
import {
    UiSessionCard,
    UiSessionHeader,
    UiBook,
    UiSessionTitle,
    UiSessionFooter,
    UiSessionTime,
    UiSessionProcessing,
    UiHeaderDetail,
} from './SessionCard.style';


export const SessionCard = ({
    type,
    session,
    onClick
}) => {
    const isProcessing = getIsProcessing(session);
    // 上課時間
    const durationText = getDurationText(session);
    const eduSubjectsText = getEduSubjectsText(session);
    const gradesText = getGradesText(session);
    const newSession = { ...session, eduSubjectsText, gradesText, durationText };
    return (
        <UiSessionCard onClick={() => onClick(newSession)}>
            <UiSessionHeader>
                <UiBook src={type} />
                <UiHeaderDetail>
                    <UiSessionTitle>{session.title}</UiSessionTitle>
                </UiHeaderDetail>
            </UiSessionHeader>
            <UiSessionFooter>
                <UiSessionTime>
                    {durationText}
                </UiSessionTime>
                {
                    isProcessing && <UiSessionProcessing />
                }
            </UiSessionFooter>
        </UiSessionCard>
    );
};

SessionCard.propTypes = {
    type: PropTypes.string,
    session: PropTypes.object,
    onClick: PropTypes.func,
};