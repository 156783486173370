import { HomeGroupLayout, HomeSupplementaryMaterialsTable } from 'components';
import React from 'react';


/**
 * 輔助教材資源頁面
 */

export const HomeSupplementaryMaterialsPage = () => {
  return (
    <HomeGroupLayout>
      <HomeSupplementaryMaterialsTable />
    </HomeGroupLayout>
  );
};
