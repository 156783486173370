import { DEACTIVATION_PAGES, COUNTY_DOMAIN } from 'constants/index';
import libphonenumber from 'google-libphonenumber';

export const phoneNumberValid = (number) => {
  //+886987654321
  let isValidNumber = false;
  if (number.length < 6) return isValidNumber;

  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const parseNumber = phoneUtil.parse(number);
  isValidNumber = phoneUtil.isValidNumber(parseNumber);
  return isValidNumber;
};
export const emailReg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;

export const deactivationPageValid = ({ item, componentName, nowAt = Date.now() }) => {
  const componentNameMap = {
    AppRoutes: 'PATH',
    SideBar: 'LABEL',
    SideBarFloat: 'LABEL',
  };
  const itemCheckedValueMap = {
    AppRoutes: 'path',
    SideBar: 'label',
    SideBarFloat: 'text',
  };
  // 是否停用
  const isDeactivate = DEACTIVATION_PAGES.some((PAGE) => {
    // 取得 DEACTIVATION_PAGES 需要比較用的 value
    const deactivationPagesComparedValue = PAGE[componentNameMap[componentName]];
    // 取得 item 需要檢查的 value
    const itemCheckedValue = item[itemCheckedValueMap[componentName]];
    // 判斷是否為要停用的頁面
    const isDeactivationPage = deactivationPagesComparedValue === itemCheckedValue;
    // 判斷是否現在開始為停用日
    const isDeactivationDate = nowAt >= PAGE.DEACTIVATION_TIMESTAMP;
    return isDeactivationPage && isDeactivationDate;
  });
  return isDeactivate;
};

export const isCompany =
  window.location.origin === 'http://localhost:3000' ||
  window.location.origin === 'http://127.0.0.1:3000' ||
  window.location.origin === process.env.REACT_APP_ONELINK_DOMAIN;

export const isCounty = COUNTY_DOMAIN.includes(window.location.origin);
