import { Box, TableRow } from '@material-ui/core';
import styled from 'styled-components';

export const UiHomeAttendanceTeacher = styled.div`
  margin: 0 auto;
`;

export const UiGoBackButtonBox = styled.div`
  margin-bottom: 18px;
`;

export const UiGoBackButton = styled.div`
  display: flex;
  color: #8b90a0;
  font-weight: 400;
  font-size: 14px;
  font-family: Noto Sans TC;
  cursor: pointer;
  width: fit-content;
`;

export const UiFlexBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const UiFlexBoxCol = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const UiTipsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UiTipBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 1px;
  color: #637381;
  font-size: 14px;
  font-weight: 400;
  font-family: Noto Sans TC;
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #242c3f;
  margin-bottom: 28px;
`;

export const UiTitleRow = styled(TableRow)`
  height: 56px;
  background-color: #fafafc;
  padding: 16px 24px;
  box-sizing: border-box;
`;

export const SortImg = styled.img`
  display: inline-block;
  padding-left: 5px;
`;

export const Pointer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BodyRow = styled(TableRow)`
  height: 56px;
  padding: 16px 24px;
  box-sizing: border-box;
`;

export const NoCheck = styled.span`
  color: #b72136;
  font-size: 14px;
  line-height: 24px;
  background: rgba(236, 121, 99, 0.16);
  border-radius: 6px;
  padding: 1px 8px;
`;
export const LeaveStatus = styled.div`
  color: #0c53b7;
  font-size: 14px;
  line-height: 24px;
  background: rgba(24, 144, 255, 0.16);
  border-radius: 6px;
  padding: 1px 8px;
  text-align: center;
`;

export const ReviseCheck = styled.span`
  color: #229a16;
  font-size: 14px;
  line-height: 24px;
  background: rgba(84, 214, 44, 0.16);
  border-radius: 6px;
  padding: 1px 8px;
  margin-left: 8px;
`;

export const ModalSubtitle = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 151.2%;
  color: #a1a4b1;
`;

export const GreenText = styled.span`
  margin-top: 10px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #4caf50;
`;

export const GreyText = styled.span`
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #454b5c;
`;

export const CheckResultBlock = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background: rgba(85, 164, 176, 0.12);
  border: 1px solid rgba(139, 188, 196, 0.8);
  border-radius: 16px;
`;

export const CheckResultContent = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 12px;
`;

export const CheckResultName = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #5ea4ae;
`;

export const CheckResultTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #a1a4b1;
`;

export const CheckResultMessage = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #3a4052;
`;

export const SnackbarIconBg = styled.div`
  width: 40px;
  height: 40px;
  background: rgba(84, 214, 44, 0.16);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;
export const WhiteSnackBar = styled.div`
  width: 284px;
  height: 64px;
  box-sizing: border-box;
  padding: 12px 8px 12px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #212b36;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.16);
  border-radius: 8px;
  display: flex;
  align-items: center;
`;
