import { HomeworkSubmittedTable, HomeGroupLayout } from 'components';
import React from 'react';

// import { UiHomeworkSubmittedPage } from './HomeworkSubmittedPage.style';


/**
 * 作業的繳交狀況頁面
 */

export const HomeworkSubmittedPage = () => {
  return (
    <HomeGroupLayout>
      <HomeworkSubmittedTable />
    </HomeGroupLayout>
  );
};


