import styled from 'styled-components';

export const UiSWOTItem = styled.div`
  flex: 1;
  display: flex;
  width: ${prop => prop.width || 'calc(50% - 10px)'};
  background-color: ${prop => prop.backgroundColor || '#F5F5F5'};
  padding: 24px;
  margin-top: ${prop => prop.mt + 'px' || '0'};
  margin-left: ${prop => prop.ml + 'px' || '0'};
  border-radius: 16px;

  .content {
    color: #3A4052;
    margin-left: 24px;
    .title {
        font-weight: 600;
        font-size: 18px;
    }  
    .description {
      margin-top: 8px;
      font-size: 16px;
      line-height: 1.5;
    }
  }
`;