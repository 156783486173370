import forest from 'assets/images/forest.svg';
import { Table, Button, EmptyPrompt, Loading } from 'components';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getVideoRecord } from 'services/api/home/video';
import { useCustomer } from 'store/customer';
import { useSetState } from 'utils/hooks/useSetState';
import { UiHomeStudentHistoryTable } from './HomeStudentHistoryTable.style';

/**
 * 影音紀錄
 */

export const HomeStudentHistoryTable = () => {
  const { organizationId, classId, videoId, teacherId } = useParams();
  const [, { getCustomers }] = useCustomer();
  const history = useHistory();
  const [{ recordData, customersData, nowPage, rowsPage, isLoading }, setState] = useSetState({
    recordData: [],
    customersData: [],
    nowPage: 0,
    rowsPage: 100,
    isLoading: true,
  });

  const schema = {
    attendeeNumber: {
      name: '座號',
      defaultValue: '-',
    },
    nickname: {
      name: '學生',
      defaultValue: '-',
    },
    isWatchedText: {
      name: '觀看狀態',
      defaultValue: '-',
    },
  };

  const fetchVideoRecord = async () => {
    const payload = {
      teacherId,
      classId,
      videoId,
    };
    const res = await getVideoRecord(payload);
    if (res.isSuccess) {
      setState({
        recordData: res.data,
      });
    }
  };

  const data = useMemo(() => {
    if (!customersData || customersData.length === 0) return [];
    const res = customersData.map((item) => {
      const customer = recordData.find((record) => record.userId === item.id);
      const viewTime = customer?.viewAt ? format(customer.viewAt, 'yyyy/MM/dd HH:mm') : null;
      const isWatchedText = viewTime ? `${viewTime}` : '尚未觀看';
      return {
        ...item,
        isWatchedText,
      };
    });
    return res;
  }, [customersData, recordData]);

  const changePage_Rows = (params) => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage,
    });
  };

  const fetchAssignVideoList = async () => {
    const res = await getCustomers({ nowPage, rowsPage });
    setState({ customersData: res.userProfiles, isLoading: false });
  };

  const fetchData = async () => {
    setState({ isLoading: true });
    await fetchAssignVideoList();
    await fetchVideoRecord();
    setState({ isLoading: false });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <UiHomeStudentHistoryTable>
      <div className="flex">
        <h4>學生觀看紀錄列表</h4>
        <Button buttonColor="new" onClick={() => history.push(`/home/${organizationId}/class/${classId}/video?tab=1`)}>
          返回上一頁
        </Button>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Table
            data={data}
            schema={schema}
            changePage_Rows={changePage_Rows}
            totalPage={data.length}
            nowPage={nowPage}
          />
          {data.length === 0 && <EmptyPrompt img={forest} message="尚無班級學生" messageColor={'#8B90A0'} />}
        </>
      )}
    </UiHomeStudentHistoryTable>
  );
};

HomeStudentHistoryTable.propTypes = {};
