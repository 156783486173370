import PropTypes from 'prop-types';
import React from 'react';
import { UiBlindBrick } from './BlindBrick.style';


/**
 * 在這邊描述這個組件
 */

export const BlindBrick = ({
  title,
  href,
  ref,
  className,
  id,
  accessKey
}) => {
  return (
    <UiBlindBrick
      aria-label={title}
      ref={ref}
      className={className}
      id={id}
      accessKey={accessKey}
      href={href}
      title={title}
    >
      :::
    </UiBlindBrick>
  );
};

BlindBrick.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  ref: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  accessKey: PropTypes.string
};


