import {
  FormControl as MuiFormControl,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core';
import styled from 'styled-components';

export const UiSearchWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

export const UiTeachersContainer = styled.div`
`;

export const UiMuiFormControl = styled(MuiFormControl)`
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 5px;
`;

export const UiMuiFormControlLabel = styled(MuiFormControlLabel)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  margin: 0;
  padding: 0 23px;

  &:last-child {
    border-bottom: 0;
  };
`;