import { HomeGroupLayout, HomeStudentHistoryTable } from 'components';
import React from 'react';

/**
 * 影音學習學生觀看紀錄
 */

export const HomeStudentHistoryPage = () => {
  return (
    <HomeGroupLayout>
      <HomeStudentHistoryTable />
    </HomeGroupLayout>
  );
};
