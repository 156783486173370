import { NormalLayout, MySessions } from 'components';
import React from 'react';


/**
 * 所有課程頁面
 */

export const MySessionsPage = () => {
  return (
    <NormalLayout>
      <MySessions />
    </NormalLayout>
  );
};
