import { HomeGroupLayout, BrowserSuggestion } from 'components';
import React from 'react';
import { UiBrowserSuggestion } from './BrowserSuggestionPage.style';

export const BrowserSuggestionPage = () => {
    return (
        <HomeGroupLayout showSideBar={false}>
            <UiBrowserSuggestion>
                <BrowserSuggestion />
            </UiBrowserSuggestion>
        </HomeGroupLayout>
    );
};