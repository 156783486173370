import emptyPaper from 'assets/images/emptyPaper.png';
import React from 'react';
import {
  UiNoDataBox,
  UiNoDataTextBox,
  UiNoDataTextPrimary,
  UiNoDataTextSecondary,
  UiPictureBox,
} from './HomeOnlineExamNoDataScreen.style';

export const HomeOnlineExamNoDataScreen = () => {
  return (
    <UiNoDataBox>
      <UiPictureBox path={emptyPaper} />
      <UiNoDataTextBox>
        <UiNoDataTextPrimary>沒有試卷</UiNoDataTextPrimary>
        <UiNoDataTextSecondary>可以前往上方的「題庫出卷」，開始出自己的考卷！</UiNoDataTextSecondary>
      </UiNoDataTextBox>
    </UiNoDataBox>
  );
};
