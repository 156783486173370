import { SESSION_STATUS } from 'constants/index';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from '@material-ui/core';
import forest from 'assets/images/forest.svg';
import { Button, EmptyPrompt, HomeSessionActions, Loading } from 'components';
import { UiHomeSessionTableBox } from 'components/pages/HomeSessionPage/HomeSessionPage.style';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSessions } from 'services/api/home/sessions';
import { formatTimestamp } from 'utils/date';
import { useAlert } from 'utils/hooks/useAlert';
import { useUuid } from 'utils/hooks/useUuid';
import { UiDateTimeBox, UiActionsBox } from './HomeSessionTableCanceled.style';

const TABLE_HEAD_CELLS = [
  {
    TITLE: '課程名稱',
  },
  {
    TITLE: '科目',
  },
  {
    TITLE: '時間',
  },
  {
    TITLE: '教師',
  },
  {
    TITLE: '開始上課',
  },
  {
    TITLE: '',
  },
];

const ROWS_PER_PAGE_OPTIONS = [10, 15, 20];

export const HomeSessionTableCanceled = () => {
  const { classId } = useParams();
  const { setAlert } = useAlert();
  const [getUuid] = useUuid();
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState();
  const [sessionsApiData, setSessionsApiData] = useState();
  const [pagination, setPagination] = useState({
    count: 0,
    rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    page: 0,
  });
  const hasSessions = !!sessionsApiData?.total;

  const sessions = useMemo(() => {
    if (!sessionsApiData) return [];
    const newSessions = sessionsApiData.sessions.map((session) => {
      const subjectText = session.subjects.map((subject) => subject.name).join('、');
      const date = formatTimestamp(session.startAt, 'yyyy-MM-dd');
      const startTime = formatTimestamp(session.startAt, 'HH:mm');
      const endTime = formatTimestamp(session.endAt, 'HH:mm');
      const durationText = {
        time: `${startTime}-${endTime}`,
        minute: `(${(session.endAt - session.startAt) / 1000 / 60}分)`,
      };
      const teacher = {
        oneClubId: session.hostId,
        name: session.hostName,
      };
      const newSession = {
        id: session.id,
        name: session.name,
        subjectText,
        date,
        durationText,
        teacher,
        timeSpanId: session.timeSpanId,
        type: session.type,
        hostId: session.hostId,
        mmsCourseId: session?.mmsCourseId,
        users: session.users,
        resources: session.resources,
        resourceObject: session.resourceObject,
      };
      return newSession;
    });
    return newSessions;
  }, [sessionsApiData]);

  /* 取得課程列表 */
  const fetchSessions = async ({ page, rowsPerPage, filterData }) => {
    const payload = {
      ...filterData,
      status: SESSION_STATUS.CANCELED,
      nowPage: page,
      rowsPage: rowsPerPage,
    };
    const { data, status } = await getSessions(classId)(payload);
    status === 'success' ? setSessionsApiData(data) : setAlert('取得課程列表失敗', 'error');
    setPagination((prev) => ({
      ...prev,
      count: data?.total || prev.count,
    }));
  };

  /* 搜尋課程 */
  const searchSessions = async (filterData) => {
    setIsLoading(true);
    const page = 0;
    setFilterData((prev) => ({ ...prev, ...filterData }));
    setPagination((prev) => ({
      ...prev,
      page,
    }));
    await fetchSessions({ page, rowsPerPage: pagination.rowsPerPage, filterData });
    setIsLoading(false);
  };

  /* 切上下頁 */
  const changePage = async (event, newPage) => {
    setIsLoading(true);
    const page = newPage;
    setPagination((prev) => ({
      ...prev,
      page,
    }));
    await fetchSessions({ page, rowsPerPage: pagination.rowsPerPage, filterData });
    setIsLoading(false);
  };

  /* 改每頁列數 */
  const changeRowsPerPage = async (event) => {
    setIsLoading(true);
    const page = 0;
    const rowsPerPage = parseInt(event.target.value);
    setPagination((prev) => ({
      ...prev,
      rowsPerPage,
      page,
    }));
    await fetchSessions({ page, rowsPerPage, filterData });
    setIsLoading(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    await fetchSessions({ page: pagination.page, rowsPerPage: pagination.rowsPerPage });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <UiHomeSessionTableBox>
      <HomeSessionActions onSearch={searchSessions} />
      {!isLoading && !hasSessions ? (
        <EmptyPrompt img={forest} message={'尚無課程'} messageColor={'#8B90A0'} />
      ) : (
        <>
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD_CELLS.map((cell) => {
                    const uuid = getUuid();
                    const title = cell.TITLE;
                    return <TableCell key={uuid}>{title}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={TABLE_HEAD_CELLS.length}>
                      <Loading />
                    </TableCell>
                  </TableRow>
                ) : (
                  sessions.map((session) => (
                    <TableRow key={session.id}>
                      <TableCell>{session.name}</TableCell>
                      <TableCell>{session.subjectText}</TableCell>
                      <TableCell>
                        <UiDateTimeBox>
                          <Typography className="date">{session.date}</Typography>
                          <Typography>{`${session.durationText.time} ${session.durationText.minute}`}</Typography>
                        </UiDateTimeBox>
                      </TableCell>
                      <TableCell>{session.teacher.name}</TableCell>
                      <TableCell>
                        <UiActionsBox>
                          <Box>
                            <Button buttonColor="cancel" disabled>
                              課程取消
                            </Button>
                          </Box>
                        </UiActionsBox>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={pagination.count}
            labelRowsPerPage="每頁顯示"
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onChangePage={changePage}
            onChangeRowsPerPage={changeRowsPerPage}
          />
        </>
      )}
    </UiHomeSessionTableBox>
  );
};
