import { Box, useTheme } from '@material-ui/core';
import { MenuRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

export const SidebarToggler = ({ onToggle }) => {
  const theme = useTheme();
  return (
    <Box
      onClick={onToggle}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        width: theme.spacing(5.5),
        height: theme.spacing(5.5),
      }}
    >
      <MenuRounded style={{ fontSize: theme.spacing(3) }} />
    </Box>
  );
};

SidebarToggler.propTypes = {
  onToggle: PropTypes.func,
};
