import { QUERY, DEVICE } from 'constants/dimension';
import { Box } from '@material-ui/core';
import { Header, SessionGroup, BlindBrick } from 'components';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useServerTime } from 'store/serverTime/index';
import { useResponsive } from 'utils/hooks/useResponsive';
import { UiSingleLayout, UiSingleContent } from './SingleLayout.style';

/**
 * 沒有側邊欄的版型
 */

const GREETINGS = {
  MORNING: '早安',
  AFTERNOON: '午安',
  NIGHT: '晚安',
};

export const SingleLayout = ({ children, showLeftSidebar = true }) => {
  const isTabletL = useResponsive({ query: QUERY.DOWN, start: DEVICE.TABLET.WIDTH_L });
  const isTabletS = useResponsive({ query: QUERY.DOWN, start: DEVICE.TABLET.WIDTH_S });
  const [{ timestamp: serverTimestamp }] = useServerTime();

  const getTimeState = useCallback(() => {
    if (!serverTimestamp) return;
    const nowHour = new Date(serverTimestamp).getHours();
    if (nowHour >= 0 && nowHour <= 12) {
      return `${GREETINGS.MORNING} !`;
    } else if (nowHour > 12 && nowHour <= 18) {
      return `${GREETINGS.AFTERNOON} !`;
    } else if (nowHour > 18 && nowHour <= 24) {
      return `${GREETINGS.NIGHT} !`;
    }
  }, [serverTimestamp]);

  return (
    <UiSingleLayout>
      <UiSingleContent showLeftSidebar={showLeftSidebar} isTabletL={isTabletL} isTabletS={isTabletS}>
        <div className="header">
          <Header isNeedLogo={true} />
        </div>
        {showLeftSidebar && (
          <div className="leftSidebar">
            <BlindBrick
              className="accesskey"
              id="left_container"
              accessKey="l"
              href="#left_container"
              title="左方選單區塊"
            />
            <Box className="greetingsBox">
              {!isTabletS && (
                <h2 className="greetings" tabIndex={0}>
                  {getTimeState()}
                </h2>
              )}
              <div className="main">
                <div className="subTitle">
                  <h3 tabIndex={0}>今日課程</h3>
                  <Link className="readMore" to="/sessions">
                    查看課程
                  </Link>
                </div>
              </div>
            </Box>
            <Box className="sessionGroupBox">
              <SessionGroup />
            </Box>
          </div>
        )}
        <div className="main">{children}</div>
      </UiSingleContent>
    </UiSingleLayout>
  );
};

SingleLayout.propTypes = {
  children: PropTypes.node,
};
