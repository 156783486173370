// import PropTypes from 'prop-types';
import sessionReportEmpty from 'assets/images/sessionReportEmpty.png';
import { Icon, Loading } from 'components';
import { format, intervalToDuration } from 'date-fns';
import { zhTW } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import ReactApexCharts from 'react-apexcharts';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getSessionById, getSessionMmsFeedback } from 'services/api/home/sessions';
import { getUserRecords } from 'services/api/oneBoard';
import { useAlert } from 'utils/hooks/useAlert';
import { chartOptions, options, CHART_STATE_MAP, COURSE_TYPE_MAPPING, ERR_MSG } from './SessionFeedback.constant';
import {
  UiEmptyWrapper,
  UiEmptyImgContainer,
  UiEmptyMessage,
  UiActionWrapper,
  UiPreviousPageContainer,
  UiFeedbackTitle,
  UiInformationPanel,
  UiInfoIntro,
  UiDivider,
  UiInfoTrophy,
  UiInfoTrophyIntro,
  UiInfoTrophyIntroTitle,
  UiInfoTrophyIntroCount,
  UiInfoTrophyIcon,
  UiFeedbackTitleItem,
  UiFeedbackTitleItemLabel,
  UiSection,
  UiSectionTitle,
  UiSWOT,
  UiContent,
  UiChartBox,
} from './SessionFeedback.style';
import SWOTItem from './SWOTItem';

// 英文轉分數
const convertEngToNum = (eng) => {
  return ([...options].reverse().findIndex((option) => option.value === eng) + 1) * 20;
};
const transformChartData = (data) => data.map((eng) => convertEngToNum(eng));
const formatDate = (date, pattern) => format(new Date(date || 0), pattern, { locale: zhTW });
const getDuration = (startAt, endAt) => {
  const { hours, minutes } = intervalToDuration({
    start: new Date(startAt || 0),
    end: new Date(endAt || 0),
  });
  return hours * 60 + minutes;
};

export const SessionFeedback = () => {
  const history = useHistory();
  const location = useLocation();
  const { setAlert } = useAlert();
  const { organizationId, classId, sessionId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [sessionMmsFeedbackOri, setSessionMmsFeedbackOri] = useState(null);
  const [oneBoardRecordsOri, setOneBoardRecordsOri] = useState([]);
  const [sessionOri, setSessionOri] = useState();

  const infoPanelTitle = `${sessionOri?.name}－課後回饋`;
  const courseType = `${COURSE_TYPE_MAPPING[sessionOri?.mmsCourseType]}`;
  const teacherName = `${sessionOri?.mmsTeacher?.lastName}${sessionOri?.mmsTeacher?.firstName}`;

  const date = formatDate(sessionOri?.startAt, 'yyyy/MM/dd（iiiii）');
  const startTime = formatDate(sessionOri?.startAt, 'HH:mm');
  const endTime = formatDate(sessionOri?.endAt, 'HH:mm');
  const duration = getDuration(sessionOri?.startAt, sessionOri?.endAt);
  const courseTime = `${date}${startTime}~${endTime}（${duration}分）`;

  const chartDatum = [
    sessionMmsFeedbackOri?.academic,
    sessionMmsFeedbackOri?.concentration,
    sessionMmsFeedbackOri?.understanding,
    sessionMmsFeedbackOri?.reaction,
    sessionMmsFeedbackOri?.participation,
  ];

  const SWOTItems = [
    {
      name: { label: CHART_STATE_MAP.academic, key: 'academic' },
      lvOption: options.find(({ value }) => value === sessionMmsFeedbackOri?.academic),
      comment: sessionMmsFeedbackOri?.academicComment,
    },
    {
      name: { label: CHART_STATE_MAP.concentration, key: 'concentration' },
      lvOption: options.find(({ value }) => value === sessionMmsFeedbackOri?.concentration),
      comment: sessionMmsFeedbackOri?.concentrationComment,
    },
    {
      name: { label: CHART_STATE_MAP.understanding, key: 'understanding' },
      lvOption: options.find(({ value }) => value === sessionMmsFeedbackOri?.understanding),
      comment: sessionMmsFeedbackOri?.understandingComment,
    },
    {
      name: { label: CHART_STATE_MAP.reaction, key: 'reaction' },
      lvOption: options.find(({ value }) => value === sessionMmsFeedbackOri?.reaction),
      comment: sessionMmsFeedbackOri?.reactionComment,
    },
    {
      name: { label: CHART_STATE_MAP.participation, key: 'participation' },
      lvOption: options.find(({ value }) => value === sessionMmsFeedbackOri?.participation),
      comment: sessionMmsFeedbackOri?.participationComment,
    },
  ];

  const studentId = sessionOri?.users[0];
  // const student = 'resturrokbste';
  const oneBoardRecord = oneBoardRecordsOri.find(({ userId }) => userId === studentId);

  const goBack = () => {
    // const { nowPage, rowsPage } = location.state.table;
    const currLocation = {
      ...location,
      pathname: `/home/${organizationId}/class/${classId}/sessions`,
      search: '?tab=done',
    };
    history.push(currLocation);
  };

  /* 取得課程資料 */
  const fetchSession = async () => {
    const response = await getSessionById(sessionId);
    response.status === 'success' && setSessionOri(response.data[0]);
    return response;
  };
  /* 取得課後回饋資料 */
  const fetchSessionMmsFeedback = async (session) => {
    const { mmsCourseId, mmsTeacher } = session || sessionOri;
    // const mmsCourseId = '649fdb20581c82245764d279';
    // const mmsTeacher = { id: '63688862af3aa8b2f0e40206' };
    const { data, status } = await getSessionMmsFeedback(mmsCourseId, mmsTeacher.id);
    if (status === 'success') {
      setSessionMmsFeedbackOri(data);
    } else {
      throw new Error(ERR_MSG.fetchSessionMmsFeedback);
    }
  };
  /* 取得 OneBoard 資料 */
  const fetchUserRecords = async (session) => {
    const params = { courseId: session?.mmsCourseId || sessionOri.mmsCourseId };
    const { data, status } = await getUserRecords(params);
    if (status === 'success') {
      setOneBoardRecordsOri(data);
    } else {
      throw new Error(ERR_MSG.fetchUserRecords);
    }
    // status === 'success' ? setOneBoardRecordsOri(data) : setAlert(ERR_MSG.fetchUserRecords, 'error');
  };
  const fetchData = async () => {
    try {
      const sessionResponse = await fetchSession();
      if (sessionResponse.status === 'success') {
        const session = sessionResponse.data[0];
        await Promise.all([fetchSessionMmsFeedback(session), fetchUserRecords(session)]);
      } else {
        throw new Error(ERR_MSG.fetchSession);
      }
    } catch (error) {
      setAlert(error || ERR_MSG.fetchSessionMmsFeedback, 'error');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <UiActionWrapper>
        <UiPreviousPageContainer onClick={() => goBack()}>
          <Icon title="返回上一頁" name="arrowBackIos" size="1.25rem" haveBg={false} icolor="#8B90A0" />
          返回上一頁
        </UiPreviousPageContainer>
      </UiActionWrapper>
      {isLoading ? (
        <Loading />
      ) : !sessionMmsFeedbackOri ? (
        <UiEmptyWrapper>
          <UiEmptyImgContainer>
            <img src={sessionReportEmpty} alt="無課堂報告" />
          </UiEmptyImgContainer>
          <UiEmptyMessage>尚無課後回饋</UiEmptyMessage>
        </UiEmptyWrapper>
      ) : (
        <div>
          <UiInformationPanel>
            <UiInfoIntro>
              <UiFeedbackTitle>{infoPanelTitle}</UiFeedbackTitle>
              <UiFeedbackTitleItem>
                <UiFeedbackTitleItemLabel>課程類型</UiFeedbackTitleItemLabel>｜{courseType}
              </UiFeedbackTitleItem>
              <UiFeedbackTitleItem>
                <UiFeedbackTitleItemLabel>授課科目</UiFeedbackTitleItemLabel>｜英語
              </UiFeedbackTitleItem>
              <UiFeedbackTitleItem>
                <UiFeedbackTitleItemLabel>授課教師</UiFeedbackTitleItemLabel>｜{teacherName}
              </UiFeedbackTitleItem>
              <UiFeedbackTitleItem>
                <UiFeedbackTitleItemLabel>課程時間</UiFeedbackTitleItemLabel>｜{courseTime}
              </UiFeedbackTitleItem>
            </UiInfoIntro>
            <UiDivider />
            <UiInfoTrophy>
              <UiInfoTrophyIntro>
                <UiInfoTrophyIntroTitle>獎盃數</UiInfoTrophyIntroTitle>
                <UiInfoTrophyIntroCount>{oneBoardRecord?.summary?.trophy}</UiInfoTrophyIntroCount>
              </UiInfoTrophyIntro>
              <UiInfoTrophyIcon>
                <UiInfoTrophyIntroTitle>&nbsp;</UiInfoTrophyIntroTitle>
                <Icon.Svg name="Trophy" haveBg={false} color="#F9C74F" size="50px" />
              </UiInfoTrophyIcon>
            </UiInfoTrophy>
          </UiInformationPanel>
          <UiSection>
            <UiSectionTitle>學習表現</UiSectionTitle>
            <UiChartBox>
              <ReactApexCharts
                type="radar"
                options={chartOptions}
                series={[
                  {
                    name: 'series-1',
                    data: transformChartData(chartDatum),
                  },
                ]}
                width="100%"
                height="100%"
              />
            </UiChartBox>
          </UiSection>
          <UiSection>
            <UiSWOT>
              <SWOTItem icon="Weakness" title="可加強、需改善原因" backgroundColor="#FEF8F7">
                {SWOTItems.map(
                  ({ lvOption: { value }, name: { label, key }, comment }) =>
                    (value === 'd' || value === 'e') && (
                      <div key={label + comment}>
                        {label}：{comment}
                      </div>
                    ),
                )}
              </SWOTItem>
            </UiSWOT>
          </UiSection>
          <UiSection>
            <UiSectionTitle>學習重點</UiSectionTitle>
            <UiContent>{sessionMmsFeedbackOri.recommend}</UiContent>
          </UiSection>
          <UiSection>
            <UiSectionTitle>老師的話</UiSectionTitle>
            <UiContent>{sessionMmsFeedbackOri.comment}</UiContent>
          </UiSection>
        </div>
      )}
    </>
  );
};
