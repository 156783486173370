import { HomeMemberProfileCard } from 'components';
import React from 'react';
import { useUser } from 'store/user';
import { timestampFormatStr } from 'utils/date';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiHomeMemberProfile,
  UiTipBox,
  UiTip,
} from './HomeMemberProfile.style';

/**
 * 會員中心-個人資料
 */
export const HomeMemberProfile = () => {
  const [{ profile }, { userRefreshProfile, updateUserProfile }] = useUser();
  const [{
    editType,
    isLoading,
  }, setState] = useSetState({
    editType: 'read',
    isLoading: false,
  });

  const changeCardTypeHandler = (type) => {
    setState({
      editType: type
    });
  };
  const refreshUserDataHandler = () => {
    userRefreshProfile();
  };
  const submitformHandler = async (val) => {
    setState({
      isLoading: true
    });
    val.birthday = val.birthday ?
      timestampFormatStr(val.birthday) :
      '';
    const success = await updateUserProfile(val);
    setState({
      isLoading: false
    });
    if (success) setState({ editType: 'read' });
  };
  return (
    <UiHomeMemberProfile>
      <HomeMemberProfileCard
        type={editType}
        data={profile}
        changeCardType={changeCardTypeHandler}
        refreshUserData={refreshUserDataHandler}
        submitformHandler={submitformHandler}
        isLoading={isLoading}
      />
      <UiTipBox>
        <UiTip>
          <p>為什麼需要寫填這麼多資料呢？</p>
          <p>OneClass會員中心除了實現「單一帳號，暢行無阻」的目標，讓您可以用同一組帳號登入OneClass相關平台，更能針對您的身份，精準提供專屬於您的服務與產品。</p>
          <p>您填寫的資料越仔細，將有助於我們了解您，並提供更優質的服務。</p>
        </UiTip>
      </UiTipBox>
    </UiHomeMemberProfile>
  );
};

HomeMemberProfile.propTypes = {

};


