import { recordTypeList } from 'constants/index';
import {
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SortImage from 'assets/images/sort.png';
import { Icon, Loading } from 'components';
import { format, subDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAttendance } from 'store/attendance';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiHomeAttendanceStudent,
  UiGoBackButtonBox,
  UiGoBackButton,
  UiFlexBox,
  UiTitleRow,
  NoCheck,
  ReviseCheck,
  Pointer,
  SortImg,
  BodyRow,
  Title,
} from './HomeAttendanceStudent.style';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 256,
  },
  buttonFill: {
    background: '#121232',
    color: '#fff',
    fontSize: '16px',
    padding: '8px',
    height: '48px',
    '&:hover': {
      background: '#121232',
    },
  },
}));

const switchSortOrderMap = {
  asc: 'desc',
  desc: 'asc',
};

export const HomeAttendanceStudent = () => {
  const history = useHistory();
  const [{ recordList, totalRecord }, { searchRecordStudent }] = useAttendance();
  const { organizationId } = useParams();
  const [{ startDate, endDate, recordType, rowsPage, page, sortKey, sortOrder }, setSearchQuery] = useSetState({
    startDate: format(subDays(new Date(), 7), 'yyyy/MM/dd'),
    endDate: format(new Date(), 'yyyy/MM/dd'),
    recordType: 0,
    rowsPage: 10,
    page: 0,
    sortKey: 'punchIn',
    sortOrder: 'desc',
  });

  const [isLoading, setIsLoading] = useState(true);
  const today = new Date();
  const classes = useStyles();

  const getSearch = async () => {
    setIsLoading(true);
    await searchRecordStudent(organizationId, {
      startAt: startDate.replaceAll('/', ''),
      endAt: endDate.replaceAll('/', ''),
      recordType,
      page,
      limit: rowsPage,
      sortKey,
      sortOrder,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getSearch();
  }, [rowsPage, sortKey, sortOrder, rowsPage, page]);

  const recordTypeChange = (event) => {
    setSearchQuery({
      recordType: event.target.value,
    });
  };

  const startDateChange = (date) => {
    setSearchQuery({
      startDate: format(date, 'yyyy/MM/dd'),
    });
  };

  const endDateChange = (date) => {
    setSearchQuery({
      endDate: format(date, 'yyyy/MM/dd'),
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchQuery({
      rowsPage: event.target.value,
      page: 0,
    });
  };

  const handleChangePage = (event, newPage) => {
    setSearchQuery({
      page: newPage,
    });
  };

  const switchSortOrder = (searchType) => {
    setSearchQuery({
      sortKey: searchType,
      sortOrder: switchSortOrderMap[sortOrder],
    });
  };

  const TableContent = () => {
    const TableCellCondition = ({ data }) => {
      if (data) {
        return (
          <TableCell>
            {format(data.recordTime, 'yyyy/MM/dd HH:mm')}
            {data.revise && <ReviseCheck>補</ReviseCheck>}
          </TableCell>
        );
      } else {
        return (
          <TableCell>
            <NoCheck>未打卡</NoCheck>
          </TableCell>
        );
      }
    };

    if (isLoading) {
      return <Loading />;
    } else {
      return (
        <>
          <Grid container>
            <Table>
              <TableHead>
                <UiTitleRow>
                  <TableCell>
                    <Box display="table">
                      <Pointer onClick={() => switchSortOrder('punchIn')}>
                        到班時間
                        <SortImg src={SortImage} alt="sort" />
                      </Pointer>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="table">
                      <Pointer onClick={() => switchSortOrder('punchOut')}>
                        離班時間
                        <SortImg src={SortImage} alt="sort" />
                      </Pointer>
                    </Box>
                  </TableCell>
                </UiTitleRow>
              </TableHead>
              <TableBody>
                {recordList.map((item, index) => {
                  return (
                    <BodyRow key={index}>
                      <TableCellCondition data={item.punchIn}></TableCellCondition>
                      <TableCellCondition data={item.punchOut}></TableCellCondition>
                    </BodyRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            <TablePagination
              count={totalRecord}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPage}
              labelRowsPerPage={'每頁顯示'}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              component="div"
            />
          </Box>
        </>
      );
    }
  };

  return (
    <UiHomeAttendanceStudent>
      <UiGoBackButtonBox>
        <UiGoBackButton onClick={() => history.push('/home')}>
          <Icon.Svg name="ChevronLeft" />
          <Typography>返回班級列表</Typography>
        </UiGoBackButton>
      </UiGoBackButtonBox>

      <Title>我的打卡紀錄</Title>
      <Box mb="28px">
        <UiFlexBox flexWrap="wrap">
          <UiFlexBox>
            <KeyboardDatePicker
              format="yyyy/MM/dd"
              label={'起始日期'}
              value={startDate}
              onChange={startDateChange}
              cancelLabel="取消"
              okLabel="確定"
              clearLabel="清除"
              inputVariant="outlined"
              maxDate={today}
            />
            <KeyboardDatePicker
              format="yyyy/MM/dd"
              label={'結束日期'}
              value={endDate}
              onChange={endDateChange}
              cancelLabel="取消"
              okLabel="確定"
              clearLabel="清除"
              inputVariant="outlined"
              maxDate={today}
            />
          </UiFlexBox>

          <UiFlexBox>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="check-label">打卡類型</InputLabel>
              <Select
                labelId="check-label"
                variant="outlined"
                label="打卡類型"
                value={recordType}
                onChange={recordTypeChange}
              >
                {recordTypeList.map((type, index) => (
                  <MenuItem key={index} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </UiFlexBox>

          <UiFlexBox>
            <Button className={classes.buttonFill} onClick={() => getSearch()}>
              搜尋
            </Button>
          </UiFlexBox>
        </UiFlexBox>
        {<TableContent />}
      </Box>
    </UiHomeAttendanceStudent>
  );
};
