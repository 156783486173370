import {
	Checkbox as MatCheckbox,
} from '@material-ui/core';
import styled from 'styled-components';

export const UiTransferList = styled.div`
	margin-top: 15px;
`;
export const UiTransferContent = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const UiCheckList = styled.div`
	display: flex;
	overflow-x: hidden;
	width: ${props => props.type === 'singleChoice' ? '100%' : '48%'};
	min-width: 300px;
	height: 350px;
	background-color: ${({ theme }) => theme.table.tdBgColorGray};
	border-radius: 5px;
	flex-direction: column;

	& + div {
		margin-left: 15px;
	}
`;
export const UiCheckTitle = styled.div`
	position: relative;
	padding: 10px;
	width: 100%;
	font-size: 0.9rem;
	font-weight: bold;
	margin-bottom: 5px;

	&::after {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		margin: auto;
		width: calc(100% - 20px);
		height: 1px;
		background-color: #004b62;
		content: '';
	}
`;
export const UiCheckItem = styled.div`
	display: flex;
	align-items: center;
	padding: 3px 10px;
	width: 100%;
	color: #424242;
	transition: 0.35s;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;

	&:hover {
		text-decoration: none;
		color: #fff;
		background-color: ${({ theme }) => theme.palette.primary.main};
	}

	& > span {
		padding: 0 0.3rem 0 0;
	}
`;

export const UiCheckbox = styled(MatCheckbox)`
	color: #fff;
`;

export const UiCheckContent = styled.div`
	padding: 5px 8px;
	text-align: left;

	& > div:nth-child(1) {
		font-size: 1.1rem;
	}

	& > div:nth-child(2) {
		font-size: 1rem;
		text-align: left;
	}
`;

export const UiButtonBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: auto;
	padding: 1rem 0;

	& > button {
		margin: 0 5px;
	}
`;
