import { HomeGroupLayout, HomeVideoTable } from 'components';
import React from 'react';


/**
 * 影音
 */

export const HomeVideoPage = () => {
  return (
    <HomeGroupLayout>
      <HomeVideoTable />
    </HomeGroupLayout>
  );
};
