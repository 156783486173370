import { stringify } from 'query-string';
import { Api } from 'services/api';

/**
 * 取得所有公開課資訊
 * @param {object} params
 * @returns
 */
export const getPublished = async (params) => {
  const querystring = stringify(params, { arrayFormat: 'index' });
  const endpoint = `${process.env.REACT_APP_COURSES_DOMAIN}/api/courses/published?${querystring}`;
  const response = await Api.get(endpoint);
  return response;
};
