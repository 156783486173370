import { Icon as MatIcon } from '@material-ui/core';
import MatButton from '@material-ui/core/Button';
import styled from 'styled-components';

import extraClassMan from '../../molecules/Icon/svg/extra_class_man.svg';
const WIDTH_PAGE_MAN = '172px';

export const UiExtraLearningContent = styled.div`
  margin: -1rem;
`;

export const UiTitleWrapper = styled.div`
  ${({ isTabletL }) =>
    isTabletL
      ? {
          padding: '36px 40px',
          marginRight: 'unset',
        }
      : {
          padding: `0px calc(40px + ${WIDTH_PAGE_MAN}) 40px 0px`,
          marginRight: '109px',
        }}
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UiButton = styled(MatButton)`
  position: relative;
  border-radius: 4px;
  font-size: 16px;
  background: #fff;
  padding: 12px 16px;
  line-height: 24px;
  font-family: Noto Sans CJK TC;
  white-space: nowrap;
  color: #121232;
  min-width: 136px;

  &:hover {
    background-color: #fff;
    border-color: #121232;
  }
`;

export const UiPageManContainer = styled.div`
  width: ${WIDTH_PAGE_MAN};
  position: absolute;
  top: 10%;
  right: 0;
`;

export const UiPageMan = styled.img.attrs({
  src: extraClassMan,
})`
  height: 100%;
  width: 100%;
`;

export const UiCustomIcon = styled(MatIcon)`
  padding: 2px;
  width: 15px;
  margin-right: 10px;
`;
