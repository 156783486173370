import styled, { css } from 'styled-components';

export const UiCalendar = styled.div`
  border: 1px solid #D5D7DE;
  border-radius: 16px;
  position: relative;

  & .fc {
    --fc-today-bg-color: #FAFAFC;
  };

  /* 以週顯示，header 部分 */
  .fc-col-header th {
    border-left: none;
    border-right: none;
    border-bottom: 4px solid rgba(145, 158, 171, 0.12);
    padding: 13px 0;
  };
  .fc-theme-standard th {
    border-right: none;
  };

  /* 以週顯示，body 部分 */
  .fc-timegrid table {
    border-left: none;
  };
  .fc-scrollgrid-section-body td {
    border-right: none;
    border-bottom: none;
  }
  
  /* 捲軸 */
  .fc-scroller {
    overflow: scroll;
    ::-webkit-scrollbar{ 
      background: rgba(145, 158, 171, 0.12);
      width: 8px;
    };
  }
  .fc-scroller-liquid-absolute, .fc-scroller-liquid {
    overflow: scroll;
    ::-webkit-scrollbar{ 
      background: rgba(145, 158, 171, 0.12);
      border-radius: 0 0 16px 0;
      width: 8px;
    };
    ::-webkit-scrollbar-thumb{
        border-radius: 16px;
        background-color: rgba(145, 158, 171, 0.48);
    }
  }

  /* event 時間 */
  .fc-event-time {
    font-weight: 600;
  }

  /* event 整條, 時間表 event */
  .fc-event-main, .fc-list-event{
    cursor: pointer;
  }
`;

export const UiScreen = styled.div`
  background: #FFF;
  border-radius: 0 0 16px 16px;
  width: 100%;
  position: absolute;
  z-index: 999;

  ${({ toolbarHeight }) =>
        css`
      margin-top: ${toolbarHeight}px;
      height: calc(100% - ${toolbarHeight}px);
    `
    };
`;

export const UiLoading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
