import { Icon as Iconify } from '@iconify/react';
import { Icon } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { UiButton, UiCircularProgress, UiInlineFlex } from './Button.style';
/**
 * Use `Button` to highlight key info with a predefined status. Easy peasy!!!!
 */

export const Button = ({
  buttonColor = 'normal',
  children,
  onClick,
  type,
  disabled = false,
  loading = false,
  size,
  btnSize,
  icon,
  endIcon,
  textColor,
  iconify,
  iconSvg,
  className,
}) => {
  const clickEvent = !loading && !disabled ? onClick : () => {};
  return (
    <UiButton
      type={type}
      size={btnSize}
      disabled={loading}
      textColor={textColor}
      buttoncolor={disabled ? 'disable' : buttonColor}
      onClick={clickEvent}
      className={className}
    >
      {iconify && <Iconify icon={iconify} />}
      {icon && (
        <UiInlineFlex>
          <Icon icolor={textColor} name={icon} haveBg={false} />
        </UiInlineFlex>
      )}
      {iconSvg && <Icon.Svg name={iconSvg} color={buttonColor === 'cancel' ? '#121232' : '#FFF'} />}
      {children}
      {loading && <UiCircularProgress size={size} color={buttonColor === 'cancel' ? '#00000042' : '#FFF'} />}
      {endIcon && (
        <UiInlineFlex endIcon={true}>
          <Icon name={endIcon} haveBg={false} icolor={textColor} />
        </UiInlineFlex>
      )}
    </UiButton>
  );
};

Button.propTypes = {
  buttonColor: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.number,
  icon: PropTypes.string,
  endIcon: PropTypes.string,
  textColor: PropTypes.string,
  iconify: PropTypes.string,
  className: PropTypes.string,
};
