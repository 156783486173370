import { Icon, Table } from 'components';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import {
  UiScreenRecord,
  UiRecordBox,
  UiRecordCard,
  UiRecordIcon,
  UiRecordTitle,
  UiRecordText,
  UiRecordNumber,
  UiRecordTable
} from './VideoRecord.style';


/**
 * 上課記錄
 */

const detailsSchema = {
  order: {
    name: '次序',
    defaultValue: '',
  },
  createAt: {
    name: '觀看時間',
    defaultValue: '',
  },
};

export const VideoRecord = ({ data }) => {
  const [{ myClasses }] = useClass();
  const [{ profile }] = useUser();
  const { dataInfo } = myClasses;
  // 班級身份判斷
  const isUser = dataInfo?.users?.some(user => user === profile.id);


  const studentRecords = useMemo(() => {
    if (!data) return [];
    const { userRecords } = data;
    const nextUserRecord = userRecords.map(record => {
      const { userId, viewedRecordVideoTime } = record;

      return {
        userId,
        role: '學生',
        viewedRecordVideo: viewedRecordVideoTime.map((item, index) => ({ id: item.createAt, order: index + 1, createAt: format(item.createAt, 'yyyy-MM-dd HH:mm') })),
      };
    });
    return nextUserRecord;
  }, [data]);


  return (
    <>
      {
        isUser &&
        <>
          <UiRecordTitle>
            課程錄影
          </UiRecordTitle>
          <UiScreenRecord>
            <UiRecordCard>
              <UiRecordIcon>
                <Icon.Svg name="Video" color="#fff" size="2rem" />
              </UiRecordIcon>
              <UiRecordBox>
                <UiRecordText>觀看次數</UiRecordText>
                <UiRecordNumber>
                  {studentRecords?.[0]?.viewedRecordVideo?.length || 0}
                  <span>次</span>
                </UiRecordNumber>
              </UiRecordBox>
            </UiRecordCard>
            <UiRecordTable>
              <Table
                data={studentRecords?.[0]?.viewedRecordVideo}
                schema={detailsSchema}
                totalPage={10}
                isNeedPagination={false}
              >
              </Table>
            </UiRecordTable>
          </UiScreenRecord>
        </>
      }

    </>
  );
};

VideoRecord.propTypes = {
  data: PropTypes.object,
};
