import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getGroupPosts as getGroupPostsApi,
  createGroupPost as createGroupPostApi,
  updateGroupPost as updateGroupPostApi,
  deleteGroupPost as deleteGroupPostApi,
  banGroupPost as banGroupPostApi,
  likeGroupPost as likeGroupPostApi,
  undoLikeGroupPost as undoLikeGroupPostApi,
  replyGroupPost as replyGroupPostApi,
  getGroupPostReplies as getGroupPostRepliesApi,
} from 'services/api/home/posts';
import { uniqueArray } from 'utils/array';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';


const GET_POSTS_LIST = 'GET_POSTS_LIST';
const CLEAR_POSTS_LIST = 'CLEAR_POSTS_LIST';
const CREATE_POST = 'CREATE_POST';
const UPDATE_POST = 'UPDATE_POST';
const DELETE_POST = 'DELETE_POST';
const LIKE_POST = 'LIKE_POST';
const UNDO_LIKE_POST = 'UNDO_LIKE_POST';
const REPLY_POST = 'REPLY_POST';
const UPDATE_REPLY_POST = 'UPDATE_REPLY_POST';
const GET_POST_REPLIES = 'GET_POST_REPLIES';
const DELETE_POST_REPLIES = 'DELETE_POST_REPLIES';
const initState = {
  postsList: {
    data: [],
    totalPage: 0,
  },
};

const actions = {
  getPostList: postsList => ({
    type: GET_POSTS_LIST,
    payload: { ...postsList },
  }),
  clearPostList: () => ({
    type: CLEAR_POSTS_LIST,
  }),
  createPost: ({ data }) => ({
    type: CREATE_POST,
    payload: { data }
  }),
  updatePost: ({ data }) => ({
    type: UPDATE_POST,
    payload: { data }
  }),
  deletePost: (postId) => ({
    type: DELETE_POST,
    payload: { postId }
  }),
  likePost: (data) => ({
    type: LIKE_POST,
    payload: { data }
  }),
  undoLikePost: (data) => ({
    type: UNDO_LIKE_POST,
    payload: { data }
  }),
  replyPost: (params, postId) => ({
    type: REPLY_POST,
    payload: { params, postId }
  }),
  updateReplyPost: (params, postId) => ({
    type: UPDATE_REPLY_POST,
    payload: { params, postId }
  }),
  getPostReplies: (params, postId) => ({
    type: GET_POST_REPLIES,
    payload: { params, postId }
  }),
  deletePostReplies: (postId, replyTo) => ({
    type: DELETE_POST_REPLIES,
    payload: { postId, replyTo }
  }),
};

export const usePosts = () => {
  const { setAlert } = useAlert();
  const { classId } = useParams();

  const dispatch = useDispatch();
  const { postsList } = useSelector(state => state.posts);

  const getPostList = async params => {
    const { isSuccess, data } = await getGroupPostsApi(classId)(params);
    if (!isSuccess) return;
    const { posts, total } = data;

    dispatch(actions.getPostList({ data: posts, totalPage: total }));
    return { posts, total };
  };
  const clearPostList = () => dispatch(actions.clearPostList());


  const createPost = async params => {
    const { isSuccess, error, data } = await createGroupPostApi(classId)(params);
    try {
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
      dispatch(actions.createPost({ data }));
      return isSuccess;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'error', errorCode);
      return isSuccess;
    }
  };

  const updatePost = postId => async params => {
    const { isSuccess, error, data } = await updateGroupPostApi(classId, postId)(params);
    try {
      if (!isSuccess) throw error;
      dispatch(actions.updatePost({ data }));
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
      return isSuccess;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return isSuccess;
    }
  };

  // 刪除作業 (自刪用delete 導師刪除別人貼文用ban)刪除回復也是用這個
  const deletePost = async params => {
    const { id: postId, type, replyTo } = params;
    try {
      const { isSuccess, data, error } = type === 'delete' ?
        await deleteGroupPostApi(classId)(params) :
        await banGroupPostApi(classId)(params);
      if (!isSuccess) throw error;
      setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');

      replyTo ?
        dispatch(actions.deletePostReplies(postId, replyTo)) :
        dispatch(actions.deletePost(postId));
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.DELETE_FAIL, 'error', errorCode);
      return null;
    }
  };

  // 貼文按讚
  const likePost = async postId => {
    try {
      const { isSuccess, data, error } = await likeGroupPostApi(classId, postId);
      if (!isSuccess) throw error;
      dispatch(actions.likePost(data));
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return null;
    }
  };
  // 貼文收回讚
  const undoLikePost = async postId => {
    try {
      const { isSuccess, data, error } = await undoLikeGroupPostApi(classId, postId);
      if (!isSuccess) throw error;
      dispatch(actions.undoLikePost(data));
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return null;
    }
  };

  //回覆貼文
  const replyPost = postId => async payload => {
    try {
      const { isSuccess, data, error } = await replyGroupPostApi(classId, postId)(payload);
      if (!isSuccess) throw error;
      dispatch(actions.replyPost(data, postId));
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
  };

  //編輯回覆
  const updateReplyPost = postId => async params => {
    const { isSuccess, data, error } = await updateGroupPostApi(classId, postId)(params);
    try {
      if (!isSuccess) throw error;
      dispatch(actions.updateReplyPost(data, postId));
      return isSuccess;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
      return isSuccess;
    }
  };

  const getPostReplies = postId => async payload => {
    try {
      const { isSuccess, data, error } = await getGroupPostRepliesApi(classId, postId)(payload);
      if (!isSuccess) throw error;
      dispatch(actions.getPostReplies(data, postId));
      return data;
    } catch (error) {
      const { errorCode } = error;
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error', errorCode);
    }
  };

  useEffect(() => {
    clearPostList();
  }, [classId]);

  return [
    { postsList }, // state
    {
      getPostList,
      clearPostList,
      createPost,
      updatePost,
      deletePost,
      likePost,
      undoLikePost,
      replyPost,
      updateReplyPost,
      getPostReplies,
    }, // eventHanlder
  ];
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_POSTS_LIST: {
      const newposts = state.postsList.data.concat(action.payload.data).filter(item => !item.isBanned);
      const nextPosts = uniqueArray(newposts);
      return {
        ...state,
        postsList: {
          data: nextPosts,
          total: action.payload.totalPage
        }
      };
    }
    case CREATE_POST: {
      const newPosts = [action.payload.data];
      const nextPosts = newPosts.concat(state.postsList.data);
      return {
        ...state,
        postsList: {
          data: nextPosts,
          totalPage: state.postsList.totalPage + 1
        }
      };
    }
    case UPDATE_POST: {
      const { id, content, attachments } = action.payload.data;
      const nextPosts = state.postsList.data.map(item => {
        if (item.id === id) {
          item.content = content;
          item.attachments = attachments;
        }
        return item;
      });
      return {
        ...state,
        postsList: {
          data: nextPosts,
        }
      };
    }
    case DELETE_POST: {
      const { postId } = action.payload;
      const nextPosts = state.postsList.data.filter(item => item.id !== postId);

      return {
        ...state,
        postsList: {
          data: nextPosts,
          totalPage: state.postsList.totalPage - 1
        }
      };
    }
    case REPLY_POST: {
      const { params, postId } = action.payload;
      const nextPosts = state.postsList.data.map(item => {
        if (item.id === postId) {
          if (!item.replies) {
            const replies = {
              posts: [params],
              total: 1
            };
            return { ...item, replies };
          } else {
            item.replies.posts.push(params);
            item.replies.total += 1;
          }
        }
        return item;
      });
      return {
        ...state,
        postsList: {
          data: nextPosts,
          total: state.postsList.data.totalPage
        }
      };
    }
    case UPDATE_REPLY_POST: {
      const { params, postId } = action.payload;
      const nextPosts = state.postsList.data.map(post => {
        post.replies && (post.replies.posts = post.replies.posts.map(item => {
          if (item.id === postId) {
            item = params;
          }
          return item;
        }));
        return post;
      });
      return {
        ...state,
        postsList: {
          data: nextPosts,
          total: state.postsList.data.totalPage
        }
      };
    }
    case DELETE_POST_REPLIES: {
      const { postId, replyTo } = action.payload;
      const nextPosts = state.postsList.data.map(item => {
        if (item.id === replyTo) {
          item.replies.posts = item.replies.posts.filter(item => item.id !== postId);
          item.replies.total -= 1;
        }
        return item;
      });
      return {
        ...state,
        postsList: {
          data: nextPosts,
          total: state.postsList.data.totalPage
        }
      };
    }
    case GET_POST_REPLIES: {
      const { params: { replies: { posts } }, postId } = action.payload;
      const nextPosts = state.postsList.data.map(item => {
        if (item.id === postId) {
          item.replies.posts = posts.concat(item.replies.posts);
        }
        return item;
      });
      return {
        ...state,
        postsList: {
          data: nextPosts,
          total: state.postsList.data.totalPage
        }
      };
    }
    case CLEAR_POSTS_LIST: {
      return {
        ...state,
        postsList: {
          ...initState.postsList
        }
      };
    }
    case LIKE_POST: {
      const { data } = action.payload;
      const nextPosts = state.postsList.data.map(item => {
        if (item.id === data.id) {
          return {
            ...item,
            likedUsers: data.likedUsers
          };
        } else {
          if (item.replies) {
            const newRepliesPosts = item.replies.posts.map(post => {
              if (post.id === data.id) {
                post.likedUsers = data.likedUsers;
              }
              return post;
            });
            return {
              ...item,
              replies: {
                ...item.replies,
                post: newRepliesPosts
              }
            };
          }
        }
        return item;
      });

      return {
        ...state,
        postsList: {
          data: nextPosts,
        }
      };
    }
    case UNDO_LIKE_POST: {
      const { data } = action.payload;
      const nextPosts = state.postsList.data.map(item => {
        if (item.id === data.id) {
          return {
            ...item,
            likedUsers: data.likedUsers
          };
        } else {
          if (item.replies) {
            const newRepliesPosts = item.replies.posts.map(post => {
              if (post.id === data.id) {
                post.likedUsers = data.likedUsers;
              }
              return post;
            });
            return {
              ...item,
              replies: {
                ...item.replies,
                post: newRepliesPosts
              }
            };
          }
        }
        return item;
      });
      return {
        ...state,
        postsList: {
          data: nextPosts,
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;
