import { stringify } from 'query-string';
import { Api } from 'services/api';

/**
 * 取得貼文列表
 */
export const getGroupPosts = classId => async params => {
  const { nowPage = 0, rowsPage, isCounseling } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  queryParams.isCounseling = isCounseling ? isCounseling : false;
  const querystring = stringify(queryParams);
  const response = await Api.get(`/groups/${classId}/posts?${querystring}`);
  return response;
};

/**
 *
 * @param {string} classId
 * @param {object} payload // title:string , content:string
 */
export const createGroupPost = classId => async params => {
  const { content, attachments, isCounseling } = params;
  const payload = {};
  if (content) payload.content = content;
  if (attachments && attachments.length > 0) payload.attachments = attachments;
  payload.isCounseling = isCounseling ? isCounseling : false;
  const response = await Api.post(`/groups/${classId}/posts`, payload);
  return response;
};


export const updateGroupPost = (classId, postId) => async params => {
  const { content, attachments } = params;
  const payload = {};
  if (content) payload.content = content;
  if (attachments) payload.attachments = attachments;

  const response = await Api.put(`/groups/${classId}/posts/${postId}`, payload);
  return response;
};

export const deleteGroupPost = classId => async params => {
  const { id: postId } = params;

  const response = await Api.delete(`/groups/${classId}/posts/${postId}`);
  return response;
};


export const banGroupPost = classId => async params => {
  const { id: postId } = params;

  const response = await Api.post(`/groups/${classId}/posts/${postId}/ban`);
  return response;
};


/**
 * Like Group Post
 * 貼文按讚
 */

export const likeGroupPost = async (classId, postId) => {
  const response = await Api.post(`/groups/${classId}/posts/${postId}/likes`);
  return response;
};

/**
 * Undo Like Group Post
 * 貼文取消讚
 */

export const undoLikeGroupPost = async (classId, postId) => {
  const response = await Api.delete(`/groups/${classId}/posts/${postId}/likes`);
  return response;
};

/**
 * 回覆貼文
 */
export const replyGroupPost = (classId, postId) => async payload => {
  const response = await Api.post(`/groups/${classId}/posts/${postId}/replies`, payload);
  return response;
};

/**
 * Get Group Post Replies
 * 取得貼文回覆
 */

export const getGroupPostReplies = (classId, postId) => async payload => {
  const { pageLimit, pageSkip } = payload;
  const queryParams = {};
  if (pageLimit) {
    queryParams.limit = pageLimit;
  }
  if (pageSkip) {
    queryParams.skip = pageSkip;
  }
  const querystring = stringify(queryParams);

  const response = await Api.get(`/groups/${classId}/posts/${postId}/replies?${querystring}`);
  return response;
};
