import { QUERY, DEVICE } from 'constants/dimension';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useResponsive } from 'utils/hooks/useResponsive';
import { UiDashBoardCard } from './DashBoardCard.style';

/**
 * 班級課程卡牌
 */

export const DashBoardCard = ({
  className,
  onClick,
  disabled = false,
  src,
  title,
  groupName,
  sessionInterval,
  isActive,
  hostName,
  defaultImg,
}) => {
  const isDesktop = useResponsive({ query: QUERY.UP, start: DEVICE.TABLET.WIDTH_L });
  const isTabletS = useResponsive({ query: QUERY.DOWN, start: DEVICE.TABLET.WIDTH_S });
  const isMobileL = useResponsive({ query: QUERY.DOWN, start: DEVICE.MOBILE.WIDTH_L });
  const [isSrc, setIsSrc] = useState(false);
  useEffect(() => {
    (async () => {
      await fetch(src)
        .then((response) => {
          if (response.status === 404) {
            setIsSrc(false);
          } else {
            setIsSrc(true);
          }
        })
        .catch(() => {
          setIsSrc(false);
        });
    })();
  }, [setIsSrc]);

  const onClickHandle = () => {
    disabled || (onClick && onClick());
  };
  return (
    <UiDashBoardCard
      className={className}
      onClick={onClickHandle}
      tabIndex={0}
      isDesktop={isDesktop}
      isTabletS={isTabletS}
      isMobileL={isMobileL}
    >
      {isDesktop && (
        <div className="image">
          <img src={isSrc ? src : defaultImg} alt="session" data-testid="Img" />
        </div>
      )}
      <div className="info">
        <div className="info_item">
          <div className="title">{title}</div>
          <div className="badge">{groupName}</div>
        </div>
        <div className="info_item">
          <div className="time">
            {sessionInterval}
            {isActive || <span>已結束</span>}
          </div>
          <div className="name">{hostName} 老師</div>
        </div>
      </div>
    </UiDashBoardCard>
  );
};

DashBoardCard.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  src: PropTypes.any,
  title: PropTypes.string,
  groupName: PropTypes.string,
  sessionInterval: PropTypes.string,
  isActive: PropTypes.bool,
  hostName: PropTypes.string,
  defaultImg: PropTypes.any,
};
