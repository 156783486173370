import { Icon, Button } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { usePage } from 'utils/hooks/usePage';
import {
  UiHomeMemberProfileCardRead,
  UiHomeMemberProfileLabelGroup,
  UiHomeMemberProfileLabel,
  UiMatAvatar,
  UiTip,
} from './HomeMemberProfileCardRead.style';

/**
 * 會員資料卡牌
 */

export const HomeMemberProfileCardRead = ({ data = {}, changeCardType = () => {}, refreshUserData = () => {} }) => {
  const history = useHistory();
  const { pagePath } = usePage();
  const genderOptions = {
    male: '男',
    female: '女',
    other: '其他',
  };
  return (
    <UiHomeMemberProfileCardRead>
      {data.isTeacher && (
        <UiHomeMemberProfileLabelGroup>
          <Icon name="done" />
          <span>認證教師</span>
        </UiHomeMemberProfileLabelGroup>
      )}
      {data.mobileNumber && (
        <UiHomeMemberProfileLabelGroup>
          <UiHomeMemberProfileLabel>手機電話:</UiHomeMemberProfileLabel>
          <UiHomeMemberProfileLabel>{data.mobileNumber}</UiHomeMemberProfileLabel>
        </UiHomeMemberProfileLabelGroup>
      )}
      {data.phoneNumber && (
        <UiHomeMemberProfileLabelGroup>
          <UiHomeMemberProfileLabel>電話號碼:</UiHomeMemberProfileLabel>
          <UiHomeMemberProfileLabel>{data.phoneNumber}</UiHomeMemberProfileLabel>
        </UiHomeMemberProfileLabelGroup>
      )}
      {data.email && (
        <UiHomeMemberProfileLabelGroup>
          <UiHomeMemberProfileLabel>電子信箱:</UiHomeMemberProfileLabel>
          <UiHomeMemberProfileLabel>{data.email}</UiHomeMemberProfileLabel>
        </UiHomeMemberProfileLabelGroup>
      )}
      <UiHomeMemberProfileLabelGroup>
        <UiMatAvatar src={data.thumbnailUrl || ''} />
      </UiHomeMemberProfileLabelGroup>
      {data.nickname && (
        <UiHomeMemberProfileLabelGroup>
          <UiHomeMemberProfileLabel>暱稱:</UiHomeMemberProfileLabel>
          <UiHomeMemberProfileLabel>{data.nickname}</UiHomeMemberProfileLabel>
        </UiHomeMemberProfileLabelGroup>
      )}
      {data.lastName && data.firstName && (
        <UiHomeMemberProfileLabelGroup>
          <UiHomeMemberProfileLabel>姓名:</UiHomeMemberProfileLabel>
          <UiHomeMemberProfileLabel>
            <span>{data.lastName}</span>
            <span>{data.firstName}</span>
          </UiHomeMemberProfileLabel>
        </UiHomeMemberProfileLabelGroup>
      )}
      {data.gender && (
        <UiHomeMemberProfileLabelGroup>
          <UiHomeMemberProfileLabel>性別:</UiHomeMemberProfileLabel>
          <UiHomeMemberProfileLabel>{genderOptions[data.gender]}</UiHomeMemberProfileLabel>
        </UiHomeMemberProfileLabelGroup>
      )}
      {data.birthday && (
        <UiHomeMemberProfileLabelGroup>
          <UiHomeMemberProfileLabel>生日:</UiHomeMemberProfileLabel>
          <UiHomeMemberProfileLabel>
            {data.birthday.substr(0, 4) + '-' + data.birthday.substr(4, 2) + '-' + data.birthday.substr(6, 2)}
          </UiHomeMemberProfileLabel>
        </UiHomeMemberProfileLabelGroup>
      )}
      {data.phone && (
        <UiHomeMemberProfileLabelGroup>
          <UiHomeMemberProfileLabel>連絡電話:</UiHomeMemberProfileLabel>
          <UiHomeMemberProfileLabel>{data.phone}</UiHomeMemberProfileLabel>
        </UiHomeMemberProfileLabelGroup>
      )}
      {data.address && (
        <UiHomeMemberProfileLabelGroup>
          <UiHomeMemberProfileLabel>連絡地址:</UiHomeMemberProfileLabel>
          <UiHomeMemberProfileLabel>
            <div>
              <span>{data.addressCountyName}</span>
              <span>{data.addressDistrictName}</span>
            </div>
            {data.address}
          </UiHomeMemberProfileLabel>
        </UiHomeMemberProfileLabelGroup>
      )}
      <UiTip>若發現您的資料與OneClass不同，請點擊下方按鈕同步資料。</UiTip>
      <UiHomeMemberProfileLabelGroup>
        <Button buttonColor="new" onClick={() => history.push(pagePath.memberCenter.changePassword)}>
          修改密碼
        </Button>
        <Button
          buttonColor="new"
          onClick={() => {
            changeCardType('edit');
          }}
        >
          修改資料
        </Button>
        <Button buttonColor="visibility" onClick={refreshUserData}>
          同步資料
        </Button>
      </UiHomeMemberProfileLabelGroup>
    </UiHomeMemberProfileCardRead>
  );
};

HomeMemberProfileCardRead.propTypes = {
  data: PropTypes.shape({}),
  changeCardType: PropTypes.func,
  refreshUserData: PropTypes.func,
};
