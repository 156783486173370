import { HomeGroupLayout, HomeVideoHistoryTable } from 'components';
import React from 'react';


/**
 * 查看學生觀看紀錄頁面
 */

export const HomeVideoHistoryTablePage = () => {
  return (
    <HomeGroupLayout>
      <HomeVideoHistoryTable />
    </HomeGroupLayout>
  );
};
