import organizationDefaultImage from 'assets/images/organization.png';
import PropTypes from 'prop-types';
import React from 'react';
import { UiPictureBox } from './PictureBox.style';
/**
 * 相簿,相片圖片組件
 */

export const PictureBox = ({ path = organizationDefaultImage, id = '' }) => {
  return (
    <UiPictureBox path={path === '' ? organizationDefaultImage : path} />
  );
};

PictureBox.propTypes = {
  path: PropTypes.string,
  id: PropTypes.string,
};


