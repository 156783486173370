import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Icon,
  ClickablePopoverMenu,
  Modal,
  CheckboxGroup
} from 'components';
import { format, getTime } from 'date-fns';
import linkifyHtml from 'linkifyjs/html';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiAnnouncementCardRead,
  UiContentTitle,
  UiContent,
  UiCreateWeek,
  UiCreateDate,
  UiCreateDateBox,
  UiContentBox,
  UiCardHeader,
  UiCardContent,
  UiCreateDateAll,
  UiCardFooter,
  UiCardHeaderRow,
  UiCardFooterRow,
  UiCardFooterBox,
  UiCardFooterBoxTitle,
  UiCardFooterFileBox,
  UiCardFooterFileBoxLabel,
  UiCardFooterFileBoxIcon,
  UiCardType,
  UiCheckBlock,
  UiRolesGroup
} from './AnnouncementCardRead.style';


const useStyles = makeStyles({
  download: {
    fontSize: '1.2rem !important',
    color: '#454b5c !important',
    cursor: 'pointer',
  }
});

const ROLES = {
  groupOwner: '導師',
  staff: '教師',
  student: '學生',
  parent: '家長'
};

export const AnnouncementCardRead = ({
  data = {},
  onDelete = () => { },
  onPin = () => { },
  cancelPin = () => { },
  onCheckListChange = () => { },
  isPinned = false,
  isCanUse
}) => {
  const {
    id: Announcementid, week, day, months, year, title, nickname, clock, groupName,
    checkList,
    content,
    fileName,
    fileUrl,
    publishedAt,
    isSchedule,
    isStaff,
    audiences
  } = data;

  const classes = useStyles();

  const [{ isModalOpen, editStatus,
    goods,
    goodsList,
    goodsLength,
    checkFlag,
    checkDelay,
    // firstHref,
  }, setState] = useSetState({
    isModalOpen: false,
    editStatus: null,
    goods: [],
    goodsList: [],
    goodsLength: 0,
    checkFlag: true,
    checkDelay: 3,
    // firstHref: '',
  });
  const [{ profile }] = useUser();
  const history = useHistory();
  const { organizationId, classId } = useParams();

  const setEditStatus = status => () => {
    if (status === EDIT_STATUS.EDIT) {
      history.push(!classId ? `/home/${organizationId}/announcement/${Announcementid}/${EDIT_STATUS.EDIT}`
        : organizationId ?
          `/home/${organizationId}/class/${classId}/announcement/${Announcementid}/${EDIT_STATUS.EDIT}` :
          `/home/class/${classId}/announcement/${Announcementid}/${EDIT_STATUS.EDIT}`
      );
    }
    setState({ editStatus: status, isModalOpen: status !== EDIT_STATUS.EDIT });
  };
  const EDIT_STATUS = {
    ALIGN_TOP: 'verticalAlignTop',
    DELETE: 'delete',
    EDIT: 'edit'
  };
  const popoverMenuList = [
    {
      label: isPinned ? '取消置頂' : '置頂',
      func: setEditStatus(EDIT_STATUS.ALIGN_TOP),
      iconName: 'Thumbtack',
      isSvg: true,
      isShow: isCanUse
    },
    { label: '刪除', func: setEditStatus(EDIT_STATUS.DELETE), iconName: 'delete', isShow: isCanUse },

    { label: '編輯', func: setEditStatus(EDIT_STATUS.EDIT), iconName: 'edit', isShow: isCanUse }
  ];
  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };

  const deleteModalConfig = {
    text: '是否要將此項公告刪除？',
    confirmAction: () => onDelete(data)
  };

  const pinModalConfig = {
    text: '是否要將此項公告置頂？',
    confirmAction: () => onPin(data)
  };

  const cancelPinModalConfig = {
    text: '是否要將此項公告取消置頂？',
    confirmAction: () => cancelPin(data)
  };

  const ModalConfig =
    editStatus === EDIT_STATUS.DELETE ? deleteModalConfig : (isPinned ? cancelPinModalConfig : pinModalConfig);
  const buttons = [
    {
      text: '確認',
      func: ModalConfig.confirmAction
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  const roleTags = () => {
    if (classId) return null;
    if (audiences?.length >= 3) {
      return (
        <div className="tag">
          所有人
        </div>
      );
    } else {
      return audiences ? audiences.map((audience, index) => {
        return (
          <div className="tag" key={index}>
            {ROLES[audience]}
          </div>
        );
      }) : null;
    }
  };

  useEffect(() => {
    if (!checkList) return;
    const { id } = profile;//用戶ID
    let goodsLength = checkList.length;
    let checkedValue = [];
    let goodsList = [];
    checkList.forEach((item, index) => {
      let obj = {};
      obj['value'] = item.id;
      obj['label'] = item.dueAt ?
        <div>{item.description}
          <span className='active'> ~ {format(new Date(item.dueAt), 'yyyy-MM-dd')}</span>
        </div>
        : <div>{item.description}</div>;
      obj['disabled'] = (item.dueAt && (getTime(new Date(item.dueAt)) < getTime(new Date()))) ? true : false;
      goodsLength = (item.dueAt && (getTime(new Date(item.dueAt)) < getTime(new Date()))) ?
        goodsLength - 1 :
        goodsLength;
      item.checkUsers.length > 0 && item.checkUsers.forEach((val) => {
        if (val === id) {
          checkedValue.push(item.id);
        }
      });
      goodsList.push(obj);
    });
    setState({
      goodsList,
      goodsLength,
      goods: checkedValue
    });
  }, []);
  const changeHandler = async (val, itemVal, type) => {
    if (!checkFlag) return;
    setState({
      checkFlag: false
    });
    onCheckListChange(val, itemVal, type, Announcementid);
    setState({
      goods: val
    });
    let _cd = checkDelay;
    let clock = setInterval(() => {
      _cd -= 1;
      if (_cd <= 0) {
        clearInterval(clock);
        setState({
          checkFlag: true
        });
      }
    }, 1000);
  };

  const downloadFile = async (u, name) => {
    const fileBlob = await fetch(u).then(res => res.blob());
    const url = window.URL.createObjectURL(new Blob([fileBlob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };

  const AnnouncementHeader = (
    <>
      <UiCardHeader>
        <UiCardHeaderRow>
          <UiCreateDateBox>
            <UiCreateWeek>{week}</UiCreateWeek>
            <UiCreateDateAll>
              <UiCreateDate>{months}-{day}</UiCreateDate>
              <UiCreateDate>{year}</UiCreateDate>
            </UiCreateDateAll>
          </UiCreateDateBox>
          <UiContentBox>
            <UiContentTitle>
              {title}
              {
                isPinned && (
                  <Icon.Svg haveBg={false} name="Thumbtack" color="#EC7963" />
                )
              }
            </UiContentTitle>
            <UiContent>{nickname} {year}年 {months}月{day}日 {week} {clock}</UiContent>
            <UiContent>{groupName}</UiContent>
          </UiContentBox>
        </UiCardHeaderRow>
        <UiCardHeaderRow>
          <UiRolesGroup>
            {roleTags()}
          </UiRolesGroup>
          {!publishedAt &&
            <UiCardType type='draft'>草稿</UiCardType>
          }
          {isSchedule &&
            <UiCardType>排程</UiCardType>
          }
          {isCanUse &&
            <ClickablePopoverMenu menuList={popoverMenuList}>
              <Icon name="moreHoriz" icolor="#242C3F" haveBg={false} className={classes.root} onClick={() => { }} />
            </ClickablePopoverMenu>
          }
        </UiCardHeaderRow>
      </UiCardHeader>
    </>
  );

  const handleKeyDown = (event, fileUrl, fileName) => {
    if (event.key === 'Enter') {
      downloadFile(fileUrl, fileName);
    }
  };

  const AnnouncementFooter = (
    <>
      <UiCardFooter>
        <UiCardFooterRow>
          {goodsList.length > 0 &&
            <UiCardFooterBox>
              {(isStaff || !checkFlag) &&
                <UiCheckBlock></UiCheckBlock>
              }
              <UiCardFooterBoxTitle>提醒事項餘{goodsLength - goods.length}個</UiCardFooterBoxTitle>
              <CheckboxGroup type={'checkList'} data={goodsList} onChange={changeHandler} defaultValue={goods} />
            </UiCardFooterBox>
          }
          {fileName &&
            <UiCardFooterFileBox
              tabIndex={0}
              aria-label='下載檔案'
              onClick={() => downloadFile(fileUrl, fileName)}
              onKeyDown={(e) => handleKeyDown(e, fileUrl, fileName)} >
              <UiCardFooterFileBoxLabel>
                {fileName}
              </UiCardFooterFileBoxLabel>
              <UiCardFooterFileBoxIcon
              >
                <Icon.Svg
                  name='DownloadBtn'
                  haveBg={false}
                  className={classes.download}
                />
              </UiCardFooterFileBoxIcon>
            </UiCardFooterFileBox>
          }
        </UiCardFooterRow>
      </UiCardFooter>
    </>
  );
  return (
    <>
      {isCanUse ?
        <>
          <Card header={AnnouncementHeader} backgroundColor={'#fff'} footer={AnnouncementFooter}>
            <UiAnnouncementCardRead>
              <UiCardContent>
                <UiContent dangerouslySetInnerHTML={{ __html: linkifyHtml(content) }}>
                </UiContent>
              </UiCardContent>
            </UiAnnouncementCardRead>
          </Card>
          <Modal
            isOpen={isModalOpen}
            text={ModalConfig.text}
            buttons={buttons}
            getModalState={getModalStateHandler}
          />
        </>
        :
        (publishedAt || !isSchedule) &&
        <>
          <Card header={AnnouncementHeader} backgroundColor={'#fff'} footer={AnnouncementFooter}>
            <UiAnnouncementCardRead>
              <UiCardContent>
                <UiContent dangerouslySetInnerHTML={{ __html: content }}></UiContent>
              </UiCardContent>
            </UiAnnouncementCardRead>

          </Card>
          <Modal
            isOpen={isModalOpen}
            text={ModalConfig.text}
            buttons={buttons}
            getModalState={getModalStateHandler}
          />
        </>

      }

    </>
  );
};

AnnouncementCardRead.propTypes = {
  data: PropTypes.shape({
    week: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    from: PropTypes.string,
    content: PropTypes.string
  }),
  isOwner: PropTypes.bool,
  onDelete: PropTypes.func,
  onPin: PropTypes.func,
  cancelPin: PropTypes.func,
  onCheckListChange: PropTypes.func,
  isPinned: PropTypes.bool,
  isCanUse: PropTypes.bool
};


