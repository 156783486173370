import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import styled from 'styled-components';

export const UiListItem = styled(ListItem)(({ theme, isActive }) => ({
  background: isActive ? '#FEF2F0' : 'unset',
  minWidth: '200px',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
  '& svg': {
    color: isActive ? '#EC7963' : '#8b90a0',
  },
  '&.second': {
    background: 'unset',
  },
  '&:hover': {
    background: '#FEF2F0',
  },
}));

export const UiListItemIcon = styled(ListItemIcon)(({ theme, isActive }) => ({
  minWidth: 'unset',
  marginRight: theme.spacing(2),
  '&.second': {
    '& > svg': {
      width: isActive ? theme.spacing(1) : theme.spacing(0.75),
      height: isActive ? theme.spacing(1) : theme.spacing(0.75),
    },
  },
}));

export const UiListItemText = styled(ListItemText)(({ isActive }) => ({
  '& > .MuiTypography-root': {
    color: isActive ? '#EC7963' : '#8b90a0',
    fontFamily: isActive ? 'Noto Sans CJK TC' : 'Noto Sans TC',
    fontWeight: isActive ? 500 : 400,
  },
  '&.second': {
    '& > .MuiTypography-root': {
      color: isActive ? '#242C3F' : '#8b90a0',
    },
  },
}));
