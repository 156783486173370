import { FormHelperText as MatFormHelperText } from '@material-ui/core';

import { TextField as MatTextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useSetState } from 'utils/hooks/useSetState';
import {
  UiNameInput,
  UiInput,
  UiHelperText,

} from './NameInput.style';

/**
 * 姓名input
 */

export const NameInput = ({
  onChange,
  helperText,
  error = true,
  defaultFirstName,
  defaultLastName,
  disabled = false,
  value,
  required = true,
  variant = 'filled',//'outlined',
}) => {
  const [{ firstName, lastName }, setState] = useSetState({
    firstName: defaultFirstName || '',
    lastName: defaultLastName || ''
  });
  useEffect(() => {
    if (
      !value ||
      Object.keys(value).length === 0 ||
      value.firstName === '' ||
      value.lastName === '') return;
    const { firstName, lastName } = value;
    setState({
      firstName,
      lastName,
    });
  }, [value]);

  useEffect(() => {
    onChange({ firstName, lastName });
  }, [firstName, lastName]);
  return (
    <UiNameInput>
      <UiInput>
        <MatTextField
          disabled={disabled}
          error={error}
          label='姓'
          placeholder='請輸入姓'
          helperText={helperText}
          value={lastName}
          variant={variant}
          onChange={(event) => {
            event && setState({ lastName: event.target.value });
          }}
        />
        {
          (required && !lastName && firstName) &&
          <UiHelperText>
            <MatFormHelperText>本欄位為必填</MatFormHelperText>
          </UiHelperText>
        }

      </UiInput>

      <UiInput>
        <MatTextField
          disabled={disabled}
          error={error}
          label='名'
          placeholder='請輸入名'
          helperText={helperText}
          value={firstName}
          variant={variant}
          onChange={(event) => {
            event && setState({ firstName: event.target.value });
          }}
        />
        {
          (required && !firstName && lastName) &&
          <UiHelperText>
            <MatFormHelperText>本欄位為必填</MatFormHelperText>
          </UiHelperText>
        }
      </UiInput>

    </UiNameInput>
  );
};

NameInput.propTypes = {
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  defaultFirstName: PropTypes.string,
  defaultLastName: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
};


