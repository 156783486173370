import { OrganizationTable } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { UiOrganization } from './Organization.style';


/**
 * 在這邊描述這個組件
 */

export const Organization = () => (
  <UiOrganization>
    <OrganizationTable />
  </UiOrganization>
);


Organization.propTypes = {
  children: PropTypes.node,
};


