import { FormHelperText as MatFormHelperText } from '@material-ui/core';
import { MemberCenterCard, Button } from 'components';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from 'store/user';
import { usePage } from 'utils/hooks/usePage';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiHomeMemberChangePassword,
  UiHomeMemberChangePasswordCard,
  UiForm,
  MatTextField,
  UiButtonWrapper,
  UiHelperText,
} from './HomeMemberChangePassword.style';

/**
 * 修改密碼
 */
export const HomeMemberChangePassword = () => {
  const history = useHistory();
  const { pagePath } = usePage();
  const [, { changeUserPassword, userLogout }] = useUser();
  const [{ isDisabled, isLoading, passwordError, oldPassword, newPassword, confirmPassword }, setState] = useSetState({
    isDisabled: true,
    isLoading: false,
    passwordError: 'nothing',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const submitHandler = async () => {
    if (isDisabled || !checkPassword()) return;
    setState({
      isLoading: true,
      isDisabled: true,
    });
    const params = {
      oldpassword: oldPassword,
      newpassword: newPassword,
    };
    const isSuccess = await changeUserPassword(params);
    if (isSuccess) {
      await userLogout();
    } else {
      setState({
        isLoading: false,
        passwordError: 'oldPasswordError',
      });
    }
  };
  const checkPassword = () => {
    if (newPassword !== confirmPassword) {
      setState({
        passwordError: 'passwordIsNotSame',
      });
      return false;
    }
    return true;
  };

  const inputChangeHandler = (val, type) => {
    setState({
      [type]: val.replace(/[^\w/]/gi, ''),
    });
  };

  useEffect(() => {
    if ((oldPassword + newPassword + confirmPassword).length >= 24) {
      setState({
        isDisabled: false,
      });
    } else {
      setState({
        isDisabled: true,
      });
    }
    setState({
      passwordError: 'nothing',
    });
  }, [oldPassword, newPassword, confirmPassword]);

  return (
    <UiHomeMemberChangePassword>
      <MemberCenterCard title="修改密碼">
        <UiHomeMemberChangePasswordCard>
          <UiForm>
            {passwordError === 'oldPasswordError' && (
              <UiHelperText>
                <MatFormHelperText>您輸入的舊密碼不正確，請再次確認修改</MatFormHelperText>
              </UiHelperText>
            )}

            <MatTextField
              id="password1"
              variant="outlined"
              inputProps={{ maxLength: 24 }}
              type="password"
              fullWidth={true}
              label="舊密碼"
              value={oldPassword}
              placeholder="八位數以上含英文字母,大小寫視為不同"
              onChange={(e) => inputChangeHandler(e.target.value, 'oldPassword')}
            />
            <MatTextField
              id="password2"
              variant="outlined"
              inputProps={{ maxLength: 24 }}
              type="password"
              fullWidth={true}
              value={newPassword}
              label="新密碼"
              placeholder="八位數以上含英文字母,大小寫視為不同"
              onChange={(e) => inputChangeHandler(e.target.value, 'newPassword')}
            />
            <MatTextField
              id="password3"
              variant="outlined"
              inputProps={{ maxLength: 24 }}
              label="確認新密碼"
              type="password"
              fullWidth={true}
              value={confirmPassword}
              placeholder="八位數以上含英文字母,大小寫視為不同"
              onChange={(e) => inputChangeHandler(e.target.value, 'confirmPassword')}
            />
            {passwordError === 'passwordIsNotSame' && (
              <UiHelperText>
                <MatFormHelperText>密碼不一致，請重新輸入</MatFormHelperText>
              </UiHelperText>
            )}
            <UiButtonWrapper>
              <Button buttonColor="cancel" onClick={() => history.push(pagePath.memberCenter.profile)}>
                取消
              </Button>
              <Button
                disabled={isDisabled}
                loading={isLoading}
                onClick={() => {
                  submitHandler();
                }}
              >
                修改密碼
              </Button>
            </UiButtonWrapper>
          </UiForm>

          {/* <Form
            schema={schema}
            onSubmit={submitHandler}
            buttons={buttons}
            onFormChange={onFormChangeHandler}
          /> */}
        </UiHomeMemberChangePasswordCard>
      </MemberCenterCard>
    </UiHomeMemberChangePassword>
  );
};

HomeMemberChangePassword.propTypes = {};
