import { TextField as MatTextField } from '@material-ui/core';
import { BsModal, DateTimePicker } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSetState } from 'utils/hooks/useSetState';
import { UiOnlineExamModal } from './OnlineExamModal.style';


/**
 * 線上派卷 我要派卷 彈窗
 */

export const OnlineExamModal = ({
  open,
  examPaperId,
  onClick,
  onCancel,
  onChange,
}) => {
  const [{
    answerTime,
    startTime,
    endTime,
  }, setState] = useSetState({
    answerTime: null,
    startTime: null,
    endTime: null,
  });

  const onAnswerTimeHandle = time => {
    setState({
      answerTime: time,
    });
  };

  const onStartTimeHandler = timestamp => {
    setState({ startTime: timestamp });
  };

  const onEndTimeHandler = timestamp => {
    if ((timestamp - startTime) < answerTime * 60 * 1000) return;
    setState({ endTime: timestamp });
  };

  useEffect(() => {
    if (!answerTime || !startTime) {
      setState({ endTime: null });
    }
  }, [answerTime, startTime]);

  useEffect(() => {
    const modalObj = {
      examPaperId,
      startAt: startTime,
      endAt: endTime,
      examPeriod: parseInt(answerTime),
    };

    onChange(modalObj);
  }, [endTime]);

  const onCancelHandle = () => {
    onCancel();
    setState({
      answerTime: null,
      startTime: null,
      endTime: null,
    });
  };

  const onOkHandle = () => {
    onClick();
    setState({
      answerTime: null,
      startTime: null,
      endTime: null,
    });
  };

  return (
    <UiOnlineExamModal>
      <BsModal
        open={open}
        title="我要派卷"
        okText="派卷"
        onOk={onOkHandle}
        onCancel={onCancelHandle}
        okDisabled={!endTime}
      >
        <MatTextField
          label="作答時間(分鐘)*"
          variant="outlined"
          style={{ width: '70%', margin: '10px 0' }}
          value={answerTime}
          onChange={e => onAnswerTimeHandle(e.target.value)}
        />
        <DateTimePicker
          label="開始時間*"
          style={{ width: '70%', margin: '10px 0' }}
          value={startTime}
          onChange={onStartTimeHandler}
        />
        {
          (answerTime && startTime) && <DateTimePicker
            label="結束時間*"
            style={{ width: '70%', margin: '10px 0' }}
            value={endTime}
            onChange={onEndTimeHandler}
          />
        }
      </BsModal>
    </UiOnlineExamModal>
  );
};

OnlineExamModal.propTypes = {
  open: PropTypes.bool,
  examPaperId: PropTypes.string,
  onClick: PropTypes.func,
  onCancel: PropTypes.func,
};


