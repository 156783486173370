import { Button } from 'components';
import { Icon } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { isCompany } from 'utils/validation';
import { UiOrganizationContainer, UiHead, UiGroupsBox } from './OrganizationContainer.style';

const ONE_LINK_OMS_URL = isCompany ? process.env.REACT_APP_ONELINK_OMS_DOMAIN : process.env.REACT_APP_URL_BACKSTAGE;

/**
 * 組織集合
 */
export const OrganizationContainer = ({ organizationId, name, isOwner, children, punchClockSetting }) => {
  const history = useHistory();
  const goMyOrganization = () => {
    history.push(`/home/${organizationId}/announcement`);
  };
  const goOrg = () => {
    window.location.href = `${ONE_LINK_OMS_URL}/home?organizationId=${organizationId}`;
  };

  const goAttendance = () => {
    history.push(`/home/${organizationId}/attendance`);
  };

  return (
    <UiOrganizationContainer>
      <UiHead>
        <span tabIndex={0}>{name}</span>
        {punchClockSetting === 'enabled' && (
          <Button buttonColor="ui03" textColor="#242C3F" onClick={goAttendance}>
            <Icon.Svg name="UserClock" />
            打卡紀錄
          </Button>
        )}
        {isOwner && (
          <Button buttonColor="ui03" textColor="#242C3F" onClick={goOrg}>
            後台管理
          </Button>
        )}
        <Button buttonColor="new" onClick={goMyOrganization}>
          機構公告
        </Button>
      </UiHead>
      <UiGroupsBox>{children}</UiGroupsBox>
    </UiOrganizationContainer>
  );
};

OrganizationContainer.propTypes = {
  organizationId: PropTypes.string,
  name: PropTypes.string,
  isOwner: PropTypes.bool,
  children: PropTypes.node,
};
