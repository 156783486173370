import styled from 'styled-components';

export const UiExamIcons = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;

  > div {
    font-size: 2rem;
    cursor: pointer;

    > svg {
      width: auto;
      height: auto;
    }
  }
`;
