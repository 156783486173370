import Tab from '@material-ui/core/Tab';
import TabList from '@material-ui/lab/TabList';
import styled from 'styled-components';

export const UiTabList = styled(TabList)(() => ({
  borderBottom: '1px solid #D5D7DE',
  '& .MuiTabs-indicator': {
    backgroundColor: '#EC7963',
  },
}));

export const UiTab = styled(Tab)(() => ({
  '&.Mui-selected': {
    color: '#EC7963',
  },
}));
