import { AnnouncementCardStatus } from 'constants/index';
import { AnnouncementCardRead } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { UiAnnouncementCard } from './AnnouncementCard.style';
/**
 * 公告卡牌
 */

export const AnnouncementCard = ({
  type = 'read',
  isStaff = false,
  onDelete = () => { },
  onPin = () => { },
  cancelPin = () => { },
  onCheckListChange = () => { },
  data,
  isPinned = false,
  isCanUse
}) => {
  return (
    <UiAnnouncementCard>
      {
        type === AnnouncementCardStatus.READ ? (
          <AnnouncementCardRead
            isPinned={isPinned}
            data={data}
            isStaff={isStaff}
            onDelete={onDelete}
            onPin={onPin}
            cancelPin={cancelPin}
            onCheckListChange={onCheckListChange}
            isCanUse={isCanUse}
          />
        ) : (
          ''
        )
      }
    </UiAnnouncementCard>
  );
};

AnnouncementCard.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  isStaff: PropTypes.bool,
  onDelete: PropTypes.func,
  onPin: PropTypes.func,
  cancelPin: PropTypes.func,
  onCheckListChange: PropTypes.func,
  isPinned: PropTypes.bool,
  isCanUse: PropTypes.bool,
};


