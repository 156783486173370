import styled from 'styled-components';

export const UiScreenRecord = styled.div`
  display: flex;
  padding: 20px 0;
  gap: 24px;
  flex-wrap: wrap;
`;

export const UiRecordCardBox = styled.div`
  display: flex;
`;
export const UiRecordCard = styled.div`
  display: flex;
  align-items: center;
  padding: 1.3rem 0;
  padding-left: 1rem;
  /* width: 30%; */
  background-color: #fafafc;
  border-radius: 8px;
  flex: 0.3;
  min-width: 327px;
`;
export const UiRecordBox = styled.div`
  padding: 1rem 1.75rem;
  border-radius: 8px;
`;
export const UiRecordIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: #f9c74f;
  border-radius: 20px;

  > svg {
    font-size: 2.5rem;
  }
`;
export const UiRecordText = styled.div`
  font-size: 20px;
  color: #4e5d78;
`;
export const UiRecordNumber = styled.div`
  font-size: 40px;
  font-weight: 700;

  > span {
    font-size: 25px;
  }
`;
export const UiRecordSession = styled.div`
  color: #8b90a0;
`;
export const UiRecordTitle = styled.div`
  margin: 66px 0 48px;
  font-size: 25px;
  font-weight: 700;
`;

export const UiRecordUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
  width: 100%;
`;
export const UiRecordUserText = styled.div`
  margin-right: 20px;
`;
export const UiRecordUserIcon = styled.div`
  margin-right: 10px;
  width: 16px;
  height: 16px;
  background-color: #ec7963;
  border-radius: 50%;
`;
export const UiRecordTable = styled.div`
  border: 1px solid #e4e7ec;
  border-radius: 1.25rem;
  padding: 1.5rem;
  flex: 1;
  min-width: 327px;
`;
export const UiDetailTitle = styled.h5`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #8b90a0;
`;

export const UiDetailButton = styled.button`
  color: #377bff;
  text-decoration: underline;
`;
