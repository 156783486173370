import { Link } from '@material-ui/core';
import styled from 'styled-components';

export const UiWebResourceContainer = styled.div`
  display: block;
`;
export const UiWebResourceBox = styled.div`
  margin: 1.5rem 0;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.875rem;
`;
export const UiWebResourceBoxTitle = styled.div`
  display: flex;
  align-items: center;
  h3 {
    font-size: 1.125rem;
    font-weight: 400;
    margin-left: 0.625rem;
  }
`;
export const UiWebResourceBoxContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
`;
export const UiWebResourceBoxItem = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 320px;
  min-width: 220px;
  text-decoration: none !important;
  &:hover {
    > div {
      background-color: #fbe4e0;
    }
  }
`;
export const UiWebResourceBoxCol = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 1rem 0.75rem 0;
  padding: 22px 20px;
  border-radius: 15px;
  background-color: #fafafc;
  width: 100%;
  div {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #f5bbb0;
    color: #fff;
  }
  & > p {
    flex: 1;
    font-family: Noto Sans TC;
    font-size: 1rem;
    font-weight: 500;
    margin: 0 0 0 0.75rem;
    color: #242c3f;
    line-height: 1.5rem;
    letter-spacing: 0.046875rem;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`;
export const UiPrompt = styled.div`
  color: #8b90a0;
  font-weight: 400;
  max-width: 90%;
`;
