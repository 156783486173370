import { stringify } from 'query-string';
import { Api } from 'services/api';

/**
 * 取得公告列表
 */
export const getGroupAnnouncements = (organizationId, classId) => async params => {
  const { nowPage = 0, rowsPage } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  const querystring = stringify(queryParams);
  const response = await Api.get(
    classId ? `/groups/${classId}/announcements?${querystring}`
      : `/organizations/${organizationId}/announcements?${querystring}`);
  return response;
};

export const createGroupAnnouncement = (organizationId, classId) => async params => {
  const { payload } = params;
  const response = await Api.post(classId ? `/groups/${classId}/announcements`
    : `/organizations/${organizationId}/announcements`, payload);
  return response;
};

export const pinGroupAnnouncement = (organizationId, classId, announcementId) => async params => {
  const response = await Api.post(classId ? `/groups/${classId}/announcements/${announcementId}/pin`
    : `/organizations/${organizationId}/announcements/${announcementId}/pin`);
  return response;
};

export const cancelPinGroupAnnouncement = (organizationId, classId, announcementId) => async params => {
  const response = await Api.delete(classId ? `/groups/${classId}/announcements/${announcementId}/pin`
    : `/organizations/${organizationId}/announcements/${announcementId}/pin`);
  return response;
};

export const deleteGroupAnnouncement = (organizationId, classId, announcementId) => async params => {
  const response = await Api.delete(classId ? `/groups/${classId}/announcements/${announcementId}`
    : `/organizations/${organizationId}/announcements/${announcementId}`);
  return response;
};


export const updateGroupAnnouncement = (organizationId, classId, announcementId) => async params => {
  const { payload } = params;

  const response = await Api.put(classId ? `/groups/${classId}/announcements/${announcementId}`
    : `/organizations/${organizationId}/announcements/${announcementId}`, payload);
  return response;
};


/**
 * Confirm Group Announcement Check List Item
 */

export const confirmGroupAnnouncementCheckListItem = async (
  organizationId,
  classId,
  announcementId,
  checkListItemId) => {
  const response =
    await Api.post(
      classId ? `/groups/${classId}/announcements/${announcementId}/check-list/${checkListItemId}/confirm`
        : `/organizations/${organizationId}/announcements/${announcementId}/check-list/${checkListItemId}/confirm`);
  return response;
};

/**
 * Unconfirm Group Announcement Check List Item
 */

export const unconfirmGroupAnnouncementCheckListItem = async (
  organizationId,
  classId,
  announcementId,
  checkListItemId) => {
  const response =
    await Api.post(
      classId ? `/groups/${classId}/announcements/${announcementId}/check-list/${checkListItemId}/unconfirm`
        : `/organizations/${organizationId}/announcements/${announcementId}/check-list/${checkListItemId}/unconfirm`);
  return response;
};

export const getGroupAnnouncementById = (organizationId, classId, announcementId) => async () => {
  const response = await Api.get(classId ? `/groups/${classId}/announcements/${announcementId}`
    : `/organizations/${organizationId}/announcements/${announcementId}`);
  return response;
};



