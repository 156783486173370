import {
	Divider as MatDivider,
	ListItem as MatListItem,
	ListItemIcon as MatListItemIcon
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const UiBsNavigator = styled.div`
	display: block;
`;

export const UiItem = styled(MatListItem)`
	padding-top: 1px;
	padding-bottom: 1px;
	background-color: ${({ theme }) => theme.palette.dark.block};

	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.palette.dark.blockhover};
	}

	&:disabled {
		color: #ddd;
	}
`;

export const UiItemIcon = styled(MatListItemIcon)`
	margin-right: ${({ theme }) => `${theme.spacing(2)}px`};
	min-width: auto;
`;

export const UiCategoryHeader = styled(MatListItem)`
	padding-top: ${({ theme }) => `${theme.spacing(1)}px`};
	padding-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
	color: ${({ theme }) => theme.palette.common.white};
	background-color: ${({ theme }) => theme.palette.dark.block};
`;


export const UiItemCategory = styled(UiItem)`
	padding-top: ${({ theme }) => `${theme.spacing(2)}px`};
	padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
	background-color: ${({ theme }) => theme.palette.dark.blocktitle};
	box-shadow: 0 -1px 0 #404854 inset;
`;


export const UiOfficialName = styled.span`
		font-size: 1.5rem;
		color: ${({ theme }) => theme.palette.common.white};

		& > select {
			font-size: 1.2rem;
			color: ${({ theme }) => theme.palette.common.white};
			background-color: transparent;
			border: 0;

			& > option {
				color: #555;
			}
		}
`;

export const UiLink = styled(NavLink)`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	text-decoration: none;
	color: rgba(255, 255, 255, 0.7);

	&.active {
		color: #4fc3f7;
	}
`;

export const UiDivider = styled(MatDivider)`
	margin-top: ${({ theme }) => `${theme.spacing(2)}px`};
`;
