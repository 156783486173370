import { Box } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useUser } from 'store/user';
import { decrypt } from 'utils/crypto';

const URL_SEARCH_PARAM = new URLSearchParams(window.location.search);
const TOKEN = URL_SEARCH_PARAM.get('token');

/* 解密 login token */
function decryptLoginToken(token) {
  const payload = decrypt(token);
  const parsedPayload = JSON.parse(payload);
  return parsedPayload;
}

/**
 * 透過 QRCode 登入頁
 */
export const QRCodeLoginPage = () => {
  const [, { userLogin }] = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const payload = decryptLoginToken(TOKEN);
  /* 登入 */
  const login = async (payload) => {
    setIsLoading(true);
    const { account, password } = payload;
    const formData = {
      username: account,
      password,
    };
    await userLogin(formData);
    setIsLoading(false);
  };
  useEffect(() => {
    login(payload);
  }, []);
  return (
    <Box style={{ display: 'flex', height: '100vh' }}>
      <Box style={{ margin: 'auto' }}>{isLoading ? <CircularProgress /> : <Box>請登入</Box>}</Box>
    </Box>
  );
};
