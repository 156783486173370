import { HomeGroupLayout, HomePostForm } from 'components';
import React from 'react';



/**
 * 新增編輯貼文表單頁面
 */

export const HomePostFormPage = () => {
  return (
    <HomeGroupLayout>
      <HomePostForm />
    </HomeGroupLayout>
  );
};


