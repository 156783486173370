import { List as MuiList } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useUuid } from 'utils/hooks/useUuid';
import ListItem from './ListItem';

export const List = ({ list, className }) => {
  const [getUuid] = useUuid();
  return (
    <MuiList disablePadding>
      {list.map((item) => {
        const uuid = getUuid();
        return <ListItem key={uuid} itemData={item} className={className} />;
      })}
    </MuiList>
  );
};

List.propTypes = {
  list: PropTypes.array,
  className: PropTypes.string,
};
