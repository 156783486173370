import { TeacherManagementForm, BsHeaderTitle } from 'components';
import React from 'react';
// import PropTypes from 'prop-types';
// import { UiTeacherManagementFormPage } from './TeacherManagementFormPage.style';
import { UiBsContentContainer } from 'styles';


/**
 * 教師管理 表單頁面
 */
export const TeacherManagementFormPage = props => {
  return (
    <>
      <BsHeaderTitle title="教師管理" />
      <UiBsContentContainer>
        <TeacherManagementForm {...props} />
      </UiBsContentContainer>
    </>
  );
};

TeacherManagementFormPage.propTypes = {

};


