import { ResourceModal, SessionCard, BlindBrick } from 'components';
import { Button, Loading, Checkbox, Icon } from 'components';
import { stringify } from 'query-string';
import React, { useEffect } from 'react';

import { useServerTime } from 'store/serverTime/index';
import { useSessions } from 'store/sessions';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';

import {
  UiMySessions,
  UiSessionTab,
  UiSessionTabItem,
  UiTrack,
  UiButtonBox,
  UiCenterBox,
  UiMainContent,
} from './MySessions.style';

/**
 * 所有課程
 */

const noSessionsPrompt = {
  today: {
    host: '您今天沒有安排授課課程喔！',
    all: '您今天沒有安排任何課程喔！',
  },
  future: {
    host: '您明天起沒有安排授課課程喔！',
    all: '您明天起沒有安排任何課程喔！',
  },
  past: {
    host: '您目前還沒有教授過課程喔！',
    all: '您目前還沒有上過任何課程喔!',
  },
  all: {
    host: '您目前沒有授課課程喔！',
    all: '您目前沒有安排任何課程喔！',
  },
};

export const MySessions = () => {
  const [{ mySessions }, { getMySessions }] = useSessions();
  const [{ profile }] = useUser();
  const [{ timestamp: serverTimestamp, todayInterval, pastInterval, futureInterval, allInterval }] = useServerTime();
  const [{ classDateType, startAt, endAt, session, isLoading, isHost, isOpenResourceModal }, setState] = useSetState({
    classDateType: 'today',
    startAt: null,
    endAt: null,
    session: null,
    isLoading: true,
    isHost: false,
    isOpenResourceModal: false,
  });
  const sessionTabs = [
    {
      id: 'today',
      name: '今日課程',
      func: () => {
        const { startAt, endAt } = todayInterval;
        setState({
          classDateType: 'today',
          startAt,
          endAt,
          isHost,
        });
      },
    },
    {
      id: 'future',
      name: '未來課程',
      func: () => {
        const { startAt, endAt } = futureInterval;
        setState({
          classDateType: 'future',
          startAt,
          endAt,
          isHost,
        });
      },
    },
    {
      id: 'past',
      name: '過去課程',
      func: () => {
        const { startAt, endAt } = pastInterval;
        setState({
          classDateType: 'past',
          startAt,
          endAt,
          isHost,
        });
      },
    },
    {
      id: 'all',
      name: '全部課程',
      func: () => {
        const { startAt, endAt } = allInterval;
        setState({
          classDateType: 'all',
          startAt,
          endAt,
          isHost,
        });
      },
    },
  ];

  /* click 課程教材彈窗 - 取消按鈕 */
  const clickResourceModalCancelHandler = () => {
    setState({ isOpenResourceModal: false });
  };

  /* click 課程教材彈窗 - 確認按鈕 */
  const clickResourceModalOkHandler = (resourceValue) => {
    const classRoomUrl = `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${resourceValue}`;
    window.open(classRoomUrl, '_blank'); // 跳轉至 oneBook 教材
    setState({ isOpenResourceModal: false });
  };

  const getMySessionsHandler = async (params) => {
    setState({
      isLoading: true,
    });
    await getMySessions(params, serverTimestamp);
    setState({
      isLoading: false,
    });
  };

  const checkHandler = (value) => {
    setState({
      isHost: value === 'isHost' ? true : false,
    });
  };

  const enterClass = (session) => {
    const { id, resources, classType, hostId, type, users, organizationId } = session;
    if (classType !== 'class') return;
    const enterInteractiveSession = () => {
      const usersLength = users?.length || 0;
      let classType = '';
      switch (type) {
        case 'interactive':
          classType = usersLength <= 1 ? 'single' : 'group';
          break;
        case 'collaborative':
          classType = usersLength <= 1 ? 'sync-single' : 'sync-multiple';
          break;
        case 'video':
          classType = 'group';
          break;
        default:
          break;
      }
      const userType = hostId === profile.id ? 'teacher' : 'student';
      const querystring = stringify(
        {
          bookIds: resources,
          classType: classType,
          role: userType,
          userName: profile.nickname,
          userId: profile.id,
          productType: 'onelink',
          organizationId: organizationId,
        },
        { arrayFormat: 'index' },
      );
      window.open(`${process.env.REACT_APP_ONEBOARD_DOMAIN}/${id}/setup?${querystring}`, '_blank');
    };

    if (type === 'interactive' || type === 'video' || type === 'collaborative') {
      enterInteractiveSession();
    } else {
      setState({
        session,
        isOpenResourceModal: true,
      });
    }
  };

  /* 第一次載入 - 今日課程 */
  useEffect(() => {
    if (!serverTimestamp) return;
    const { startAt, endAt } = todayInterval;
    setState({ startAt, endAt });
  }, [serverTimestamp]);

  useEffect(() => {
    if (startAt === null || endAt === null) return;
    const params = {
      nowPage: 0,
      rowsPage: 8,
      startAt,
      endAt,
      isHost,
    };
    getMySessionsHandler(params);
  }, [startAt, endAt, isHost]);

  return (
    <>
      {/* 一般課程進入教室 彈窗 */
      isOpenResourceModal && (
        <ResourceModal
          session={session}
          isOpenResourceModal={isOpenResourceModal}
          onOk={clickResourceModalOkHandler}
          onCancel={clickResourceModalCancelHandler}
        />
      )}

      <UiTrack>
        <UiSessionTab>
          <BlindBrick
            className="accesskey"
            id="left_container"
            accessKey="l"
            href="#left_container"
            title="左方選單區塊"
          />
          {sessionTabs.map((tab) => {
            return (
              <UiSessionTabItem
                tabIndex={0}
                aria-label={tab.name}
                active={classDateType === tab.id ? true : false}
                key={tab.id}
                onClick={() => tab.func()}
                onKeyDown={(e) => e.key === 'Enter' && tab.func()}
              >
                <Icon name="classIcon" haveBg={false} />
                {tab.name}
              </UiSessionTabItem>
            );
          })}
        </UiSessionTab>
        <Checkbox label="只顯示授課課程" checkedValue="isHost" onChange={(value) => checkHandler(value)} />
      </UiTrack>
      <UiMainContent>
        <UiMySessions>
          <BlindBrick
            className="accesskey"
            id="accessibility_center_container"
            accessKey="c"
            href="#accessibility_center_container"
            title="中央內容區塊，為本頁主要內容區。"
          />
          {isLoading ? (
            <UiCenterBox>
              <Loading />
            </UiCenterBox>
          ) : mySessions.data.length > 0 ? (
            mySessions.data.map((session) => (
              <SessionCard
                key={session.id}
                groupName={session.groupName}
                sessionName={session.name}
                date={session.startAt}
                coverPath={`https://cdn.oneclass.com.tw/uploadoutput/${session.resourceId}/cover.jpg`}
                time={session.during}
                type={session.classType}
                people={session.users ? session.users.length : 0}
                teacher={session.hostName}
                role={profile.id === session.hostId ? 'teacher' : 'user'}
                onClick={() => enterClass(session)}
              />
            ))
          ) : (
            <UiCenterBox>
              <p tabIndex={0}>{noSessionsPrompt[classDateType][isHost ? 'host' : 'all']}</p>
            </UiCenterBox>
          )}
        </UiMySessions>
        {isLoading || mySessions.total === mySessions.data.length || (
          <UiButtonBox>
            <Button
              onClick={() =>
                getMySessionsHandler({
                  nowPage: 0,
                  rowsPage: mySessions.total,
                  startAt,
                  endAt,
                  isHost,
                })
              }
            >
              顯示更多
            </Button>
          </UiButtonBox>
        )}
      </UiMainContent>
    </>
  );
};
