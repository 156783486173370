import { QUERY, DEVICE } from 'constants/dimension';
import styled from 'styled-components';
import { useResponsive } from 'utils/hooks/useResponsive';

export const UiSessionPerformance = styled.div``;

export const UiPerformanceTitle = styled.div`
  margin: 66px 0 48px;
  font-size: 25px;
  font-weight: 700;
`;

export const UiPerformanceForUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
`;

export const UiPerformanceCard = styled('div')(() => {
  const isMobileL = useResponsive({ query: QUERY.DOWN, start: DEVICE.MOBILE.WIDTH_L });
  return {
    padding: '1rem',
    flex: isMobileL ? 'unset' : 1,
    minWidth: '290px',
    width: isMobileL ? '100%' : 'unset',
    backgroundColor: '#fafafc',
    borderRadius: '15px',
    '&:last-child': {
      maxWidth: isMobileL ? 'unset' : 'calc(50% - 12px)',
    },
  };
});

export const UiPerformanceCardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
  color: #4e5d78;
`;

export const UiPerformanceCardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: #000;
  border-radius: 50%;

  &.trophy {
    background-color: #ffab00;
  }

  &.running {
    background-color: #ff5630;
  }

  &.raisehand {
    background-color: #377dff;
  }

  &.messageWaveform {
    background-color: #38cb89;
  }
`;

export const UiPerformanceCardContent = styled.div`
  font-size: 40px;
  font-weight: 700;
`;

export const UiPerformanceCardAverage = styled.div`
  color: #8b90a0;
`;
