import {
  TextField as MatTextField,
  Typography as MatTypography
} from '@material-ui/core';
import {
  Table,
  Select,
  IconInput,
  Checkbox,
  Button,
  Modal,
  Icon
} from 'components';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useAdmin } from 'store/admin';
import {
  UiActionBox,
  UiflexBox,
  UiEmphasize
} from 'styles';
import { useSetState } from 'utils/hooks/useSetState';
import { useUuid } from 'utils/hooks/useUuid';


import {
  UiOrganizationResourceTable,
  UiActionComponentWraper,
  UiAddResourseInputGroup,
  UiDeleteModalText
} from './OrganizationResourceTable.style';


/**
 * 『機構管理』列表
 */

export const OrganizationResourceTable = () => {
  const [getUuid] = useUuid();
  const [{
    nowPage,
    newRowsPage,
    selectOption,
    search,
    isSelectable,
    deleteModalState,
    deleteStaged,
    addModalState,
    addStaged,
    resourceIdInput
  }, setState] = useSetState({
    nowPage: 0,
    newRowsPage: 10,
    selectOption: '',
    search: '',
    isSelectable: false,
    deleteModalState: false,
    deleteStaged: [],
    addModalState: false,
    addStaged: [],
    resourceIdInput: ''
  });

  const [{ resources }, { getResources, deleteResources, addResources }] = useAdmin();
  const { data, total } = resources;

  // 初始近來呼叫 api
  useEffect(() => {
    let query = { nowPage, rowsPage: newRowsPage };
    query[selectOption] = search;
    getResources(query);
  }, [nowPage, newRowsPage, search]);


  const schema = {
    resourceId: {
      name: '教材ID',
      defaultValue: '',
    },
    originalName: {
      name: '教材名稱',
      defaultValue: '',
    }
  };


  const checkboxChangeHandler = (resourceId, value) => {
    if (value) {
      deleteStaged.push(resourceId);
    } else {
      deleteStaged.splice(deleteStaged.indexOf(resourceId), 1);
    }
    setState({ deleteStaged });
  };

  // table 操作
  const ActionComponents = ({ params: { resourceId } }) => {
    return <UiActionComponentWraper>
      {
        isSelectable && (
          <Checkbox
            onChange={(val) => checkboxChangeHandler(resourceId, val)}
            checkedValue={resourceId}
            checked={deleteStaged.includes(resourceId)}
          />
        )
      }
    </UiActionComponentWraper>;
  };

  // 更換 每頁顯示
  const changePageRows = params => {
    setState({ nowPage: params.newPage, newRowsPage: params.newRowsPage });
  };

  // 下拉選單系列
  const selectProps = {
    label: '篩選條件',
    options: [
      {
        name: '教材名稱',
        value: 'resourceName'
      },
      {
        name: '教材ID',
        value: 'resourceId'
      }
    ],
    submitHandler: value => {
      setState({ selectOption: value, nowPage: 0 });
    }
  };

  // 搜尋系列
  const IconInputProps = {
    placeholder: '搜尋欄位名稱',
    onChange: value => {
      setState({ search: value, nowPage: 0 });
    }
  };

  const editButtonToggleHandler = () => {
    setState({ isSelectable: !isSelectable });
  };

  const deleteHandler = () => {
    setState({ deleteModalState: true });
  };

  const buttons = useMemo(() => [
    {
      text: '確認',
      func: async () => {
        if (deleteStaged.length > 0) {
          await deleteResources(deleteStaged);
        }
        setState({
          deleteModalState: false,
          isSelectable: false,
          deleteStaged: []
        });
      }
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => setState({ deleteModalState: false })
    }
  ], [deleteStaged]);

  const DeleteModalText = count => (
    <UiDeleteModalText>
      是否刪除 <UiEmphasize>{count}</UiEmphasize> 筆教材資源？
    </UiDeleteModalText>
  );

  const addButtonHandler = () => {
    setState({ addModalState: true });
  };

  const resourceIdInputChangeHandler = value => {
    setState({ resourceIdInput: value });
  };

  const addResourceIdHandler = () => {
    if (!resourceIdInput) return;
    addStaged.push({
      id: getUuid(),
      resourceId: resourceIdInput
    });
    setState({
      addStaged,
      resourceIdInput: ''
    });
  };

  const deleteAddStagedHandler = index => {
    addStaged.splice(index, 1);
    setState({ addStaged });
  };

  const addModalButtons = useMemo(() => [
    {
      text: '確認',
      func: async () => {
        if (addStaged.length > 0) {
          const resourceIds = addStaged.map(item => item.resourceId);
          await addResources(resourceIds);
        }
        setState({
          addModalState: false,
          isSelectable: false,
          addStaged: []
        });
      }
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => {
        setState({
          addModalState: false,
          isSelectable: false,
          addStaged: []
        });
      }
    }
  ], [addStaged]);

  return (
    <UiOrganizationResourceTable>
      <UiActionBox>
        <UiflexBox>
          <Select
            label={selectProps.label}
            value={selectOption}
            options={selectProps.options}
            submitHandler={selectProps.submitHandler}
          />
          <IconInput
            placeholder={IconInputProps.placeholder}
            onChange={IconInputProps.onChange}
          />
        </UiflexBox>
        <UiflexBox>

          {
            isSelectable ? (
              <>
                <Button buttonColor='danger' onClick={deleteHandler}>刪除</Button>
                <Button onClick={editButtonToggleHandler}>取消</Button>
              </>
            ) : (
              <>
                <Button buttonColor='highlight' onClick={addButtonHandler}>新增教材</Button>
                <Button onClick={editButtonToggleHandler}>編輯</Button>
              </>
            )
          }
        </UiflexBox>
      </UiActionBox>
      <Table
        data={data}
        schema={schema}
        totalPage={total}
        ActionComponents={ActionComponents}
        changePage_Rows={changePageRows}
        nowPage={nowPage}
      />
      <Modal
        isOpen={addModalState}
        isIconShow={false}
        title="新增教材"
        buttons={addModalButtons}
        getModalState={state => setState({ addModalState: state })}
      >
        <UiAddResourseInputGroup>
          <MatTextField
            label="請輸入書本ID"
            variant="outlined"
            value={resourceIdInput}
            onChange={e => resourceIdInputChangeHandler(e.target.value)}
          />
          <Button onClick={addResourceIdHandler}>新增</Button>
        </UiAddResourseInputGroup>

        {
          addStaged.map((item, index) => {
            return (
              <UiAddResourseInputGroup key={item.id}>
                <span>{index + 1}.</span>
                <MatTypography variant="h6">{item.resourceId}</MatTypography>
                <Icon name='close' type='danger' size="small" onClick={() => deleteAddStagedHandler(index)} />
              </UiAddResourseInputGroup>
            );
          })
        }

      </Modal>
      <Modal
        isOpen={deleteModalState}
        content={DeleteModalText(deleteStaged.length)}
        getModalState={state => setState({ deleteModalState: state })}
        buttons={buttons}
      />
    </UiOrganizationResourceTable>
  );
};

OrganizationResourceTable.propTypes = {
  children: PropTypes.node,
};


