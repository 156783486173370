import { InputAdornment as MatInputAdornment } from '@material-ui/core';
import styled from 'styled-components';
export const UiTraceTextForm = styled.div`
	display: block;
`;

export const UiMatInputAdornment = styled(MatInputAdornment)`
	font-size: 0.875rem;
	color: #888;
`;
