import Tooltip from '@material-ui/core/Tooltip';
import emptyExam from 'assets/images/emptyExam.png';
import {
  Table,
  Icon,
  Button,
  Loading,
} from 'components';
import { format } from 'date-fns';
import { stringify } from 'query-string';
import React, { useEffect, useMemo, } from 'react';
// import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { getMarkToken } from 'services/api/home/exam';
import { getGroupExamResults } from 'services/api/home/onlineExam';
import { useUser } from 'store/user/index';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';
import { paginationFilter } from 'utils/pagination';
import {
  UiActions,
  UiHomeExamResults,
  UiHomeExamGoBack,
  UiHomeExamTitleWrapper,
  UiHomeExamTable,
  UiEmptyBox,
  UiEmptyImage,
  UiEmptyText,
  UiTitle,
} from './HomeExamResults.style';


/**
 * 作答結果
 */

const EXAMNOTFINISHED = 'examNotFinished';
const ANSWERED = 'answered';
const MISSED = 'missed';
const TOBECORRECTED = 'toBeCorrected';

const EXAM_STATUS = {
  [EXAMNOTFINISHED]: '尚未作答',
  [ANSWERED]: '作答完成',
  [MISSED]: '缺考',
  [TOBECORRECTED]: '待批改'
};

const answerStatusMap = (status) => {
  switch (status) {
    case EXAMNOTFINISHED:
    case ANSWERED:
    case MISSED:
      return EXAM_STATUS[status];
    case TOBECORRECTED:
      return EXAM_STATUS[ANSWERED];
    default:
      return '';
  }
};

const schema = {
  attendeeNumber: {
    name: '座號',
    defaultValue: '-',
  },
  userNickname: {
    name: '學生姓名',
    defaultValue: '',
  },
  statusText: {
    name: '作答狀態',
    defaultValue: '',
  },
  // submitTime:{
  //   name:'交卷時間',
  //   defaultValue:'',
  // },
  // answerPeriodText:{
  //   name:'作答時間',
  //   defaultValue:'',
  // },
  // scoreText:{
  //   name:'分數',
  //   defaultValue:'-',
  // }
};

export const HomeExamResults = () => {
  const history = useHistory();
  const { examName, includingWriting, isExamEnded, markers } = history.location.state;
  const { classId, examId, organizationId } = useParams();
  const { setAlert } = useAlert();
  const [{ profile }] = useUser();
  const { id: userId, nickname } = profile;
  const [{
    isMarkChecking,
    isFetching,
    examResults,
    currentPage,
    rowsPerPage
  }, setState] = useSetState({
    isMarkChecking: false,
    isFetching: false,
    examResults: [],
    currentPage: 0,
    rowsPerPage: 10,
  });

  const isExamTeacher = useMemo(() => {
    if (!markers || !userId) return false;
    return markers.some(marker => marker === userId);
  }, [markers, userId]);

  const queryStringForOneData = useMemo(() =>
    stringify({ classId, institutionId: organizationId, examIds: examId })
    , [classId, organizationId, examId]);

  const goBack = () => {
    history.goBack();
  };

  const fetchMarkToken = async () => {
    let token = null;
    const url = window.location.href;
    const payload = {
      examId: examId,
      teacherName: nickname,
      returnUrl: url,
      groupId: classId,
    };
    const response = await getMarkToken(payload);
    const { status } = response;
    status === 'success'
      ? token = response.content.token
      : setAlert(response.content, 'error');
    return token;
  };

  /* click 非選批改 按鈕 */
  const clickMark = async () => {
    setState({ isMarkChecking: true });
    const token = await fetchMarkToken();
    if (token) {
      const params = {
        token,
        classId,
      };
      const querystring = stringify(params);
      const markUrl = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/exam/mark/${examId}?${querystring}`;
      window.open(markUrl, '_blank');
    }
    setState({ isMarkChecking: false });
  };

  // 操作
  const ActionComponents = ({ params }) => {
    const { status, userId, examId } = params;
    const goExamResults = () => {
      const url = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/user/report/${examId}/${userId}`;
      window.open(url);
    };

    return (
      <Button onClick={goExamResults} disabled={![ANSWERED, TOBECORRECTED].includes(status)}>
        作答結果
      </Button>
    );
  };

  const changePage = params => {
    const { newPage, newRowsPage } = params;
    setState({
      currentPage: newPage,
      rowsPerPage: newRowsPage
    });
  };

  const fetchExamResults = async (classId, examId) => {
    setState({ isFetching: true });
    const { data } = await getGroupExamResults(
      classId,
      examId
    );
    setState({
      examResults: data.map(item => {
        const { status, submittedAt, answerPeriod } = item;
        return {
          ...item,
          scoreText: item.score.toString(),
          statusText: <div key={item.examId} style={{ color: status === MISSED ? '#F44336' : '#505565' }}>
            {answerStatusMap(status)}
          </div>,
          submitTime: submittedAt ? format(submittedAt, 'yyyy-MM-dd HH:mm') : '-',
          answerPeriodText: answerPeriod ? `${answerPeriod} 分` : '-',
        };
      })
    });
    setState({ isFetching: false });
  };

  useEffect(() => {
    if (!classId || !examId) return;
    fetchExamResults(classId, examId);
  }, [classId, examId]);

  // 有任一人交卷才可以批改
  const canMark = useMemo(() =>
    examResults.some(result => [ANSWERED, TOBECORRECTED].includes(result.status))
    , [examResults]);
  // 沒非選 或 有非選但測驗已結束
  const canReview = useMemo(() =>
    !includingWriting || isExamEnded,
    [includingWriting, isExamEnded]
  );

  return (
    <UiHomeExamResults>
      <UiHomeExamGoBack onClick={goBack}>
        <Icon.Svg name="ChevronRight" />
        <span>返回上一頁</span>
      </UiHomeExamGoBack>
      <UiHomeExamTitleWrapper>
        <UiTitle>{examName}</UiTitle>
        {examResults.length > 0 && <UiActions>
          {/* 使用者必須是批改老師才可以顯示 */}
          {
            includingWriting && isExamTeacher &&
            <Button
              buttonColor='new'
              loading={isMarkChecking}
              onClick={clickMark}
              disabled={!canMark}
            >
              <Icon.Svg name='Pen' color='#e4e7ec' />
              批改測驗
            </Button>
          }
          <Tooltip title={canReview ? '' : '需先批改完成並公布成績'} arrow placement='top'>
            <div>
              <Button
                buttonColor='new'
                onClick={() => window.open(`${process.env.REACT_APP_ONEEXAM_DOMAIN}/user/review/${examId}`)}
                disabled={!canReview}
              >
                <Icon.Svg name='Chalkboard' color='#e4e7ec' />
                檢討測驗
              </Button>
            </div>
          </Tooltip>
          <Tooltip title={canReview ? '' : '需先批改完成並公布成績'} arrow placement='top'>
            <div>
              <Button
                buttonColor='new'
                onClick={() => window.open(`${process.env.REACT_APP_ONEDATA_DOMAIN}/exam?${queryStringForOneData}`)}
                disabled={!canReview}
              >
                <Icon.Svg name='FileMedical' color='#e4e7ec' />
                班級分析報表
              </Button>
            </div>
          </Tooltip>
        </UiActions>}
      </UiHomeExamTitleWrapper>
      <UiHomeExamTable>
        {
          isFetching
            ? <Loading />
            : examResults.length === 0
              ? <UiEmptyBox>
                <UiEmptyImage path={emptyExam} />
                <UiEmptyText>該測驗目前尚無學生進行作答</UiEmptyText>
              </UiEmptyBox>
              : <Table
                data={paginationFilter(examResults, currentPage, rowsPerPage)}
                schema={schema}
                totalPage={examResults.length}
                ActionComponents={ActionComponents}
                nowPage={currentPage}
                changePage_Rows={changePage}
                actionName='作答結果'
              />
        }
      </UiHomeExamTable>
    </UiHomeExamResults>
  );
};

HomeExamResults.propTypes = {

};
