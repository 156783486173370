import styled from 'styled-components';

export const UiOrganizationContainer = styled.div`
  margin: 3rem 0 4rem;
`;

export const UiHead = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background-color: #f2f4f8;
  margin-bottom: 30px;
  border-radius: 4px;

  & > span {
    font-size: 1.6rem;
    color: #242c3f;
  }

  & > span:last-of-type {
    margin-right: auto;
  }

  & > button {
    margin-left: 8px;
  }
`;

export const UiGroupsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;
