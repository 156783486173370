import { DEVICE, QUERY } from 'constants/dimension';
import { Drawer } from '@material-ui/core';
import { Header, Footer, SideBar, SideBarFloat, BlindBrick } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useResponsive } from 'utils/hooks/useResponsive';
import { UiHomeGroupLayout, UiHomeGroupContent, UiChildrenContainer } from './HomeGroupLayout.style';

/**
 * 前台頁面組件 沒有SideBar 用於條款頁
 */

export const HomeGroupLayout = ({ children, sideBarType, showSideBar = true }) => {
  const isTabletL = useResponsive({
    query: QUERY.DOWN,
    start: DEVICE.TABLET.WIDTH_L,
  });
  const [isOpenSidebar, setIsOpenSidebar] = useState(true);

  const toggleSidebar = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpenSidebar((prev) => !prev);
  };

  useEffect(() => {
    setIsOpenSidebar(!isTabletL);
  }, [isTabletL]);

  return (
    <UiHomeGroupLayout>
      {/* <HomeBanner showSideBar={showSideBar} /> */}

      <UiHomeGroupContent>
        <Header isNeedLogo={true} toggleSidebar={toggleSidebar} />
        {isTabletL ? (
          <Drawer open={isOpenSidebar} onClose={toggleSidebar}>
            <SideBarFloat />
          </Drawer>
        ) : (
          showSideBar && isOpenSidebar && <SideBar type={sideBarType} />
        )}
        <UiChildrenContainer isTabletL={isTabletL}>
          <BlindBrick
            className="accesskey"
            id="accessibility_center_container"
            accessKey="c"
            href="#accessibility_center_container"
            title="中央內容區塊，為本頁主要內容區。"
          />
          {children}
          <Footer />
        </UiChildrenContainer>
      </UiHomeGroupContent>
    </UiHomeGroupLayout>
  );
};

HomeGroupLayout.propTypes = {
  sideBarType: PropTypes.string,
  children: PropTypes.node,
  showSideBar: PropTypes.bool,
};
