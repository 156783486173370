import { HomeGroupLayout, SessionFeedback } from 'components';
import React from 'react';

export const SessionFeedbackPage = () => {
  return (
    <HomeGroupLayout>
      <SessionFeedback />
    </HomeGroupLayout>
  );
};
