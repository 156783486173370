import { AuthorityTable } from 'components';
import React from 'react';


/**
 * 付費帳號列表
 */

export const AuthorityPage = () => {
  return (
    <AuthorityTable />
  );
};
