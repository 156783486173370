import { Box, Typography } from '@material-ui/core';
import lineConnectSuccessImg from 'assets/images/line_connect_success.png';
import { NormalLayout } from 'components';
import React from 'react';
import { UiLineConnectSuccessState } from './LineConnectedPage.style';

export const LineConnectedPage = () => {
  return (
    <NormalLayout>
      <Box style={{ margin: 'auto' }}>
        <UiLineConnectSuccessState>
          <Box>
            <img src={lineConnectSuccessImg} alt="line connect success" />
          </Box>
          <Box className="connectMessageBox">
            <Typography className="connectMessage">已成功完成手機綁定！</Typography>
          </Box>
        </UiLineConnectSuccessState>
      </Box>
    </NormalLayout>
  );
};
