/* 檔案副檔名 */
export const FILE_EXTENSION_MAP = {
  XLSX: 'xlsx',
  PDF: 'pdf',
  PNG: 'png',
  TXT: 'txt',
};

const TYPE_MAP = {
  [FILE_EXTENSION_MAP.XLSX]: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64',
  [FILE_EXTENSION_MAP.PDF]: 'data:application/pdf;base64',
  [FILE_EXTENSION_MAP.PNG]: 'data:image/png;base64',
  [FILE_EXTENSION_MAP.XLSX]: 'data:text/plain;base64',
};

export function downloadFile({ buffer, fileName, type }) {
  try {
    return new Promise((resolve) => {
      const uint8Array = Uint8Array.from(buffer);
      const blob = new Blob([uint8Array], {
        type: TYPE_MAP[type],
      });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      return resolve();
    });
  } catch (error) {
    console.log('下載失敗');
  }
}
