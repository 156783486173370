import forest from 'assets/images/forest.svg';
import { EmptyPrompt, Loading, Icon, HomeTitle } from 'components';
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getWebResourceList } from 'services/api/organization/webResource';
import { useAlert } from 'utils/hooks/useAlert';
import {
  UiWebResourceContainer,
  UiWebResourceBox,
  UiWebResourceBoxTitle,
  UiWebResourceBoxContent,
  UiWebResourceBoxItem,
  UiWebResourceBoxCol,
  UiPrompt
} from './WebResource.style';


/**
 * 常用資源
 */

const getHrefURL = (link) => {
  return new RegExp(/https?:\/\//g).test(link) ?
    link : `//${link}`;
};

export const WebResource = () => {
  const { organizationId } = useParams();
  const { setAlert } = useAlert();
  const [resources, setResources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchGetWebResourcesList = async () => {
    try {
      const { isSuccess, data } = await getWebResourceList(organizationId);
      if (isSuccess) setResources([...data]);
      setIsLoading(false);
    } catch (error) {
      alert(error);
      setAlert(error, 'error');
    }
  };
  useEffect(() => {
    fetchGetWebResourcesList();
  }, []);

  const resourcesList = useMemo(() => {
    return resources
      .map(item => ({
        ...item, webResourceData: item.webResourceData.sort((a, b) => {
          if (!a.createdAt || !b.createdAt) return 0;
          return new Date(a.createdAt) - new Date(b.createdAt);
        })
      }))
      .sort((a, b) => {
        if (!a.createdAt || !b.createdAt) return 0;
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
  }, [resources]);

  return (
    <UiWebResourceContainer>
      <HomeTitle title="常用資源" />
      {
        isLoading ?
          <Loading /> :
          resourcesList.length === 0 || resourcesList.every(item => item?.webResourceData?.length === 0) ?
            <UiPrompt>
              <EmptyPrompt img={forest} message="尚無相關資源" messageColor={'#8B90A0'} />
            </UiPrompt>
            :
            resourcesList.map(item => (
              <UiWebResourceBox key={item.id}>
                {
                  item.webResourceData.length > 0 &&
                  <>
                    <UiWebResourceBoxTitle>
                      <Icon.Svg name={'AttachFile'} color={'#8B90A0'} />
                      <h3 tabIndex={0}>{item.title}</h3>
                    </UiWebResourceBoxTitle>
                    <UiWebResourceBoxContent>
                      {
                        item.webResourceData.map(data => (
                          <UiWebResourceBoxItem key={data.id} href={getHrefURL(data.url)} target='_blank'>
                            <UiWebResourceBoxCol>
                              <div>
                                <Icon.Svg name={'Link'}></Icon.Svg>
                              </div>
                              <p>{data.name}</p>
                            </UiWebResourceBoxCol>
                          </UiWebResourceBoxItem>))
                      }
                    </UiWebResourceBoxContent>
                  </>
                }
              </UiWebResourceBox>
            ))
      }
    </UiWebResourceContainer>
  );
};



