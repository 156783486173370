/* eslint-disable react/prop-types */
import { makeStyles } from '@material-ui/core/styles';


import defaultAvatarImage from 'assets/images/Avatar_Member_Default.png';
import {
  Card,
  Icon,
  ClickablePopoverMenu,
  Modal,
  IconTextArea,
  PostModal,
  AlbumSlider,
  LikeListModal,
} from 'components';
import * as linkify from 'linkifyjs';
import Linkify from 'linkifyjs/react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { ReactTinyLink } from 'react-tiny-link';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiPostCard,
  UiUser,
  UiUserBox,
  UiTime,
  UiContentBox,
  UiContentText,
  UiAvatar,
  UiInfo,
  UiCircleIcon,
  UiMessage,
  UiMessageItem,
  UiAttachmentBox,
  UiReplie,
  UiReplieBox,
  UiReplieContent,
  UiAwesomeSpan,
  UiAwesomeBox,
  UiReplieMenu,
  UiPrevMessage,
  UiMore,
  UiPostCardAll,
  UiIsRead,
  UiGridListTile,
  UiImageBg,
  UiOnePicture,
  UiGridList,
  UiComment,
  UiLikePeople,
  UiAvatarBox,
  UiReplieContentDetail,
} from './PostCard.style';


const EDIT_STATUS = {
  ALIGN_TOP: 'verticalAlignTop',
  DELETE: 'delete',
  EDIT: 'edit'
};


const linkOptions = {
  nl2br: true
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    'marginBottom': '1rem',
  },

  gridimg: {
    cursor: 'pointer',
  },

  gridvideo: {
    width: '100% !important',
    height: 'auto !important',
  },

  gridaudio: {
    width: '300px !important',
  }
}));

/**
 * 貼文回覆
 */

export const Replie = ({
  post,
  isLike,
  isSecret = false,
  onLikePostChage = () => { },
  onDelete = () => { },
  canEdit = false,
  canDelete = false,
  editReplieMessage = () => { },
  isEditTarget = false,
  sendEditId = () => { },
}) => {
  const EDIT_STATUS = {
    READ: 'read',
    ALIGN_TOP: 'verticalAlignTop',
    DELETE: 'delete',
    EDIT: 'edit'
  };
  const [{
    isModalOpen,
    likeFlag,
    checkDelay,
    isLikeClick,
    awesomeCount,
    editStatus,
    memberModalState
  }, setState] = useSetState({
    isModalOpen: false,
    editStatus: EDIT_STATUS.READ,
    modalState: false,
    likeFlag: true,
    checkDelay: 1,
    isLikeClick: isLike,
    awesomeCount: post.likedUsers ? post.likedUsers.length : 0,
    memberModalState: false,
  });
  const isFirstRun = useRef(true);

  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };

  const closeMemberModalStateHandler = () => {
    setState({
      memberModalState: false
    });
  };

  //修改成編輯or刪除模式
  const setEditStatus = status => () => setState({ editStatus: status, isModalOpen: status !== EDIT_STATUS.EDIT });

  const sendEditIdHandle = (id) => {
    sendEditId(id);
  };

  //修改成閱讀模式
  const setEditStatusToRead = status => () => setState({ editStatus: status });

  const popoverMenuList = [
    {
      label: '取消',
      func: setEditStatusToRead(EDIT_STATUS.READ),
      iconName: 'close',
      isShow: editStatus === EDIT_STATUS.EDIT
    },
    { label: '編輯', func: () => sendEditIdHandle(post.id), iconName: 'edit', isShow: canEdit },
    { label: '刪除', func: setEditStatus(EDIT_STATUS.DELETE), iconName: 'delete', isShow: canDelete },
  ];

  const deleteModalConfig = {
    text: '是否要將此項回覆刪除？',
    confirmAction: () => onDelete(post)
  };

  const ModalConfig = editStatus === EDIT_STATUS.DELETE ? deleteModalConfig : '';

  const buttons = [
    {
      text: '取消',
      color: 'cancel',
      func: () => { }
    },
    {
      text: '確認',
      color: 'new',
      func: ModalConfig.confirmAction
    }
  ];
  const changeLikePostReply = (id) => {
    if (!likeFlag) return;
    setState({
      likeFlag: false,
      isLikeClick: !isLikeClick,
    });

    let _cd = checkDelay;
    let clock = setInterval(() => {
      _cd -= 1;
      if (_cd <= 0) {
        clearInterval(clock);
        setState({
          likeFlag: true
        });
      }
    }, 1000);
  };

  const editReplieMessageHandler = async (params, id) => {
    await editReplieMessage(params, id);
    setEditStatusToRead(EDIT_STATUS.READ)();
    sendEditIdHandle('');
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    setState({
      awesomeCount: isLikeClick ? awesomeCount + 1 : awesomeCount - 1,
    });
    onLikePostChage(isLikeClick, post.id);
  }, [isLikeClick]);

  return (
    <>
      <UiReplie key={post.id}>
        <UiAvatarBox>
          <UiAvatar src={post.ownerThumbnailUrl || defaultAvatarImage} />
        </UiAvatarBox>
        {
          !isEditTarget ?
            <UiReplieBox>
              <UiReplieContent>
                <span>{post.ownerName}</span>
                <UiReplieContentDetail>
                  {post.content && <Linkify tagName="span" options={linkOptions}>{post.content}</Linkify>}
                  {(post.attachments && post.attachments.length > 0) &&
                    (post.attachments[0].attachmentType.split('/')[0] === 'image' ?
                      <img src={post.attachments[0].attachmentUrl} alt={post.attachments[0].attachmentName} /> :
                      <a href={post.attachments[0].attachmentUrl} target='_blank' rel="noopener noreferrer">
                        {
                          post.attachments[0].attachmentName
                        }
                      </a>)
                  }
                </UiReplieContentDetail>
              </UiReplieContent>
              {
                !isSecret &&
                <UiAwesomeSpan>
                  {
                    isLikeClick ?
                      <Icon.Svg name='HeartOn' onClick={() => changeLikePostReply(post.id)} title="收回讚" /> :
                      <Icon.Svg name='HeartOff' onClick={() => changeLikePostReply(post.id)} title="讚" />
                  }
                  {
                    awesomeCount > 0 &&
                    <UiAwesomeBox onClick={() => setState({ memberModalState: true })}>
                      <span>{awesomeCount}</span>
                    </UiAwesomeBox>
                  }
                </UiAwesomeSpan>
              }

            </UiReplieBox> :
            <div className="inputBox">
              <IconTextArea
                isCanUploadFile={!isSecret}
                defaultContent={post.content}
                defaultFiles={post.attachments ? post.attachments : []}
                onKeyPress={params => editReplieMessageHandler(params, post.id)}
                onEsc={() => sendEditIdHandle('')}
                autoFocus={true}
              />
            </div>
        }
        <UiReplieMenu>
          {(canEdit || canDelete) ?
            <ClickablePopoverMenu menuList={popoverMenuList}>
              <Icon haveBg={false} name="moreHoriz" icolor="#8B90A0" />
            </ClickablePopoverMenu> : ''
          }
        </UiReplieMenu>
        {isEditTarget && <div className="cancel">按 Esc 鍵可<span onClick={() => sendEditIdHandle('')}>取消</span></div>}
      </UiReplie >
      <Modal
        isOpen={isModalOpen}
        text={ModalConfig.text}
        buttons={buttons}
        getModalState={getModalStateHandler}
      />
      <LikeListModal
        open={memberModalState}
        onCancel={closeMemberModalStateHandler}
        memberData={post.likedUsers}
      />
    </>
  );
};


/**
 * 貼文卡牌內容
 */

export const PostCard = ({
  data = null,
  isSecret = false,
  canDelete = false,
  canEdit = false,
  isLike = false,
  editModalState,//貼文編輯完關閉彈窗
  avatarSrc,
  time = '',
  isAdmin = false,
  isRead = false,
  onLikePostChage = () => { },
  onDelete = () => { },
  onEdit = () => { },
  onPin = () => { },
  replieMessage = () => { },
  editReplieMessage = () => { },
  getPrevMessage = () => { },
  getEditModalState = () => { },
}) => {
  const classes = useStyles();
  const {
    id,
    likedUsers,
    ownerThumbnailUrl,
    ownerName,
    content,
    attachments = [],
    replies = {
      posts: [],
      total: 0
    },
  } = data;
  const [{ profile: { nickname, id: userId } }] = useUser();
  const [{
    isModalOpen,
    editStatus,
    modalState,
    isLikeClick,
    likeFlag,
    checkDelay,
    awesomeCount,
    dataLimit,
    remainTotal,
    dataSkipPage,
    firstHref,
    sliderState,
    defaultContentHeight,
    contentHeight,
    isContentHidden,
    initalSlide,
    editId,
    messageState,
    memberModalState,
  }, setState] = useSetState({
    isModalOpen: false,
    editStatus: null,
    modalState: false,
    isLikeClick: isLike,
    awesomeCount: likedUsers ? likedUsers.length : 0,
    likeFlag: true,
    checkDelay: 1,
    dataLimit: 5,
    remainTotal: replies.total,
    dataSkipPage: 0,
    firstHref: '',
    sliderState: false,
    defaultContentHeight: 0,
    contentHeight: null,
    isContentHidden: false,
    initalSlide: 0,
    editId: '',
    messageState: false,
    memberModalState: false
  });
  const isFirstRun = useRef(true);
  const contentRef = useRef();

  const setEditStatus = status => () => setState({ editStatus: status, isModalOpen: status !== EDIT_STATUS.EDIT });

  useEffect(() => {
    calGetReplySkip();
    if (!content) return;
    if (linkify.find(content).length > 0) {
      setState({
        firstHref: linkify.find(content)[0].href
      });
    }
  }, []);

  const calGetReplySkip = () => {
    let replyParams = {
      rowPage: 0,
      pageSkip: 0,
      pageLimit: dataLimit
    };
    if (remainTotal <= 0) return;
    if (remainTotal > 5) {
      const skipPage = dataSkipPage * 5;
      replyParams.pageSkip = skipPage;
      setState({
        remainTotal: remainTotal - dataLimit,
        dataSkipPage: dataSkipPage + 1
      });
    } else {
      replyParams.pageLimit = remainTotal;
      replyParams.pageSkip = dataSkipPage * 5;
      setState({
        remainTotal: 0
      });
    }
    return replyParams;
  };

  const loadingPrevPage = async (id) => {
    if (!likeFlag) return;
    setState({
      likeFlag: false
    });

    const data = calGetReplySkip();
    await getPrevMessage(data, id);

    setState({
      likeFlag: true
    });
  };

  //貼文操作
  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };


  //編輯貼文彈窗
  const getPostModalState = state => {
    getEditModalState(state);//把貼文的編輯跳窗傳給pasteTable
    setState({
      modalState: state
    });
  };

  useEffect(() => {
    if (!editModalState) {//false的時候才做 true做的話其他的編輯會跑出來
      setState({
        modalState: editModalState
      });
    }
  }, [editModalState]);


  const popoverMenuList = [
    { label: '編輯', func: () => getPostModalState(true), iconName: 'edit', isShow: canEdit },
    { label: '刪除', func: setEditStatus(EDIT_STATUS.DELETE), iconName: 'delete', isShow: canDelete },
  ];

  const deleteModalConfig = {
    text: '是否要將此項貼文刪除？',
    confirmAction: () => onDelete(data)
  };

  const pinModalConfig = {
    text: '是否要將此項貼文置頂？',
    confirmAction: () => onPin(data)
  };

  const ModalConfig = editStatus === EDIT_STATUS.DELETE ? deleteModalConfig : pinModalConfig;

  const buttons = [
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    },
    {
      text: '確認',
      func: ModalConfig.confirmAction
    }
  ];

  const changeLikePost = () => {
    if (!likeFlag) return;
    setState({
      likeFlag: false,
      isLikeClick: !isLikeClick,
    });

    let _cd = checkDelay;
    let clock = setInterval(() => {
      _cd -= 1;
      if (_cd <= 0) {
        clearInterval(clock);
        setState({
          likeFlag: true
        });
      }
    }, 1000);
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    setState({
      awesomeCount: isLikeClick ? awesomeCount + 1 : awesomeCount - 1,
    });
    onLikePostChage(isLikeClick, id);
  }, [isLikeClick]);

  const onSubmit = async data => {
    await onEdit(data);
  };

  // 修改相簿狀態
  const openAlbumSlider = (state, index) => {
    setState({
      sliderState: state,
      initalSlide: index,
    });
  };

  //判斷相簿每格高度
  const albumLayout = length => {
    switch (length) {
      case 6:
        return [
          {
            cols: 1.5,
            rows: 2
          },
          {
            cols: 1.5,
            rows: 2
          },
          {
            cols: 1,
            rows: 1
          },
          {
            cols: 1,
            rows: 1
          },
          {
            cols: 1,
            rows: 1
          },
          {
            cols: 3,
            rows: 1
          }
        ];
      case 5:
        return [
          {
            cols: 1.5,
            rows: 2
          },
          {
            cols: 1.5,
            rows: 2
          },
          {
            cols: 1,
            rows: 1
          },
          {
            cols: 1,
            rows: 1
          },
          {
            cols: 1,
            rows: 1
          }
        ];
      case 4:
        return [
          {
            cols: 1.5,
            rows: 2
          },
          {
            cols: 1.5,
            rows: 2
          },
          {
            cols: 1.5,
            rows: 1
          },
          {
            cols: 1.5,
            rows: 1
          }
        ];
      case 3:
        return [
          {
            cols: 1,
            rows: 2
          },
          {
            cols: 1,
            rows: 2
          },
          {
            cols: 1,
            rows: 2
          }
        ];
      case 2:
        return [
          {
            cols: 1.5,
            rows: 3
          },
          {
            cols: 1.5,
            rows: 3
          },
        ];
      case 1:
        return [
          {
            cols: 1,
            rows: 2
          }
        ];
      default:
        return [];
    }
  };

  const moreContent = () => {
    setState({
      isContentHidden: false,
      contentHeight: defaultContentHeight
    });
  };

  const sendEditId = id => {
    setState({
      editId: id
    });
  };

  const closeHandler = e => {
    if (!e) {
      setState({
        sliderState: false
      });
      return;
    }
    if (e.currentTarget !== e.target) {
      e.stopPropagation();
      return;
    }
    setState({
      sliderState: false
    });
  };

  const closeMemberModalStateHandler = () => {
    setState({
      memberModalState: false
    });
  };

  useEffect(() => {
    if (contentRef.current) {
      if (contentRef.current.clientHeight > 85) {
        setState({
          defaultContentHeight: contentRef.current.clientHeight,
          isContentHidden: true,
          contentHeight: 85
        });
      }
    }
  }, [contentRef]);

  const goodHandleKeyDown = (event, fileUrl, fileName) => {
    if (event.key === 'Enter') {
      changeLikePost();
    }
  };

  const messageHandleKeyDown = (event, fileUrl, fileName) => {
    if (event.key === 'Enter') {
      setState({ messageState: !messageState });
    }
  };

  return (
    <>
      <UiPostCardAll>
        {
          isSecret &&
          <UiAvatar isSecret={isSecret} src={ownerThumbnailUrl || defaultAvatarImage} />
        }
        <Card>
          <UiPostCard>
            <UiUserBox>
              <div className="flexBox">
                {
                  isRead &&
                  <UiIsRead>
                    <Icon name='done' />
                  </UiIsRead>
                }
                {
                  !isSecret &&
                  <UiAvatar src={ownerThumbnailUrl || defaultAvatarImage} />
                }
                <UiUser>{ownerName}
                  <UiTime>{time}</UiTime>
                </UiUser>
              </div>
              {(canEdit || canDelete) ?
                <ClickablePopoverMenu menuList={popoverMenuList}>
                  <Icon haveBg={false} name="moreHoriz" icolor="#8B90A0"></Icon>
                </ClickablePopoverMenu> : ''
              }
            </UiUserBox>
            <UiContentBox>
              <UiContentText style={{ height: contentHeight && contentHeight, }} ref={contentRef}>
                <Linkify tagName="span" options={linkOptions} >{content}</Linkify>
              </UiContentText>
              {isContentHidden &&
                <UiMore onClick={() => moreContent()}>
                  看更多...
                </UiMore>
              }
              <UiAttachmentBox>
                <div className={classes.root}>
                  <UiGridList cellHeight={150} cols={3}>
                    {
                      attachments.length > 0 &&
                      attachments.map((file, index, arr) => {
                        const type = file.attachmentType.split('/')[0];
                        if (type === 'image') {
                          if (index === 5) return null;
                          if (arr.length === 1) {
                            return (
                              <UiOnePicture onClick={() => openAlbumSlider(true, index)}>
                                <img
                                  className={classes.gridImg}
                                  src={file.attachmentUrl}
                                  alt={file.attachmentUrl}
                                />
                              </UiOnePicture>
                            );
                          }
                          return (
                            <UiGridListTile
                              key={file.attachmentUrl}
                              cols={albumLayout(attachments.length)[index]?.cols || 1}
                              rows={albumLayout(attachments.length)[index]?.rows || 1}
                              onClick={() => openAlbumSlider(true, index)}
                            >
                              <img
                                className={classes.gridImg}
                                src={file.attachmentUrl}
                                alt={file.attachmentUrl} />
                              {
                                (index === 4 && arr.length >= 6) &&
                                <UiImageBg>+{arr.length - 5}</UiImageBg>
                              }
                            </UiGridListTile>
                          );
                        } else if (type === 'video') {
                          return (
                            <video className={classes.gridVideo} key={index} controls="controls">
                              <source src={file.attachmentUrl} type={file.type} />
                            </video>
                          );
                        } else if (type === 'audio') {
                          return (
                            <audio className={classes.gridAudio} key={index} controls="controls">
                              <source src={file.attachmentUrl} type={file.type} />
                            </audio >
                          );
                        } else {
                          return (
                            <a
                              key={index}
                              href={file.attachmentUrl}
                              alt={file.attachmentName}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.attachmentName}
                            </a>
                          );
                        }
                      })
                    }
                  </UiGridList>
                </div>
              </UiAttachmentBox>
              {
                firstHref &&
                <ReactTinyLink
                  cardSize="large"
                  showGraphic={true}
                  maxLine={2}
                  minLine={1}
                  url={firstHref}
                />
              }

            </UiContentBox>
            <UiInfo>
              {
                !isSecret &&
                <UiCircleIcon tabIndex={0} onClick={changeLikePost} onKeyDown={(e) => goodHandleKeyDown(e)}>
                  {
                    !isLike ? <Icon.Svg name='HeartOff' title="讚" /> : <Icon.Svg name='HeartOn' title="收回讚" />
                  }
                </UiCircleIcon>
              }
              <UiComment tabIndex={0} onClick={() => setState({ messageState: !messageState })} onKeyDown={(e) => messageHandleKeyDown(e)}>
                <Icon.Svg name="Comment" title="留言" />
                <span>{replies.total}</span>
              </UiComment>
            </UiInfo>
            <UiLikePeople onClick={() => setState({ memberModalState: true })}>
              {
                likedUsers?.length > 0 && <div><span>{likedUsers.length}</span>人說讚</div>
              }
            </UiLikePeople>
            <UiMessageItem>
              <IconTextArea avatar={avatarSrc} isCanUploadFile={!isSecret} onKeyPress={params => replieMessage(params, id)} />
            </UiMessageItem>
            <UiMessage isShow={messageState}>
              {
                remainTotal > 0 &&
                <UiPrevMessage onClick={() => { loadingPrevPage(id); }}>顯示先前留言</UiPrevMessage>
              }
              {
                replies.posts.length > 0 && replies.posts.map(post => {
                  const canDelete = (userId === post.ownerId || isAdmin) ? true : false;
                  const canEdit = (userId === post.ownerId) ? true : false;
                  const isLike = post.likedUsers && post.likedUsers.some(user => user.userId === userId);
                  return (
                    <Replie
                      key={post.id}
                      post={post}
                      isSecret={isSecret}
                      onDelete={onDelete}
                      canDelete={canDelete}
                      canEdit={canEdit}
                      isLike={isLike}
                      onLikePostChage={onLikePostChage}
                      editReplieMessage={editReplieMessage}
                      sendEditId={sendEditId}
                      isEditTarget={post.id === editId}
                    />
                  );
                })
              }
            </UiMessage>
          </UiPostCard>
        </Card>
      </UiPostCardAll>

      <Modal
        isOpen={isModalOpen}
        text={ModalConfig.text}
        buttons={buttons}
        getModalState={getModalStateHandler}
      />
      <PostModal
        data={data}
        isOpen={modalState}
        getModalState={getPostModalState}
        onSubmit={onSubmit}
        name={nickname}
        avatarSrc={ownerThumbnailUrl || defaultAvatarImage}
        modalState="edit"
        isSecret={isSecret}
        title={isSecret ? '編輯導師商談' : '編輯貼文'}
        dialogContent={<span>你尚未儲存貼文<br />確定要離開頁面且不儲存貼文</span>}
      />
      <AlbumSlider
        isOpen={sliderState}
        close={closeHandler}
        defaultData={{ attachments }}
        initalSlide={initalSlide}
        type={'slider'}
      />
      <LikeListModal
        open={memberModalState}
        onCancel={closeMemberModalStateHandler}
        memberData={likedUsers}
      />
    </>
  );
};

PostCard.propTypes = {
  data: PropTypes.shape({
    ownerThumbnailUrl: PropTypes.string,
    ownerName: PropTypes.string,
    time: PropTypes.string,
    content: PropTypes.string,
  }),
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
  isLike: PropTypes.bool,
  onDelete: PropTypes.func,
  onPin: PropTypes.func,
  onLikePostChage: PropTypes.func,
  onEdit: PropTypes.func,
  replieMessage: PropTypes.func,
  getPrevMessage: PropTypes.func,
};
