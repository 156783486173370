import { HomeGroupLayout, ClassSummary } from 'components';
import React from 'react';

/**
 * 前台班級報表
 */

export const ClassSummaryPage = () => {
  return (
    <HomeGroupLayout>
      <ClassSummary />
    </HomeGroupLayout>
  );
};
