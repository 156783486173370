import { HomeGroupLayout, HomeRuleInfo } from 'components';
import React from 'react';
import { UiHomeRulePage } from './HomeRulePage.style';

/**
 * 政策頁面
 */

export const HomeRulePage = () => {
  return (
    <HomeGroupLayout showSideBar={false}>
      <UiHomeRulePage>
        <HomeRuleInfo></HomeRuleInfo>
      </UiHomeRulePage>
    </HomeGroupLayout>
  );
};
