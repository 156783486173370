import { EDIT_STATUS, ORGANIZATION_SETTINGS_VALUE, SESSION_TYPE } from 'constants/index';
import { Box } from '@material-ui/core';
import {
  Button,
  HomeGroupLayout,
  HomeSessionTableCanceled,
  HomeSessionTableDone,
  HomeSessionTableNormal,
  HomeSessionTabs,
  LoadingCard,
  ResourceModal,
} from 'components';
import queryString from 'query-string';
import React, { useMemo, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { getOneBoardUrlForOneLink, getOneBoardUrlForMMS, getOneBoardUrlForMMSObserver } from 'utils/helper';
import { UiHomeSessionPage, UiTitleWrapper, UiTitle } from './HomeSessionPage.style';

/**
 * 前台課表
 */

export const HomeSessionPage = () => {
  const history = useHistory();
  const { organizationId, classId } = useParams();
  const location = useLocation();
  const qs = queryString.parse(location.search);
  const [tab, setTab] = useState(qs.tab || 'normal');
  const [isOpenResourceModal, setIsOpenResourceModal] = useState(false);
  const [isClickSessionResourceButton, setIsClickSessionResourceButton] = useState();
  const [session, setSession] = useState();
  const [{ myOrganization, profile }] = useUser();
  const [{ myClasses }] = useClass();
  const { isOwner, groupHostCreateSessionSetting, groupRole, userType } = myClasses.dataInfo;
  const isLoading = !myOrganization.isLoaded && myClasses.isLoading;

  const operatorAuth = useMemo(() => {
    const { groupOwnerCreateSessionSetting } = myOrganization?.organization || {};
    return isOwner && groupOwnerCreateSessionSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW;
  }, [isOwner, myOrganization]);
  const isStaffCanCreateSession = useMemo(() => {
    return groupRole === 'staff' && groupHostCreateSessionSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW;
  }, [groupRole, groupHostCreateSessionSetting]);
  /* 是否可顯示新增課程按鈕 */
  const isDisplayCreatingCourseButton = operatorAuth || isStaffCanCreateSession || groupRole === 'organizationOwner';

  /* 點擊新增課程 */
  const clickCreatingCourse = () => {
    history.push(`/home${organizationId ? `/${organizationId}` : ''}/class/${classId}/sessions/${EDIT_STATUS.CREATE}`);
  };

  /* 切 tab */
  const changeTab = (event, newTab) => {
    setTab(newTab);
    history.push(`?tab=${newTab}`);
  };

  /* 開關課程教材彈窗 */
  const toggleResourceModal = () => {
    setIsOpenResourceModal((prev) => !prev);
  };

  /* click 課程教材按鈕 */
  const clickSessionResource = ({ session, isClickSessionResourceButton }) => () => {
    setSession(session);
    setIsClickSessionResourceButton(isClickSessionResourceButton);
    toggleResourceModal();
  };

  /* click 課程教材彈窗 - 確認按鈕 */
  const clickResourceModalOkButton = (resourceValue) => {
    const classRoomUrl = isClickSessionResourceButton
      ? `${process.env.REACT_APP_ONEBOOK_TEACHER_DOMAIN}/${resourceValue}`
      : `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${resourceValue}`;
    window.open(classRoomUrl, '_blank'); // 跳轉至 oneBook 教材
    toggleResourceModal();
  };

  /* 進入 OneBoard */
  const enterOneBoard = ({ session }) => {
    let url = '';
    const courseId = session.id;
    const userName = myOrganization.userProfile.nickname;
    const userId = profile.id;
    const usersLength = session.users?.length || 0;
    if (session?.mmsCourseId) {
      const isStudent = session.users.includes(profile.id);
      url = isStudent
        ? getOneBoardUrlForMMS({
            mmsCourseId: session.mmsCourseId,
            resources: session.resources,
            courseType: session.mmsCourseType,
            userName,
            userId,
          })
        : getOneBoardUrlForMMSObserver({
            mmsCourseId: session.mmsCourseId,
            resources: session.resources,
            courseType: session.mmsCourseType,
            userName,
            userId,
          });
    } else {
      url = getOneBoardUrlForOneLink({
        courseId,
        sessionsType: session.type,
        usersLength,
        resources: session.resources,
        userType,
        userName,
        userId,
        organizationId,
      });
    }
    window.open(url, '_blank');
  };

  /* 處理進入教室 */
  const handleEnterClassroom = ({ session }) => {
    const sessionType = session.type;
    switch (sessionType) {
      // 協作、互動、視訊課程
      case SESSION_TYPE.COLLABORATIVE:
      case SESSION_TYPE.INTERACTIVE:
      case SESSION_TYPE.VIDEO:
        enterOneBoard({ session });
        break;
      // 一般課程
      case SESSION_TYPE.NORMAL:
      default:
        clickSessionResource({ session, isClickSessionResourceButton: false })();
        break;
    }
  };

  return (
    <HomeGroupLayout>
      <UiHomeSessionPage>
        {isLoading ? (
          <LoadingCard />
        ) : (
          <>
            <UiTitleWrapper>
              <Box>
                <UiTitle>課程列表</UiTitle>
              </Box>
              {isDisplayCreatingCourseButton && (
                <Button icon="add" onClick={() => clickCreatingCourse()}>
                  新增課程
                </Button>
              )}
            </UiTitleWrapper>
            <HomeSessionTabs tab={tab} onChange={changeTab} />
            {
              {
                normal: (
                  <HomeSessionTableNormal
                    handleEnterClassroom={handleEnterClassroom}
                    clickSessionResource={clickSessionResource}
                  />
                ),
                done: (
                  <HomeSessionTableDone
                    handleEnterClassroom={handleEnterClassroom}
                    clickSessionResource={clickSessionResource}
                  />
                ),
                canceled: <HomeSessionTableCanceled />,
              }[tab]
            }
          </>
        )}
      </UiHomeSessionPage>

      {/* 課程教材、一般課程進入教室 彈窗 */
      isOpenResourceModal && (
        <ResourceModal
          session={session}
          isOpenResourceModal={isOpenResourceModal}
          onOk={clickResourceModalOkButton}
          onCancel={toggleResourceModal}
        />
      )}
    </HomeGroupLayout>
  );
};
