import {
  Breadcrumbs,
  Button,
  Loading,
  ChooseSeatModal,
  Modal,
} from 'components';
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useCustomer } from 'store/customer';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiMemberSeatsSelectTable,
  UiMemberSeatsWrapper,
  UiMemberSeatsContent,
  UiMemberSeatsItem,
  UiMemberSeatsButtonWrapper,
  UiMemberInfo,
  UiMemberNumber,
} from './MemberSeatsSelectTable.style';

/**
 * 設定座號學生列表
 */
const setNumbersStatus = {
  CANCEL: 'cancel',
  NOTALLSET: 'notAllSet'
};

export const MemberSeatsSelectTable = () => {
  const { classId, organizationId } = useParams();
  const history = useHistory();
  const [
    { customers },
    {
      getCustomers,
      updateCustomersAttendeeNumbers,
    }
  ] = useCustomer();

  const [{
    nowPage,
    rowsPage,
    listLoading,
    seatsData,
    isModalOpen,
    sendData,
    seatsNumber,
    nowCustomerId,
    seatsStatus,
    isStateModalOpen,
    isLoading,
  }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 100,
    listLoading: true,
    seatsData: [],
    isModalOpen: false,
    sendData: {},
    seatsNumber: null,
    nowCustomerId: null,
    seatsStatus: null,
    isStateModalOpen: false,
    isLoading: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      await getCustomers({ nowPage, rowsPage });
      setState({
        listLoading: false,
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    setState({
      seatsData: customers.seatsData,//component ChooseSeatModal use
      sendData: customers.sendData,//Api use
    });
  }, [customers]);

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '成員',
    },
    {
      name: '學生列表',
      link: `/home/class/${classId}/member`
    },
    {
      name: '設定座號',
    }
  ];
  //麵包屑
  const orgBreadcrumbsList = [
    {
      name: '成員',
      link: `/home/${organizationId}/class/${classId}/member`
    },
    {
      name: '設定座號',
    }
  ];

  const showSeatsModal = (id, num = null) => {
    setState({
      isModalOpen: true,
      seatsNumber: num !== null ? parseInt(num) : num,//目前選擇的學生座號
      nowCustomerId: id,//把目前要設定的ID存起來
    });
  };

  const getSeats = num => {
    if (seatsNumber)//之前的密碼取消選擇
      seatsData[seatsNumber - 1].isChoosed = false;
    if (num === null) {//取消號碼
      delete sendData[nowCustomerId];
    } else {//選擇新號碼
      sendData[nowCustomerId] = String(num);
      seatsData[num - 1].isChoosed = true;
    }
  };
  //座號選擇彈窗
  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };
  //訊息彈窗
  const getStateModalStateHandler = state => {
    setState({ isStateModalOpen: state });
  };

  const sendSeatsData = async () => {
    setState({
      isLoading: true
    });
    const isSuccess = await updateCustomersAttendeeNumbers({ numbers: sendData });
    setState({
      isLoading: false
    });
    if (isSuccess) {
      goToMemberTable();
    }
  };

  const goToMemberTable = () => {
    if (organizationId) {
      history.push(`/home/${organizationId}/class/${classId}/member`);
    } else {
      history.push(`/home/class/${classId}/member`);
    }
  };

  const submitHandler = () => {
    if (Object.keys(sendData).length < customers.data.length) {
      setState({
        seatsStatus: setNumbersStatus.NOTALLSET,
        isStateModalOpen: true,
      });
    } else {
      sendSeatsData();
    }
  };

  const cancelHandler = () => {
    setState({
      seatsStatus: setNumbersStatus.CANCEL,
      isStateModalOpen: true,
    });
  };
  //組件新增或刪除座號欄位時 更新
  const updateSeatsData = (data) => {
    setState({
      seatsData: data
    });
  };

  const numbersNotAllSetConfig = {
    text: '學生座號尚未設定完成，是否仍要保存目前設定、回到成員列表',
    confirmAction: () => sendSeatsData()
  };

  const numbersCancelConfig = {
    text: '您所設定的座號將都被捨棄不保存，是否仍要取消',
    confirmAction: () => goToMemberTable()
  };

  const ModalConfig = seatsStatus === 'cancel' ?
    numbersCancelConfig : numbersNotAllSetConfig;

  const buttons = [
    {
      text: '確認',
      func: ModalConfig.confirmAction
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  const EmptyBlock = ({ num }) => {
    switch (num) {
      case 2:
        return (
          <UiMemberSeatsItem />
        );
      case 1:
        return (
          <>
            <UiMemberSeatsItem />
            <UiMemberSeatsItem />
          </>
        );
      case 0:
      default:
        return (
          <></>
        );
    }
  };

  return (
    <UiMemberSeatsSelectTable>
      {
        organizationId ?
          <Breadcrumbs list={orgBreadcrumbsList} /> :
          <Breadcrumbs list={BreadcrumbsList} />
      }
      {listLoading ? <Loading /> :
        <>
          <UiMemberSeatsWrapper>
            <UiMemberSeatsContent>
              {
                customers && customers.data.map((item, index) => {
                  return (
                    <>
                      <UiMemberSeatsItem
                        key={index}
                      >
                        <UiMemberInfo>
                          {
                            sendData[item.id] &&
                            <UiMemberNumber>{sendData[item.id]}</UiMemberNumber>
                          }
                          <span>{item.nickname}</span>
                        </UiMemberInfo>
                        <div>
                          <Button
                            onClick={() => { showSeatsModal(item.id, sendData[item.id]); }}
                          >編輯</Button>
                        </div>
                      </UiMemberSeatsItem>
                      {
                        (index + 1) === customers.total &&
                        <EmptyBlock num={customers.total % 3} />
                      }

                    </>
                  );
                })
              }
            </UiMemberSeatsContent>
          </UiMemberSeatsWrapper>
          <UiMemberSeatsButtonWrapper>
            <Button
              buttonColor='cancel'
              loading={isLoading}
              onClick={() => { cancelHandler(); }}
            >取消</Button>
            <Button
              buttonColor='new'
              loading={isLoading}
              onClick={() => { submitHandler(); }}
            >確認</Button>
          </UiMemberSeatsButtonWrapper>
        </>
      }

      <ChooseSeatModal
        seats={seatsData}
        seatsNumber={seatsNumber}
        isOpen={isModalOpen}
        getSeats={getSeats}
        getModalState={getModalStateHandler}
        updateSeatsData={updateSeatsData}
      />
      <Modal
        isOpen={isStateModalOpen}
        text={ModalConfig.text}
        buttons={buttons}
        getModalState={getStateModalStateHandler}
      />
    </UiMemberSeatsSelectTable>
  );
};

MemberSeatsSelectTable.propTypes = {

};


