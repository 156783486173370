import { Icon } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { UiLatestNews } from './LatestNews.style';


/**
 * 最新通知
 */

export const LatestNews = ({
  onClick
}) => {
  return (
    <UiLatestNews onClick={onClick ? onClick : () => { }}>
      <Icon.Svg name="ChevronUp" />
      <div>最新通知</div>
    </UiLatestNews>
  );
};

LatestNews.propTypes = {
  onClick: PropTypes.func,
};


