import {
  FormControlLabel as MatFormControlLabel,
  Checkbox as MatCheckbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useSetState } from 'utils/hooks/useSetState';
import {
  UiCheckbox,
  UiRemoveLine,
} from './Checkbox.style';
/**
 * Checkbox
 */

const useStyles = makeStyles({
  root: {
    '&$checked': {
      color: '#ec7963 !important',
    },

    '& .active': {
      color: '#e1ce53',
    }
  },

  checked: {
    color: '#ec7963 !important',
  },
});

export const Checkbox = ({
  label = '',
  checkedValue = '',
  value = '',
  checked,
  type = '',
  className,
  disabled = false,
  onChange = () => { },
  style,
  ...props
}) => {
  const [{ isChecked }, setState] = useSetState({ isChecked: checked });
  const classes = useStyles();
  const changeHandler = value => event => {
    const nextCheck = !isChecked;
    setState({ isChecked: nextCheck });
    onChange(nextCheck ? value : '');
  };

  useEffect(() => {
    setState({ isChecked: checked });
  }, [checked]);

  return (
    <>
      {type === 'checkList' ?//公告用 點擊後有刪除線
        <UiCheckbox style={style}>
          <MatFormControlLabel
            control={
              <MatCheckbox
                value={value}
                color="primary"
                checked={(isChecked || disabled)}
                className={classes.root}
                {...props}
                onChange={changeHandler(checkedValue)}
                disabled={disabled}
              />
            }
            className={(isChecked || disabled) ? classes.checked : classes.root}
            label={label}
          />
          <UiRemoveLine showLine={(isChecked || disabled)} />
        </UiCheckbox>
        :
        <MatFormControlLabel
          control={
            <MatCheckbox
              color="primary"
              checked={isChecked}
              disabled={disabled}
              {...props}
              onChange={changeHandler(checkedValue)}
            />
          }
          style={style}
          label={label}
        />
      }

    </>
  );
};

Checkbox.propTypes = {
  label: PropTypes.node,
  props: PropTypes.any,
  checked: PropTypes.bool,
  checkedValue: PropTypes.string, // 拿來回傳用的value
  onChange: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string
};
