import { Icon, Button, Loading, OutlineButton, ResourceModal } from 'components';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getSessionById } from 'services/api/home/sessions';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiSessionSummary,
  UiSummaryTitle,
  UiSummaryClass,
  UiSummaryHostBox,
  UiSummaryIconBox,
  UiSummaryHostName,
  UiSummaryContent,
  UiSummaryInfo,
  UiInfoClassName,
  UiInfoClassTeacher,
  UiInfoClassDataTime,
  UiInfoClassButtonsBox,
  UiSummaryTime,
  UiTimeCardBox,
  UiTimeCard,
  UiTimeBox,
  UiTimeIcon,
  UiTimeText,
  UiTimeNumber,
  UiTimeSession,
} from './SessionSummary.style';

/**
 * 課堂摘要
 */

export const SessionSummary = ({ data }) => {
  const history = useHistory();
  const { setAlert } = useAlert();
  const [locationState, setLocationState] = useState({
    className: null,
    resourceId: null,
  });
  const [sessionApiData, setSessionApiData] = useState();
  const [{ myClasses }] = useClass();
  const [{ profile }] = useUser();
  const { dataInfo } = myClasses;
  const [{ isOpenResourceModal }, setState] = useSetState({
    isOpenResourceModal: false,
  });
  const isLoading = !sessionApiData;
  const session = sessionApiData?.[0];

  // 班級身份判斷
  const isUser = dataInfo?.users.some((user) => user === profile.id);
  // 班導
  const ownerName = dataInfo?.ownerName;

  const {
    sessionName,
    hostNickname,
    sessionId,
    startAt,
    endAt,
    sessionActualStartAt,
    sessionActualEndAt,
    userRecords,
    mmsCourseId,
  } = data;
  // 課程日期
  const sessionDate = format(new Date(startAt), 'yyyy.MM.dd');

  // 課程開始時間
  const sessionStartTime = format(new Date(startAt), 'HH:mm');
  // 課程結束時間
  const sessionEndTime = format(new Date(endAt), 'HH:mm');

  // 預計上課時間
  const sessionAt = new Date(endAt - startAt) / 1000 / 60;
  // 實際上課時間
  const sessionActualAt = useMemo(() => {
    if (sessionActualStartAt) {
      if (sessionActualEndAt) {
        return {
          text: Math.floor((sessionActualEndAt - sessionActualStartAt) / 60 / 1000),
          isNeedMinute: true,
        };
      } else {
        return {
          text: '上課中',
          isNeedMinute: false,
        };
      }
    } else {
      return {
        text: '0',
        isNeedMinute: true,
      };
    }
  }, [sessionActualStartAt, sessionActualEndAt]);

  // 出席人數(學生)
  const sessionAttendsArr = userRecords.filter((user) => user.attendances.length > 0);
  const sessionAttends = sessionAttendsArr.length;
  // 缺席人數(學生)
  const sessionAbsentArr = userRecords.filter((user) => user.attendances.length <= 0);
  const sessionAbsent = sessionAbsentArr.length;

  // 錄影
  const sessionVodHandle = () => {
    const url = `${process.env.REACT_APP_ONEBOARD_BS_DOMAIN}/player/${mmsCourseId || sessionId}`;
    window.open(url);
  };

  // 教材
  const sessionResourceHandle = () => {
    setState({ isOpenResourceModal: true });
  };

  // 學生視角
  const userTotalSessionPeriod = userRecords[0]?.totalSessionPeriod;
  const joinTime = userRecords[0]?.totalSessionPeriod && Math.floor(userRecords[0]?.totalSessionPeriod / 60);
  const firstJoinTime = userRecords[0]?.joinedSessionAt && format(new Date(userRecords[0].joinedSessionAt), 'HH:mm');
  const lastLeftTime = userRecords[0]?.leftSessionAt && format(new Date(userRecords[0].leftSessionAt), 'HH:mm');

  useEffect(() => {
    if (!history.location.state) return;
    const { className, resourceId } = history.location.state;
    setLocationState({
      className,
      resourceId,
    });
  }, [history.location.state]);

  /* click 課程教材彈窗 - 確認按鈕 */
  const clickResourceModalOkHandler = (resourceValue) => {
    const classRoomUrl = `${process.env.REACT_APP_ONEBOOK_TEACHER_DOMAIN}/${resourceValue}`;
    window.open(classRoomUrl, '_blank'); // 跳轉至 oneBook 教材
    setState({ isOpenResourceModal: false });
  };

  /* click 課程教材彈窗 - 取消按鈕 */
  const clickResourceModalCancelHandler = () => {
    setState({ isOpenResourceModal: false });
  };

  const fetchSession = async () => {
    const { data, status } = await getSessionById(sessionId);
    status === 'success' ? setSessionApiData(data) : setAlert('取得課程資料失敗', 'error');
  };

  useEffect(() => {
    fetchSession();
  }, []);

  return (
    <>
      {/* 課程教材、一般課程進入教室 彈窗 */
      isOpenResourceModal && (
        <ResourceModal
          session={session}
          isOpenResourceModal={isOpenResourceModal}
          onOk={clickResourceModalOkHandler}
          onCancel={clickResourceModalCancelHandler}
        />
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <UiSessionSummary>
          <UiSummaryTitle>
            <UiSummaryClass>{locationState.className}</UiSummaryClass>
            <UiSummaryHostBox>
              <UiSummaryIconBox>
                <Icon.Svg name="Vector" color="#a1a4b1" />
                <span>導師</span>
              </UiSummaryIconBox>
              <UiSummaryHostName>{ownerName}</UiSummaryHostName>
            </UiSummaryHostBox>
          </UiSummaryTitle>

          <UiSummaryContent>
            <UiSummaryInfo>
              <UiInfoClassName>{sessionName}</UiInfoClassName>
              <UiInfoClassTeacher>{hostNickname}</UiInfoClassTeacher>
              <UiInfoClassDataTime>
                <span>{sessionDate}</span>
                <span>
                  {sessionStartTime} - {sessionEndTime}
                </span>
              </UiInfoClassDataTime>
              <UiInfoClassButtonsBox>
                <Button iconSvg="FullVideo" radio="50px" onClick={sessionVodHandle}>
                  課程錄影
                </Button>
                <OutlineButton icon="BookOpen" radio="50px" buttonColor="info" onClick={sessionResourceHandle}>
                  查看教材
                </OutlineButton>
              </UiInfoClassButtonsBox>
            </UiSummaryInfo>
            <UiSummaryTime>
              <UiTimeCardBox>
                {!isUser && (
                  <UiTimeCard>
                    <UiTimeIcon>
                      <Icon.Svg name="Calendar" color="#fff" size="2rem" />
                    </UiTimeIcon>
                    <UiTimeBox>
                      <UiTimeText>預定課程時長</UiTimeText>
                      <UiTimeNumber haveNumber={sessionAt}>
                        {sessionAt} <span>分</span>
                      </UiTimeNumber>
                    </UiTimeBox>
                  </UiTimeCard>
                )}
                <UiTimeCard>
                  <UiTimeIcon>
                    <Icon.Svg name="Clock" color="#fff" size="2rem" />
                  </UiTimeIcon>
                  <UiTimeBox>
                    <UiTimeText>實際課程時長</UiTimeText>
                    <UiTimeNumber haveNumber={sessionActualAt}>
                      {sessionActualAt.text}
                      {sessionActualAt.isNeedMinute && <span>分</span>}
                    </UiTimeNumber>
                  </UiTimeBox>
                </UiTimeCard>
                {isUser && (
                  <UiTimeCard>
                    <UiTimeIcon>
                      <Icon.Svg name="Hourglass" color="#fff" size="2rem" />
                    </UiTimeIcon>
                    <UiTimeBox>
                      <UiTimeText>學生上課時長</UiTimeText>
                      <UiTimeNumber haveNumber={userTotalSessionPeriod}>
                        {userTotalSessionPeriod ? (
                          <>
                            {joinTime} <span>分</span>
                          </>
                        ) : (
                          '無紀錄'
                        )}
                      </UiTimeNumber>
                    </UiTimeBox>
                  </UiTimeCard>
                )}
              </UiTimeCardBox>
              <UiTimeCardBox>
                {!isUser && (
                  <>
                    <UiTimeCard>
                      <UiTimeIcon>
                        <Icon.Svg name="DoorIn" color="#fff" size="2rem" />
                      </UiTimeIcon>
                      <UiTimeBox>
                        <UiTimeText>出席學生</UiTimeText>
                        <UiTimeNumber haveNumber={sessionAttends}>
                          {sessionAttends}
                          <span>人</span>
                        </UiTimeNumber>
                      </UiTimeBox>
                    </UiTimeCard>
                    <UiTimeCard>
                      <UiTimeIcon>
                        <Icon.Svg name="DoorOut" color="#fff" size="2rem" />
                      </UiTimeIcon>
                      <UiTimeBox>
                        <UiTimeText>缺席學生</UiTimeText>
                        <UiTimeNumber haveNumber={sessionAbsent}>
                          {sessionAbsent}
                          <span>人</span>
                        </UiTimeNumber>
                      </UiTimeBox>
                    </UiTimeCard>
                  </>
                )}
                {isUser && (
                  <>
                    <UiTimeCard>
                      <UiTimeIcon>
                        <Icon.Svg name="DoorIn" color="#fff" size="2rem" />
                      </UiTimeIcon>
                      <UiTimeBox>
                        <UiTimeText>進入教室時間</UiTimeText>
                        <UiTimeNumber haveNumber={firstJoinTime}>{firstJoinTime || '無紀錄'}</UiTimeNumber>
                        {sessionActualStartAt && (
                          <UiTimeSession>開始上課時間 {format(new Date(sessionActualStartAt), 'HH:mm')}</UiTimeSession>
                        )}
                      </UiTimeBox>
                    </UiTimeCard>
                    <UiTimeCard>
                      <UiTimeIcon>
                        <Icon.Svg name="DoorOut" color="#fff" size="2rem" />
                      </UiTimeIcon>
                      <UiTimeBox>
                        <UiTimeText>離開教室時間</UiTimeText>
                        <UiTimeNumber haveNumber={lastLeftTime}>{lastLeftTime || '無紀錄'}</UiTimeNumber>
                        {sessionActualEndAt && (
                          <UiTimeSession>結束上課時間 {format(new Date(sessionActualEndAt), 'HH:mm')}</UiTimeSession>
                        )}
                      </UiTimeBox>
                    </UiTimeCard>
                  </>
                )}
              </UiTimeCardBox>
            </UiSummaryTime>
          </UiSummaryContent>
        </UiSessionSummary>
      )}
    </>
  );
};

SessionSummary.propTypes = {
  data: PropTypes.object,
};
