import forest from 'assets/images/forest.svg';
import { Table, Button, EmptyPrompt, Loading } from 'components';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getStudentAllVideoRecord } from 'services/api/home/video';
import { usePageVisibility } from 'utils/hooks/usePageVisibility';
import { useSetState } from 'utils/hooks/useSetState';
import { paginationFilter } from 'utils/pagination';
import { UiHomeVideoHistoryTable } from './HomeVideoHistoryTable.style';

/**
 * 查看學生觀看紀錄
 */

export const HomeVideoHistoryTable = () => {
  const isVisible = usePageVisibility();
  const history = useHistory();
  const { organizationId, classId, userId } = useParams();
  const [{ data, isLoading, nowPage, rowsPage }, setState] = useSetState({
    data: [],
    isLoading: true,
    nowPage: 0,
    rowsPage: 100,
  });

  const videos = useMemo(() => {
    if (!data) return [];
    const videos = paginationFilter(data, nowPage, rowsPage);
    const newVideos = videos.map((video) => {
      const viewTime = format(video.addAt, 'yyyy/MM/dd HH:mm');
      return {
        ...video,
        viewTime,
      };
    });
    return newVideos;
  }, [data, nowPage, rowsPage]);

  const changePage_Rows = (params) => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage,
    });
  };

  const schema = {
    item: {
      name: '品項',
      defaultValue: '-',
    },
    name: {
      name: '影片名稱',
      defaultValue: '--',
    },
    time: {
      name: '影片時長',
      defaultValue: '--',
    },
    viewTime: {
      name: '學生觀看時間',
      defaultValue: '',
    },
  };

  const fetchStudentVideoRecord = async () => {
    setState({ isLoading: true });
    const res = await getStudentAllVideoRecord({ classId, studentId: userId });
    if (res.isSuccess) {
      const nextData = res.data.sort((a, b) => b.addAt - a.addAt);
      setState({ data: nextData });
    }
    setState({ isLoading: false });
  };

  useEffect(() => {
    fetchStudentVideoRecord();
  }, [isVisible]);

  // const tableData = paginationFilter(data, nowPage, rowsPage);

  return (
    <UiHomeVideoHistoryTable>
      <div className="flex">
        <h4>觀看紀錄列表</h4>
        <Button buttonColor="new" onClick={() => history.push(`/home/${organizationId}/class/${classId}/video?tab=0`)}>
          返回上一頁
        </Button>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Table
            data={videos}
            schema={schema}
            changePage_Rows={changePage_Rows}
            totalPage={data.length}
            nowPage={nowPage}
          />

          {videos.length === 0 && (
            <EmptyPrompt img={forest} message="該學生尚無任何觀看紀錄" messageColor={'#8B90A0'} />
          )}
        </>
      )}
    </UiHomeVideoHistoryTable>
  );
};
