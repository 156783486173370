import { Box, Typography } from '@material-ui/core';
import oneClassLogoSvg from 'assets/svg/one-class-logo.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { UiOneClassLogo } from './OneClassLogo.style';

export const OneClassLogo = ({ logoSrc = oneClassLogoSvg, isShowLabel = true, logoImgStyle, logoLabelStyle }) => {
  return (
    <UiOneClassLogo>
      <Box component="img" src={logoSrc} className="logo-img" style={{ ...logoImgStyle }} />
      {isShowLabel && (
        <Typography className="logo-label" style={{ ...logoLabelStyle }}>
          OneClass
        </Typography>
      )}
    </UiOneClassLogo>
  );
};

OneClassLogo.propTypes = {
  logoSrc: PropTypes.string,
  isShowLabel: PropTypes.bool,
  logoImgStyle: PropTypes.shape(),
  logoLabelStyle: PropTypes.shape(),
};
