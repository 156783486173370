import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const UiCountyLoginForm = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '450px',
  width: '100%',
  maxHeight: '830px',
  padding: '60px 20px 20px 20px',
  // backgroundRepeat: 'no-repeat',
  // backgroundPosition: 'top -100px center',
}));

export const UiMain = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '36px',
  maxWidth: '400px',
  width: '100%',
  padding: '24px',
  backgroundColor: '#FFF',
  border: '1px solid #F4F6F8',
  borderRadius: '16px',
  boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);',

  '& > .logos-box': {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',

    '& > .divider': {
      color: '#596066',
      fontFamily: 'Noto Sans TC',
      fontSize: '26.5px',
      fontWeight: 500,
    },

    '& > .county-logo-box': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '84px',
      '& > img': {
        width: '100%',
      },
    },
  },
}));

export const UiForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '& > .inputs-box': {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',

    '& > .MuiFormControlLabel-root': {
      gap: '4px',
      alignItems: 'flex-start',
      margin: 'unset',

      '& > .MuiFormControl-root > .MuiInputBase-root': {
        borderRadius: '8px',
        '& > .MuiInputBase-input': {
          height: '40px',
          boxSizing: 'border-box',
        },
      },

      '& > .MuiTypography-root': {
        color: '#A4A4A4',
        fontFamily: 'Noto Sans TC',
        fontSize: '16px',
        fontWeight: 500,
      },
    },
  },

  '& > .remember-me-and-forget-password-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > label > .MuiCheckbox-root': {
      color: '#D1D1D1',
      '&.Mui-checked': {
        color: '#EC7963',
      },

      '& .MuiSvgIcon-root': {
        width: '24px',
        height: '24px',
      },
    },

    '& > a': {
      color: '#A4A4A4',
      fontFamily: 'Noto Sans TC',
      fontSize: '14px',
      fontWeight: 400,
      textDecoration: 'none',
    },
  },

  '& > .buttons-box': {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    marginTop: '32px',

    '& > button': {
      border: '1px solid #D1D1D1',
      borderRadius: '8px',
      width: '100%',
    },
    '& > .cloud-login-button': {
      backgroundColor: '#B8E2EF',
      color: '#4E4E4E',
    },
  },
}));
