import emptyImage from 'assets/images/empty.png';
import { InlineSelect, Table, Icon, OnlineExamModal } from 'components';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createGroupOnlineQuizzes } from 'services/api/home/quizzes';
import { getPrivateQuizzes } from 'services/api/home/users';
import { useClass } from 'store/class';
import { useAlert } from 'utils/hooks/useAlert';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiSetAQuestion,
  UiSelectBox,
  UiIconBox,
  UiPdf,
  UiPlane,
  UiNoDataBox,
  UiPictureBox,
  UiNoDataText,
} from './SetAQuestion.style';

/**
 * 試卷列表
 */

export const SetAQuestion = () => {
  // const [{ subjectArr, countyName,}, setState] = useSetState({
  //   subjectArr: [],
  //   countyName: '',
  // });

  // store
  const { classId } = useParams();
  const groupId = classId;

  const [{ myClasses }] = useClass();
  const {
    dataInfo: { userType },
  } = myClasses;
  const isTeacher = userType === 'teacher' ? true : false;

  const [
    {
      nowPage,
      rowsPage,
      isOpen,
      subjectCode,
      productCode,
      quizzes,
      quizzesTotal,
      subjectOptions,
      productInfoOptions,
      testNowPage,
      examModalObj,
      examPaperId,
    },
    setState,
  ] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    isOpen: false,
    subjectCode: '',
    productCode: '',
    quizzes: [],
    quizzesTotal: 0,
    subjectOptions: [],
    productInfoOptions: [],
    testNowPage: 0,
    testRowsPage: 10,
    examModalObj: null,
    examPaperId: null,
  });
  const { setAlert } = useAlert();

  const quizzesSchema = {
    productInfoText: {
      name: '品項名稱',
      defaultValue: '-',
    },
    subjectText: {
      name: '科目',
      defaultValue: '-',
    },
    productNumberText: {
      name: '回數',
      defaultValue: '-',
    },
  };

  const toPdf = (id) => {
    window.open(`${process.env.REACT_APP_ONLINEQUIZ_DOMAIN}${id}/quiz.pdf`);
  };

  const changeQuizzesPage_Rows = (params) => {
    const { newPage, newRowsPage } = params;
    setState({
      testNowPage: newPage,
      testRowsPage: newRowsPage,
    });
  };

  const ActionQuizzesComponents = (obj) => {
    return (
      <UiIconBox>
        <UiPdf onClick={() => toPdf(obj.params.itemId)}>
          <Icon.Svg name="Pdf" />
        </UiPdf>
        <div></div>
        <UiPlane onClick={() => setState({ isOpen: true, examPaperId: obj.params.itemId })}>
          <Icon.Svg name="Plane" />
        </UiPlane>
      </UiIconBox>
    );
  };

  const onCancel = () => {
    setState({ isOpen: false });
  };

  const onClick = () => {
    setState({ isOpen: false });
    fetchCreateGroupOnlineQuizzes(examModalObj);
  };

  const onChange = (obj) => {
    setState({ examModalObj: obj });
  };

  const selectChangeHandler = (value, key) => {
    setState({ [key]: value });
  };

  const fetchQuizzes = async (params) => {
    const { data, isSuccess } = await getPrivateQuizzes(params);
    if (isSuccess) {
      const quizzesData = data.quizzes.map((quiz) => {
        return {
          ...quiz,
          subjectText: quiz.subject.name,
          productInfoText: quiz.productInfo.name,
          productNumberText: quiz.productNumber.name,
        };
      });
      const defaultOption = [
        {
          name: '全部科目',
          value: '',
        },
      ];
      const subjectData = defaultOption.concat(
        data.options.subject.map((subject) => {
          return {
            name: subject.name,
            value: subject.code,
          };
        }),
      );
      const defaultProdOption = [
        {
          name: '全部品項',
          value: '',
        },
      ];
      const productInfoData = defaultProdOption.concat(
        data.options.product.map((product) => {
          return {
            name: product.name,
            value: product.code,
          };
        }),
      );
      setState({
        quizzes: quizzesData,
        quizzesTotal: data.total,
        subjectOptions: subjectData,
        productInfoOptions: productInfoData,
      });
    } else {
      setState({
        quizzes: [],
        quizzesTotal: 0,
        subjectOptions: [],
        productInfoOptions: [],
      });
    }
  };

  const fetchCreateGroupOnlineQuizzes = async (params) => {
    const { isSuccess } = await createGroupOnlineQuizzes(groupId, params);
    if (isSuccess) {
      setAlert('派卷成功!', 'success');
    } else {
      setAlert('派卷失敗!', 'wrarning');
    }
  };

  useEffect(() => {
    // 初始 縣市
    // const nextSubjectArr = Object.entries(COUNTY).map(([, item]) => ({
    //   name: item,
    //   value: item,
    // }));
    // setState({
    //   subjectArr: nextSubjectArr,
    // });
  }, []);

  useEffect(() => {
    const params = {
      nowPage,
      rowsPage,
      subjectCode,
      productCode,
    };

    fetchQuizzes(params);
  }, [nowPage, rowsPage, subjectCode, productCode]);

  // useEffect(() => {
  //   console.log('data', data);
  //   setState({examList: data})
  // },[data])

  return (
    <UiSetAQuestion>
      {quizzes && (
        <UiSelectBox>
          <InlineSelect
            label="科目"
            placeholder="科目"
            options={subjectOptions}
            value={subjectCode}
            onChange={(value) => selectChangeHandler(value, 'subjectCode')}
          />
          <InlineSelect
            label="品項"
            placeholder="品項"
            options={productInfoOptions}
            value={productCode}
            onChange={(value) => selectChangeHandler(value, 'productCode')}
          />
        </UiSelectBox>
      )}
      {!quizzes && (
        <UiNoDataBox>
          <UiPictureBox path={emptyImage} />
          <UiNoDataText>
            {isTeacher && (
              <>
                <div>您尚未購買任何考卷</div>
                <div>如欲使用此功能請洽各區南一業務購買</div>
              </>
            )}
            {!isTeacher && <div>目前老師尚無指派測驗</div>}
          </UiNoDataText>
        </UiNoDataBox>
      )}
      {quizzes && (
        <>
          <Table
            data={quizzes}
            schema={quizzesSchema}
            changePage_Rows={changeQuizzesPage_Rows}
            totalPage={quizzesTotal}
            ActionComponents={ActionQuizzesComponents}
            nowPage={testNowPage}
          />
          <OnlineExamModal
            open={isOpen}
            examPaperId={examPaperId}
            onClick={onClick}
            onCancel={onCancel}
            onChange={onChange}
          />
        </>
      )}
    </UiSetAQuestion>
  );
};

SetAQuestion.propTypes = {};
