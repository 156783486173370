import { TextField, NativeSelect, FormControl, InputBase, Tooltip as MatTooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import forest from 'assets/images/forest.svg';
import qrCodeOversea from 'assets/images/qrCodeOversea.svg';
import qrCodeSchool from 'assets/images/qrCodeSchool.svg';
import qrCodeTutor from 'assets/images/qrCodeTutor.svg';
import {
  Button,
  Modal,
  Icon,
  BsModal,
  OrganizationContainer,
  GroupCard,
  Loading,
  EmptyPrompt,
  BlindBrick
} from 'components';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getOrganizationsByScroll as getOrganizationsByScrollApi
} from 'services/api/organization/organizations';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { UiflexBox } from 'styles';
import { useIntersectionObserver } from 'utils/hooks/useIntersectionObserver';
import { useSchoolYear } from 'utils/hooks/useSchoolYear';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiMyOrganizations,
  UiModal,
  UiModalInput,
  UiUnitButton,
  UiCreateUnitBox,
  UiQrModal,
  UiQrTitle,
  UiQrBox,
  UiQrText,
  UiEmptyContent,
} from './MyOrganizations.style';

const ALL_SCHOOL_YEAR = 'all';
const SCHOOL = 'SCHOOL';
const TUTOR = 'TUTOR';
const OVERSEA = 'OVERSEA';
const CREATE_CLASS_UNITS = [
  {
    icon: 'School',
    label: <>國中小及高中<br />生生用平板專案</>,
    value: SCHOOL
  },
  {
    icon: 'Vector',
    label: '補教服務專區',
    value: TUTOR
  },
  {
    icon: 'AirPlane',
    label: '海外僑校',
    value: OVERSEA
  },
];
const CREATE_CLASS_QRCODE_CONTENTS = {
  SCHOOL: {
    title: '國中小及高中 生生用平板專案',
    description: '加入後，請回傳您的所在縣市、機構名稱（例：台北市、南一國中）',
    qrCodeImage: qrCodeSchool,
    qrCodeUrl: 'https://line.me/R/ti/p/@170hbltp'
  },
  TUTOR: {
    title: '補教服務專區',
    description: <>加入後，回傳您的所在縣市、學區學校、補習班名<br />（例：台北市、南一國中、南一補習班）</>,
    qrCodeImage: qrCodeTutor,
    qrCodeUrl: 'https://lin.ee/JnvPqmw'
  },
  OVERSEA: {
    title: '海外僑校 LINE 客服',
    description: '',
    qrCodeImage: qrCodeOversea,
    qrCodeUrl: 'https://line.me/R/ti/p/%40412ljgqi'
  }
};

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    zIndex: 2,
  },

  margin: {
    margin: theme.spacing(1),
    width: '120px',
  },
}));

/**
 * 我的組織列表
 */

export const MyOrganizations = () => {
  const history = useHistory();
  const classes = useStyles();
  const ref = useRef(null);
  const [
    { profile, isNeedReload }, { isNeedReloadDashBoard }
  ] = useUser();
  const [, { joinClassByInvitationCode }] = useClass();
  const { schoolYearOptionsWithAll: schoolYearOptions } = useSchoolYear();
  const [isFetching, setIsFetching] = useState(false);
  const { isTeacher } = profile;
  const [{
    isLoading,
    isOpenModal,
    isModalBtnDisabled,
    InviteCode,
    year,
    isOpenBsModal,
    organizationsData,
    organizationsTotal,
    organizationsNowPage,
    organizationsRowsPage,
    isQrModal,
    qrCodeContent
  }, setState] = useSetState({
    isLoading: false,
    isOpenModal: false,
    isModalBtnDisabled: true,
    InviteCode: null,
    year: ALL_SCHOOL_YEAR,
    isOpenBsModal: false,
    organizationsData: [],
    organizationsTotal: 0,
    organizationsNowPage: 0,
    organizationsRowsPage: 10,
    isQrModal: false,
    qrCodeContent: {
      title: '',
      description: '',
      qrCodeImage: '',
      qrCodeUrl: ''
    }
  });
  const isEmptyUser = useMemo(() => {
    return profile.organizations.length === 0;
  }, [profile]);

  const onFilterChange = key => event => setState({ [key]: event.target.value });

  const isBottomVisible = useIntersectionObserver(ref, {
    threshold: 0
  }, false);

  const goCreateFreeGroup = () => {
    if (isTeacher) {
      history.push('/home/school/create');
    } else {
      setState({ isOpenBsModal: true });
    }
  };

  const openModalHandler = () => {
    setState({ isOpenModal: true });
  };

  const getModalStateHandler = state => {
    setState({ isOpenModal: state });
  };

  // 輸入代碼改變事件
  const inputChangeHandler = value => {
    setState({
      InviteCode: value.replace(/[^a-zA-Z]/gi, '').toUpperCase(),
    });
    if (value.length >= 6) {
      setState({
        isModalBtnDisabled: false,
      });
    } else {
      setState({ isModalBtnDisabled: true });
    }
  };

  // 加入班級事件
  const joinClassHandler = async () => {
    setState({ isLoading: true });
    const isSuccess = await joinClassByInvitationCode(InviteCode);
    setState({ isLoading: false });
    if (isSuccess) setState({ isOpenModal: false });
  };

  const fetchOrganizations = async () => {
    setIsFetching(true);
    const params = {
      nowPage: isNeedReload ? 0 : organizationsNowPage,
      rowsPage: organizationsRowsPage
    };
    const {
      data: { organizations, total },
      isSuccess
    } = await getOrganizationsByScrollApi(params);
    setIsFetching(false);
    if (isSuccess) {
      const nextOrganizationsData = organizations.map(item => {
        const isOwner = (item.organization?.permissionGroups && item.organization?.permissionGroups.length > 0);
        return {
          ...item.organization,
          isOwner,
          role: item.userProfile.role,
          userId: item.userProfile.id
        };
      });

      const newOrganizationsData = isNeedReload ? nextOrganizationsData : organizationsData.concat(nextOrganizationsData);
      setState({
        organizationsData: newOrganizationsData,
        organizationsTotal: total,
        organizationsNowPage: isNeedReload ? 0 : organizationsNowPage + 1
      });
      isNeedReloadDashBoard(false);
    }
  };

  const openQrCodeModal = (value) => {
    setState({
      isQrModal: true,
      qrCodeContent: CREATE_CLASS_QRCODE_CONTENTS[value]
    });
  };

  useEffect(() => {
    if (profile.teacherVerificationApplication) {
      setState({ validationTeacherForm: profile.teacherVerificationApplication });
    }
  }, [profile]);

  useEffect(() => {
    (isBottomVisible && organizationsData.length < organizationsTotal) && fetchOrganizations();
  }, [isBottomVisible]);

  useEffect(() => {
    //第一次
    fetchOrganizations();
  }, [isNeedReload]);


  return (
    <UiMyOrganizations>
      <BlindBrick className='accesskey' id='accessibility_center_container' accessKey='c' href='#accessibility_center_container' title='中央內容區塊，為本頁主要內容區。' />
      <div className="actionBox">
        <div className="title" tabIndex={0}>我的班級</div>
        <UiflexBox>
          <FormControl className={classes.margin}>
            <NativeSelect
              id="demo-customized-select-native"
              value={year}
              label="學年度"
              placeholder="篩選學年度"
              onChange={onFilterChange('year')}
              input={<BootstrapInput />}
            >
              {schoolYearOptions.map((item, index) => {
                return (
                  <option key={index} value={item.value}>{item.name}</option>
                );
              })}
            </NativeSelect>
          </FormControl>
          <MatTooltip arrow title="輸入班級代碼，加入您的班級" open={false}>
            <div style={{ marginRight: '10px' }}>
              <Button
                buttonColor='new'
                icon='add'
                onClick={openModalHandler}
              >加入班級</Button>
            </div>
          </MatTooltip>
          <Button
            tabIndex={-1}
            icon='add'
            onClick={() => goCreateFreeGroup()}
          >新建班級
          </Button>
        </UiflexBox>
      </div>
      {
        isFetching ? <Loading /> :
          isEmptyUser ?
            <UiEmptyContent>
              <EmptyPrompt img={forest} title='尚未加入任何班級' message={<p>點擊加入班級按鈕，輸入班級代碼<br />或查看右上角通知，接受老師邀請並加入班級</p>} messageColor={'#8B90A0'}></EmptyPrompt>
            </UiEmptyContent> :
            organizationsData.length > 0 &&
            organizationsData.map(organization => {
              return (
                <OrganizationContainer
                  punchClockSetting={organization.punchClockSetting}
                  key={organization.id}
                  name={organization.officialName}
                  organizationId={organization.id}
                  isOwner={organization.isOwner}
                >
                  <GroupCard
                    organizationId={organization.id}
                    year={year}
                    isOwner={organization.isOwner}
                    role={organization.role}
                    userId={organization.userId}
                  />
                </OrganizationContainer>
              );
            })
      }
      <div ref={ref} style={{ width: '100%', height: '20px' }}></div>
      <Modal
        isOpen={isOpenModal}
        size='small'
        title='加入班級'
        isIconShow={false}
        content={
          <UiModal>
            <UiModalInput>
              <TextField
                placeholder="請輸入班級代碼(共六碼大寫英文字母)"
                variant="outlined"
                inputProps={{ maxLength: 6 }}
                value={InviteCode}
                onChange={e => inputChangeHandler(e.target.value)}
              />
            </UiModalInput>
            <div style={{ margin: '8px 0', display: 'flex', justifyContent: 'space-between' }}>
              <Button buttonColor="cancel" onClick={() => setState({ isOpenModal: false })}>取消</Button>
              <Button
                buttonColor="new"
                loading={isLoading}
                disabled={isModalBtnDisabled}
                onClick={joinClassHandler}
              >加入班級</Button>
            </div>
          </UiModal>
        }
        getModalState={getModalStateHandler}
      />
      <BsModal
        open={isOpenBsModal}
        title='選擇您的所屬單位'
        oKDisplay={false}
        cancelDisplay={false}
        onClose={() => setState({ isOpenBsModal: false })}
        width='auto'
      >
        <UiCreateUnitBox>
          {CREATE_CLASS_UNITS.map(unit =>
            <UiUnitButton key={unit.value} onClick={() => openQrCodeModal(unit.value)}>
              <Icon.Svg name={unit.icon} />
              <div>{unit.label}</div>
            </UiUnitButton>
          )}
        </UiCreateUnitBox>
      </BsModal>
      <BsModal
        open={isQrModal}
        onCancel={() => setState({ isQrModal: !isQrModal })}
        oKDisplay={false}
        cancelDisplay={false}
        backgroundColor="#FEF2F0"
        width='640px'
        height='421px'
      >
        <UiQrModal>
          <UiQrTitle>加入 LINE 客服，由專人為您服務！</UiQrTitle>
          <UiQrBox
            href={qrCodeContent.qrCodeUrl}
            target='_blank'
            rel="noopener noreferrer"
          >
            <img src={qrCodeContent.qrCodeImage} alt={qrCodeContent.qrCodeUrl} />
          </UiQrBox>
          <UiQrText>
            <div className='title'>{qrCodeContent.title}</div>
            <div className='description'>{qrCodeContent.description}</div>
          </UiQrText>
        </UiQrModal>
      </BsModal>
    </UiMyOrganizations>
  );
};


