import { stringify } from 'query-string';
import { Api } from 'services/api';

/**
 * @取得&查詢 organizations List
 * @param {string} ownerName
 * @param {string} ownerMobileNumber
 * @param {string} officialName
 * @param {string} skip
 * @param {string} limit
 *
 * @return {object} organizations list
 */
export const getOrgs = async params => {
  const { ownerName, ownerMobileNumber, officialName, nowPage, rowsPage } = params;

  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };

  if (nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }

  if (ownerName) {
    queryParams.ownerName = ownerName;
  }

  if (ownerMobileNumber) {
    queryParams.ownerMobileNumber = ownerMobileNumber;
  }

  if (officialName) {
    queryParams.officialName = officialName;
  }

  const querystring = await stringify(queryParams);
  const response = await Api.get(`/admin/organizations?${querystring}`);

  return response;
};

/**
 * 取得 organization information
 * @param {string} organizationId
 *
 * @return {object} organization sign information
 */
export const getOrgInfo = async id => {
  const response = await Api.get(`/admin/organizations/${id}`);
  return response;
};

/**
 * 建立 organization information
 * @param {string} id
 * @param {string} ownerId
 * @param {string} ownerName
 * @param {string} ownerMobileNumber
 * @param {string} officialName
 * @param {string} GUINumber
 * @param {string} cityName
 * @param {string} address
 *
 * @return {object} organization information
 */
export const createOrgInfo = async (params = {}) => {
  const response = await Api.post('/admin/organizations/', params);
  return response;
};

/**
 * 更新 organization information
 * @param {string} officialName
 * @param {string} status
 * @param {string} GUINumber
 * @param {string} comment
 *
 * @return {object} organization information
 */
export const updateOrgInfo = async (id, params = {}) => {
  const response = await Api.put(`/admin/organizations/${id}`, params);
  return response;
};
