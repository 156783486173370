import { HomeGroupLayout, HomeAttendanceStudent, HomeAttendanceTeacher } from 'components';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from 'store/user';

export const HomeAttendancePage = () => {
  const [{ myAllOrganizations }] = useUser();
  const { organizationId } = useParams();

  const isTeacher = useMemo(() => {
    if (!myAllOrganizations?.dataMap || !Object.keys(myAllOrganizations?.dataMap).length) return null;
    const { userProfile } = myAllOrganizations.dataMap[organizationId];
    const { role } = userProfile;
    if (role === 'staff') {
      return true;
    } else if (role === 'customer') {
      return false;
    } else {
      return null;
    }
  }, [myAllOrganizations]);

  const chooseAttendance = () => {
    if (isTeacher !== null) {
      if (isTeacher) {
        return <HomeAttendanceTeacher></HomeAttendanceTeacher>;
      } else {
        return <HomeAttendanceStudent></HomeAttendanceStudent>;
      }
    }
  };

  return <HomeGroupLayout showSideBar={false}>{chooseAttendance()}</HomeGroupLayout>;
};
