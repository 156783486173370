import { Icon } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import { UiSlider, UiArrowButton } from './Slider.style';

/**
 * Slider https://react-slick.neostack.com/docs/api
 */

// eslint-disable-next-line react/prop-types
const ArrowButton = ({ onClick, left, right }) => {
  let ariaLabelText = '';
  if (left) {
    ariaLabelText = '切換上一張圖片';
  }
  if (right) {
    ariaLabelText = '切換下一張圖片';
  }

  return (
    <UiArrowButton aria-label={ariaLabelText} onClick={onClick} left={left} right={right}>
      {left && <Icon name="chevronLeft" />}
      {right && <Icon name="chevronRight" />}
    </UiArrowButton>
  );
};
export const Slider = ({ config = {}, initalSlide = 0, children }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    accessibility: true,
    nextArrow: <ArrowButton right />,
    prevArrow: <ArrowButton left />,
    ...config,
  };

  return (
    <UiSlider ref={(slider) => slider && slider.slickGoTo(initalSlide)} {...settings}>
      {children}
    </UiSlider>
  );
};

Slider.propTypes = {
  config: PropTypes.object,
  initalSlide: PropTypes.number,
  children: PropTypes.node,
};
