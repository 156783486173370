import { NativeSelect, FormControl, InputBase } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * 瘦身Select
 */

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    zIndex: 2,
  },

  margin: {
    margin: 0,
    width: '120px',
  },
}));

export const InlineSelect = ({ onChange, value, options = [], label = '', placeholder = '', disabled, style }) => {
  const classes = useStyles();
  const onChangeHandle = (e) => {
    onChange && onChange(e.target.value);
  };

  return (
    <FormControl className={classes.margin} style={style}>
      <NativeSelect
        id="demo-customized-select-native"
        value={value}
        label={label}
        placeholder={placeholder}
        onChange={onChangeHandle}
        input={<BootstrapInput />}
        disabled={disabled}
      >
        {options.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          );
        })}
      </NativeSelect>
    </FormControl>
  );
};

InlineSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};
