import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const UiCountyLoginPage = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  height: '100dvh',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom',
}));
