import { Table, Button } from 'components';
import React, { useEffect } from 'react';
import { useUser } from 'store/user';
import { UiTable, UiTableButtonBox } from 'styles';
import { useSetState } from 'utils/hooks/useSetState';
import { UiTableRow } from './HomeInvitationTable.style';

/**
 * 邀請中列表Table
 */
const schema = {
  nickname: {
    name: '教師暱稱',
    defaultValue: '無'
  }
};

const memberSchema = {
  nickname: {
    name: '學生暱稱',
    defaultValue: '無'
  }
};


export const HomeInvitationTable = () => {
  const [{
    groupTeacherInvitations,
    groupMemberInvitations
  }, {
    getGroupTeacherInvitations,
    getGroupMemberInvitations,
    cancelGroupInvitation
  }] = useUser();
  const [{
    nowPage,
    rowsPage,
    nowMemberPage,
    rowsMemberPage,
    isLoading
  }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    nowMemberPage: 0,
    rowsMemberPage: 10,
    isLoading: false
  });

  // table 操作
  const ActionComponents = ({ params }) => {
    return (
      <UiTableButtonBox>
        <Button
          onClick={() => doCancelGroupInvitation(params)}
          isLoading={isLoading}
        >
          取消邀請
        </Button>
      </UiTableButtonBox >
    );
  };

  const doCancelGroupInvitation = async (params) => {
    const { userId, invitedAs } = params;
    await cancelGroupInvitation(userId);
    reloadInvitationsList(invitedAs);
  };

  const reloadInvitationsList = (invitedAs) => {
    if (invitedAs === 'hostCandidate') {
      setState({
        nowPage: 0
      });
      getGroupTeacherInvitations({ nowPage, rowsPage });
    } else {
      setState({
        nowMemberPage: 0
      });
      getGroupMemberInvitations({ nowPage: nowMemberPage, rowsPage: rowsMemberPage });
    }
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };
  const changeMemberPage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowMemberPage: newPage,
      rowsMemberPage: newRowsPage
    });
  };

  useEffect(() => {
    getGroupTeacherInvitations({ nowPage, rowsPage });
  }, [nowPage, rowsPage]);
  useEffect(() => {
    getGroupMemberInvitations({ nowPage: nowMemberPage, rowsPage: rowsMemberPage });
  }, [nowMemberPage, rowsMemberPage]);
  return (
    <UiTable>
      <UiTableRow>
        <Table
          data={groupTeacherInvitations.invitations}
          schema={schema}
          changePage_Rows={changePage_Rows}
          totalPage={groupTeacherInvitations.total}
          nowPage={nowPage}
          ActionComponents={ActionComponents}
        />
      </UiTableRow>
      <UiTableRow>
        <Table
          data={groupMemberInvitations.invitations}
          schema={memberSchema}
          changePage_Rows={changeMemberPage_Rows}
          totalPage={groupMemberInvitations.total}
          nowPage={nowMemberPage}
          ActionComponents={ActionComponents}
        />
      </UiTableRow>
    </UiTable>);
};


