import { COMPANY_TITLE } from 'constants/index';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { UiFooter, UiLink, UiContent, UiContentWrapper } from './Footer.style';

/**
 * 在這邊描述這個組件
 */

const isCompany = process.env.REACT_APP_TITLE === COMPANY_TITLE;

export const Footer = () => {
  const history = useHistory();
  const goToRule = () => {
    history.push('/rule');
  };
  const goToBrowserSuggestion = () => {
    window.open('/browserSuggestion');
  };
  return (
    <>
      {
        isCompany
          ? <UiFooter>
            <UiContentWrapper>
              <div>
                Made with Copyright © NANI BOOK ENTERPRISE CO.,LTD.All Rights Reserved
              </div>
              <UiLink onClick={() => { goToRule(); }}>
                OneLink 政策暨服務條款
              </UiLink>
              <UiLink onClick={() => goToBrowserSuggestion()}>
                瀏覽器下載
              </UiLink>
            </UiContentWrapper>
            <UiContent>v{process.env.REACT_APP_VERSION}</UiContent>
          </UiFooter>
          : <UiFooter>
            <UiContent>v{process.env.REACT_APP_VERSION}</UiContent>
          </UiFooter>
      }
    </>

  );
};

Footer.propTypes = {

};


