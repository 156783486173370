import { Divider as MatDivider } from '@material-ui/core';
import styled from 'styled-components';

export const UiMyOrganizations = styled.div`
position: relative;
display: block;

> .actionBox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 34px;

	> .title {
		font-size: 32px;
		font-weight: 700;
		line-height: 36px;
		color: #242c3f;
	}
}
`;

export const UiOrganizationWrapper = styled.div`
	margin-top: 2rem;
`;

export const UiOrganizationInvitation = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem 0;
	flex-wrap: wrap;

	& > span {
		margin-right: 1.5rem;
		font-size: 1.5rem;
		font-weight: bold;
		color: ${({ theme }) => theme.palette.primary.sec};
	}

	& > div {
		margin: 0.5rem 0 1rem;
		padding: 15px;
		width: 100%;
		font-size: 1.3rem;
		color: #f00;
		background-color: #eee;
		border-radius: 5px;
	}
`;

export const UiDivider = styled(MatDivider)`
	margin: 3rem 0 4rem;
`;


export const UiPrompt = styled.div`
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	margin: auto;
	max-width: 230px;
	font-size: 1.5rem;
	font-weight: bold;
	color: #0393a4;
	background-color: rbga(255, 255, 255, 0.8);
	transform: translateY(-50%);
	letter-spacing: 2px;
`;

export const UiModal = styled.div`
	& > div {
		margin: 1rem 0;
	}
`;

export const UiModalInput = styled.div`
	margin: 1rem 0;

	& > div {
		width: 100%;
	}
`;

export const UiDefaultGroupCard = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: 0.35s;
	cursor: pointer;

	& > div {
		padding: 15px;
		color: ${({ theme }) => theme.palette.primary.sec};
		letter-spacing: 2px;
		line-height: 1.5;

		& > div:nth-child(1) {
			font-size: 1rem;
			opacity: 0.8;
		}

		& > div:nth-child(2) {
			font-size: 1.2rem;
		}
	}

	&:hover {
		box-shadow: 0 6px 7px rgba(0, 0, 0, 0.2), 0 5px 6px rgba(0, 0, 0, 0.17);
	}
`;
export const UiDefaultGroupCardImage = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 200px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #eee;
	background-image: ${(props) => `url(${props.url}) `};

	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background-color: rgba(0, 0, 0, 0.1);
		z-index: 1;
	}
`;

export const UiDefaultGroupCardHint = styled.div`
position: absolute;
bottom: -4rem;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 3rem;
font-size: 1.2rem;
color: #fff;
background-color: #666;

&::after {
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 100px 100px 100px;
	border-color: transparent transparent #007bff transparent;
}
`;

export const UiDefaultGroupCardWrapper = styled.div`
	position: relative;
	width: 17%;
`;

export const UiUnitButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 160px;
	height: 160px;
	font-size: 16px;
	color: #242c3f;
	border: 1px solid #d5d7de;
	border-radius: 16px;
	flex-direction: column;
	line-height: 1.5;
	text-align: center;

	&:not(:last-child) {
		margin-right: 20px;
	}

	> svg {
		font-size: 3rem;

		path {
			fill: #ec7963;
		}
	}

	&:hover {
		color: #fff;
		background-color: #ee8773;
		border: 0;
		cursor: pointer;

		> svg {
			path {
				fill: #fff;
			}
		}
	}
`;

export const UiCreateUnitBox = styled.div`
	display: flex;
	padding-top: 42px;
	padding-bottom: 90px;
`;

export const UiQrModal = styled.div`
	display: flex;
	color: #242c3f;
	line-height: 1.5;
	align-items: center;
	flex-direction: column;
`;
export const UiQrTitle = styled.div`
	margin-bottom: 32px;
	font-size: 20px;
	font-weight: 500;
`;
export const UiQrBox = styled.a`
	display: flex;
	margin-bottom: 16px;
	width: 180px;
	height: 180px;
	align-items: center;
	justify-content: center;
`;
export const UiQrText = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;

	> .title {
		margin-bottom: 8px;
		font-size: 18px;
		font-weight: 500;
	}

	> .description {
		color: #ec7963;
		font-size: 16px;
	}
`;

export const UiEmptyContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	text-align: center;
`;