/* eslint-disable quotes */
import {
  FormControl as MatFormControl,
  InputLabel as MatInputLabel,
  MenuItem as MatMenuItem,
  FormHelperText as MatFormHelperText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Loading } from 'components';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { UiSelect, UiSelectLoading, UiMatSelect } from './Select.style';



/**
 * 基礎select樣式
 */

const useStyles = makeStyles(theme => ({
  "formcontrol": {
    minWidth: 120,

    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #acdae8',
    }
  },

  "insideofform": {
    margin: '5px 0',
    width: '100%',
  }
}));

export const Select = ({
  label,
  value,
  isOutline = true,
  options,
  submitHandler,
  error,
  helperText,
  loading = false,
  variant = 'filled',
  width,
  onChangeHandler = () => { },
  id = null
}) => {
  const classes = useStyles();
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [selectValue, setSelectValue] = useState(value || '');
  const changeHandler = event => {
    const value = event.target.value;
    setSelectValue(value);
    submitHandler(value);
    onChangeHandler(value);
  };

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    setSelectValue(value);
  }, [options, value]);

  return (
    <UiSelect width={width}>
      {
        loading && <UiSelectLoading><Loading /></UiSelectLoading>
      }
      <MatFormControl
        variant={isOutline ? 'outlined' : variant ? variant : 'filled'}
        className={isOutline ? classes.formcontrol : classes.insideofform}
        error={error}
      >
        <MatInputLabel ref={inputLabel} id={id}>
          {label}
        </MatInputLabel>
        <UiMatSelect
          labelId={id}
          id={id ? `${id}Options` : ''}
          value={selectValue || ''}
          onChange={changeHandler}
          labelWidth={labelWidth}
        >
          <MatMenuItem value="undefined" disabled>
            <em>請選擇條件</em>
          </MatMenuItem>
          {
            options && options.map(option => {
              return (
                <MatMenuItem key={option?.value} value={option?.value}>{option?.name}</MatMenuItem>
              );
            })
          }
        </UiMatSelect>
        {
          helperText && (
            <MatFormHelperText>{helperText}</MatFormHelperText>
          )
        }
      </MatFormControl>
    </UiSelect>
  );
};

Select.defaultProps = {
  label: '標題',
  options: [{
    name: '測試',
    value: 'ya'
  }],
  submitHandler: (value) => { }
};

Select.propTypes = {
  label: PropTypes.string,
  defaultOption: PropTypes.string,
  isOutline: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any
    })
  ).isRequired,
  submitHandler: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
  variant: PropTypes.string,
  width: PropTypes.string,
  onChangeHandler: PropTypes.func
};


