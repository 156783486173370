import { Button, Modal, } from 'components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ResourceModalContent from './ResourceModalContent';

import {
  UiResourceModal,
  UiButtonContainer,
  UiResourcesContainer,
  UiMatFormHelperText,
} from './SessionFormResourceModal.style';

export const SessionFormResourceModal = ({
  buttonName,
  hostId,
  error,
  title,
  onChange,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);  // 彈窗是否開啟
  const [isCheckedCheckboxFull, setIsCheckedCheckboxFull] = useState(false); // 勾選的 checkbox 是否已滿
  const [isClickSubmit, setIsClickSubmit] = useState(false);  // 確定按鈕是否按下
  const [checkedCheckbox, setCheckedCheckbox] = useState([]); // 勾選的 checkbox 內容 {id, name, isChecked}

  /* 關閉 modal */
  const getModalStateHandler = (state) => {
    setIsModalOpen(state);
  };
  const clickCloseHandler = () => {
    setIsModalOpen(false);
  };

  /* checkbox 取消勾選 */
  const changeUncheckCheckboxHandler = (resourceId) => {
    setCheckedCheckbox(checkedCheckbox.filter(el => el.id !== resourceId));
  };

  /* change checkbox 勾選狀況 */
  const changeCheckboxHandler = (e) => {
    let newCheckedCheckbox = [...checkedCheckbox];
    const id = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;

    // 取消勾選
    if (newCheckedCheckbox.some(obj => obj.id === id)) {
      changeUncheckCheckboxHandler(id);
    }
    // 勾選
    else {
      // 選項未滿
      if (checkedCheckbox.length < 6) {
        newCheckedCheckbox.push({ id, name, isChecked });
        setCheckedCheckbox(newCheckedCheckbox);
      }
      // 選項已滿
      else if (checkedCheckbox.length === 6) {
        setIsCheckedCheckboxFull(true);
        setTimeout(() =>
          setIsCheckedCheckboxFull(false)
          , [1500]);
      }
    }
  };

  /* click 確定，傳回至 SessionForm.js schema -> resourceIdList -> onChange() */
  const clickSubmitHandler = () => {
    // Array，checkedCheckbox.id
    const resourceIdList = checkedCheckbox.map(checkbox => {
      return checkbox.id;
    });
    setIsClickSubmit(true);
    setIsModalOpen(false);
    onChange(resourceIdList); //傳回至 SessionForm.js
  };

  return (
    <UiResourceModal>
      <UiButtonContainer>
        <Button
          icon='add'
          onClick={() => { setIsModalOpen(true); }}>
          {buttonName}
        </Button>
      </UiButtonContainer>
      <UiResourcesContainer>
        {
          // 錯誤提示
          (error && checkedCheckbox.length === 0) &&
          <UiMatFormHelperText>
            請選擇教學資源
          </UiMatFormHelperText>
        }
        {
          // 選擇之選項
          isClickSubmit && checkedCheckbox.map(checkbox =>
            <span key={checkbox.id}>
              {checkbox.name}(ID:{checkbox.id})
            </span>
          )
        }
      </UiResourcesContainer>

      <Modal
        size='large'
        isIconShow={false}
        isOpen={isModalOpen}
        title={title}
        getModalState={getModalStateHandler}
        content={
          <ResourceModalContent
            checkedCheckbox={checkedCheckbox}
            isCheckedCheckboxFull={isCheckedCheckboxFull}
            hostId={hostId}
            onChangeCheckbox={changeCheckboxHandler}
            onChangeUncheckCheckbox={changeUncheckCheckboxHandler}
            onClickClose={clickCloseHandler}
            onClickSubmit={clickSubmitHandler}
          />
        }
      />
    </UiResourceModal>
  );
};

export default SessionFormResourceModal;

SessionFormResourceModal.propTypes = {
  buttonName: PropTypes.string,
  error: PropTypes.string,
  title: PropTypes.string,
  hostId: PropTypes.string,
  onChange: PropTypes.func,
};