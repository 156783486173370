import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { UiBsModal, UiCircularProgress, UiWhiteCircularProgress } from './BsModal.style';


/**
 * 組織後台彈窗組件
 */

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));


export const BsModal = ({
  title,
  open,
  onOk,
  onClose,
  onCancel,
  okDisabled = false,
  oKDisplay = true,
  cancelDisplay = true,
  okText = '確認',
  cancelText = '取消',
  children,
  isLoading = false,
  btnPositionStyle,
  backgroundColor,
  width,
  height
}) => {
  const classes = useStyles();
  const handleClose = () => {
    (onCancel && !isLoading) && onCancel();
  };
  const handleOk = () => {
    (onOk && !isLoading && !okDisabled) && onOk();
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      disableAutoFocus
    >
      <UiBsModal width={width} height={height} btnPositionStyle={btnPositionStyle} backgroundColor={backgroundColor}>
        <button className="close" onClick={onClose ? onClose : handleClose}>
          <Icon name="close" icolor="#a1a4b1" haveBg={false} />
        </button>
        {title && <div className="title">{title}</div>}
        <div className="content">
          {children}
        </div>
        {(cancelDisplay || oKDisplay) && <div className="buttonBox">
          {
            cancelDisplay && <button
              className="button white"
              onClick={handleClose}
            >
              {isLoading ? <UiWhiteCircularProgress size={18} /> : cancelText}
            </button>
          }
          {
            oKDisplay && <button
              className={okDisabled ? 'button black disabled' : 'button black'}
              onClick={handleOk}
            >
              {isLoading ? <UiCircularProgress size={18} /> : okText}
            </button>
          }
        </div>}
      </UiBsModal>
    </Modal>
  );
};

BsModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  okDisabled: PropTypes.bool,
  oKDisplay: PropTypes.bool,
  cancelDisplay: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  btnPositionStyle: PropTypes.string,
};


