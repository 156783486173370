import { Collapse } from '@material-ui/core';
import { FiberManualRecord, ExpandMore } from '@material-ui/icons';
import { Icon } from 'components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { List } from '../List';
import { UiListItem, UiListItemIcon, UiListItemText } from './ListItem.style';

export const ListItem = ({ itemData, className }) => {
  const { icon, text, url, nestedList } = itemData;
  const history = useHistory();
  const [isOpenNestedList, setIsOpenNestedList] = useState(itemData.isActive);
  const [isActive, setIsActive] = useState(itemData.isActive);
  const clickListItem = () => {
    if (url) {
      history.push(url);
    } else if (nestedList?.length) {
      setIsOpenNestedList((prev) => !prev);
      setIsActive((prev) => !prev);
    }
  };
  return (
    <>
      <UiListItem button isActive={isActive} onClick={clickListItem} className={className}>
        <UiListItemIcon isActive={isActive} className={className}>
          {icon ? (
            <Icon.Svg name={icon} color={isActive ? '#EC7963' : '#8b90a0'} />
          ) : (
            <FiberManualRecord />
          )}
        </UiListItemIcon>
        <UiListItemText primary={text} isActive={isActive} className={className} />
        {nestedList?.length > 0 && (
          isOpenNestedList ? (
            <ExpandMore />
          ) : (
            <Icon.Svg name="ChevronRight" color={isActive ? '#EC7963' : '#8b90a0'} />
          )
        )}
      </UiListItem>
      {nestedList?.length > 0 && (
        <Collapse in={isOpenNestedList} timeout="auto" unmountOnExit>
          <List list={nestedList} className={nestedList.length && 'second'} />
        </Collapse>
      )}
    </>
  );
};

ListItem.propTypes = {
  itemData: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
    nestedList: PropTypes.array,
    isActive: PropTypes.bool,
  }),
  className: PropTypes.string,
};
