import {
  Toolbar as MatToolbar,
  Grid as MatGrid,
  Typography as MatTypography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { UiAppBar } from './BsHeaderTitle.style';


/**
 * 後台Header
 */

export const BsHeaderTitle = ({ title = '' }) => {
  return (
    <UiAppBar
      component="div"
      color="primary"
      position="static"
      elevation={0}
    >
      <MatToolbar>
        <MatGrid container alignItems="center" spacing={1}>
          <MatGrid item xs>
            <MatTypography color="inherit" variant="h5" component="h1">
              {title}
            </MatTypography>
          </MatGrid>
        </MatGrid>
      </MatToolbar>
    </UiAppBar>
  );
};

BsHeaderTitle.propTypes = {
  title: PropTypes.string
};


