import { TextareaAutosize as MatTextareaAutosize } from '@material-ui/core';
import styled from 'styled-components';

export const UiSchoolHomeSetting = styled.div`
	display: block;
	width: 60%;
`;

export const UiHomeSettingTitle = styled.div`
	font-size: 1.25rem;
	font-weight: bold;
	color: ${({ theme }) => theme.palette.text.normal};
	margin-bottom: 1rem;
`;

export const UiHomeSettingContent = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;

	& > div {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 1rem 0;
		flex-wrap: wrap;

		& .MuiFormControl-root {
			margin: 0 0 0.5rem 0;
			width: 100%;
		}

		& .MuiSelect-root {
			width: 100%;
			background-color: #fff;
		}
	}

	& > div:nth-child(1) {
		width: 35%;
	}

	& > div:nth-child(2) {
		width: 60%;
	}
`;

export const UiHomeSettingInput = styled.input`
	padding: 8px 5px;
	width: 100%;
	font-size: 1rem;
	border-radius: 5px;
`;

export const UiHomeSettingImage = styled.div`
	overflow: hidden;
	width: 100%;
	font-size: 0;
	margin-bottom: 1.5rem;

	& > div {
		width: 100%;
	}

	& img {
		max-width: 100%;
		border-radius: 5px;
	}
`;

export const UiHomeSettingButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 14px;
	width: 100%;
	color: ${({ theme }) => theme.palette.text.normal};
	background-color: ${({ theme }) => theme.palette.btn.menu};
	border-radius: 15px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;

	& > svg {
		margin-right: 0.5rem;
		font-size: 1.5rem;
		color: ${({ theme }) => theme.palette.text.show};
	}

	& > input {
		display: none;
	}
`;


export const UiTextArea = styled(MatTextareaAutosize)`
	padding: 1rem;
	width: 100%;
	min-height: 200px;
	border-radius: 5px;
	resize: none;
`;

export const UiButtonBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 0;

	& > button {
		width: 24%;
	}
`;
