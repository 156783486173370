import {
  HomeGroupLayout,
  HomeAlbumTable,
} from 'components';
import React from 'react';
import LazyLoad from 'react-lazyload';
/**
 * 相簿頁面
 */

export const HomeAlbumPage = () => {
  return (
    <HomeGroupLayout>
      <LazyLoad>
        <HomeAlbumTable></HomeAlbumTable>
      </LazyLoad>

    </HomeGroupLayout>
  );
};

HomeAlbumPage.propTypes = {

};


