import {
  Tab,
} from 'components';
import React from 'react';
// import PropTypes from 'prop-types';
import {
  UiHomeRuleInfo,
  UiRuleTitle,
  UiRuleContent,
} from './HomeRuleInfo.style';

/**
 * 政策頁面
 */

export const HomeRuleInfo = () => {
  const Tab1 = () => {
    return (
      <>
        <UiRuleTitle>智慧財產權政策</UiRuleTitle>
        <UiRuleContent>
          <p>
            歡迎您進入南一書局企業股份有限公司（以下簡稱「本公司」）所提供OneClass數位服務之「OneLink課室管理平台」（以下簡稱「本網站」），
            若有任何使用本網站之用戶侵害他人之智慧財產權時，本公司將依本智慧財產權政策處理。
          </p>
          <ol>
            <li>
              權利人檢舉
              <ol>
                <li>
                  若著作權之權利人（以下簡稱「權利人」）認為其著作權受到侵害，
                  權利人得向本公司提出「檢舉通知」，內容應包括下列資料及聲明：
                  <ol>
                    <li>權利人或權利人之代理人之姓名、聯絡地址及聯絡電話、電子信箱。以代理人名義提出者，應同時聲明其已受該權利人委任，並載明該權利人之姓名或名稱。</li>
                    <li>被侵害之著作名稱及內容。</li>
                    <li>請求將涉有侵害著作權之內容移除或中斷連結之聲明。</li>
                    <li>提供侵害著作權內容及其存取路徑（即所在頁面網址），使本網站確認有侵權事實之相關資訊。</li>
                    <li>聲明權利人確係基於善意，相信該侵權內容的使用是未經過合法授權或違反著作權法，且聲明您於通知書所載相關資料均為真實，並請求移除侵害著作權之內容。</li>
                    <li>保證上開聲明如有不實，致本網站或他人受損害者，權利人願負法律責任。</li>
                    <li>同意本公司將檢舉通知轉送予被檢舉人。</li>
                  </ol>
                </li>
                <li>前項檢舉通知權利人應作成書面（紙本），於該書面簽名或蓋章並掃瞄成電子檔後，以電子郵件附件方式寄送至下列郵件地址： service@oneclass.com.tw</li>
                <li>若本條第①項檢舉通知就第①項各款有遺漏或不明之處，本網站得通知權利人或權利人之代理人於五個工作日內補正，若未按期補正者，本網站得拒絕受理該檢舉。</li>
              </ol>
            </li>
            <li>
              被檢舉人異議
              <ol>
                <li>本公司接獲權利人的檢舉通知後，將依據本網站與其他用戶（以下簡稱「被檢舉人」）約定之聯絡方式或其留存之聯絡資訊，轉知被檢舉人涉有侵權事件。</li>
                <li>
                  倘若被檢舉人對於權利人主張的侵權事件有異議，被檢舉人得於收到本公司轉知之通知後於五個工作日內，
                  向本網站提出異議通知（以下稱「異議通知」），內容應包括下列資料及聲明：
                  <ol>
                    <li>被檢舉人或其代理人之姓名、聯絡地址及聯絡電話、電子郵件信箱。以代理人名義提出者，應同時聲明其已受該被檢舉人委任，並載明該被檢舉人之姓名</li>
                    <li>請求回復被移除或中斷連結內容之聲明。</li>
                    <li>使本公司知悉該內容之相關資訊。</li>
                    <li>表示被檢舉人善意相信其有合法權利利用該內容，而該內容被移除或中斷連結，係出於權利人或其代理人不實或錯誤之陳述。</li>
                    <li>同意本公司將異議通知轉送予權利人或其代理人。</li>
                    <li>註明如有不實致他人受損害者，被檢舉人願負法律責任。</li>
                  </ol>
                </li>
                <li>前項異議通知被檢舉人應作成書面（紙本），於該書面簽名或蓋章並掃瞄成電子檔後，以電子郵件附件方式寄送至下列郵件地址： service@oneclass.com.tw</li>
                <li>若本條第②項異議通知就第②項各款有遺漏或不明之處，本網站得通知被檢舉人或被檢舉人之代理人於五個工作日內補正，若未按期補正者，視為未提出異議通知。</li>
              </ol>
            </li>
            <li>
              其他
              <ol>
                <li>經權利人同意，本公司或本網站得將權利人的姓名、電子郵件或電話等提供予被檢舉人，使被檢舉人得直接與權利人聯絡及溝通，以解決爭議。</li>
                <li>本公司或本網站保留移除權利人所主張涉有侵害著作權或其他智慧財產權網頁內容之權利。</li>
                <li>本公司有權終止或暫停提供服務予侵害他人智慧財產權之人。此外，使用本網站之任何用戶如遭檢舉涉及侵權情事達3次者，本網站得終止該用戶全部或部分之服務。</li>
                <li>
                  您知悉，除經其他用戶同意外，本網站僅得依法令或司法機關之要求，提供用戶之識別資料予第三人。
                  因此，若您依本智慧財產權政策提出檢舉，本網站不會提供您被檢舉人之識別資料。您如欲取得被檢舉人資料，
                  請您向相關司法機關請求，本網站將依相關司法機關要求提供被檢舉人資料後，立即配合提供之。
                </li>
                <li>另依著作權法之規定，因故意或過失向本公司提出不實的檢舉通知或異議通知，致本公司、其他用戶、著作權人、製版權人或他人受有損害者，應負損害賠償責任。</li>
                <li>本公司或本網站可能會不定時修改本智慧財產權政策，本公司或本網站對本智慧財產權政策的修改均會揭露在此頁面，您應於使用本網站前仔細閱讀本智慧財產權政策之全部條款，並定期檢閱本網站有關之更新公告。</li>
              </ol>
            </li>
          </ol>
        </UiRuleContent>
      </>
    );
  };
  const Tab2 = () => {
    return (
      <>
        <UiRuleTitle>隱私權政策</UiRuleTitle>
        <UiRuleContent>
          <p>
            歡迎您進入南一書局企業股份有限公司（以下簡稱「本公司」）所提供OneClass數位服務之「OneLink課室管理平台」
            （以下簡稱「本網站」），本公司在您訪問本網站過程中所蒐集到您的個人資訊，
            都將依本隱私權政策予以處理，請您仔細閱讀以下條款，瞭解本公司在蒐集、使用
            您的個人資料和資訊方面及其利用的方式。當您訪問本網站時，視為您已經閱讀並同
            意及接受本隱私權政策，如果您不同意或不願意遵守本隱私權政策或相關法令，或者
            您所屬國家或地區排除本隱私權政策之全部或一部時，您應立即離開並停止使用本網站。
          </p>
          <ol>
            <li>
              個人資料的取得
              <ul>
                <li>
                  本網站為提供您更完善及多元之服務，依所提供之服務需要，蒐集您的基本資料
                  （可能包含但不限於電子郵件信箱、姓名、聯絡資訊），並依您提供資料時所授
                  權使用的目的進行服務之提供。
                </li>
              </ul>
            </li>
            <li>
              蒐集之目的
              <ul>
                <li>
                  本網站所取得的個人資料，僅供本網站內部於前揭蒐集目的之範圍內使用，
                  除非事先說明、或依照相關法律規定，否則本網站不會將用戶個人資料提供
                  給第三人、或移作其他目的使用。
                </li>
              </ul>
            </li>
            <li>
              利用之期間、地區、對象及方式
              <ol>
                <li>期間：除法律另有規定外，以您要求停止使用或本公司停止提供本服務之日為止。</li>
                <li>地區：您的個人資料僅限於中華民國(台、澎、金、馬)地區內使用。 </li>
                <li>利用對象(範圍)：本公司、業務委外機構或/及相關配合之廠商，或與本公司因業務需要訂有契約關係或業務往來之公私機構，或依法有權之機關或經您同意，提供您所需的行銷活動及相關優惠訊息給您。</li>
                <li>利用(蒐集)方式：以自動化機器或其他非自動化之利用方式，如書面、傳真、電子郵件、網際網路及蒐集目的之通常處理、利用之方式。</li>
              </ol>
            </li>
            <li>
              您就個人資料之權利
              <ol>
                <li>
                  依照個人資料保護法第3條規定，您得就所提供之個人資料行使下列權利：
                  <ol>
                    <li>查詢或請求閱覽。</li>
                    <li>請求製給複製本。</li>
                    <li>請求補充或更正。</li>
                    <li>請求停止蒐集、處理或利用。</li>
                    <li>請求刪除。</li>
                  </ol>
                </li>
                <li>
                  您如欲行使前項權利，可來電本公司客服進行申請。但查詢或請求閱覽個人資料或製給複製本者，
                  本公司將酌收必要成本費用。而前述之申請，應填具申請文件，本公司得向您請求提出可資確認之
                  身分證明文件；若委託他人代為申請者，並應出具委任書，且提供本人及代理人之身分證明文件。
                </li>
                <li>
                  如您不同意上開條款，您可拒絕提供個人資料予本網站，惟您須自行承擔拒絕提供所需之資料，
                  或所提供之資料不完整，將可能導致無法享受完整服務或完全無法使用本服務。本公司於取得您
                  同意後即進行資料之移除或停止使用。
                </li>

              </ol>
            </li>
            <li>
              其他
              <ol>
                <li>
                  在下列情況下，本公司有權將您的個人資料給有權機關、或主張其權利受侵害並提出適當證明的第三人：
                  <ol>
                    <li>依法律之規定、或依司法、檢警調機關與其他有權機關合法之要求。</li>
                    <li>維護本網站系統的正常運作及安全。</li>
                    <li>您違反本服務條款，而本公司依法行使權利者。</li>
                  </ol>
                </li>
                <li>若您利用本網站之超連結功能連結至其他外部網站，就其他外部網站的隱私權政策，本公司不承擔任何責任，您應仔細閱讀所訪問的每一個其他外部網站之隱私權政策。</li>
                <li>本隱私權政策僅適用於本網站上蒐集的資訊。</li>
              </ol>
            </li>
            <li>
              隱私權政策修訂
              <ul>
                <li>
                  本公司可能會不定時修改本隱私權政策，本公司對隱私權政策的修改均會揭露在此頁面，
                  您應於使用本網站前仔細閱讀本隱私權政策之全部條款，並定期檢閱本網站有關之更新公告。
                </li>
              </ul>
            </li>
          </ol>
        </UiRuleContent>
      </>
    );
  };
  const Tab3 = () => {
    return (
      <>
        <UiRuleTitle>服務約定條款</UiRuleTitle>
        <UiRuleContent>
          <p>
            歡迎您進入南一書局企業股份有限公司（以下簡稱「本公司」）所提供OneClass數位服務之「OneLink課室管理平台」（以下簡稱「本網站」），
            當您進入本網站並使用本公司或本網站所提供之任何服務（包含但不限於現有或未來新增之各項服務，
            以下簡稱「本服務」）時，即表示您已經閱讀、瞭解並同意遵守以下服務約定條款之各項規定（以下簡稱「本服務條款」），
            以及其它適用於本網站、互聯網之相關法令。如果您不同意或不願意遵守本服務條款或相關法令，或者您所屬國家或地區排除本服務條款之全部或一部時，您應立即離開並停止使用本網站或本服務。
          </p>
          <ol>
            <li>
              認知與接受條款
              <ol>
                <li>您知悉本公司有權於任何時間修改、
                  變更或新增本服務條款之內容，
                  修改、變更或新增後的服務條款將公布於本網站上，
                  您應隨時注意該等修改、變更或新增。如您於本服務
                  條款之內容經任何修改、變更或新增後仍繼續使用本
                  網站或本服務者，視為您已閱讀、瞭解並同意接受該內
                  容之修改、變更或新增；如果您不同意或不願意遵守修改
                  、變更或新增後之本服務條款，或者您所屬的國家或地域之法
                  律排除修改、變更或新增後之本服務條款之全部或一部之適用
                  時，您應立即停止使用本網站或本服務。
                </li>
                <li>
                  如您為公司、組織或其他法律上之實體，您保證公司或組織是根據註
                  冊國當地法律合法設立並有效存續之公司或組織，您聲明並保證公司或組織
                  已充分合法授權以接受本服務條款。如您代表您的雇主或其他法律實體接受本
                  服務條款，您聲明並保證您擁有完整合法的權利，可確保您的雇主或其他法律
                  實體將接受本服務條款之拘束，並將充分履行本服務條款之任何及全部義務。
                </li>
              </ol>
            </li>
            <li>未成年人
              <ul>
                <li>
                  若您未滿二十歲，應於您的法定監護人閱讀、瞭解並同意接受本服務條款之所有內容
                  （包含修改、變更或新增後之本服務條款）後，方得使用或繼續使用本網站或本服務。
                  當您使用或繼續使用時，即表示您的家長或法定監護人已閱讀、
                  瞭解並同意接受本服務條款之所有內容（包含修改、變更或新增後之本服務條款）。
                </li>
              </ul>
            </li>
            <li>
              用戶帳號真實揭露及維持機密義務
              <ol>
                <li>
                  您使用本網站或本服務前，您必須註冊您的個人帳號（以下稱「用戶帳號」），
                  用戶帳號使用權限將依您所提供之資料決定（例如您是教師或是學生而有不同的使用權限），
                  因此必要時，您於註冊時必須輸入或提供您的其他個人資料。您所提供的資料須為真實且完整，
                  您並同意將持續更新個人資料以確保其真實和完整，如您因登錄資料不實或有冒用他人名義，
                  致您個人權利受有損害或有侵害他人之權利或有違反法令時，
                  您應自負相關之法律責任。
                </li>
                <li>
                  您有義務妥善保管並維持您的用戶帳號及密碼之機密與安全，您不得將您的用戶帳號轉讓、
                  出借或出售予第三人。若您發現用戶帳號及密碼遭第三人盜用或發生其他不當使用情事，
                  您應立即通知本網站，以利本網站採取相應保護措施，但本網站所採取之任何保護措施
                  不得被解釋為本公司或本網站因此而明示或默示對您負有任何形式之賠償或補償義務。
                </li>
                <li>
                  您同意您必須為使用您的用戶帳號及密碼登入本網站後，
                  所從事之一切活動及行為負責，本公司或本網站不負任何賠償或補償責任。
                </li>
                <li>
                  您知悉並同意，如您有違反本條第(一)項登錄資料不實或有冒用他人名義或有違反法令，或有第(二)項將您的用戶帳號轉讓、出借或出售予第三人者，
                  本網站得於知悉時起隨時逕行中斷、終止或拒絕您使用本網站或本服務之權利。
                </li>
              </ol>
            </li>
            <li>
              用戶使用規範
              <ol>
                <li>您同意於使用本網站或本服務時，不進行以下行為：
                  <ol>
                    <li>非法使用本網站或本服務，或於使用時有違反中華民國相關法令，及一切國際網際網路規定與慣例之行為；</li>
                    <li>使用本網站或本服務組織或從事政治或黨派活動；</li>
                    <li>非法或未經授權侵入本公司或本網站網路、或透過本網站直接或間接取得或連結之任何第三方設備、帳號、系統或網絡，對本公司或服務供應商的伺服器或網路進行系統滲透的活動；</li>
                    <li>進行或從事廣告或販賣之行為；</li>
                    <li>以任何非法方式獲取或意圖獲取他人帳號、密碼或個人資料；</li>
                    <li>未經他人同意蒐集或散布個人資料；</li>
                    <li>移除、規避、損害或妨礙本網站或本服務的安全性功能；</li>
                    <li>發布或上傳或使用任何病毒、發送垃圾郵件或郵件炸彈、惡意軟體，或任何意圖破壞或妨礙本網站運作或其他用戶主機或網路安全，或非經許可自本網站擷取內容、資料或個人資訊的程式；</li>
                    <li>冒用他人名義使用本服務；</li>
                    <li>濫發廣告郵件、垃圾郵件、連鎖信、違法之多層次傳銷訊息等；</li>
                    <li>以任何方法傷害未成年人；</li>
                    <li>發布或上傳任何涉及違反中華民國法令、或侵害他人智慧財產權及其他權利、或含有犯罪、侮辱、威脅、騷擾、褻瀆、猥褻、血腥暴力、具冒犯性、誹謗、誤導、詐欺攻擊性、不雅、猥褻、不實、
                      違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於本服務上的內容；</li>
                    <li>其他本網站有正當理由認為不適當之行為。</li>
                  </ol>
                </li>
                <li>未經本公司事前書面同意，您不得將本網站或本服務用於商業目的，包含但不限於將本網站或本服務所提供您使用之權利轉售，或與其他產品或服務結合後銷售予第三人。</li>
                <li>您同意並承諾以您的用戶帳號於本網站發布或上傳、傳輸之一切內容或所為之行為均代表您個人之立場之行為，並同意自行承擔所有衍生之相關責任，概與本公司或本網站無涉。</li>
                <li>您同意本公司或本網站對您的行為是否符合上開規範有最終決定權，
                  若本公司或本網站認為您的行為違反上開規範，您同意本公司或本網站除得逕自刪除您發布或上傳、
                  傳輸之內容外，並得隨時停止您的用戶帳號使用權或清除您的用戶帳號，您並應自負相關之法律責任。
                </li>

              </ol>
            </li>
            <li>智慧財產權之遵守
              <ol>
                <li>本網站所使用之軟體、程式及網站上所有內容，包含但不限於文字、
                  圖片、影片、檔案、教材或教科用書、資料、網站架構、網頁設計、
                  服務約定條款等，均由本網站或其他權利人依法擁有其智慧財產權，
                  包含但不限於商標權、專利權、著作權、營業秘密與專有技術等。
                  任何人未經合法授權不得逕為使用（包含但不限於重製、編輯、出租、
                  公開播送、公開傳輸、公開上映、改作、散布、發行、公開發表、
                  進行還原工程、解編、反向組譯，或其他任何方式），亦不得提供予任何第三人。
                </li>
                <li>您知悉您並不會因為使用本網站或本服務，而使您取得關於本網站或
                  本服務或您所存取內容之智慧財產權之授權，您並同意在未經本公司或其
                  他用戶書面授權前，不得將本網站包含但不限於本網站之內容或其他用戶
                  之貼/撰文或上傳、傳輸之檔案等進行拷貝、重製、複印、散布、轉載，不
                  論係基於營利或非營利目的。
                </li>
                <li>您同意在未經本公司書面授權前，不得擅自使用本網站之logo、商標、服務標章或以本網站名義進行任何活動。</li>
                <li>您並知悉您在本網站所上傳或傳輸之任何檔案、或您所發布之任何貼/撰文，
                  包含但不限於文字、圖片、影音檔、軟體或音樂等，如有涉及他人之著作權或其他智慧財產權或其他權利時，
                  您應取得權利人之合法授權，您亦承諾您於本網站為發布或上傳 、傳輸之動作時，已取得該合法授權。
                  若您無合法權利得授權他人使用、修改、重製、公開播送、改作、散布、發行、公開發表某資料，
                  並將前述權利轉授權第三人，請勿擅自將該資料上載、傳送、輸入 或提供予本網站。
                </li>
              </ol>
            </li>
            <li>隱私權政策
              <ul>
                <li>您了解在本網站上提供之個人資料可能會被本網站蒐集或使用，詳細情形與您的權益請參考隱私權政策。</li>
              </ul>
            </li>
            <li>
              免責約定
              <ol>
                <li>
                  您知悉本公司或本網站您或其他用戶所發布或上傳、傳輸之任何內容或檔案（包含但不限於文字、圖片、
                  音樂、影像、軟體、資訊及各種資料等），或/及利用超連結功能連結至其他外部網站等，均不做任何形式或
                  實質審查，亦不擔保其係正確、完整、安全或可靠無虞的，如您有使用由其他用戶所提供之內容及檔案，或/
                  及透過超連結功能連結至其他外部網站應自行驗證其正確性或安全性。
                </li>
                <li>
                  本網站或本服務如有提供搜尋服務時，僅係提供您或其他用戶得方便且迅速查詢到您或其他用戶想要的資訊，本公司或本網站不擔保您或其他用戶使用搜尋服務所產生之資訊係正確、完整、安全或可靠無虞的。</li>
                <li>
                  您知悉且同意本網站關於線上課程之提供均為您或其他用戶所開設(以下簡稱「開課老師」)，如因
                  開課老師就線上課程有取消、中斷或調整/重新安排開課時間或調整線上課程之作業、測驗、或評量方法
                  等，概與本公司或本網站無涉，您不得因此而要求本公司或本網站對您進行任何賠償或補償。但本網站基
                  於第八條第(一)項規定就線上課程得取消、中斷或調整/重新安排開課時間。
                </li>
                <li>
                  若您與第三人進行買賣或各種交易行為而取得使用本網站或本服務之權利，則與您有契約關係存在之人為
                  該第三人，概與本公司無涉，本公司不負任何擔保您可以正常使用本網站或本服務之責任，如日後本網站或本
                  服務提供之使用權利有任何瑕疵時，本公司亦不負任何賠償責任。
                </li>
                <li>如本公司將您發布或上傳、傳輸之任何內容及檔案刪除或未儲存，本公司不負擔任何賠償責任，您應該於發布或上傳、傳輸前自行備份留存。</li>
                <li>如您在本網站或本服務中所下載之任何檔案及資料，造成您電腦系統的損壞或電腦中資料的遺失，本公司不負任何賠償責任。</li>
                <li>
                  本網站或本服務可能因為本公司、設備廠商或相關電信業者之網路系統軟硬體設備故障或失靈，
                  而全部或一部暫時無法使用、或造成資料發布或傳輸錯誤，或遭駭客侵入系統竄改或偽造變造等，
                  因此您透過本網站或本服務取得任何內容及檔案，應踐行本條第(一)項之驗證責任，本公司不負任
                  何擔保該內容及檔案係正確、完整、安全或可靠無虞或賠償責任。
                </li>
              </ol>
            </li>
            <li>
              服務之停止或中斷提供
              <ol>
                <li>
                  您知悉有下列情事時，本網站有權停止或中斷提供本服務：
                  <ol>
                    <li>本網站之設備於進行保養及施工時；</li>
                    <li>突發性之網路系統軟硬體設備故障、失靈發生時；</li>
                    <li>本網站所配合之相關電信業者無法提供服務時，包含但不限於網路系統軟硬體設備故障或失靈；</li>
                    <li>因天災等不可抗力之因素所致時。</li>

                  </ol>
                </li>
                <li>您同意本網站或本服務可能因本公司或相關電信業者網路系統軟硬體設備之故障、
                  失靈或人為操作上之疏失而造成全部或部份中斷、暫時無法使用、延遲或造成資料發布或
                  上傳、傳輸或儲存上之錯誤、或遭第三人侵入系統篡改或偽造變造資料等，您不得因此而要求
                  本公司或本網站對您進行任何賠償或補償。
                </li>
              </ol>
            </li>
            <li>
              未來新增之服務提供
              <ol>
                <li>您可以透過本網站或本服務進行線上網路授課或上課，本公司則得依未來新增之服務提供酌收或調整費用，以用於網站維護或網站運行等。</li>
                <li>前項新增之服務提供部分，本公司將於收受費用全額款項及您完成相關書面條款閱覽同意後，正式開通啟用。</li>
                <li>若您依前項繳付費用後，但就下期部分有遲延繳付者，本網站得逕行中斷或終止您使用新增之服務提供權利。</li>
              </ol>
            </li>
            <li>
              以電子文件為表示方法
              <ol>
                <li>
                  您同意於使用本服務時之所有內容包含意思表示等皆以電子文件作為表示方法，
                  倘您於各項服務與功能等頁面點選包含但不限於登入/教育雲端登入、確定或確認、
                  新建班級或進入課程、新增作業或附加檔案或上傳等功能鍵時，即視為您正式之意思表示。
                </li>
              </ol>
            </li>
            <li>
              賠償或補償之提出
              <ol>
                <li>您同意不會就您瀏覽、存取、進入或使用本網站或本服務，而向本公司或本網站各自的代表人、董事、僱員、代理人和或受讓人就本服務提起任何請求、賠償、補償或/及訴訟。</li>
                <li>
                  您並同意如因您違反相關法令或違背本服務條款之任一規定，致本公司或本網站之代表人、
                  受僱人、受託人、代理人及其他相關履行輔助人因而受有損害或支出費用（包括且不限於因
                  進行民事、刑事及行政程序所支出之律師費用）時，您應負擔損害賠償責任或填補其費用。
                </li>
              </ol>
            </li>
            <li>
              檢舉與異議
              <ol>
                <li>
                  若您認為本服務中之任何網頁內容或使用者使用本服務侵害您的著作權或其他智慧財產權，請依本網站之智慧財產權政策進行檢舉與異議提出。
                </li>
              </ol>
            </li>
            <li>
              您對本網站之授權
              <ol>
                <li>
                  您所上載、傳送、輸入或提供予本網站之任何資料，其權利仍為您或您的授權人所有；任何資料一經您上載、傳送、輸入 或提供予本網站時，即表示您同意：
                  <ol>
                    <li>由本網站及其合作之相關團體儲存或管理該資料，並由本網站及其合作之相關團體之搜尋工具進行索引及抓取，並公開刊登、使用於本網站相關系統網路上；</li>
                    <li>授權本網站及其合作之相關團體可以基於公益或為宣傳、推廣或經營本網站及本服務之目的，進行使用、修改、重製、公開播送、改作、
                      散布、發行、公開發表、公開傳輸、公開上映、翻譯該等資料，並得在此範圍內將前述權利轉授權他人。您並保證本網站及其關係企業使用、
                      修改、重製、公開播送、改作、散布、發行、公開發表、公開傳輸、公開上映、翻譯、轉授權該等資料，不致侵害任何第三人之智慧財產權，
                      否則應對本網站及其合作之相關團體負損害賠償責任。</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              終止
              <ol>
                <li>您同意本網站得依其判斷因任何理由，包含但不限於一定期間未使用、法院或政府機關命令、本服務無法繼續或服務內容實質變更、
                  無法預期之技術或安全因素或問題、您所為詐欺或違法行為、未依約支付費用，或其他本公會認為您已經違反本服務條款的明文規定及精神，
                  而終止或限制您使用帳號（或其任何部分）或本服務之使用，並將本服務內任何「會員內容」加以移除並刪除。</li>
                <li>您並同意本網站亦得依其自行之考量，於通知或未通知之情形下，隨時終止或限制您使用本服務或其任何部分。</li>
                <li>您承認並同意前開終止或限制，本網站得立即關閉、刪除或限制存取您的帳號及您帳號中全部或部分相關資料及檔案，及停止本服務全部或部分之使用。</li>
                <li>您同意若本服務之使用被終止或限制時，本網站對您或任何第三人均不承擔責任。</li>
              </ol>
            </li>
            <li>
              一般事項
              <ol>
                <li>若本服務條款中有任何條款經法院或主管機關宣告無效或無法執行，但不影響其他條款者，其餘條款仍為繼續有效。</li>
                <li>您知悉您使用本網站或本服務之行為，並不成立您與本公司間有任何建立任何合資、合夥、僱傭或代理關係。</li>
                <li>非經本公司書面同意或法律明文規定，您不得轉讓您的權利或義務予第三人。</li>
                <li>您同意若有任何因本服務條款所生或相關之爭議，應以中華民國法律為準據法，並以臺灣臺南地方法院為第一審管轄法院。</li>
                <li>如依法或其他相關規定須為通知時，本網站得以包括但不限於：電子郵件、一般信件、簡訊、多媒體簡訊、文字訊息、張貼於本服務網頁，
                  或其他現在或未來合理之方式通知您，包括本服務條款之變更。但若您違反本服務條款，以未經授權的方式存取本服務，您將不會收到前述通知。
                  當您經由授權的方式存取本服務，而同意本服務條款時，您即同意本網站所為之任何及所有給您的通知，都視為送達。</li>
              </ol>
            </li>
          </ol>
        </UiRuleContent>
      </>
    );
  };
  const Tab4 = () => {
    return (
      <>
        <UiRuleTitle>版權聲明</UiRuleTitle>
        <UiRuleContent>
          <p>
            歡迎您進入南一書局企業股份有限公司（以下簡稱「本公司」）所提供OneClass數位服務之「OneLink課室管理平台」（以下簡稱「本網站」），
            若有任何使用本網站之用戶侵害他人之智慧財產權時，本公司將依本智慧財產權政策處理。OneLink之內容包括但不限於內容、文字、圖片、聲音、
            影像、檔案、網站架構、軟體及程式或未來各種形式之利用及衍生著作均為南一書局企業股份有限公司所有或已獲得版權持有者授權使用，
            並受國際著作權公約以及中華民國法律保護。
          </p>
          <p>非經南一書局企業股份有限公司明示之授權或同意，任何人均不得做全部或一部之嵌入、重製、使用、修改、公開播送、公開傳輸、公開演出、改作、
            引用、散布、發行、公開發表、進行還原工程、解編、出售、出租、互易、出借、或反向組譯等透過網路及其他方式對外公佈。</p>
          <p>一切其他涉嫌商業利益之公開展示、公開傳輸、出租、出售、互易或任何部份內容之重製，均屬違法之侵權行為，南一書局企業股份有限公司基於維護著作權益之精神，必追究到底，絕無寬待。</p>
        </UiRuleContent>
      </>
    );
  };

  let tabData = [
    { label: '智慧財產權政策', Component: Tab1 },
    { label: '隱私權政策', Component: Tab2 },
    { label: '服務約定條款', Component: Tab3 },
    { label: '版權聲明', Component: Tab4 }
  ];
  return (
    <UiHomeRuleInfo>
      <Tab data={tabData} />
    </UiHomeRuleInfo>);
};

HomeRuleInfo.propTypes = {

};


