import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Header, Footer, BlindBrick } from 'components';
import { Icon } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  UiHomeMemberCenterLayout,
  UiMemberCenterActionsWrapper,
  UiMemberCenterGoBackButton,
  UiMemberCenterTitle,
  UiHomeMemberCenterContent,
  UiChildrenContainer,
} from './HomeMemberCenterLayout.style';

/**
 * 會員中心layout
 */

export const HomeMemberCenterLayout = ({ children }) => {
  const history = useHistory();
  return (
    <UiHomeMemberCenterLayout>
      <Header />
      <UiMemberCenterActionsWrapper>
        <UiMemberCenterGoBackButton onClick={() => history.push('/home')}>
          <Icon.Svg name="ChevronLeft" />
          <Typography>返回班級列表</Typography>
        </UiMemberCenterGoBackButton>
      </UiMemberCenterActionsWrapper>
      <UiMemberCenterTitle>
        <Box>會員中心</Box>
      </UiMemberCenterTitle>
      <UiHomeMemberCenterContent>
        {/* <SideBar type="memberCenter" /> */}
        <UiChildrenContainer>
          <BlindBrick
            className="accesskey"
            id="accessibility_center_container"
            accessKey="c"
            href="#accessibility_center_container"
            title="中央內容區塊，為本頁主要內容區。"
          />
          {children}
        </UiChildrenContainer>
      </UiHomeMemberCenterContent>
      <Footer />
    </UiHomeMemberCenterLayout>
  );
};

HomeMemberCenterLayout.propTypes = {
  children: PropTypes.node,
};
