import { Icon as MatIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const UiIcon = styled(MatIcon)`
	padding: 2px;
	font-size: ${({ fontSize }) => fontSize || '2rem'};
	color: ${({ icolor }) => icolor};
	border-radius: 50%;
	${({ theme, haveBg, type }) =>
		haveBg &&
		{
			backgroundColor: type === 'danger' ?
				theme.palette.main.danger :
				'#242C3F'
		}
	}
`;

export const UiSvgIcon = styled(MatIcon)`
	padding: 2px;

	> path {
		fill: ${({ color }) => color};
	}
`;

export const useStyles = makeStyles((theme) => ({
	disabled: {
		opacity: 0.5,
		pointerEvents: 'none',
	},
}));