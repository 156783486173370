import {
	Tabs as MatTabs,
} from '@material-ui/core';
import styled from 'styled-components';

export const UiHomeVideoTable = styled.div`
	.flex {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> h4 {
			font-size: 20px;
			line-height: 30px;
			color: #242c3f;
		}
	}
`;

export const UiMatTabs = styled(MatTabs)`
	display: block;
	margin: 1rem 0;

	.MuiTab-textColorInherit.Mui-selected {
		color: #ec7963;
	}

	.PrivateTabIndicator-colorSecondary-4 {
		background-color: #ec7963;
	}

	.PrivateTabIndicator-colorSecondary-3 {
		background-color: #ec7963;
	}
`;
