import React, { useEffect } from 'react';
import AppRoutes from 'routes';
// import { useBitrix } from 'utils/hooks/useBitrix';
import { useServerTime } from 'store/serverTime/index';
import { useLocationChange } from 'utils/hooks/useLocationChange';

const App = () => {
  const [, { fetchServerTime }] = useServerTime();
  useLocationChange();

  useEffect(() => {
    fetchServerTime();
  }, []);

  // const { insert, hideButton } = useBitrix();

  // useEffect(() => {
  //   insert();
  //   let time = setInterval(() => {
  //     const isShow = document.getElementsByClassName('b24-widget-button-wrapper')?.[0];
  //     if (isShow) {
  //       clearInterval(time);
  //       hideButton();
  //     }
  //   }, 100);

  // }, []);

  return <AppRoutes />;
};

export default App;
