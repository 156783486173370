import styled from 'styled-components';

import cactus from '../../../molecules/Icon/svg/cactus.svg';

export const UiClassRoomWrapper = styled.div`
  background: ${({ background }) => background ?? '#fff'};
  width: 100%;
  margin-top: -10px;
  padding: 44px 40px;
`;

export const UiClassRoomTitleWrapper = styled.div`
  font-family: Noto Sans CJK TC;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #3a4052;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const UiTag = styled.div`
  background-color: #fef2f0;
  color: #ec7963;
  width: 41px;
  height: 24px;
  padding: 4px 8px;
  font-size: 12px;
  font-family: Noto Sans CJK TC;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 4px;
`;

export const UiClassRoomContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const UiEmptyImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
`;

export const UiEmptyImg = styled.img.attrs({
  src: cactus,
})`
  margin-bottom: 24px;
`;

export const UiEmptyImgFigcaption = styled.div`
  color: #242c3f;
  font-family: Noto Sans CJK TC;
  font-size: 14px;
`;
