import { EDIT_STATUS } from 'constants/index';
import { Table, Select, IconInput, Icon, Button } from 'components';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAdmin } from 'store/admin';
import { useSetState } from 'utils/hooks/useSetState';


import { UiOrganizationTable, UiFilterBox, UiFilterBoxLeft, UiFilterBoxRight } from './OrganizationTable.style';


/**
 * 『機構管理』列表
 */

export const OrganizationTable = () => {
  const [{ nowPage, newRowsPage, selectOption, search }, setState] = useSetState({
    nowPage: 0,
    newRowsPage: 10,
    selectOption: '',
    search: ''
  });
  const history = useHistory();
  const [{ orgs }, { getOrganzitions }] = useAdmin();
  const { data, total } = orgs;

  // 初始近來呼叫 api
  useEffect(() => {
    let query = { nowPage, rowsPage: newRowsPage };
    query[selectOption] = search;
    getOrganzitions(query);
  }, [nowPage, newRowsPage, search]);


  const schema = {
    officialName: {
      name: '機構名稱',
      defaultValue: '',
    },
    GUINumber: {
      name: '統一編號',
      defaultValue: '',
    },
    cityName: {
      name: '城市',
      defaultValue: '',
    },
    address: {
      name: '地址',
      defaultValue: '',
    },
    ownerMobileNumber: {
      name: '負責人手機',
      defaultValue: '',
    },
    ownerName: {
      name: '負責人名稱',
      defaultValue: '',
    },
    ownerId: {
      name: '負責人Id',
      defaultValue: '',
    },
    pointer: {
      name: '',
      defaultValue: '',
    },
    status: {
      name: '狀態',
      defaultValue: '',
    },
    comment: {
      name: '備註',
      defaultValue: '',
    }
  };

  // table 操作
  const ActionComponents = params => {
    return (
      <>
        <Icon
          onClick={() => history.push(`/admin/organizations/${EDIT_STATUS.EDIT}/${params.params.id}`)}
          name='edit'
        />
        <Icon
          onClick={() => history.push(`/admin/organizations/${params.params.id}/resources`)}
          name='listAlt'
        />
      </>
    );
  };

  // 更換 每頁顯示
  const changePageRows = params => {
    setState({ nowPage: params.newPage, newRowsPage: params.newRowsPage });
  };

  // table 狀態
  const stateShowText = [
    {
      id: 'status',
      state: {
        active: '開通',
        inactive: '未開通',
        suspended: '停權'
      }
    }
  ];

  // 下拉選單系列
  const selectProps = {
    label: '篩選條件',
    options: [
      {
        name: '負責人名稱',
        value: 'ownerName'
      },
      {
        name: '負責人手機',
        value: 'ownerMobileNumber'
      },
      {
        name: '機構名稱',
        value: 'officialName'
      }
    ],
    submitHandler: value => {
      setState({ selectOption: value, nowPage: 0 });
    }
  };

  // 搜尋系列
  const IconInputProps = {
    placeholder: '搜尋欄位名稱',
    onChange: value => {
      setState({ search: value, nowPage: 0 });
    }
  };

  return (
    <UiOrganizationTable>
      <UiFilterBox>
        <UiFilterBoxLeft>
          <Select
            label={selectProps.label}
            value={selectOption}
            options={selectProps.options}
            submitHandler={selectProps.submitHandler}
          />
          <IconInput
            placeholder={IconInputProps.placeholder}
            onChange={IconInputProps.onChange}
          />
        </UiFilterBoxLeft>
        <UiFilterBoxRight>
          <Button onClick={() => history.push(`/admin/organizations/${EDIT_STATUS.CREATE}`)}>新增機構</Button>
        </UiFilterBoxRight>
      </UiFilterBox>
      <Table
        data={data}
        schema={schema}
        stateShowText={stateShowText}
        totalPage={total}
        ActionComponents={ActionComponents}
        changePage_Rows={changePageRows}
        nowPage={nowPage}
      />
    </UiOrganizationTable >
  );
};

OrganizationTable.propTypes = {
  children: PropTypes.node,
};


