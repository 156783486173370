import firebase from 'firebase/app';
import { useCallback, useEffect } from 'react';
import 'firebase/auth';
import 'firebase/storage';
import { useSetState } from 'utils/hooks/useSetState';

const oneClassFirebaseConfig = {
  apiKey: process.env.REACT_APP_ONECLASS_API_KEY,
  authDomain: process.env.REACT_APP_ONECLASS_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_ONECLASS_DATABASE_URL,
  projectId: process.env.REACT_APP_ONECLASS_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ONECLASS_STORAGE_BUCKET,
};
export const useFirebase = (config = oneClassFirebaseConfig) => {
  const [{ storage }, setState] = useSetState({
    storage: null,
  });
  const initialize = useCallback(() => {
    let app;
    if (firebase.apps.length <= 0 || !firebase.app('oneclass')) {
      app = firebase.initializeApp(config, 'oneclass');
    } else {
      app = firebase.app('oneclass');
    }

    app
      .auth()
      .signInAnonymously()
      .then(() => {
        setState({ storage: app.storage() });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('sign in anonymously error', err));
  }, [config, setState]);
  useEffect(() => {
    if (!config) return;
    initialize();
  }, [config, initialize]);
  return { storage };
};
