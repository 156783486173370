import { QUERY, DEVICE } from 'constants/dimension';
import styled from 'styled-components';
import { useResponsive } from 'utils/hooks/useResponsive';

export const UiSessionReport = styled.div(() => {
  const isTabletL = useResponsive({ query: QUERY.DOWN, start: DEVICE.TABLET.WIDTH_L });
  const isMobileL = useResponsive({ query: QUERY.DOWN, start: DEVICE.MOBILE.WIDTH_L });
  return {
    padding: isMobileL ? '16px 24px' : isTabletL ? '16px 32px' : '48px 0 48px 32px',
    width: isTabletL ? 'calc(100vw - 16px)' : '99%',
  };
});

export const UiActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
`;

export const UiActionContainer = styled.div`
  display: flex;
  align-items: center;
  color: #8b90a0;
`;

export const UiPreviousPageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  color: #8b90a0;
`;

export const UiSessionEmpty = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const UiEmptyImg = styled.div`
	width: 29%;
	height: 207px;
	background: url("${({ path }) => path}");
	background-position: center;
	background-size: cover;
	margin-bottom: 25px;
`;
