import { OUTPUT_TYPE_NAME, OUTPUT_TYPE_NAME_KEY } from 'constants/index';
import { Button, Icon, Label, Loading, Table, HomeOnlineExamNoDataScreen } from 'components';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { getPaperPackage } from 'services/api/onePaper/index';
import { useClass } from 'store/class';
import { useAlert } from 'utils/hooks/useAlert';
import { ONLINE_EXAM_REQUEST_TYPES, ONLINE_EXAM_LABEL_COLOR } from '../HomeOnlineExam/HomeOnlineExam';
import { UiExamIcons } from './HomeExamPaperPackage.style';

const SCHEMA = {
  year: {
    name: '學年度',
    defaultValue: '',
  },
  eduSubjectName: {
    name: '學制科目',
    defaultValue: '',
  },
  name: {
    name: '試卷名稱',
    defaultValue: '',
  },
  packageName: {
    name: '試卷包名稱',
    defaultValue: '',
  },
  updateTime: {
    name: '修改時間',
    defaultValue: '',
  },
  redeemTime: {
    name: '取得時間',
    defaultValue: '',
  },
  paperType: {
    name: '試卷類型',
    defaultValue: '',
  },
};

/* 篩選出搜尋後的試卷包 */
function filterPaperPackageByPaperName(paperPackageApiData, filterData) {
  const keyword = filterData.name;
  const papers = paperPackageApiData.papers.filter((paper) => {
    return paper.paperName.includes(keyword);
  });
  return { papers };
}

/* 過濾出分頁後的試卷包 */
function filterPaperPackageByPagination(papers, pagination) {
  const start = pagination.nowPage * pagination.rowsPage;
  const end = start + pagination.rowsPage;
  const pagedPapers = papers.slice(start, end);
  return pagedPapers;
}

export const HomeExamPaperPackage = ({ filterData, onCreateExam, onPrompt }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [paperPackageApiData, setPaperPackageApiData] = useState();
  const [filteredPaperPackage, setFilteredPaperPackage] = useState();
  const [pagination, setPagination] = useState({
    nowPage: 0,
    rowsPage: 10,
    totalPage: 0,
  });
  const [
    {
      myClasses: {
        dataInfo: { users: classUsers },
      },
    },
  ] = useClass();
  const { setAlert } = useAlert();

  /* 操作 */
  const ActionComponents = ({ params }) => {
    const { eduSubjectName, name, uid: paperId, download, downloadName, outputTypeName } = params;
    const isFiles = outputTypeName === OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.FILES];
    const isCantCreate = classUsers?.length > 0;
    const onViewSession = () => {
      const url = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/paper/preview/${paperId}`;
      window.open(url);
    };

    const downloadFile = async (u, name) => {
      const fileBlob = await fetch(u).then((res) => res.blob());
      const url = window.URL.createObjectURL(new Blob([fileBlob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    };

    return (
      <>
        <UiExamIcons>
          <div tabIndex={0}>
            <Icon.Svg name="Visibility" onClick={onViewSession} />
          </div>
          <div tabIndex={0}>
            <Icon.Svg name="DownloadFile" onClick={() => downloadFile(download, downloadName)} disabled={!download} />
          </div>
          {!isFiles && (
            <div tabIndex={0} style={{ minWidth: '88px' }}>
              <Button
                iconSvg="Plane"
                onClick={isCantCreate ? () => onCreateExam({ eduSubjectName, paperId, name }) : onPrompt}
              >
                派發測驗
              </Button>
            </div>
          )}
        </UiExamIcons>
      </>
    );
  };

  /* 試卷列表 */
  const examPaperList = useMemo(() => {
    if (!filteredPaperPackage || !filteredPaperPackage?.papers?.length) return [];
    const pagedPapers = filterPaperPackageByPagination(filteredPaperPackage.papers, pagination);
    const papers = pagedPapers.map((paper) => {
      const outputTypeName = OUTPUT_TYPE_NAME[paper.outputStyle];
      // 線上測驗或速測 ? 線上測驗 : 紙本卷類
      const paperType = ONLINE_EXAM_REQUEST_TYPES.includes(outputTypeName)
        ? OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.ONLINE]
        : OUTPUT_TYPE_NAME[OUTPUT_TYPE_NAME_KEY.FILES];
      const updateTime = format(new Date(paper.editTime), 'yyyy/MM/dd HH:mm');
      const redeemTime = paper?.redeemTime ? format(new Date(paper.redeemTime), 'yyyy/MM/dd HH:mm') : '';
      const newPaper = {
        year: paper.year,
        eduSubjectName: paper.eduSubjectName,
        name: paper.paperName,
        packageName: paper.packageName,
        updateTime,
        redeemTime,
        uid: paper.paperId,
        download: paper.downloadUrl,
        downloadName: `${paper.packageName}.zip`,
        outputTypeName,
        paperType: <Label color={ONLINE_EXAM_LABEL_COLOR[paperType]}>{paperType}</Label>,
      };
      return newPaper;
    });
    return papers;
  }, [pagination]);

  /* 取得機構試卷 */
  const fetchPaperPackage = async (params) => {
    setIsLoading(true);
    const response = await getPaperPackage(params);
    response?.papers ? setPaperPackageApiData(response) : setAlert('取得試卷包失敗', 'error');
    setIsLoading(false);
  };

  /* 換頁 */
  const changePage_Rows = (params) => {
    const { newPage, newRowsPage } = params;
    setPagination((prev) => ({
      ...prev,
      nowPage: newPage,
      rowsPage: newRowsPage,
    }));
  };

  useEffect(() => {
    fetchPaperPackage();
  }, []);

  useEffect(() => {
    if (paperPackageApiData) {
      if (filterData.name === null) {
        setFilteredPaperPackage(paperPackageApiData);
        setPagination((prev) => ({
          ...prev,
          totalPage: paperPackageApiData.papers.length || 0,
        }));
      } else {
        const filteredPaperPackage = filterPaperPackageByPaperName(paperPackageApiData, filterData);
        setFilteredPaperPackage(filteredPaperPackage);
        setPagination((prev) => ({
          ...prev,
          nowPage: 0,
          totalPage: filteredPaperPackage.papers.length || 0,
        }));
      }
    }
  }, [paperPackageApiData, filterData]);

  return isLoading ? (
    <Loading />
  ) : examPaperList.length ? (
    <Table
      data={examPaperList}
      schema={SCHEMA}
      ActionComponents={ActionComponents}
      changePage_Rows={changePage_Rows}
      totalPage={pagination.totalPage}
      nowPage={pagination.nowPage}
      rowsPerPage={pagination.rowsPage}
    />
  ) : (
    <HomeOnlineExamNoDataScreen />
  );
};

HomeExamPaperPackage.propTypes = {
  filterData: PropTypes.string,
  onCreateExam: PropTypes.func,
  onPrompt: PropTypes.func,
};
