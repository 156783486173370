import styled from 'styled-components';

export const UiBackGround = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
	overflow: hidden;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0);
	z-index: 100;
`;

export const UiNotificationList = styled.div`
	position: absolute;
	top: 50px;
	right: 100px;
	display: block;
	display: flex;
	width: 100%;
	max-width: 440px;
	height: max-content;
	background-color: #fff;
	border: 1px solid #d5d7de;
	border-radius: 8px;
	flex-direction: column;

	> .content {
		overflow-y: auto;
		padding: 16px 0;
		max-height: 680px;

		> .noContent {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			height: 680px;

			> div {
				margin-top: 24px;
				max-width: 200px;
				font-size: 14px;
				text-align: center;
				color: #3a4052;
				line-height: 24px;
			}
		}
	}
`;

export const UiNotificationItem = styled.div`
	display: flex;
	align-items: flex-start;
	padding: 12px 24px;
	width: 100%;
	background-color: ${({ isNew }) => isNew ? '#FEF2F0' : '#fff'};
	transition: 0.35s;

	&:hover {
		background-color: ${({ isNew }) => isNew ? '#FEF2F0' : '#f5f5f5'};
	}

	> .content {
		flex: 1;
		padding: 0 8px;
		font-size: 14px;
		line-height: 24px;
		color: #242c3f;

		span {
			font-weight: bold;
		}
	}
`;
