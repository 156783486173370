import { createMuiTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

export const UiHomeOnlineExamPage = styled.div`
	display: block;
	flex: 1;
`;
export const theme = createMuiTheme({
	overrides: {
		MuiInputBase: {
			root: {
				height: '50px',
			},
		},
		MuiAutocomplete: {
			inputRoot: {
				overflow: 'hidden',
			},
		},
		MuiChip: {
			root: {
				height: '30px',
			},
		},
		MuiRadio: {
			root: {
				color: '#EC7963',
				height: '22px'
			},
			colorSecondary: {
				'&$checked': {
					color: '#EC7963',
				},
			},
		},
	},
	typography: {
		fontFamily: [
			'Noto Sans TC',
			'sans-serif'
		].join(','),
		boxSizing: 'border-box'
	},
	palette: {
		main: {
			disable: '#828282',
			warning: '#FF9800',
			danger: '#F44336',
			rose: '#E91E63',
			success: '#038C8C',
			info: '#1A73E8',
			new: '#121232'
		},
		text: {
			normal: '#3c4858',
			sub: '#777777',
			placeholder: '#999999',
			show: '#00828F',
			active: '#64b5f6',
			white: '#ffffff',
			light: '#d0e0e2',
			greenblue: '#325b88',
		},
		line: {
			light: '#d0e0e2',
			dark: '#2a4865',
		},
		background: {
			main: '#F9F9F9',
			sec: '#dfeeff'
		},
		block: {
			white: '#ffffff',
			light: '#f4f3f0',
			lighthover: '#dfeeff',
		},
		btn: {
			normal: '#EC7963',
			normalhover: '#EE8773',
			menu: '#a1e4ed;',
			info: '#006db2',
			infohover: '#009be5',
			highlight: '#f38a04',
			highlighthover: '#f29f05',
			disable: '#F2F4F8',
			disablehover: '#F2F4F8',
			visibility: '#4C99A5',
			visibilityhover: '#5EA4AE',
			delete: '#f44336',
			deletehover: '#f6564b',
			bar: '#a1e6ef',
			barhover: '#71dae0',
			white: '#fff',
			new: '#121232',
			newhover: '#2A2A47',
			ui03: '#e4e7ec',
			ui03hover: '#f4f7fc',
			brand03: '#4C99A5',
			brand03hover: '#5EA4AE',
			cancel: '#FAFAFC',
			cancelhover: '#FAFAFC',
			error: '#F44336',
			errorhover: '#F6564B',
		},
		tab: {
			normal: '#a1e4ed',
			normalhover: '#71dae0',
			info: '#a9cff2',
			infohover: '#89bded',
			highlight: '#eed24f',
			highlighthover: '#dfc545',
		},
		primary: {
			main: '#8B90A0',
			sec: '#163e6f'
		},
		secondary: {
			main: '#D0E0E2',
		},
		backstage: {
			primary: {
				main: '#8B90A0'
			},
			block: {
				bar: '#64b5f6',
				title: '#0787cf',
			}
		},
		home: {
			primary: {
				main: '#06F3F9',
				sec: '#f4f3f0'
			}
		},
		user: {
			block: {
				bar: '#39C5BB',
				barhover: '#a1e6ef',
				title: '#ace5ed',
			}
		},
		dark: {
			block: '#18202b',
			blocktitle: '#232f3f',
			blockhover: '#2a303c',
		}
	},
});
