import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export const LABEL_COLOR = {
  default: 'default',
  primary: 'primary',
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
};
const BACKGROUND_COLOR = {
  [LABEL_COLOR.default]: '#919EAB29',
  [LABEL_COLOR.primary]: '#FF725729',
  [LABEL_COLOR.info]: '#1890ff29',
  [LABEL_COLOR.success]: '#54D62C29',
  [LABEL_COLOR.warning]: '#FFB73D29',
  [LABEL_COLOR.error]: '#FF484229',
};
const CHILDREN_COLOR = {
  [LABEL_COLOR.default]: '#637381',
  [LABEL_COLOR.primary]: '#B72C2B',
  [LABEL_COLOR.info]: '#0C53B7',
  [LABEL_COLOR.success]: '#229A16',
  [LABEL_COLOR.warning]: '#B7731E',
  [LABEL_COLOR.error]: '#B72136',
};

export const Label = ({ children, color = 'default' }) => {
  return (
    <Box style={{ display: 'inline', padding: '6px 8px', borderRadius: '6px', background: BACKGROUND_COLOR[color] }}>
      <Box component="span" style={{ color: CHILDREN_COLOR[color] }}>
        {children}
      </Box>
    </Box>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  color: PropTypes.shape(['info']),
};
