import { stringify } from 'query-string';

export const uploadPdfFileApi = async (queryParams, data) => {
  const querystring = await stringify(queryParams);
  const apiUrl = await `${process.env.REACT_APP_PDF_API_DOMAIN}/upload_srcfile?${querystring}`;
  const settings = Object.assign({
    body: data ? data : undefined,
    method: 'POST',
    headers: undefined
  });
  const response = await fetch(apiUrl, settings);
  return response.json();
};

