import { Chip as MatChip } from '@material-ui/core';
import styled from 'styled-components';

export const UiChip = styled(MatChip)`
	max-width: 240px;
	padding: 0.5rem;

	& > svg {
		font-size: 1.5rem;
	}
`;
