import {
  MemberCenterCard,
  HomeMemberProfileCardRead,
  HomeMemberProfileCardEdit,
} from 'components';
import PropTypes from 'prop-types';
import React from 'react';
// import { UiHomeMemberProfileCard } from './HomeMemberProfileCard.style';

/**
 * 會員資料卡牌組件
 */

export const HomeMemberProfileCard = ({
  type = 'read',
  data = {},
  changeCardType = () => { },
  refreshUserData = () => { },
  cityChange = () => { },
  submitformHandler = () => { },
  isLoading,
}) => {
  return (
    <MemberCenterCard title={type === 'read' ? '基本資料' : '修改基本資料'}>
      {type === 'read' ?
        <HomeMemberProfileCardRead
          data={data}
          changeCardType={changeCardType}
          refreshUserData={refreshUserData}
        />
        :
        <HomeMemberProfileCardEdit
          data={data}
          changeCardType={changeCardType}
          cityChange={cityChange}
          submitformHandler={submitformHandler}
          isLoading={isLoading}
        />
      }
      {/* <UiHomeMemberProfileCard>
        HomeMemberProfileCard
      </UiHomeMemberProfileCard> */}
    </MemberCenterCard>

  );
};

HomeMemberProfileCard.propTypes = {
  type: PropTypes.string,
  data: PropTypes.shape({

  }),
  changeCardType: PropTypes.func,
  refreshUserData: PropTypes.func,
  cityChange: PropTypes.func,
  submitformHandler: PropTypes.func,
  isLoading: PropTypes.bool,
};


