import { FormHelperText as MatFormHelperText } from '@material-ui/core';
import styled from 'styled-components';

export const UiResourceModal = styled.div`
  display: flex;
`;

export const UiButtonContainer = styled.div`
  margin-right: 1rem;
`;

export const UiResourcesContainer = styled.div`
  display: flex;
  flex-direction: column ;
`;

export const UiMatFormHelperText = styled(MatFormHelperText)`
	color: #f44336;
`;