export const QUERY = {
  UP: 'up',
  DOWN: 'down',
  BETWEEN: 'between',
};

export const DEVICE = {
  MOBILE: {
    WIDTH_S: 375,
    WIDTH_L: 767,
  },
  TABLET: {
    WIDTH_S: 980,
    WIDTH_L: 1120,
  },
};
