import { Select, NewBatchList, Button, Breadcrumbs } from 'components';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { inviteGroupUsers } from 'services/api/home/class';
import { useAlert } from 'utils/hooks/useAlert';

import { useSetState } from 'utils/hooks/useSetState';
import {
  UiInviteOrganizationGroupHostForm,
  UiCheckboxWrapper,
  UiBatchList,
  UiButtonBox,
} from './InviteOrganizationGroupHostForm.style';

/**
 * 邀請機構老師表單
 */

const INPUT_KEY = {
  USERID: 'userId',
  EMAIL: 'email',
  MOBILE_NUMBER: 'mobileNumber',
};

export const InviteOrganizationGroupHostForm = () => {
  const history = useHistory();
  const { classId, organizationId } = useParams();
  const { setAlert } = useAlert();
  const [{ isLoading, selectOption, data, errorData }, setState] = useSetState({
    isLoading: false,
    selectOption: INPUT_KEY.USERID,
    reset: false,
    data: null,
    errorData: null,
  });

  const submitformHandler = async (data) => {
    setState({ isLoading: true });
    if (!data || data.length <= 0) {
      setState({ isLoading: false });
      setAlert('請填入成員資料', 'error');
      return;
    }

    const { isSuccess, error } = await inviteGroupUsers(classId, {
      users: data,
      role: 'host',
    });

    setState({ isLoading: false });
    if (isSuccess) {
      setAlert('已成功送出邀請', 'success');
      setState({ reset: true });
      history.push(`/home/${organizationId}/class/${classId}/member`);
    } else {
      switch (error.errorCode) {
        case 20005:
          setAlert('本機構已不開放前台邀請老師，請聯絡機構管理者處理', 'error', error.errorCode);
          break;
        case 30021:
          setState({ errorData: error.invalidUsers }); // 將錯誤訊息顯示在畫面上
          break;
        default:
          setAlert('邀請失敗', 'error', error.errorCode);
      }
    }
  };

  const selectProps = {
    label: '註冊方式',
    options: [
      {
        name: '使用者 id',
        value: INPUT_KEY.USERID,
      },
      {
        name: '手機號碼',
        value: INPUT_KEY.MOBILE_NUMBER,
      },
      {
        name: 'email',
        value: INPUT_KEY.EMAIL,
      },
    ],
    submitHandler: (value) => {
      setState({ selectOption: value });
    },
  };

  const batchListChangeHandler = (data) => {
    setState({ data });
  };

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '成員',
    },
    {
      name: '成員列表',
      link: `/home/${organizationId}/class/${classId}/member`,
    },
    {
      name: '邀請老師',
    },
  ];

  return (
    <>
      <Breadcrumbs list={BreadcrumbsList} />
      <UiInviteOrganizationGroupHostForm>
        <UiCheckboxWrapper>
          <Select
            label={selectProps.label}
            value={selectOption}
            options={selectProps.options}
            submitHandler={selectProps.submitHandler}
          />
        </UiCheckboxWrapper>
        <UiBatchList>
          <NewBatchList type={selectOption} onChange={batchListChangeHandler} errorData={errorData} />
        </UiBatchList>
        <UiButtonBox>
          <Button buttonColor="cancel" onClick={() => history.goBack()}>
            取消
          </Button>
          <Button loading={isLoading} buttonColor="new" onClick={() => submitformHandler(data)}>
            確認
          </Button>
        </UiButtonBox>
      </UiInviteOrganizationGroupHostForm>
    </>
  );
};
