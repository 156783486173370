import { ClassForm, BsHeaderTitle, BsClassPageHeaderTab } from 'components';
import React from 'react';

import { UiBsContentContainer } from 'styles';


/**
 * 在這邊描述這個組件
 */

export const ClassFormPage = props => {
  return (
    <>
      <BsHeaderTitle title="班級管理" />
      <BsClassPageHeaderTab activeIndex={0} />
      <UiBsContentContainer >
        <ClassForm {...props} />
      </UiBsContentContainer>
    </>
  );
};


