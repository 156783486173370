import roundRocket from '@iconify/icons-ic/round-rocket';
import { Icon as Iconify } from '@iconify/react';
import { TextField as MatTextField } from '@material-ui/core';
import { Button, PhoneForm, Icon } from 'components';
import { format } from 'date-fns';
import React, { useEffect } from 'react';

import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
import { emailReg, phoneNumberValid } from 'utils/validation';
import {
  UiUpgradeAccount,
  UiUpgradeAccountTitle,
  UiUpgradeAccountPrompt,
  UiUpgradeAccountText,
  UiUpgradeAccountForm,
  UiUpgradeAccountImage,
} from './UpgradeAccount.style';



/**
 * 升級專業版
 */

export const UpgradeAccount = () => {
  const [{ profile: { id: userId, premiumStatus, premiumExpiredAt } }, { setPremiumUsers }] = useUser();
  const [{
    mobileNumber,
    email,
    type,
    mobileNumberError,
    emailError,
    loading,
    icon,
  }, setState] = useSetState({
    mobileNumber: '',
    email: '',
    type: 'application',
    mobileNumberError: false,
    emailError: false,
    loading: false,
    icon: roundRocket,
  });
  const submitHandler = async () => {
    if (!mobileNumberCheck() || !emailCheck()) return;
    await setPremiumUsers({
      userId,
      mobileNumber,
      email
    });
  };
  useEffect(() => {
    if (!premiumStatus) return;
    switch (premiumStatus) {
      case 'pending':
        setState({
          icon: 'email'
        });
        break;
      case 'trial':
        setState({
          icon: 'eventAvailable'
        });
        break;
      case 'approved':
        setState({
          icon: 'howToReg'
        });
        break;
      case 'application':
      case 'expired':
      default:
        setState({
          icon: roundRocket
        });
        break;
    }
    setState({
      type: premiumStatus,
    });
  }, [premiumStatus]);

  const mobileNumberCheck = () => {
    const isValidNumber = phoneNumberValid(mobileNumber);
    setState({
      mobileNumberError: !isValidNumber ? true : false
    });
    if (!isValidNumber) {
      return false;
    }
    return true;
  };
  const emailCheck = () => {
    const isValidEmail = emailReg.test(email);
    setState({
      emailError: !isValidEmail ? true : false
    });
    if (!isValidEmail) {
      return false;
    }

    return true;
  };
  return (
    <UiUpgradeAccount>
      <UiUpgradeAccountImage>
        {
          (type === 'application' || type === 'expired') ?
            <Iconify icon={icon} /> :
            <Icon name={icon} icolor="#ace5ed" haveBg={false} />
        }
      </UiUpgradeAccountImage>
      {
        (type === 'application' || type === 'expired') && (
          <>
            <UiUpgradeAccountTitle>升級為專業版</UiUpgradeAccountTitle>
            <UiUpgradeAccountPrompt>升級為專業版後，您將可以開設具視訊功能的課程</UiUpgradeAccountPrompt>
            <UiUpgradeAccountText>請輸入您的手機號碼與電子郵件信箱</UiUpgradeAccountText>
            <UiUpgradeAccountText>客服人員將盡速與您聯絡</UiUpgradeAccountText>
            <UiUpgradeAccountForm>
              <PhoneForm
                variant="outlined"
                error={mobileNumberError}
                helperText={mobileNumberError ? '手機格式錯誤' : ''}
                onChange={(countryCode, number) => setState({ mobileNumber: countryCode + number })}
              />
              <MatTextField
                label='電子郵件'
                variant="outlined"
                placeholder="請輸入電子郵件信箱"
                error={emailError}
                helperText={emailError ? 'e-mail格式錯誤' : ''}
                value={email}
                onChange={e => setState({ email: e.target.value })}
              />
              <Button onClick={() => submitHandler()} loading={loading} >送出</Button>
            </UiUpgradeAccountForm>

          </>
        )
      }
      {
        type === 'pending' && (
          <>
            <UiUpgradeAccountTitle>您已送出專業版升級需求</UiUpgradeAccountTitle>
            <UiUpgradeAccountText>客服人員將盡速與您聯絡，請您耐心等候</UiUpgradeAccountText>
          </>
        )
      }
      {
        type === 'trial' && (
          <>
            <UiUpgradeAccountTitle>您目前正使用OneLink專業版30天免費體驗</UiUpgradeAccountTitle>
            <UiUpgradeAccountText>到期日為 {format(premiumExpiredAt, 'yyyy-MM-dd')}</UiUpgradeAccountText>
          </>
        )
      }
      {
        type === 'approved' && (
          <>
            <UiUpgradeAccountTitle>您目前正使用OneLink專業版</UiUpgradeAccountTitle>
            <UiUpgradeAccountText>您所使用的付費方式為月費制</UiUpgradeAccountText>
            <UiUpgradeAccountText>到期日為 {format(premiumExpiredAt, 'yyyy-MM-dd')}</UiUpgradeAccountText>
          </>
        )
      }
      <UiUpgradeAccountText>或撥打服務電話 <span> 02-2664-5500 #520</span></UiUpgradeAccountText>
      <UiUpgradeAccountText>我們將有專人為您服務</UiUpgradeAccountText>
    </UiUpgradeAccount>
  );
};
