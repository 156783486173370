import {
  FormControlLabel as MatFormControlLabel,
  Checkbox as MatCheckbox
} from '@material-ui/core';
import { Table, Button } from 'components';
import { format } from 'date-fns';
import { stringify } from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getTeacherExamList, getStudentExamList, updateExamStatus } from 'services/api/home/exam';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { usePageVisibility } from 'utils/hooks/usePageVisibility';
import { useSetState } from 'utils/hooks/useSetState';
import { paginationFilter } from 'utils/pagination';
import { UiHomeExamTable } from './HomeExamTable.style';


/**
 * 線上測驗
 */

export const HomeExamTable = () => {
  const isVisible = usePageVisibility();
  const { classId } = useParams();
  const [{ myClasses: { dataInfo: { userType, users, attendeeNumbers } } }] = useClass();
  const [{ profile }] = useUser();
  //直接打api不要再放進store

  //userType 判斷身份
  const [
    {
      nowPage,
      rowsPage,
      // isLoading,
      studentAnswered,
      examList
    }, setState] = useSetState({
      dateInterval: 'now',
      nowPage: 0,
      rowsPage: 10,
      isLoading: false,
      studentAnswered: true,
      examList: []
    });

  const ActionComponents = ({ params }) => {
    const {
      quizStatus,
      documentId,
      teacherId,
      examInfo,
      studentAnswered,
      book,
      chapter,
      education,
      subject,
      subjectName,
      version,
      year,
      teacherName,
    } = params;

    const teacherAction = async () => {
      const payload = {
        documentId
      };
      if (quizStatus) {
        await updateExamStatus(payload);
        await fetchExam();
      } else {
        // eslint-disable-next-line max-len
        window.open(`${process.env.REACT_APP_EXAM_RESULT_DOMAIN}analysis.html?roomId=${classId}&teacherId=${teacherId}&examInfo=${examInfo}`);
      }
    };

    const goExamTest = () => {
      const params = {
        userId: profile.id,
        username: profile.nickname,
        roomId: classId,
        book,
        chapter,
        education,
        subject: `${subject}_${subjectName}`,
        version,
        year,
        teacherName,
        teacherId,
      };
      if (attendeeNumbers && attendeeNumbers[profile.id]) {
        params.seatNum = attendeeNumbers[profile.id];
      }
      // stringify
      const querystring = stringify(params);
      window.open(`${process.env.REACT_APP_EXAM_RESULT_DOMAIN}?${querystring}`);
    };

    if (userType === 'teacher') {
      return (
        <Button buttonColor="new" onClick={teacherAction}>{quizStatus ? '結束作答' : '測驗結果'}</Button>
      );
    } else {
      if (studentAnswered && quizStatus) {
        return (
          <Button buttonColor="new" disabled>作答完成</Button>
        );
      }

      if (studentAnswered && !quizStatus) {
        return (
          <Button buttonColor="new" onClick={goExamTest}>查看結果</Button>
        );
      }

      if (!studentAnswered && !quizStatus) {
        return (
          <div>缺考</div>
        );
      }

      if (!studentAnswered && quizStatus) {
        return (
          <Button buttonColor="new" onClick={goExamTest}>開始測驗</Button>
        );
      }
    }
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const fetchExam = async () => {
    // userType 判斷身份
    setState({
      isLoading: true
    });
    const payload = {
      teacherId: profile.id,
      roomId: classId
    };
    const payloadS = {
      roomId: classId,
      userId: profile.id,
      studentAnswered,
    };

    if (studentAnswered) {
      delete payloadS.studentAnswered;
    }
    const { content } = userType === 'teacher' ? await getTeacherExamList(payload) : await getStudentExamList(payloadS);
    const nextContent = content.map(item => {
      const createDateText = format(new Date(item.createDate), 'yyyy-MM-dd');
      const totalStudentAnsweredText = `${item.totalStudentAnswered} / ${users.length}`;
      return {
        ...item,
        createDateText,
        totalStudentAnsweredText
      };
    });
    setState({
      examList: nextContent,
      isLoading: false
    });
  };

  const onChangeHandle = (value, key) => {
    setState({
      [key]: value
    });
  };

  const goQbk = (isNeedParams) => {
    if (isNeedParams) {
      // eslint-disable-next-line max-len
      window.open(`${process.env.REACT_APP_EXAM_DOMAIN}?roomId=${classId}&teacherId=${profile.id}&teacherName=${profile.nickname}`);
    } else {
      window.open(`${process.env.REACT_APP_EXAM_DOMAIN}`);
    }
  };

  const schema = useMemo(() => {
    if (!userType) return [];
    const userSchema = {
      teacher: {
        quizName: {
          name: '測驗名稱',
          defaultValue: ''
        },
        education_subject: {
          name: '學制科目',
          defaultValue: '--'
        },
        teacherName: {
          name: '派卷老師',
          defaultValue: '--'
        },
        createDateText: {
          name: '派卷時間',
          defaultValue: ''
        },
        totalStudentAnsweredText: {
          name: '考試 / 應試人數',
          defaultValue: ''
        }
      },
      student: {
        quizName: {
          name: '測驗名稱',
          defaultValue: ''
        },
        education_subject: {
          name: '學制科目',
          defaultValue: '--'
        },
        teacherName: {
          name: '派卷老師',
          defaultValue: '--'
        },
        createDateText: {
          name: '派卷時間',
          defaultValue: ''
        },
      }
    };
    return userSchema[userType];
  }, [userType]);

  useEffect(() => {
    if (!userType) return;
    if (isVisible) fetchExam();
  }, [isVisible, userType, studentAnswered]);


  return (
    <UiHomeExamTable>
      <div className="flex">
        <h4>線上測驗</h4>
        {
          userType === 'teacher' ?
            <Button buttonColor="new" onClick={() => goQbk(true)}>我要派卷</Button> :
            <>
              <MatFormControlLabel
                control={
                  <MatCheckbox
                    color="primary"
                    checked={!studentAnswered}
                    onChange={e => onChangeHandle(!e.target.checked, 'studentAnswered')}
                  />
                }
                label="只顯示未完成測驗"
              />
              <Button buttonColor="new" onClick={() => goQbk(false)}>自主練習</Button>
              <div className="end">自主練習的結果將不會被儲存，老師也不會看到喔!!</div>
            </>
        }
      </div>
      <Table
        data={paginationFilter(examList, nowPage, rowsPage)}
        schema={schema}
        changePage_Rows={changePage_Rows}
        totalPage={examList.length}
        ActionComponents={ActionComponents}
        nowPage={nowPage}
      />
    </UiHomeExamTable>
  );
};
