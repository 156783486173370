import {
  AlbumSlider,
  PictureCard,
  Button,
  PostModal,
  Breadcrumbs,
  Loading,
  Prompt,
  ClassProfileImageCard,
} from 'components';
import React, { useEffect } from 'react';

import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { useParams } from 'react-router-dom';
import { useAlbum } from 'store/album';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { useAlert } from 'utils/hooks/useAlert';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { useSetState } from 'utils/hooks/useSetState';
import { useUuid } from 'utils/hooks/useUuid';
import {
  UiHomePicture,
  UiPictureTableHeader,
  UiPictureTableTitle,
  UiPictureList,
  UiPictureHeader,
  UiPictureProfileList,
} from './HomePicture.style';


/**
 * 相片
 */

const categoryText = {
  image: '貼文',
  video: '影片',
  profile: '班級圖像'
};

export const HomePicture = () => {
  let sliderIndex = 0;
  const [getUuid] = useUuid();
  const [{ myOrganization, profile: { id, nickname } }] = useUser();
  const [{ myClasses, myClasses: { dataInfo: { ownerId, thumbnailId } } }, { updateClass }] = useClass();
  const { organizationId, classId, albumCategory } = useParams();
  const { upload } = useFirebaseStorage();
  const { setAlert } = useAlert();
  const [{ pictureList }, {
    getPicture,
    replyPostToAlbum,
    getPostRepliesToAlbum,
    createPicture,
    likePostToAlbum,
    undoLikePostToAlbum,
    deletePicture,
  }] = useAlbum();
  const [{
    modalState,
    nowPage,
    rowsPage,
    listLoading,
    hasNextPage,
    isOpen,
    initalSlide,
    postId,
    ownerName,
    canSubmit,
    likeFlag,
    isAdmin,
    userId,
    pictureLength,
    isLoading,
    profileImageEdit,
    chooseProfileImageId,
  }, setState] = useSetState({
    modalState: false,
    nowPage: 0,
    rowsPage: 12,
    listLoading: false,
    hasNextPage: true,
    isOpen: false,
    initalSlide: 0,
    postId: '',
    ownerName: '',
    canSubmit: false,
    likeFlag: false,
    isAdmin: false,
    userId: null,
    pictureLength: 0,
    isLoading: false,
    profileImageEdit: false,
    chooseProfileImageId: null,
  });
  const getModalState = state => {
    setState({
      modalState: state
    });
  };
  useEffect(() => {
    if (!pictureList) return;
    setState({
      ownerName: pictureList.album ? pictureList.album.ownerName : '',
      canSubmit: pictureList.album ? (pictureList.album.ownerId === id ? true : false) : false,
      pictureLength: pictureList.entries ? pictureList.entries.length : 0,
    });
  }, [pictureList]);

  //滾動加載觸發後事件
  const handleLoadMore = async () => {
    setState({
      listLoading: true
    });
    const page = await getPicture({ nowPage, rowsPage });
    const pageLength = page.entries && page.entries.length;
    let isHasNextPage = ((nowPage * rowsPage) + pageLength) >= page.total ? false : true;
    setState({
      listLoading: false,
      nowPage: nowPage + 1,
      hasNextPage: isHasNextPage,
    });
  };
  //滾動加載trigger
  const infiniteRef = useInfiniteScroll({
    loading: listLoading,
    hasNextPage,
    onLoadMore: handleLoadMore
  });

  const getPostDataHandler = (data, sliderIndex) => {
    setState({
      isOpen: true,
      postId: data.id,
      initalSlide: sliderIndex
    });
  };
  const closeHandler = e => {
    if (!e) {
      setState({
        isOpen: false
      });
      return;
    }
    if (e.currentTarget !== e.target) {
      e.stopPropagation();
      return;
    }
    setState({
      isOpen: false
    });
  };

  const onDeleteHandler = async (data) => {
    const { postId, id } = data;
    await deletePicture(postId, id);
  };

  const replieMessage = async (params, id) => {
    const { content } = params;
    const payload = {
      content
    };
    await replyPostToAlbum(id)(payload);
  };

  //上傳檔案
  const onUploaded = async file => {
    if (!file.type) return;
    const uploadPath = `${classId}/posts/${id}/${getUuid()}${file.name}`;
    const { status, url } = await upload(uploadPath, file);

    if (status) {
      return url;
    } else {
      return false;
    }
  };
  const onCreateSubmit = async createData => {
    setState({
      isLoading: true
    });
    const { files, content } = createData;
    await Promise.all(files.map(async file => {
      const response = await onUploaded(file.value);
      if (!response) throw response;
      return {
        attachmentUrl: response,
        attachmentName: file.name,
        attachmentType: file.type
      };
    })).then(async filesData => {
      setAlert('上傳檔案成功!', 'success');
      const payload = {
        content,
        attachments: filesData
      };
      const isSuccess = await createPicture(payload);
      if (isSuccess) {
        setState({ modalState: false });
      }
    }).catch(() => {
      setAlert('上傳檔案失敗!', 'wrarning');
    });
    setState({
      isLoading: false
    });
  };

  const showAllReplies = async (postId, all) => {
    const payload = {
      pageSkip: 0,
      pageLimit: all
    };
    await getPostRepliesToAlbum(postId)(payload);
  };

  const changeLikeUserStateHandler = async (id, isLiked, type = 'replie') => {
    if (likeFlag) return;
    setState({
      likeFlag: true
    });
    isLiked ? await undoLikePostToAlbum(id, type) : await likePostToAlbum(id, type);
    setTimeout(() => {
      setState({
        likeFlag: false
      });
    }, 1000);
  };

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '相簿',
      link: organizationId ?
        `/home/${organizationId}/class/${classId}/album` :
        `/home/class/${classId}/album`,
    },
    {
      name: pictureList ?
        (pictureList.album) ?
          pictureList.album.name : categoryText[albumCategory]
        : '相片',
      link: organizationId ?
        `/home/${organizationId}/class/${classId}/album/${albumCategory}` :
        `/home/class/${classId}/album/${albumCategory}`,
    }
  ];

  //權限 組織名取得
  useEffect(() => {
    const { dataInfo: { isOwner, userType } } = myClasses;
    setState({
      isAdmin: isOwner,
      userType,
      userId: id,
      chooseProfileImageId: thumbnailId,
    });
  }, [myOrganization, myClasses]);

  const submitModalHandler = () => {
    setState({ modalState: true });
  };

  const changeClassImageHandler = async (data) => {
    setState({
      isLoading: true
    });
    const isSuccess = await updateClass({ thumbnailId: data.id });
    if (isSuccess) {
      setState({
        chooseProfileImageId: data.id
      });
    }
    setState({
      isLoading: false
    });
  };
  return (
    <>
      <UiHomePicture ref={infiniteRef}>
        <UiPictureHeader>
          <UiPictureTableHeader>
            {
              albumCategory && <Breadcrumbs list={BreadcrumbsList} />
            }
            {
              canSubmit &&
              <Button
                disabled={pictureList.album.ownerId !== ownerId && pictureLength >= 50 ? true : false}
                icon='upload'
                buttonColor="highlight"
                onClick={() => {
                  submitModalHandler();
                }}>上傳相片
              </Button>
            }

          </UiPictureTableHeader>
          {
            ownerName &&
            <UiPictureTableTitle>
              {`建立者:${ownerName}`}
            </UiPictureTableTitle>

          }
        </UiPictureHeader>
        {(pictureList && pictureList.entries.length <= 0 && !listLoading) && <Prompt message={'暫無相片'}></Prompt>}
        {
          albumCategory === 'profile' ?
            <UiPictureProfileList>
              {
                pictureList && pictureList.entries.map((item, index, arr) => {
                  item.path = item.attachmentUrl || '';
                  item.userId = userId;
                  item.isAdmin = isAdmin;
                  item.canDelete = canSubmit;
                  item.postInfo = pictureList.posts.filter(val => val.id === item.postId)[0];
                  if (index !== 0) {
                    if (item.postId === arr[index - 1].postId) {
                      sliderIndex += 1;
                    } else {
                      sliderIndex = 0;
                    }
                  }
                  if (!item.isBanned) {
                    return (
                      <ClassProfileImageCard
                        key={item.id}
                        profileImageId={chooseProfileImageId}
                        data={item}
                        isEdit={profileImageEdit}
                        changeClassImage={changeClassImageHandler}
                      />
                    );
                  }
                })
              }
            </UiPictureProfileList>
            :
            <UiPictureList isProfile={albumCategory === 'profile'}>
              {
                pictureList && pictureList.entries.map((item, index, arr) => {
                  item.path = item.attachmentUrl || '';
                  item.userId = userId;
                  item.isAdmin = isAdmin;
                  item.canDelete = canSubmit;
                  item.postInfo = pictureList.posts.filter(val => val.id === item.postId)[0];
                  if (index !== 0) {
                    if (item.postId === arr[index - 1].postId) {
                      sliderIndex += 1;
                    } else {
                      sliderIndex = 0;
                    }
                  }
                  if (!item.isBanned) {
                    return (
                      <PictureCard
                        key={item.id}
                        data={item}
                        sliderIndex={sliderIndex}
                        onClick={getPostDataHandler}
                        onDelete={onDeleteHandler}
                        changeLikeUserState={changeLikeUserStateHandler}
                      />
                    );
                  }
                })
              }
            </UiPictureList>

        }
        {listLoading && <Loading />}
        <PostModal
          isOnlyImage={true}
          isOpen={modalState}
          isLoading={isLoading}
          getModalState={getModalState}
          title={'上傳圖片'}
          name={nickname}
          onSubmit={onCreateSubmit}
          type='album'
          dialogContent={<span>你尚未分享圖片<br />確定要離開頁面且不發佈圖片</span>}
        />
      </UiHomePicture>
      <AlbumSlider
        isOpen={isOpen}
        close={closeHandler}
        defaultData={pictureList && pictureList.dataMap[postId]}
        replieMessage={replieMessage}
        showAllReplies={showAllReplies}
        changeLikeUserState={changeLikeUserStateHandler}
        initalSlide={initalSlide}
        ownerId={id}
      />
    </>
  );
};
