import { SingleLayout, CustomerService } from 'components';
import React from 'react';



/**
 * 註冊/忘記密碼頁面
 */

export const CustomerServicePage = props => {
  return (
    <SingleLayout showLeftSidebar={false}>
      <CustomerService {...props} />
    </SingleLayout>
  );
};



