import { Typography as MatTypography, Divider as MatDivider } from '@material-ui/core';
import styled from 'styled-components';

export const UiLoginForm = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 44px 0 18px;
	width: 38%;
	min-width: 300px;
	max-width: 480px;
	background-color: #fff;
	border: 1px solid #f2f4f8;
	flex-direction: column;
	border-radius: 10px;
	padding: 40px;

	> .box {
		width: 100%;
		max-width: 320px;
		margin-bottom: 17px;

		> div {
			width: 100%;
		}
	}

	> .btnBox {
		margin-bottom: 24px;
		width: 100%;
		max-width: 320px;

		> button {
			width: 100%;

			& + button {
				margin-top: 12px;
			}
		}
	}
`;

export const UiLoginFormFooter = styled.div`
	text-align: center;
	color: #a1a4b1;

	a {
		padding: 0 1rem;
		text-decoration: none;
		color: #a1a4b1;
	}
`;

export const UiRow = styled.div`
	margin: 33px auto 0;
	width: 100%;
	font-size: 12px;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	text-align: center;
	color: #8b90a0;
	line-height: 16px;

	& p {
		font-size: 12px;
		line-height: 140.62%;
		text-align: center;
		letter-spacing: 0.05em;
		color: #a1a4b1;
	}
`;

export const UiContactUs = styled.div`
	margin: auto;
	width: 38%;
	min-width: 300px;
	max-width: 700px;
`;

export const UiIconList = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 1rem auto 0;
	width: 100%;
`;

export const UiIconRow = styled.a`
	display: block;
	width: 33%;
	text-align: center;
	cursor: pointer;
	text-decoration: none;

	& > svg {
		font-size: 24px;
		color: #ec7963;
	}

	& > div {
		margin-top: 20px;
		color: #3c4858;
	}
`;


export const UiTypography = styled(MatTypography)`
margin-bottom: 2rem;
width: 100%;
text-align: center;
`;

export const UiDivider = styled(MatDivider)`
`;


