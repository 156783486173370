import { EDU_OBJ, SUBJECTS_OBJ, GRADES_OBJ, ONE_BOARD_CLASS_TYPE, JUMP_ONELINKPLUS } from 'constants/index';
import { format } from 'date-fns';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { stringify } from 'query-string';
import { getCookie } from './cookie';
import { encrypt } from './crypto';

export const isExist = (value) => value !== null && value !== '' && typeof value !== 'undefined';

export const validURL = (str) => {
  if (str === '') return true;
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const getJWTValue = (key) => {
  try {
    return jwtDecode(JSON.parse(getCookie('nani_oneclass_login_token')).jwt)[key];
  } catch (error) {
    console.error(error);
    return '';
  }
};

// 驗證是否進行中
export const getIsProcessing = (session) => {
  const { endAt, startAt } = session;
  const now = Date.now();
  const isProcessing = now > startAt && now < endAt;
  return isProcessing;
};

// 取得上課時間
export const getDurationText = (session) => {
  const { endAt, startAt } = session;
  return `${format(startAt, 'HH:mm')}-${format(endAt, 'HH:mm')}`;
};

// 將課程做排序
export const getEduSubjectsText = (session) => {
  const { subjects } = session;

  /**
   * 將 subjects 依照國小、國中、高中排序
   */
  const newSubjects = Object.keys(subjects)
    .sort((next, prev) => {
      const educations = Object.keys(EDU_OBJ);
      const nextIndex = educations.indexOf(next);
      const prevIndex = educations.indexOf(prev);
      return nextIndex - prevIndex;
    })
    .reduce((obj, key) => {
      obj[key] = subjects[key];
      return obj;
    }, {});

  /**
   * 中文名之 eduSubjects 物件陣列
   * ex. [{ 國小: ['國語', '華語'], 國中: ['英語', '數學'], 高中: ['物理', '化學']}]
   */
  const newEduSubjects = Object.entries(newSubjects).reduce((prev, curr) => {
    const edu = curr[0];
    const subjects = curr[1];
    if (!subjects.length) {
      return prev;
    } else {
      const eduName = EDU_OBJ[edu];
      const subjectsNameList = subjects.map((subject) => SUBJECTS_OBJ[subject]);
      prev = prev.concat({ [eduName]: subjectsNameList });
      return prev;
    }
  }, []);

  /**
   * eduSubjectText 之陣列
   * ex. [["國小國語,華語"], ["國中英文,數學"], ["高中物理、化學"]]
   */
  const eduSubjectsTextList = newEduSubjects.reduce((prev, curr) => {
    const eduSubjectsText = `${Object.keys(curr)[0]}${Object.values(curr)[0].join('、')}`;
    prev = prev.concat(eduSubjectsText);
    return prev;
  }, []);

  const eduSubjectsText = eduSubjectsTextList.join(' / ');

  return eduSubjectsText;
};

export const getGradesText = (session) => {
  const { grades } = session;
  return grades.map((grade) => GRADES_OBJ[grade]).join('、');
};

export const getIsIncludesClassGrades = (classGrades, grades) => {
  const isIncludesClassGrades = grades.some((grade) => classGrades.includes(grade));
  return isIncludesClassGrades;
};

export function toASCII(chars) {
  var ascii = '';
  for (var i = 0, l = chars.length; i < l; i++) {
    var c = chars[i].charCodeAt(0);
    //只針對半形去轉換
    if (c >= 0xff00 && c <= 0xffef) {
      c = 0xff & (c + 0x20);
    }
    ascii += String.fromCharCode(c);
  }
  return ascii;
}

/* 取得當下在班級的身份 */
export function getRoles(dataInfo) {
  if (!dataInfo) return [];
  const role = [];
  if (dataInfo.groupRole === 'organizationOwner') {
    role.push('owner');
  } else if (dataInfo.isOwner) {
    role.push('owner');
  }
  if (dataInfo.userType === 'teacher') {
    role.push('teacher');
  } else if (dataInfo.userType === 'student') {
    role.push('student');
  }
}

/* OneLink OMS 開課使用之 url */
export const getOneBoardUrlForOneLink = ({
  courseId,
  sessionsType,
  usersLength,
  resources,
  userType,
  userName,
  userId,
  organizationId,
}) => {
  let classType = '';
  switch (sessionsType) {
    case 'interactive':
      classType = usersLength <= 1 ? 'single' : 'group';
      break;
    case 'collaborative':
      classType = usersLength <= 1 ? 'sync-single' : 'sync-multiple';
      break;
    case 'video':
      classType = 'group';
      break;
    default:
      break;
  }
  const querystringValue = {
    bookIds: resources,
    classType: classType,
    role: userType,
    userName,
    userId,
    productType: 'onelink',
    organizationId: organizationId,
  };
  const token = encrypt(JSON.stringify(querystringValue));
  const querystring = stringify(
    {
      ...querystringValue,
      token,
    },
    { arrayFormat: 'index' },
  );
  const url = `${process.env.REACT_APP_ONEBOARD_DOMAIN}/${courseId}/setup?${querystring}`;
  return url;
};

/* 課外學習（自習教室、解題教室）使用之 url */
export const getOneBoardUrlForExtraLearning = ({ userId, userName, courseId }) => {
  const querystringValue = {
    classType: 'sync-multiple',
    enterNotice: true,
    role: 'student',
    userId,
    userName,
  };
  const token = encrypt(JSON.stringify(querystringValue));
  const querystring = stringify(
    {
      ...querystringValue,
      token,
    },
    { arrayFormat: 'index' },
  );
  const url = `${process.env.REACT_APP_ONEBOARD_DOMAIN}/${courseId}/setup?${querystring}`;
  return url;
};

/* MMS 開的課，上課使用之 url */
export const getOneBoardUrlForMMS = ({ mmsCourseId, resources, courseType, userName, userId }) => {
  const classType = ONE_BOARD_CLASS_TYPE?.[courseType] || 'sync-multiple';
  const querystringValue = {
    bookIds: resources,
    classType,
    role: 'student',
    userName,
    userId,
  };
  const token = encrypt(JSON.stringify(querystringValue));
  const querystring = stringify(
    {
      ...querystringValue,
      token,
    },
    { arrayFormat: 'index' },
  );
  const url = `${process.env.REACT_APP_ONEBOARD_DOMAIN}/${mmsCourseId}/setup?${querystring}`;
  return url;
  // https://oneboard-dev.oneclass.com.tw/649ea978581c82245764b64f/observer?autoRecord=false&bookIds[0]=NEWID&classType=sync-single&role=observer&userId=staff99999&userName=%E8%A7%80%E5%AF%9F%E8%80%85
};

/* MMS 開的課，觀察者使用之 url */
export const getOneBoardUrlForMMSObserver = ({ mmsCourseId, courseType, userName, userId }) => {
  const classType = ONE_BOARD_CLASS_TYPE?.[courseType] || 'sync-multiple';
  const querystringValue = {
    classType,
    role: 'observer',
    userName,
    userId,
  };
  const token = encrypt(JSON.stringify(querystringValue));
  const querystring = stringify(
    {
      ...querystringValue,
      token,
    },
    { arrayFormat: 'index' },
  );
  const url = `${process.env.REACT_APP_ONEBOARD_DOMAIN}/${mmsCourseId}/observer?${querystring}`;
  return url;
};

export const toOnelinkPlus = (organization) => {
  if(process.env.REACT_APP_ENV === 'dev') {
    if(_.intersection(organization, JUMP_ONELINKPLUS.dev).length > 0) {
      window.location.href = process.env.REACT_APP_ONELINKPLUS;
    }
  }
  if(process.env.REACT_APP_ENV === 'uat') {
    if(_.intersection(organization, JUMP_ONELINKPLUS.uat).length > 0) {
      window.location.href = process.env.REACT_APP_ONELINKPLUS;
    }
  } 
  if(process.env.REACT_APP_ENV === 'release') {
    if(_.intersection(organization, JUMP_ONELINKPLUS.release).length > 0) {
      window.location.href = process.env.REACT_APP_ONELINKPLUS;
    }
  } 
};