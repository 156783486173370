import { BsHeaderTitle, SessionSummary } from 'components';
import React from 'react';

import { UiBsContentContainer } from 'styles';


/**
 * 課堂報表 頁面
 */

export const SessionSummaryPage = () => {
  return (
    <>
      <BsHeaderTitle title="課堂報表" />
      <UiBsContentContainer>
        <SessionSummary />
      </UiBsContentContainer>
    </>
  );
};
