import { FORGETPASSWORD, COMPANY_TITLE } from 'constants/index';
import { TextField as MatTextField, Button as MatButton, useTheme } from '@material-ui/core';
import { Checkbox, Icon, Button } from 'components';
import { setOnePaperLoginCookieByServiceRoot } from 'helper/onePaper';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';

import { getMoeLoginUrl } from 'services/api/login/login';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
// import { useDetectionDevice } from 'utils/hooks/useDetectionDevice';
import {
  UiLoginForm,
  UiLoginFormFooter,
  UiContactUs,
  UiTypography,
  UiDivider,
  UiRow,
  UiIconList,
  UiIconRow,
} from './LoginForm.style';

// import chatLogo from 'assets/images/crmButton.svg';
// import chatContext from 'assets/images/customerServiceTip.svg';

/**
 * 登入
 */

const isCompany = process.env.REACT_APP_TITLE === COMPANY_TITLE;

export const LoginForm = () => {
  const theme = useTheme();
  const [, { userLogin }] = useUser();
  // const location = useLocation();
  // const { getDeviceInfo, getBrowserInfo } = useDetectionDevice();
  const [
    {
      username,
      password,
      rememberMe,
      // isModalOpen,
      // modalText,
      isLoading,
      isShowPassword,
    },
    setState,
  ] = useSetState({
    username: '',
    password: '',
    rememberMe: false,
    // isModalOpen: false,
    // modalText: '',
    isLoading: false,
    isShowPassword: false,
  });
  // const { broswer, version } = getBrowserInfo();
  // const device = getDeviceInfo();
  // const getModalStateHandler = state => {
  //   setState({ isModalOpen: state });
  // };
  // const modalButtons = [
  //   {
  //     text: '確定',
  //     func: () => {
  //       getModalStateHandler(false);
  //     }
  //   }
  // ];
  // const getModalText = () => {
  //   switch (device) {
  //     case 'iphone':
  //       if (broswer === 'Safari') {
  //         if (version < 11) {
  //           setState({
  //             modalText: <p>您目前使用的Safari 瀏覽器版本太舊，為了確保您能獲得最佳的使用體驗，請更新Safari 瀏覽器至11.0之後的版本</p>
  //           });
  //         }
  //       } else {
  //         setState({
  //           modalText: <p>您目前使用的瀏覽器非Safari 瀏覽器，為了確保您能獲得最佳的使用體驗，請改用Safari 瀏覽器。</p>
  //         });
  //       }
  //       break;
  //     default:
  //       if (broswer === 'Chrome') {
  //         if (version < 60) {
  //           setState({
  //             modalText:
  //               <p>
  //                 您目前使用的Chrome瀏覽器版本太舊，為了確保您能獲得最佳的使用體驗，請更新Chrome瀏覽器至
  //                 <a
  //                   href="https://www.google.com/intl/zh-TW/chrome/?brand=CHBD&gclid=Cj0KCQjwsYb0BRCOARI
  //                 sAHbLPhHIx-z8Wp9VLKSs4KoW8e1Uf5sNC25U0PpvRprTDZJ0wqBi0RzXhd0aArrmEALw_wcB&gclsrc=aw.ds"
  //                   target="_blank"
  //                   rel="noopener noreferrer"
  //                 >
  //                   60之後的版本
  //                 </a>
  //               </p>
  //           });
  //         }
  //       } else {
  //         setState({
  //           modalText:
  //             <p>您目前使用的瀏覽器非Chrome瀏覽器，為了確保您能獲得最佳的使用體驗，請改用
  //               <a
  //                 href="https://www.google.com/intl/zh-TW/chrome/?brand=CHBD&gclid=Cj0KCQjwsYb0BRCOARI
  //                   sAHbLPhHIx-z8Wp9VLKSs4KoW8e1Uf5sNC25U0PpvRprTDZJ0wqBi0RzXhd0aArrmEALw_wcB&gclsrc=aw.ds"
  //                 target="_blank"
  //                 rel="noopener noreferrer"
  //               >
  //                 Chrome瀏覽器
  //               </a>
  //               。
  //             </p>
  //         });
  //       }
  //       break;
  //   }
  // };
  // useEffect(() => {
  //   getModalText();
  // }, []);
  // useEffect(() => {
  //   if (modalText !== '') {
  //     getModalStateHandler(true);
  //   }
  // }, [modalText]);

  const onkeyPress = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      submitHandler();
      return false;
    }
  };
  const submitHandler = async () => {
    if (!username || !password) return;
    if (isLoading) return;
    setState({
      isLoading: true,
    });
    const formData = {
      username,
      password,
    };

    await userLogin(formData);
    rememberMe ? localStorage.setItem('account', username) : localStorage.removeItem('account');
    setState({
      isLoading: false,
    });
  };

  const onChangeHandle = (value, key) => {
    setState({
      [key]: value,
    });
  };

  const cloudLogin = () => {
    setOnePaperLoginCookieByServiceRoot();
    getMoeLoginUrl();
  };

  useEffect(() => {
    const account = localStorage.getItem('account') === null ? '' : localStorage.getItem('account');
    setState({
      username: account,
      rememberMe: account ? true : false,
    });
  }, []);

  return (
    <>
      {/* <Modal
        isOpen={isModalOpen}
        content={modalText}
        buttons={modalButtons}
        getModalState={getModalStateHandler}
      >
      </Modal> */}
      <UiLoginForm>
        {/* <Form schema={schema} buttons={buttons} isLoading={isLoading} onSubmit={submitHandler} /> */}
        <div className="box">
          <MatTextField
            label="帳號"
            aria-label="請輸入帳號"
            placeholder="請輸入帳號"
            value={username}
            variant="outlined"
            size="small"
            onChange={(e) => onChangeHandle(e.target.value, 'username')}
            onKeyPress={onkeyPress}
          />
        </div>
        <div className="box">
          <MatTextField
            label="密碼"
            aria-label="請輸入密碼"
            placeholder="請輸入密碼"
            value={password}
            variant="outlined"
            size="small"
            type={isShowPassword ? 'text' : 'password'}
            onChange={(e) => onChangeHandle(e.target.value, 'password')}
            onKeyPress={onkeyPress}
            InputProps={{
              endAdornment: (
                <MatButton
                  style={{
                    minWidth: 'fit-content',
                    textDecoration: 'underline',
                    color: theme.palette.btn.normal,
                    padding: 'unset',
                  }}
                  onClick={() =>
                    setState({
                      isShowPassword: !isShowPassword,
                    })
                  }
                >
                  {isShowPassword ? '隱藏' : '顯示'}
                </MatButton>
              ),
            }}
          />
        </div>
        <div className="box">
          <Checkbox
            label="記住我"
            checkedValue="rememberMe"
            onChange={(value) => {
              const isRememberMe = value === 'rememberMe' ? true : false;
              onChangeHandle(isRememberMe, 'rememberMe');
            }}
            checked={rememberMe}
          />
        </div>
        <div className="btnBox">
          <Button btnSize="large" loading={isLoading} onClick={submitHandler}>
            登入
          </Button>
          {isCompany && (
            <Button btnSize="large" loading={isLoading} buttonColor="ui03" textColor="#242C3F" onClick={cloudLogin}>
              教育雲登入
            </Button>
          )}
        </div>
        <UiLoginFormFooter>
          <NavLink to={`/${FORGETPASSWORD}`}>忘記密碼</NavLink>
          <span>|</span>
          <a href="https://oneclub.oneclass.com.tw/signup/" target="_blank" rel="noreferrer">
            註冊帳號
          </a>
        </UiLoginFormFooter>
      </UiLoginForm>

      {/* {
        location.pathname === '/' &&
        <>
          <img
            src={chatLogo}
            alt=""
            style={{
              position: 'fixed',
              bottom: '56px',
              right: '56px',
              zIndex: '999',
            }}
            />
          <img src={chatContext}
            style={{
              position: 'fixed',
              bottom: '50px',
              right: '120px',
              width: '308px',
              height: '105px',
            }}
            alt="" />
        </>
      } */}

      {/* 聯繫我們 */}
      {isCompany && (
        <UiRow>
          <UiContactUs>
            <UiTypography tabIndex={0} variant="h4">
              聯繫我們
            </UiTypography>
            <UiDivider />
            <UiIconList>
              <UiIconRow href="tel:+886-2-82263699">
                <Icon name="phone" title="(02) 8226-3699" haveBg={false} />
                <div>(02) 8226-3699</div>
              </UiIconRow>
              <UiIconRow href="https://lin.ee/FdL4cmD">
                <Icon.Svg name="Line" title="南一客服小幫手Line@" />
                <div>南一客服小幫手Line@</div>
              </UiIconRow>
              <UiIconRow href="https://cdn.nani.cool/download/%E5%8D%97%E4%B8%80%E9%81%A0%E7%AB%AF%E9%80%A3%E7%B7%9A.exe">
                <Icon name="settingRemote" title="遠端連線" haveBg={false} />
                <div>遠端連線</div>
              </UiIconRow>
            </UiIconList>
          </UiContactUs>
        </UiRow>
      )}

      <UiRow tabIndex={0}>
        <p>為了讓您獲得最佳瀏覽體驗，建議您使用的瀏覽器軟體為:IE Edge、Google Chrome或Firefox。</p>
        <p>
          如使用IE瀏覽器瀏覽本網站，可能會發生部分網頁的呈現會有破圖或版面跑版的情況，亦可能導致某些網頁互動功能無法正確顯示與使用。
        </p>
      </UiRow>

      {/* Copyright */}
      {isCompany && <UiRow>Copyright © 2021 NANI</UiRow>}
    </>
  );
};
