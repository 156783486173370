import { QUERY, DEVICE } from 'constants/dimension';
import Book from 'assets/images/defaultBook.png';
import { Button, DashBoardCard, ResourceModal, Loading } from 'components';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { getMySessions } from 'services/api/home/sessions';
import { keepSessionReport } from 'services/api/organization/session';
import { useServerTime } from 'store/serverTime/index';
import { useUser } from 'store/user';
import { getOneBoardUrlForOneLink, getOneBoardUrlForMMS, getOneBoardUrlForMMSObserver } from 'utils/helper';
import { useAlert } from 'utils/hooks/useAlert';
import { useResponsive } from 'utils/hooks/useResponsive';
import { useSetState } from 'utils/hooks/useSetState';
import { UiSessionGroup, UiDashBoardCardContainer, UiExpandTogglerBox } from './SessionGroup.style';

/**
 * 今日課程
 */

/* 取得顯示課程的數量 */
function getDisplaySessionsDataCount(mySessionsApiData, isOpenAllSessions) {
  let displaySessionsDataCount;
  const apiSessionsLength = mySessionsApiData.sessions.length;
  if (isOpenAllSessions) {
    displaySessionsDataCount = apiSessionsLength;
  } else {
    if (apiSessionsLength <= 6) {
      displaySessionsDataCount = apiSessionsLength;
    } else {
      displaySessionsDataCount = 6;
    }
  }
  return displaySessionsDataCount;
}

export const SessionGroup = () => {
  const { setAlert } = useAlert();
  const isTabletS = useResponsive({ query: QUERY.DOWN, start: DEVICE.TABLET.WIDTH_S });
  const [mySessionsApiData, setMySessionsApiData] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [isLoadingExpansionButton, setIsLoadingExpansionButton] = useState(false);
  const [isOpenAllSessions, setIsOpenAllSessions] = useState(false);
  const [{ profile, myAllOrganizations }] = useUser();
  // const [{ timestamp: serverTimestamp }, { getToday }] = useServerTime();
  const [{ timestamp: serverTimestamp, todayInterval }] = useServerTime();

  const [{ isOpenResourceModal, session }, setState] = useSetState({
    isOpenResourceModal: false,
    session: null,
  });

  const hasMoreSessions = mySessionsApiData?.total > 6;

  const sessionsData = useMemo(() => {
    if (!mySessionsApiData || !mySessionsApiData?.sessions) return [];
    const sessions = [];
    const nowTime = serverTimestamp;
    const displaySessionsDataCount = getDisplaySessionsDataCount(mySessionsApiData, isOpenAllSessions);
    for (let i = 0; i < displaySessionsDataCount; i++) {
      const session = mySessionsApiData.sessions[i];
      let isActive = true;
      if (nowTime > session.endAt) isActive = false;
      const startAtText = format(session.startAt, 'HH:mm');
      const endAtText = format(session.endAt, 'HH:mm');
      const sessionInterval = `${startAtText} - ${endAtText}`;
      const organizationNickname = session.userProfile.nickname;
      const course = {
        ...session,
        isActive,
        sessionInterval,
        organizationNickname,
      };
      sessions.push(course);
    }

    return sessions;
  }, [mySessionsApiData, isOpenAllSessions]);

  /* click 課程教材彈窗 - 取消按鈕 */
  const clickResourceModalCancelHandler = () => {
    setState({ isOpenResourceModal: false });
  };

  /* click 課程教材彈窗 - 確認按鈕 */
  const clickResourceModalOkHandler = (resourceValue) => {
    const classRoomUrl = `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${resourceValue}`;
    window.open(classRoomUrl, '_blank'); // 跳轉至 oneBook 教材
    setState({ isOpenResourceModal: false });
  };

  /* 打API，更新課堂報告 */
  const fetchKeepSessionReport = async (id, timeSpanId) => {
    const payload = {
      sessionId: id,
      timeSpanId,
    };
    const { isSuccess } = await keepSessionReport(payload);
    if (!isSuccess) {
      setAlert('取得課堂報表失敗!', 'error');
    }
  };

  /* click dashboard 的今日課程 */
  const goSessionReader = (item) => {
    let url = '';
    const {
      resources,
      id: courseId,
      mmsCourseId,
      mmsCourseType,
      hostId,
      type,
      users,
      timeSpanId,
      organizationId,
      organizationNickname,
    } = item;
    const isCallReportApi = hostId === profile.id || users.some((id) => id === profile.id);
    const enterInteractiveSession = () => {
      const userName = organizationNickname;
      const userId = profile.id;
      const usersLength = users?.length || 0;
      if (mmsCourseId) {
        const isStudent = users.includes(profile.id);
        url = isStudent
          ? getOneBoardUrlForMMS({ mmsCourseId, resources, courseType: mmsCourseType, userName, userId })
          : getOneBoardUrlForMMSObserver({ mmsCourseId, resources, courseType: mmsCourseType, userName, userId });
      } else {
        const userType = hostId === profile.id ? 'teacher' : 'student';
        url = getOneBoardUrlForOneLink({
          courseId,
          sessionsType: type,
          usersLength,
          resources,
          userType,
          userName,
          userId,
          organizationId,
        });
      }
      window.open(url, '_blank');
    };
    if (type === 'interactive' || type === 'video' || type === 'collaborative') {
      // 更新課堂報告
      isCallReportApi && fetchKeepSessionReport(courseId, timeSpanId);
      enterInteractiveSession();
    } else {
      setState({
        isOpenResourceModal: TextTrackCueList,
        session: item,
      });
    }
  };

  const fetchSessions = async ({ rowsPage }) => {
    const todayStartAt = todayInterval.startAt;
    const todayEndAt = todayInterval.endAt;
    // const todayStartAt = new Date(getToday('yyyy/MM/dd')).getTime();
    // const todayEndAt = todayStartAt + 24 * 60 * 60 * 1000 - 1;
    const payload = {
      startAt: todayStartAt,
      endAt: todayEndAt,
      nowPage: 0,
      rowsPage,
    };
    const { status, data } = await getMySessions(payload);
    if (status === 'success') {
      setMySessionsApiData(data);
    } else {
      setAlert('取得今日課程失敗', 'error');
    }
  };

  /* click 展開、收合 按鈕 */
  const toggleExpansion = async () => {
    // 有更多資料 -> 打 API
    const isCurrentSessionsLess = mySessionsApiData.sessions.length <= 6;
    if (isCurrentSessionsLess && hasMoreSessions) {
      setIsLoadingExpansionButton(true);
      await fetchSessions({ rowsPage: 100 });
      setIsLoadingExpansionButton(false);
    }
    setIsOpenAllSessions((prev) => !prev);
  };

  const fetchData = async ({ rowsPage }) => {
    setIsFetching(true);
    await fetchSessions({ rowsPage });
    setIsFetching(false);
  };

  useEffect(() => {
    if (!serverTimestamp || !myAllOrganizations.isLoaded) return;
    if (isTabletS) {
      fetchData({ rowsPage: 6 });
      setIsOpenAllSessions(false);
    } else {
      fetchData({ rowsPage: 100 });
      setIsOpenAllSessions(true);
    }
  }, [serverTimestamp, myAllOrganizations, isTabletS]);

  return (
    <UiSessionGroup>
      {/* 課程教材、一般課程進入教室 彈窗 */
      isOpenResourceModal && (
        <ResourceModal
          session={session}
          isOpenResourceModal={isOpenResourceModal}
          onOk={clickResourceModalOkHandler}
          onCancel={clickResourceModalCancelHandler}
        />
      )}
      {isFetching ? (
        <Loading />
      ) : sessionsData.length > 0 ? (
        <>
          <UiDashBoardCardContainer>
            {sessionsData.map((item, index) => {
              return (
                <DashBoardCard
                  key={index}
                  src={`https://cdn.oneclass.com.tw/uploadoutput/${item.resources[0]}/cover.jpg`}
                  title={item.name}
                  groupName={item.groupName}
                  hostName={item.hostName}
                  isActive={item.isActive}
                  sessionInterval={item.sessionInterval}
                  defaultImg={Book}
                  onClick={() => item.isActive && goSessionReader(item)}
                />
              );
            })}
          </UiDashBoardCardContainer>
          {isTabletS && hasMoreSessions && (
            <UiExpandTogglerBox>
              <Button
                buttonColor="cancel"
                iconSvg={isOpenAllSessions ? 'ChevronUp' : 'ChevronDown'}
                loading={isLoadingExpansionButton}
                onClick={toggleExpansion}
              >
                {isOpenAllSessions ? '收合全部' : '展開全部'}
              </Button>
            </UiExpandTogglerBox>
          )}
        </>
      ) : (
        <p tabIndex={0}>今日沒有安排任何課程喔！</p>
      )}
    </UiSessionGroup>
  );
};
