import {
  AppBar as MatAppBar,
  Toolbar as MatToolbar,
  Tabs as MatTabs,
  Tab as MatTab
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';


// import { UiBsHeaderTab } from './BsHeaderTab.style';


/**
 * 在這邊描述這個組件
 */

export const BsHeaderTab = ({
  data,
  activeIndex = 0
}) => {
  return (
    <MatAppBar
      component="div"
      color="primary"
      position="static"
      elevation={0}
    >
      <MatToolbar>
        <MatTabs value={activeIndex} textColor="inherit">
          {
            data.map(tab => (
              <MatTab
                key={tab.label}
                textColor="inherit"
                label={tab.label}
                onClick={tab.onClick}
              />
            )
            )
          }
        </MatTabs>
      </MatToolbar>
    </MatAppBar>
  );
};

BsHeaderTab.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string
    })
  ),
  activeIndex: PropTypes.number
};


