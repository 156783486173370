import { ROLES } from 'constants/index';
import { FORGETPASSWORD, REGISTER, RULE } from 'constants/index';
import React, { useEffect, useMemo } from 'react';
import { Switch, useLocation, useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import { getAuthRoutes } from 'routes/authRoutes';
import RenderRoutes from 'routes/RenderRoutes';
import { PATH } from 'routes/routesConfig/constants';
import { useServerTime } from 'store/serverTime/index';
import { useUser } from 'store/user';
import { deactivationPageValid } from 'utils/validation';
// import { useBitrix } from 'utils/hooks/useBitrix';

const AppRoutes = () => {
  const [{ profile }, { checkLogin, getUserProfile }] = useUser();
  const [{ timestamp: serverTimestamp }] = useServerTime();

  const forgetPassword = Boolean(useRouteMatch(`/${FORGETPASSWORD}`));
  const register = Boolean(useRouteMatch(`/${REGISTER}`));
  const rule = Boolean(useRouteMatch(`/${RULE}`));
  const lineConnected = Boolean(useRouteMatch(PATH.LINE_CONNECTED));
  const qrCode = Boolean(useRouteMatch(PATH.QR_CODE));
  const qrCodeLogin = Boolean(useRouteMatch(PATH.QR_CODE_LOGIN));

  const location = useLocation();
  const history = useHistory();
  // const { showButton, hideButton } = useBitrix();

  const checkUserLoginStatus = async () => {
    if (profile.id) {
      // 如果登入狀態 在登入頁的話就跳轉
      if (location.pathname === PATH.LOGIN) {
        // eslint-disable-next-line max-len
        profile.role === ROLES.ADMIN
          ? (window.location.href = `https://onelink-admin${
              process.env.REACT_APP_ENV === 'release' ? '' : '-' + process.env.REACT_APP_ENV
            }.oneclass.com.tw/admin`)
          : history.push('/home');
      }
    } else {
      // 檢查是否有token
      const isLogin = await checkLogin();

      // 登入中
      if (isLogin) {
        getUserProfile();
      } else {
        // 沒登入狀態 導回登入頁
        if (location.pathname !== PATH.LOGIN) {
          if (forgetPassword || register || rule || lineConnected || qrCode || qrCodeLogin) {
            return;
          } else {
            history.push(PATH.LOGIN);
          }
        }
      }
    }
  };

  const routes = getAuthRoutes(profile.role);
  const activationRoutes = useMemo(() => {
    // 過濾停用的頁面
    const filteredRoutes = routes.filter((route) => {
      const isDeactivate = deactivationPageValid({ item: route, componentName: 'AppRoutes', nowAt: serverTimestamp });
      return !isDeactivate;
    });
    return filteredRoutes;
  }, [routes, serverTimestamp]);

  // useEffect(() => {
  //   if (['/home'].includes(location.pathname)) {
  //     showButton();
  //   } else {
  //     hideButton();
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    checkUserLoginStatus();
  }, [location, profile]);

  return (
    <Switch>
      <RenderRoutes routes={activationRoutes} />
    </Switch>
  );
};

export default AppRoutes;
