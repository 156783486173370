import { stringify } from 'query-string';
import { Api } from 'services/api';

export const getAttendanceRecord = async (organizationId, classId, params) => {
  const querystring = stringify(params, { skipEmptyString: true });
  const response = await Api.get(`/organizations/${organizationId}/class/${classId}/punchRecord?${querystring}`);
  return response;
};

export const getAttendanceRecordByStudent = async (organizationId, params) => {
  const querystring = stringify(params);
  const response = await Api.get(`/organizations/${organizationId}/userPunchRecord?${querystring}`);
  return response;
};

export const newRecordIn = async (organizationId, params) => {
  const response = await Api.post(`/organizations/${organizationId}/punchIn`, params);
  return response;
};

export const newReviseRecordIn = async (organizationId, classId, params) => {
  const response = await Api.post(`/organizations/${organizationId}/class/${classId}/addPunchRecord`, params);
  return response;
};

export const leaveRecordIn = async (organizationId, classId, leaveDate, params) => {
  const response = await Api.put(`/organizations/${organizationId}/class/${classId}/punchLeave/${leaveDate}`, params);
  return response;
};
