import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const UiDateTimeBox = styled(Box)(() => ({
  fontFamily: 'Noto Sans TC',
  '& > .date': {
    fontSize: '14px',
    fontWeight: 700,
  },
}));

export const UiActionsBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));
