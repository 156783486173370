import { TabContext } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import { UiTabList, UiTab } from './HomeSessionTabs.style';

export const HomeSessionTabs = ({ tab, onChange }) => {
  return (
    <TabContext value={tab}>
      <UiTabList onChange={onChange}>
        <UiTab label="即將開始" value="normal" />
        <UiTab label="已結束" value="done" />
        <UiTab label="已取消" value="canceled" />
      </UiTabList>
    </TabContext>
  );
};

HomeSessionTabs.propTypes = {
  tab: PropTypes.number,
  onChange: PropTypes.func,
};
