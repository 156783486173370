import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js';
import { Icon } from 'components';
import {
  Card,
  CardHeader,
  CardBody,
  CardIcon,
  CardFooter
} from 'components/material-dashboard-pro-react';
import PropTypes from 'prop-types';
import React from 'react';
import { isExist } from 'utils/helper';
import {
  UiCardTitle,
  UiStatsLabel,
  UiStats
} from './SummaryCard.style';



const useStyles = makeStyles(styles);

/**
 * 報表用 卡牌組件
 */

export const SummaryCard = ({
  iconName = '',
  label = '',
  value = '',
  iconBgColor = 'primary',
  statsLabel = '',
  statsValue = '',
  children,
  ...other
}) => {
  const classes = useStyles();
  return (
    <Card {...other}>
      <CardHeader stats icon>
        <CardIcon color={iconBgColor}>
          {
            iconName && <Icon name={iconName} haveBg={false} />
          }
        </CardIcon>
        <div className={classes.cardCategory}>{label}</div>
        {
          isExist(value) && (
            <UiCardTitle className={classes.cardTitle}>
              {value}
            </UiCardTitle>
          )
        }
      </CardHeader>
      {
        children && (
          <CardBody>
            {children}
          </CardBody>
        )
      }
      <CardFooter stats>
        <UiStats className={classes.stats}>
          {
            statsLabel && (
              <UiStatsLabel>
                {statsLabel}
              </UiStatsLabel>
            )
          }
          <div>
            {statsValue}
          </div>
        </UiStats>
      </CardFooter>
    </Card>
  );
};

SummaryCard.propTypes = {
  iconName: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  iconBgColor: PropTypes.string,
  statsLabel: PropTypes.string,
  statsValue: PropTypes.string,
  children: PropTypes.node
};


