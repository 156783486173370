import styled from 'styled-components';

export const UiSingleLayout = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
`;

export const UiSingleContent = styled.div`
  width: 100%;
  height: 100vh;
  ${({ isTabletS, showLeftSidebar }) =>
    isTabletS
      ? {
          display: 'block',
          /* 'grid-template-areas': `
						'header header'
						'leftSide leftSide'
						'main main'`,
          'grid-template-rows': 'auto auto 1fr', */
          '.header': {
            position: 'sticky',
            top: 0,
            zIndex: 999,
          },
        }
      : {
          display: 'grid',
          overflow: 'hidden',
          'grid-template-areas': `
						'header header'
						'leftSide main'`,
          'grid-template-rows': 'auto 1fr',
          'grid-template-columns': showLeftSidebar ? 'auto 1fr' : '0fr',

          '.header': {
            'grid-area': 'header',
          },
        }}
  > .leftSidebar {
    display: flex;
    overflow: ${({ isTabletS }) => (isTabletS ? 'unset' : 'hidden')};
    padding: ${({ isTabletL, isTabletS }) =>
      isTabletS ? '24px' : isTabletL ? '24px 12px 24px 24px' : '30px 48px'};
    background: #fafafc;
    flex-direction: column;
    grid-area: leftSide;
    min-width: 375px;
    max-width: ${({ isTabletS }) => (isTabletS ? '100%' : '504px')};

    > .logo {
      font-size: 0;
      margin-bottom: 30px;

      > a {
        object-fit: contain;
        cursor: pointer;

        > img {
          height: 40px;
        }
      }
    }

    > .greetingsBox {
      padding-right: ${({ isTabletS }) => !isTabletS && '17px'};

      > .greetings {
        padding: 14px 0;
        font-size: 32px;
        font-weight: 700;
        color: #242c3f;
        line-height: 36px;
      }

      > .dashSkeleton {
        margin: 16px 0;
      }

      > .main {
        display: flex;
        padding: 20px 0;
        flex-direction: column;
        flex: 1;

        > .subTitle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 2rem;

          > h3 {
            font-size: 20px;
            font-weight: 700;
            color: #454b5c;
            line-height: 32px;
          }

          > a {
            font-size: 16px;
            text-decoration: none;
            color: #8b90a0;
            line-height: 24px;
          }
        }

        > .wtfIssue {
          overflow: hidden;
          margin: auto;
          width: 90%;
          font-size: 0;
          border-radius: 12px;
          cursor: pointer;

          > img {
            width: 100%;
            border-radius: 12px;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }

    > .sessionGroupBox {
      ${({ isTabletS }) =>
        isTabletS
          ? {
              display: 'block',
            }
          : {
              'max-height': '410px',
              'padding-right': '8px',
              'overflow-x': 'hidden',
              'overflow-y': 'scroll',

              '::-webkit-scrollbar': {
                'background-color': '#fafafc',
                width: '8px',
              },
              '::-webkit-scrollbar-thumb': {
                'border-radius': '20px',
                'background-color': '#d9d9d9',
              },
            }}
    }
  }

  > .main {
    overflow-x: ${({ isTabletS }) => (isTabletS ? 'unset' : 'hidden')};
    height: ${({ isTabletS }) => (isTabletS ? 'unset' : '100%')};
    padding: 14px 18px 30px;
    grid-area: main;
    background-color: #fff;
  }
`;

// export const UiSingleContent = styled.div`
// 	flex: 1;
// 	margin: auto;
// 	width: 95%;
// 	font-family: ${({theme})=>theme.typography.fontFamily};
// 	${({ theme: { media } }) => media.desktop`
// 		width:${({ theme }) => theme.mediaWidth.desktop}
// 		`}
// 	${({ theme: { media } }) => media.notebook`
// 		width:${({ theme }) => theme.mediaWidth.notebook}
// 		`}
// 	${({ theme: { media } }) => media.pad`
// 		width:${({ theme }) => theme.mediaWidth.pad}
// 		`}
// 	${({ theme: { media } }) => media.phone`
// 		width:${({ theme }) => theme.mediaWidth.phone}
// 		`}
// `;
