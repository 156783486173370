import { TextField as MatTextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSetState } from 'utils/hooks/useSetState';
import { UiTraceTextForm, UiMatInputAdornment } from './TraceTextForm.style';

/**
 * TraceTextForm
 */



export const TraceTextForm = ({
  value,
  maxTextNum = 99,
  label,
  helperText,
  error,
  multiline = false,
  rows = 1,
  onChange = () => { },
}) => {
  const [{ textCount, maxTextCount, prevStr }, setState] = useSetState({
    textCount: 0,
    maxTextCount: maxTextNum,
    prevStr: value
  });
  const inputProps = {
    value: prevStr,
    endAdornment: <UiMatInputAdornment position="start" >{textCount}/{maxTextCount}</UiMatInputAdornment>
  };

  const traceText = (text) => {
    let textLength = text ? text.length : 0;
    if (textLength <= maxTextCount) setState({ prevStr: text, textCount: textLength });
  };
  useEffect(() => {
    traceText(prevStr);
    onChange(prevStr);
  }, [prevStr]);


  return (
    <UiTraceTextForm>
      <MatTextField
        multiline={multiline}
        label={label}
        rows={rows}
        fullWidth={true}
        InputProps={inputProps}
        helperText={helperText}
        error={error}
        onChange={(event) => { traceText(event.target.value); }}
      />
    </UiTraceTextForm>
  );
};

TraceTextForm.propTypes = {
  maxTextNum: PropTypes.number,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  error: PropTypes.bool,
  helperText: PropTypes.string
};


