import { HomeGroupLayout, HomeworkList } from 'components';
import React from 'react';


/**
 * 在這邊描述這個組件
 */

export const HomeWorkPage = () => {
  return (
    <HomeGroupLayout>
      <HomeworkList />
    </HomeGroupLayout>
  );
};

HomeWorkPage.propTypes = {

};


