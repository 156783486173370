import styled from 'styled-components';

export const UiHeader = styled.div`
width: 100%;
background-color: #fff;
box-shadow: inset 0 -1px 0 #f0f0f0;

> .container {
	display: flex;
	align-items: center;
	/* justify-content: ${({ isNeedLogo }) => (isNeedLogo ? 'flex-end' : 'space-between')}; */
	justify-content: space-between;
	margin: auto;
	padding: ${'14px 32px 6px'};
	padding-top: 0;
	width: 100%;
	max-width: ${'100%'};

	> .logo {
		/* flex: 1; */
		display: flex;
		align-items: center;
		> a {
			font-size: 0;
		}

		nav {
			margin-left: 50px;
		}

		/* > .tag {
				margin-left: 50px;
				padding: 4px 12px;
				font-size: 14px;
				color: #242c3f;
				background: #f5f5f5;
				border-radius: 4px;
				box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
				line-height: 24px;

				&.black {
					color: #fff;
					background: #121232;
				}

				& + .tag {
					margin-left: 8px;
				}
			} */
	}
}

.breadcrumbs {
	flex: 0.9;
}

.breadcrumbItem {
	display: flex;
	align-items: center;

	> svg {
		margin-right: 10px;
	}
}
`;

export const UiSidebarTogglerAndLogoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;

export const UiHeaderText = styled.div`
  display: flex;
  align-items: center;
  color: #454b5c;

  & svg {
    margin-right: 10px;
  }
`;

export const UiOrganizationText = styled.div`
  overflow: hidden;
  max-width: ${({ isTabletL }) => (isTabletL ? '112px' : 'unset')};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UiClassName = styled.div`
  font-weight: 700;
  overflow: hidden;
  max-width: ${({ isTabletL }) => (isTabletL ? '112px' : 'unset')};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UiNotice = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 2rem;
  background-color: #fff;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  > svg {
    font-size: 1.75rem;

    path {
      fill: #8b90a0;
    }
  }
`;
export const UiNoticeNew = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  background-color: #ec7963;
  border-radius: 50%;
`;

export const UiIsLogin = styled.div`
  display: flex;
  align-items: center;
`;

export const UiLogo = styled.div`
  height: 40px;
  object-fit: cover;
`;
