import { HomeGroupLayout, HomeOnlineExamTable } from 'components';
import React from 'react';

/**
 * 線上測驗列表
 */

export const HomeExamPage = () => {
  return (
    <HomeGroupLayout>
      <HomeOnlineExamTable />
    </HomeGroupLayout>
  );
};
