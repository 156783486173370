import { useLocation, useParams } from 'react-router-dom';

export const usePage = () => {
  const { organizationId, classId } = useParams();
  const location = useLocation();
  const { pathname } = location;
  let pagePath = {
    login: '/',
    home: '/home',
    memberCenter: {
      profile: '/home/membercenter/profile',
      changePassword: '/home/memberCenter/changePassword#center_container',
    },
  };
  let pageStatus = {
    isLoginPage: pathname === pagePath.login,
    isHomePage: pathname === pagePath.home,
    isProfilePage: pathname === pagePath.memberCenter.profile,
    isChangePasswordPage: pathname === pagePath.memberCenter.changePassword,
  };

  // 班級頁面內
  if (organizationId && classId) {
    // 頁面路徑
    const classroomPath = {
      session: `/home/${organizationId}/class/${classId}/sessions`,
    };
    pagePath = {
      ...pagePath,
      classroom: {
        ...classroomPath,
      },
    };
    // 頁面狀態
    const classroomStatus = {
      session: pathname === classroomPath.session,
    };
    pageStatus = {
      ...pageStatus,
      classroom: {
        ...classroomStatus,
      },
    };
  }
  // 機構頁面內
  else if (organizationId) {
    // 頁面路徑
    const organizationPath = {
      announcement: `/home/${organizationId}/announcement`,
    };
    pagePath = {
      ...pagePath,
      organization: {
        ...organizationPath,
      },
    };
    // 頁面狀態
    const organizationStatus = {
      announcement: pathname === organizationPath.announcement,
    };
    pageStatus = {
      ...pageStatus,
      organization: {
        ...organizationStatus,
      },
    };
  }

  return {
    pagePath,
    pageStatus,
  };
};
