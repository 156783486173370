import { useSelector, useDispatch } from 'react-redux';
import {
  getAttendanceRecordByStudent,
  getAttendanceRecord,
  newRecordIn,
  newReviseRecordIn,
  leaveRecordIn
} from 'services/api/attendance';

const GET_RECORD = 'GET_RECORD';
const GET_TOTAL = 'GET_TOTAL';

const initState = {
  recordList: [],
  totalRecord: 0,
};

const actions = {
  getRecord: (data) => ({
    type: GET_RECORD,
    payload: data,
  }),
  getTotal: (data) => ({
    type: GET_TOTAL,
    payload: data
  }),
};

export const useAttendance = () => {
  const dispatch = useDispatch();
  const recordList = useSelector((state) => state.attendance.recordList);
  const totalRecord = useSelector((state) => state.attendance.totalRecord);
  const recordInData = useSelector((state) => state.attendance.recordInData);

  const searchRecordStudent = async (organizationId, params) => {
    const { data, status } = await getAttendanceRecordByStudent(organizationId, params);
    if (status === 'success') {
      dispatch(actions.getRecord(data.records));
      dispatch(actions.getTotal(data.total));
    }
  };

  const searchRecord = async (organizationId, classId, params) => {
    const { status, data } = await getAttendanceRecord(organizationId, classId, params);
    if (status === 'success') {
      dispatch(actions.getRecord(data.records));
      dispatch(actions.getTotal(data.total));
    }
  };

  const checkIn = async (organizationId, params) => {
    const { status, data } = await newRecordIn(organizationId, params);
    return { status, data };
  };

  const reviseCheck = async (organizationId, classId, params) => {
    const { status } = await newReviseRecordIn(organizationId, classId, params);
    return status === 'success';
  };

  const leaveDate = async (organizationId, classId, leaveDate, params) => {
    const { status } = await leaveRecordIn(organizationId, classId, leaveDate, params);
    return status === 'success';
  };

  return [{
    recordList,
    totalRecord,
    recordInData
  }, {
    searchRecordStudent,
    searchRecord,
    checkIn,
    reviseCheck,
    leaveDate
  }];
};

const reducer = (state = initState, actions) => {
  switch (actions.type) {
    case GET_RECORD: {
      return {
        ...state,
        recordList: actions.payload
      };
    }

    case GET_TOTAL: {
      return {
        ...state,
        totalRecord: actions.payload
      };
    }

    default:
      return state;
  }
};


export default reducer;