import {
	AppBar as MatAppBar,
} from '@material-ui/core';
import styled from 'styled-components';


export const UiAppBar = styled(MatAppBar)`
	background-color: ${({ theme }) => theme.palette.backstage.block.bar};
`;

export const UiBsHeader = styled.div`
	display: block;
`;
