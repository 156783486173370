import styled from 'styled-components';

export const UiCodeInput = styled.div`
	display: block;

	> .text {
		display: inline-block;
		padding: 0 8px;
	}

	> input {
		padding: 10px;
		width: 48px;
		height: 48px;
		font-size: 24px;
		text-align: center;
		color: #242c3f;
		background: #f2f4f8;
		border: ${({ isError }) => isError ? '1px solid #f00' : '1px solid transparent'};
		border-radius: 8px;
	}
`;

