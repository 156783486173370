import styled from 'styled-components';

export const UiTitle = styled.div`
	width: 100%;
	font-size: 20px;
	color: #242c3f;
	margin-bottom: 1.5rem;
`;

export const UiHint = styled.p`
	margin-top: -13px;
	font-size: 16px;
	color: #8B90A0;
`;

