import styled from 'styled-components';

export const UiModalContent = styled.div`
    width: 600px;
    background-color: #fff;
    border-radius: 12px;
    padding: 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const UiClose = styled.div`
    position: absolute;
    right: 24px;
`;

export const UiDetail = styled.div`
    padding: 24px 36px;
`;

export const UiHeader = styled.div`
    margin-bottom: 20px;
`;

export const UiTitle = styled.div`
  color: #454B5C;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 12px;
`;

export const UiCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UiCategoryColor = styled.div`
  height: 10px;
  width: 10px;
  background: ${({ background }) => background};
  border-radius: 50%;
`;

export const UiCategoryName = styled.div`
  color: #212B36;
  font-size: 1rem;
`;

export const UiBody = styled.div`
`;

export const UiBodyRow = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 14px;
`;

export const UiBodyCol = styled.div`
  font-size: 1.06rem;
  font-weight: ${({ fontWeight }) => fontWeight};
  white-space: ${({ whiteSpace }) => whiteSpace};
`;